import * as React from 'react';
import DataGrid from '../../../shared/dataGrid/component';
import { RESOURCE_TYPE } from '../../../types/purchaseOrder';
import {
  Dropdown,
  Icon,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Selection,
  Stack,
} from '@fluentui/react';
import { ActionButtonState } from '../../../types/corporateProductCatalogue';
import i18n from '../../../../i18n';
import {
  readCbcContractor,
  readCbcToPbs,
  removeCbcToPbs,
} from '../../../reducers/projectBreakdownReducer';
import _ from 'lodash';
import CbcModal from './CbcModal';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import { store } from '../../../../index';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const classNames = mergeStyleSets({
  halfWidth: { width: '50%' },
  borderNone: { border: 'none !important'},
  subHeaderStyle: { fontSize: '14px !important' }
});
const CbcGrid = (props: {
  readOnly: boolean;
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (projectBreakdown: ProjectBreakdown, save: boolean) => void;
}) => {
  const [selectionDetails, setSelectionDetails] =
    React.useState<any>(null);
  const [contractor, setContractor]: any = React.useState<any>(null);
  const [isOpenModal, setIsOpenModal]: any = React.useState<any>(false);
  const [cbcLoading, setCbcLoading]: any = React.useState<any>(false);
  const dispatch = useDispatch();

  const { pbsCbcList, reFetchCbc, isLoadingCbc }: any = useSelector(
    (state: any) => state.projectBreakdown
  );

  const cbcContractors = store.getState().projectBreakdown.cbcContractor
    ? store.getState().projectBreakdown.cbcContractor
    : [];

  useEffect(() => {
    dispatch(readCbcContractor());
  }, []);

  useEffect(() => {
    if (reFetchCbc) {
      getPbsCbsList();
    }
  }, [reFetchCbc]);

  useEffect(() => {
    if (props?.projectBreakdown?.id) {
      getPbsCbsList();
    } else {
      dispatch(readCbcToPbs(props?.projectBreakdown?.id));
    }
  }, [props?.projectBreakdown?.id]);

  useEffect(() => {
    // const itemNotShowcased = pbsCbcList?.some((item: any) => item.isLotInfoShowcased === false)
    // const checkPbsLotId = pbsCbcList?.some((item: any) => item.lotId === props.projectBreakdown.pbsLotId)
    if (props.projectBreakdown?.pbsLotId) {
      setContractor({
          key: props.projectBreakdown?.pbsLotId,
          text: props.projectBreakdown?.pbsLotId
      })
    }
    // if (!itemNotShowcased) {
    //   setContractor({
    //     key: pbsCbcList?.[0]?.lotId,
    //     text: pbsCbcList?.[0]?.lotId
    //   })
    // } else {
    //   setContractor(null)
    // }
  }, [props.projectBreakdown?.pbsLotId])
  
  const openCbcModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleCbcDelete = () => {
    let ids = selectionDetails?.map((item: any) => {
      return item?.id;
    });
    dispatch(removeCbcToPbs(ids));
    setCbcLoading(true);
    // setTimeout(() => {
      // getPbsCbsList();
    // }, 4000);
  };

  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      setSelectionDetails(_gridSelection.getSelection()); 
    },
  });

  const getPbsCbsList = () => {
    dispatch(readCbcToPbs(props?.projectBreakdown?.id));
    setCbcLoading(false);
  };

  const cbcActionButtonState = () => {
    const selectionCount = selectionDetails?.length;
    let disabled: ActionButtonState;
    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  };

  const cbcColumns = [
    {
      key: 'column1',
      name: i18n.t('cbc'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: i18n.t('originalQuantity'),
      // fieldName: 'cbcQuantity',
      fieldName: 'originalQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: i18n.t('plannedQuantity'),
      fieldName: 'quantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: i18n.t('consumedQuantity'),
      fieldName: 'consumedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: i18n.t('invoiceQuantity'),
      fieldName: 'invoicedQuantity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column6',
      name: i18n.t('unit'),
      fieldName: 'unit',
      minWidth: 60,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column7',
      name: i18n.t('comments'),
      fieldName: 'comments',
      minWidth: 140,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const onSaveSuccess = () => {
    setCbcLoading(true);
    // setTimeout(() => {
      getPbsCbsList();
    // }, 4000);
  };
  
  const sortByProperty = (dataArray: [], propertyName: string) => {
    if (dataArray.length > 0) {
      return dataArray.sort((a, b) => {
        const propA = a[propertyName];
        const propB = b[propertyName];

        // You can add more complex comparison logic here if needed
        if (propA < propB) {
          return -1;
        }
        if (propA > propB) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <div className={'document-pane-card'}>
        <div className={'card-header'}>
          <Link href="#" id="pbs-cbs">
            <Label>5. {i18n.t('cbc') as string}</Label>
          </Link>
        </div>
        <div className={'card-body'}>
        <Link className={classNames.borderNone} href='#' id='pbs-cbs-5-1'>
          <Label className={classNames.subHeaderStyle} style={{ marginTop: 10 }}>{'5.1 ' + i18n.t('contractor')}</Label>
        </Link>          
          {/* <Dropdown 
            options={[]} 
            style={{width: '50%', marginTop: '8px'}}
          /> */}
          
          <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
            {/* {contractor !==null && contractor !=="" && contractor !==undefined ? */}
            <Stack.Item grow={6} styles={stackItemStyles}>
            <div className={`${classNames.halfWidth}`}>
              <ClearableDropdown
                required={false}
                placeholder={i18n.t('contractor')}
                label={''}
                options={cbcContractors}
                onChange={(event, value) => {
                  if (value) {
                    setContractor(value);
                    props.projectBreakdown.pbsLotId = value?.key
                    props.handleFormChange(
                      props.projectBreakdown,
                      true
                    );
                  }
                }}
                onRenderCaretDown={() => {
                  return <>
                    <Icon
                      style={{ fontSize: 12 }}
                      iconName={'Clear'}
                      onClick={() => {
                        setContractor(null);
                        props.projectBreakdown.pbsLotId = null
                        props.handleFormChange(
                          props.projectBreakdown,
                          true
                        );
                      }}
                    />
                  </>
                }}
                selectedKey={contractor ? contractor.key : ''}
                disabled={props.readOnly}
              />
            </div>
          </Stack.Item> 
          {/* :''} */}
            
            <Stack.Item grow={6} styles={stackItemStyles}>
            </Stack.Item>
          </Stack>
          <DataGrid
            dataList={pbsCbcList ? sortByProperty(pbsCbcList, 'articleNo') : []}
            editDataGrid={() => openCbcModal()}
            openModel={() => {
              openCbcModal();
            }}
            selection={_gridSelection}
            actionButtonState={cbcActionButtonState()}
            title={i18n.t('cbc')}
            // title={'4.2 ' + i18n.t('cbc')}
            deleteDataGrid={() => {
              handleCbcDelete();
            }}
            readOnly={props.readOnly}
            columns={cbcColumns}
            isLoaded={cbcLoading || isLoadingCbc}
            type={RESOURCE_TYPE.CBC}
            isClickable={true}
          />
        </div>
      </div>
      <CbcModal
        formData={selectionDetails ? selectionDetails[0] : {}}
        isOpen={isOpenModal}
        contractor={contractor}
        setContractor={setContractor}
        openModal={openCbcModal}
        projectBreakdown={props?.projectBreakdown}
        onSaveSuccess={onSaveSuccess}
        pbsCbcList={pbsCbcList ? pbsCbcList : []}
      />
    </>
  );
};

export default CbcGrid;
