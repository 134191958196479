import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DocumentPaneComponent } from './component';
import {
  ActionButtonState,
  SelectedNickNameItem,
} from '../../../types/corporateProductCatalogue';
import { Selection } from '@fluentui/react/lib/DetailsList';
import UprinceLogger from '../../../shared/Logger/logger';
import {
  savePbsInstruction,
  getRiskDropDownsList,
  createRisk,
  deleteRisk,
  createQuality,
  deleteQuality,
  readMaterialsData,
  savePbsRisk,
  resetRiskGridList,
  savePbsQuality,
  readLabourData,
  readToolsData,
  readConsumableData,
  readInstructionDropDown,
  uploadInstructionDoc,
  readTaxonomyData,
  createTechInstruction,
  filterCompetenceList,
  readCompetenceDropDown,
  createCompetence,
  deleteCompetence,
  deleteTechInstruction,
  createSaftyInstruction,
  deleteSaftyInstruction,
  createEnvInstruction,
  deleteEnvInstruction,
  createHelthInstruction,
  deleteHelthInstruction,
  clearFormData as clearPBSData,
  getById as getPBSData,
} from '../../../reducers/projectBreakdownReducer';
import {
  getProjectHeaderDetails,
  getUserRoles,
} from '../../../reducers/projectReducer';
import { withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import _, { isArray } from 'lodash';
import client from '../../../api';
import i18n from '../../../../i18n';
import RouteLeavingGuard from '../../../shared/routeLeavingGuard/routeLeavingGuard';
import history from '../../../history';
import RiskModal from '../risk/modal/component';
import InstructionModal from '../instructions/modal/component';
import { IPersonaProps } from '@fluentui/react';
import {
  Risk,
  ReadRisk,
  Quality,
  PbsRisk,
  PbsQuality,
  Instruction,
  InstructionLink,
  Competence,
  PbsInstruction,
} from '../../../types/projectBreakdownStructure';
import QualityModal from '../quality/modal/component';
import ResourceModal from '../resources/material/modal/component';
import { messageService } from '../../../services/messageService';

import LinkModal from '../instructions/link/component';
import {
  formatCpcResponse,
  formatPersonResponse,
  getProject, getTimeZone, isPlanBoards, isPlanning, MSG_AUTO_CLOSE_TIME,
} from '../../../shared/util';
import CompetenceModal from '../competency/modal/component';
//import MapModal from '../map/component';
//import { getById as getBorById} from '../../../reducers/billOfResourcesReducer';
//import { BillOfResource } from '../../../types/billOfResources';
import MapModal from '../../cuProjects/mapModal/component';
import {
  pmolFormData,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
  Resource,
  JournalImage,
  Journal,
  ExtraWork,
  BorFormData,
  LabourTeamMember, APPROVED_STATUS_ID, PMOLListPaneFilter, PMOL_TYPE, PMOLService, HANDED_OVER_STATUS_ID,
} from '../../../types/projectMolecule';
import {
  saveFormData,
  readResourcesByBorId,
  readLocationsByProjectId,
  createPMOLLocation,
  startHandshake,
  readPmolMaterialsData,
  readPmolToolsData,
  readPmolConsumableData,
  readPmolLaboursData,
  createMaterials,
  createTools,
  createConsumable,
  createLabour,
  uploadSignature,
  stopHandshake,
  readStopHandshakes,
  deleteMaterials,
  deleteTools,
  deleteLabours,
  deleteConsumable,
  clearFormData as clearPmolData,
  saveJournal,
  getPmolJournal,
  saveHandshakeDocuments,
  readPmolExtraWorkData,
  readPMOLTechInstruction,
  readPMOLSaftyInstruction,
  readPMOLEnvInstruction,
  readPMOLHelthInstruction,
  saveExtraWorkData,
  readTeamMembers,
  deleteTeamMembers,
  readPMOLLocationById,
  readRiskList,
  readQualityList, approvedPmol, readByPMOLId, readPmolData, readPmolPlannedWorkResources,showProgressbarPmol,setPlannedWorkFetched,
  readPMOLAllInstruction, readPMOLByID, createPMOLService, readPBSService, finishedPmol, savePMOLStateAttr,
} from '../../../reducers/pmolReducer';
import {
  clearCpcData,
  getById as getBorById,
  resetFormData as resetBorFormData,
  getCpcById,
  readDropdownData as readBORStatusDropDowns,
  updateBORStatus,
} from '../../../reducers/billOfResourcesReducer';
import {
  BillOfResource,
  BorResources,
  BorResourcesItem,
  BORStatusUpdateRequest,
  ResourceTypes,
} from '../../../types/billOfResources';
import { LocationAddress, Position } from '../../../types/projectDefinition';
import SignatureModal, { Signature } from '../handshake/modal/component';
import { CPC_PMOL_FILTER_EP } from '../../../shared/endpoints';
import LabourModal from '../resources/labour/modal/component';
import EmployeeModal from '../resources/labour/modal/employeeModal';
import axios, { CancelTokenSource } from 'axios';
import { setTimeout } from 'timers';
import PmolPDFExport from '../pdfExport/pmolPDFExport'
import EmployeeShiftModal from '../resources/labour/modal/employeeShiftModal';
import { getAllTimeClocks, getTimeClockById } from '../../../reducers/timeClockReducer';
import moment from 'moment';
import { v4 as uuid4 } from 'uuid';
import CustomAlert from "../../../shared/alert/customAlert";
import EmployeeEditEndTimeModal from '../resources/labour/modal/employeeEditEndTimeModal';
import TRMapModal from '../trMapModal/component';
export interface State {
  ////PMOL////
  isOpenMap: boolean;
  formData: ProjectMolecule;
  borResources: BorResources;
  borResourcesItem: BorResourcesItem;
  selectedResourceTypeId: string | null;
  selectedCpcEnvironment: string | null;
  isSignatureModalOpen: boolean;
  signatureLink: string;
  handshakes: Signature[];
  handshakeDocs: any[];
  extraWork: ExtraWork;
  teamMembers: LabourTeamMember[];
  teamMember: LabourTeamMember | null;
  teamMemberIsEdit: boolean;
  /////
  isDirty: boolean;
  redirect: boolean;
  loading: boolean;
  uploadedImages: string | null;
  resourceFamily: any;
  qualitySelectionDetails: {} | string;
  qualityActionButtonState: ActionButtonState;

  filter: PMOLListPaneFilter;
  riskSelectionDetails: {} | string;
  riskActionButtonState: ActionButtonState;
  filesToUpLoad: File | null;

  quality: Quality;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  isQualityEdit: boolean;

  qualityDeleteItems: [] | string[];
  riskDeleteItems: [] | string[];
  validationMessages: ProjectMoleculeValidationMessage;
  resourceFamilySearchString: string;
  submitCpc: boolean;

  isRiskEdit: boolean;
  isRiskModalVisible: boolean;
  isQualityModalVisible: boolean;
  isNewRisk: boolean;
  risk: ReadRisk;
  riskList: ReadRisk[];
  confimRiskDelete: boolean;
  qualityList: Quality[];
  materialList: Resource[];
  isMaterialModalVisible: boolean;
  materialSelectionDetails: {} | string;
  materialActionButtonState: ActionButtonState;
  material: Resource;
  isMaterialEdit: boolean;
  materialDeleteItems: [] | string[];
  tools: Resource[];
  isToolsModalVisible: boolean;
  toolsSelectionDetails: {} | string;
  toolsActionButtonState: ActionButtonState;
  tool: Resource;
  readToolsStatus: boolean;
  toolsDeleteItems: [] | string[];
  isToolEdit: boolean;
  labours: Resource[];
  isLabourModalVisible: boolean;
  isEmployeeModalVisible: boolean;
  isEmployeeShiftModalVisible: boolean;
  isEmployeeEditEndTimeModalVisible: boolean;
  labourSelectionDetails: {} | string;
  labourActionButtonState: ActionButtonState;
  labour: Resource;
  readLabourStatus: boolean;
  labourDeleteItems: [] | string[];
  isLabourEdit: boolean;
  isConsumablesModalVisible: boolean;
  consumableSelectionDetails: {} | string;
  consumableActionButtonState: ActionButtonState;
  consumable: Resource;
  readConsumableStatus: boolean;
  consumableDeleteItems: [] | string[];
  isConsumableEdit: boolean;
  consumables: Resource[];
  techInstructions: Instruction[];
  readTechInstructionStatus: boolean;
  techInstruction: any;
  //linkActionButtonState: ActionButtonState;
  isOpenTechInstructionModal: boolean;
  //instructionLink: InstructionLink;
  isOpenTechLinkModal: boolean;
  uploadedTechInstructionLink: string;
  //techInstructionLinks: InstructionLink[];
  techLinksSelectionDetails: {} | string;
  techLinksActionButtonState: ActionButtonState;
  techLink: InstructionLink;
  isEditTechLinks: boolean;
  techInstructionSelectionDetails: {} | string;
  techInstructionActionButtonState: ActionButtonState;
  isEditTechInstruction: boolean;
  editTechLinkIndex: number;
  competenceList: Competence[];
  competenceSelectionDetails: {} | string;
  competenceActionButtonState: ActionButtonState;
  competence: Competence;
  competenceDeleteItems: [] | string[];
  isCompetenceModalVisible: boolean;
  isCompetenceEdit: boolean;
  isOpenSafetyInstructionModal: boolean;
  isOpenSafetyLinkModal: boolean;
  uploadedSaftyInstructionLink: string;
  saftyInstructionSelectionDetails: {} | string;
  safetyLinksSelectionDetails: {} | string;
  safetyLinksActionButtonState: ActionButtonState;
  safetyLink: InstructionLink;
  isEditSaftyLinks: boolean;
  saftyInstruction: Instruction;
  saftyInstructions: Instruction[];
  isEditSaftyInstruction: boolean;
  saftyInstructionActionButtonState: ActionButtonState;
  editSaftyLinkIndex: number;
  //
  isOpenEnvInstructionModal: boolean;
  isOpenEnvLinkModal: boolean;
  uploadedenvInstructionLink: string;
  envInstructionSelectionDetails: {} | string;
  envLinksSelectionDetails: {} | string;
  envLinksActionButtonState: ActionButtonState;
  envLink: InstructionLink;
  isEditEnvLinks: boolean;
  envInstruction: Instruction;
  envInstructions: Instruction[];
  isEditEnvInstruction: boolean;
  envInstructionActionButtonState: ActionButtonState;
  editEnvLinkIndex: number;
  //
  isOpenHelthInstructionModal: boolean;
  isOpenHelthLinkModal: boolean;
  uploadedhelthInstructionLink: string;
  helthInstructionSelectionDetails: {} | string;
  helthLinksSelectionDetails: {} | string;
  helthLinksActionButtonState: ActionButtonState;
  helthLink: InstructionLink;
  isEditHelthLinks: boolean;
  helthInstruction: Instruction;
  helthInstructions: Instruction[];
  isEditHelthInstruction: boolean;
  helthInstructionActionButtonState: ActionButtonState;
  editHelthLinkIndex: number;
  journal: Journal;
  journalImage: JournalImage;
  //
  position: { lat: number; lon: number };
  isMapOpen: boolean;
  location: any;
  readExtraWork: boolean;
  borStatusDropdowns: { key: string; text: string }[];
  isDisabled:boolean,
  pmolType:PMOL_TYPE;
  service: PMOLService;
  cabPerson:string|null;
  shiftDetails:any;
  showError:boolean,
  errorMsg:string
  isMouValid: boolean;
  selectedInstruction: any;
  loadApproveMsg: string;
}

interface Props extends WithTranslation {
  savePbsInstruction: (instructionData: PbsInstruction) => void;
  readByInstructionRegisterId: (id: string) => void;
  formData: ProjectMolecule;
  getBorById: (id: string) => void;
  borData: BillOfResource;
  pmolDropDowns: any;
  projectHeader: [] | null;
  getProjectHeaderDetails: (id: string) => void;
  readResourcesByBorId: (id: string) => void;
  readPmolMaterialsData: (id: string, token:any) => void;
  readPmolToolsData: (id: string) => void;
  readPmolConsumableData: (id: string) => void;
  readPmolLaboursData: (id: string) => void;
  readPmolPlannedWorkResources: (id: string) => void;
  borResources: BorResources;
  pmolLocation: LocationAddress;
  locationFromPMOL:LocationAddress;
  readLocationsByProjectId: (projectId: string | null) => void;
  createPMOLLocation: (data: any) => void;
  pmolLocationId: string;
  startHandshake: (data: { locationId: string; pmolId: string }) => void;
  getCpcById: (id: string) => void;
  clearCpcData: () => void;
  cpcData: any;
  clearPBSData: () => void;
  uploadSignature: any;
  signatureLink: string;
  stopHandshake: any;
  stopHandshakeStatus: boolean;
  readStopHandshakes: (id: string | null) => void;
  handshakes: any;
  handshakeDocs: any;
  clearPmolData: () => void;
  readHandShakeStatus: boolean;
  saveHandshakeDocuments: (data: {
    pmolId: string;
    docLinks: string[];
  }) => void;
  readPmolExtraWorkData: any;
  extraWork: any;
  saveExtraWorkData: (data: ExtraWork) => any;
  showProgressBar: boolean;

  savePicturesOfTheInstallation: (files: FileList | null) => void;
  uploadedImages: string | null;
  resourceTypes: [];
  resourceFamily: [];
  languageCodes: [];
  cpcBasicUnitOfMeasure: [];
  cpcPressureClass: [];
  cpcMaterial: [];
  cpcBrand: [];
  cpcUnitOfSizeMeasure: [];
  autoSave: boolean;
  isEdit: boolean;
  selectedCpcId: string | null | undefined;
  organizations: [];
  handelGetOrganizationByName: (name: string) => void;
  submitCpc: boolean;
  projectId: string | null;
  locationTaxonomy: [];
  utilityTaxonomy: [];
  toleranceStates: [];
  productStates: [];
  itemTypes: [];
  isNew: boolean;
  uid: string | null;
  saveFormData: (formData: ProjectMolecule) => any;
  readTaxonomyData: () => void;
  getRiskDropDownsList: any;
  createRisk: any;
  readRiskList: any;
  readRiskStatus: boolean;
  riskList: ReadRisk[];
  createRiskStatus: boolean;
  deleteRiskStatus: boolean;
  saveRiskStatus: boolean;
  newRiskId: string | null;
  deleteRisk: any;
  savePbsRisk: (risk: PbsRisk) => void;
  resetRiskGridList: () => void;
  readQualityList: any;
  readQualityStatus: boolean;
  qualityList: Quality[];
  createQuality: any;
  createQualityStatus: any;
  savePbsQuality: (quality: PbsQuality) => void;
  saveQualityStatus: boolean;
  newQualityId: string | null;
  deleteQuality: any;
  deleteQualityStatus: boolean;
  readMaterialsData: (id: string) => void;
  readMaterialsStatus: boolean;
  materialList: Resource[];
  createMaterials: any;
  createMaterialsStatus: boolean;
  readToolsData: (id: string) => void;
  readToolsStatus: boolean;
  tools: Resource[];
  createTools: any;
  createToolsStatus: boolean;
  deleteMaterials: any;
  deleteMaterialsStatus: boolean;
  deleteTools: any;
  deleteToolsStatus: boolean;
  readLabourData: (id: string) => void;
  readLabourStatus: boolean;
  labours: Resource[];
  createLabourStatus: boolean;
  createLabour: any;
  deleteLabours: any;
  deleteLabourStatus: boolean;
  readConsumableStatus: boolean;
  deleteConsumableStatus: boolean;
  createConsumable: any;
  deleteConsumable: any;
  consumables: Resource[];
  readConsumableData: (id: string) => void;
  createConsumableStatus: boolean;
  readInstructionDropDown: any;
  readPMOLTechInstruction: (pbsId: string, type: string) => void;
  uploadInstructionDoc: any;
  instructionUploadStatus: boolean;
  uploadedInstructionLink: string;
  createTechInstruction: any;
  readTechInstructionStatus: boolean;
  techInstructions: Instruction[];
  competenceList: Competence[];
  filterCompetenceList: any;
  pbsSkills: [];
  pbsExperience: [];
  readCompetenceDropDown: any;
  createCompetence: (competence: Competence) => any;
  saveCompetenceStatus: boolean;
  deleteCompetenceStatus: boolean;
  deleteCompetence: (data: any[] | []) => any;
  deleteTechInstruction: any;
  createTechInstructionStatus: boolean;
  deleteTechInstructionStatus: boolean;
  createSaftyInstruction: (instruction: Instruction) => any;
  readPMOLSaftyInstruction: (pbsId: string, type: string) => void;
  readSaftyInstructionStatus: boolean;
  saftyInstructions: Instruction[];
  createSaftyInstructionStatus: boolean;
  deleteSaftyInstructionStatus: boolean;
  deleteSaftyInstruction: any;
  ////
  createEnvInstruction: (instruction: Instruction) => any;
  readPMOLEnvInstruction: (pbsId: string, type: string) => void;
  readEnvInstructionStatus: boolean;
  envInstructions: Instruction[];
  createEnvInstructionStatus: boolean;
  deleteEnvInstructionStatus: boolean;
  deleteEnvInstruction: any;
  //
  createHelthInstruction: (instruction: Instruction) => any;
  readPMOLHelthInstruction: (pbsId: string, type: string) => void;
  readHelthInstructionStatus: boolean;
  helthInstructions: Instruction[];
  createHelthInstructionStatus: boolean;
  deleteHelthInstructionStatus: boolean;
  deleteHelthInstruction: any;
  saveJournal: (journal: Journal) => void;
  getPmolJournal: (id: string) => void;
  journal: any;
  readBORStatusDropDowns: () => any;
  borStatusDropdowns: { key: string; text: string }[];
  updateBORStatus: (data: BORStatusUpdateRequest) => void;
  userRoles: [];
  getUserRoles: any;
  teamMembers: [];
  readTeamMembers: any;
  deleteTeamMembers: any;
  readPMOLLocationById:any;
  approvedPmol:any;
  readByPMOLId:any;
  newPMOLCreated:boolean;
  readPmolData:any;
  showProgressbarPmol:any;
  handshakeDataFetched:boolean;
  plannedWorkFetched: boolean;
  setPlannedWorkFetched:any;
  readPMOLAllInstruction: (pbsId:string) => void;
  finishedPmol: any;
  readPMOLByID: any;
  location:any;
  createPMOLService:any;
  service:PMOLService;
  readPMOLServiceStatus:boolean;
  getPBSData:(pbsId:string) => any;
  readPBSService:(pbsId:string) => any;
  getAllTimeClocks: any;
  getTimeClockById: any;
  shiftDetails: any;
  resetBorFormData:any;
  selectedInstruction:any;
  loadApproveMsg:string;
  isEndTimeUpdated: boolean;
  savePMOLStateAttr: (attr: string, value: any) => any;
  selectedBORforPMOL: string;
  slectedPBSForPMOL:string
  selectedPmolProjectHeader: string;
  reloadListPaneItem: () => void;
  deleteTeamMembersStatus: boolean;
  selectedLabourResourceItem: any;
}

const acceptedTypes: string[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'pdf',
  'doc',
  'docx',
];

class DocumentPane extends React.Component<Props, State> {
  private _qualitySelection: Selection;
  private _riskSelection: Selection;
  private _materialsSelection: Selection;
  private _toolsSelection: Selection;
  private _labourSelection: Selection;
  private _techLinksSelection: Selection;
  private _consumableSelection: Selection;
  private _techInstructionSelection: Selection;
  private _competenceSelection: Selection;
  private _saftyInstructionSelection: Selection;
  private _saftyLinksSelection: Selection;
  private _envInstructionSelection: Selection;
  private _envLinksSelection: Selection;
  private _helthInstructionSelection: Selection;
  private _helthLinksSelection: Selection;
  private _shiftSelection: Selection;
  subscription: any;
  materialsDataCancelToken:CancelTokenSource;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenMap: false,
      selectedInstruction: [],
      borResources: {
        consumable: [],
        labour: [],
        materials: [],
        tools: [],
      },
      borResourcesItem: {
        id: null,
        required: null,
        title: null,
        date: null,
        consumed: null,
        deliveryRequested: null,
        purchased: null,
        warf: null,
        invoiced: null,
        type: null,
        environment: null,
        isNew: false,
        resourceNumber: null,
      },
      selectedCpcEnvironment: null,
      //////
      submitCpc: false,
      isDirty: false,
      selectedCpcId: null,
      isEdit: false,
      uploadedImages: null,
      isRiskModalVisible: false,
      isNewRisk: true,
      resourceFamily: [],
      redirect: false,
      loading: false,
      qualitySelectionDetails: {},
      qualityActionButtonState: { add: false, edit: true, remove: true },
      formData: pmolFormData,
      filter: new PMOLListPaneFilter(),
      filesToUpLoad: null,
      quality: new Quality(),
      isQualityEdit: false,

      qualityDeleteItems: [],
      riskDeleteItems: [],
      validationMessages: {
        nameErrorMsg: '',
        typeErrorMsg: '',
        borErrorMsg: '',
        statusErrorMsg: '',
        productTitleErrorMsg: '',
        mouErrorMsg: '',
      },
      resourceFamilySearchString: '',

      riskActionButtonState: { add: false, edit: true, remove: true },
      riskSelectionDetails: {},
      isRiskEdit: false,
      riskList: [],
      // readRiskStatus:false,
      risk: new ReadRisk(),
      confimRiskDelete: false,
      isQualityModalVisible: false,
      qualityList: [],
      materialList: [],
      isMaterialModalVisible: false,
      materialSelectionDetails: {},
      materialActionButtonState: { add: false, edit: true, remove: true },
      material: new Resource(),
      isMaterialEdit: false,
      materialDeleteItems: [],
      readToolsStatus: false,
      tools: [],
      tool: new Resource(),
      isToolsModalVisible: false,
      toolsActionButtonState: { add: false, edit: true, remove: true },
      toolsSelectionDetails: {},
      toolsDeleteItems: [],
      isToolEdit: false,
      labours: [],
      labour: new Resource(),
      readLabourStatus: false,
      isLabourModalVisible: false,
      isEmployeeModalVisible: false,
      isEmployeeShiftModalVisible: false,
      isEmployeeEditEndTimeModalVisible: false,
      labourActionButtonState: { add: false, edit: true, remove: true },
      labourSelectionDetails: {},
      labourDeleteItems: [],
      isLabourEdit: false,
      isConsumablesModalVisible: false,
      consumableSelectionDetails: {},
      consumableActionButtonState: { add: false, edit: true, remove: true },
      consumable: new Resource(),
      readConsumableStatus: false,
      consumableDeleteItems: [],
      isConsumableEdit: false,
      consumables: [],
      techInstructions: [],
      readTechInstructionStatus: false,
      techInstruction: new Instruction(),
      //linkActionButtonState: { add: false, edit: true, remove: true },
      isOpenTechInstructionModal: false,
      //instructionLink: new InstructionLink(),
      isOpenTechLinkModal: false,
      uploadedTechInstructionLink: '',
      //techInstructionLinks: [],
      techLinksSelectionDetails: {},
      techLinksActionButtonState: { add: false, edit: true, remove: true },
      techLink: new InstructionLink(),
      isEditTechLinks: false,
      techInstructionSelectionDetails: {},
      techInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      isEditTechInstruction: false,
      editTechLinkIndex: -1,
      competenceList: [],
      competence: new Competence(),
      competenceActionButtonState: { add: false, edit: true, remove: true },
      competenceDeleteItems: [],
      competenceSelectionDetails: {},
      isCompetenceModalVisible: false,
      isCompetenceEdit: false,
      isOpenSafetyInstructionModal: false,
      isOpenSafetyLinkModal: false,
      uploadedSaftyInstructionLink: '',
      safetyLinksSelectionDetails: {},
      safetyLinksActionButtonState: { add: false, edit: true, remove: true },
      safetyLink: new InstructionLink(),
      isEditSaftyLinks: false,
      saftyInstruction: new Instruction(),
      isEditSaftyInstruction: false,
      saftyInstructions: [],
      saftyInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      saftyInstructionSelectionDetails: {},
      editSaftyLinkIndex: -1,
      ///
      isOpenEnvInstructionModal: false,
      isOpenEnvLinkModal: false,
      uploadedenvInstructionLink: '',
      envLinksSelectionDetails: {},
      envLinksActionButtonState: { add: false, edit: true, remove: true },
      envLink: new InstructionLink(),
      isEditEnvLinks: false,
      envInstruction: new Instruction(),
      isEditEnvInstruction: false,
      envInstructions: [],
      envInstructionSelectionDetails: {
        add: false,
        edit: true,
        remove: true,
      },
      envInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      editEnvLinkIndex: -1,
      //
      isOpenHelthInstructionModal: false,
      isOpenHelthLinkModal: false,
      uploadedhelthInstructionLink: '',
      helthLinksSelectionDetails: {},
      helthLinksActionButtonState: { add: false, edit: true, remove: true },
      helthLink: new InstructionLink(),
      isEditHelthLinks: false,
      helthInstruction: new Instruction(),
      isEditHelthInstruction: false,
      helthInstructions: [],
      helthInstructionSelectionDetails: {
        add: false,
        edit: true,
        remove: true,
      },
      helthInstructionActionButtonState: {
        add: false,
        edit: true,
        remove: true,
      },
      editHelthLinkIndex: -1,
      journal: {
        doneWork: null,
        encounteredProblem: null,
        lessonsLearned: null,
        reportedThings: null,
        whatsLeftToDo: null,
        id: null,
        pmolId: null,
        pictureList: [],
      },
      journalImage: {
        id: null,
        link: null,
        type: null,
      },
      position: { lat: 0.0, lon: 0.0 },
      isMapOpen: false,
      location: null,
      selectedResourceTypeId: null,
      isSignatureModalOpen: false,
      signatureLink: '',
      handshakes: [],
      handshakeDocs: [],
      extraWork: {
        id: null,
        description: null,
        pmolId: null,
        imageList: [],
        extraWorkFiles: [],
        audioList: [],
      },
      readExtraWork: false,
      borStatusDropdowns: [],
      teamMembers: [],
      teamMember: null,
      teamMemberIsEdit: false,
      isDisabled:false,
      pmolType:PMOL_TYPE.REGULAR,
      service:{
        projectTitle: null,
        productTitle: null,
        quantity: null,
        mou: null,
        unitPrice: null,
        totalPrice: null,
        documents: null,
        comments:null,
      },
      cabPerson:null,
      shiftDetails:null,
      showError:false,
      errorMsg:'',
      isMouValid: true,
      loadApproveMsg: '',
    };

    // Populate with items for demos.

    this._qualitySelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_qualitySelection',
          this._getQualitySelectionDetails()
        );
        this.setState({
          qualitySelectionDetails: this._getQualitySelectionDetails(),
          qualityActionButtonState: this._getQualityActionButtonState(),
          quality: this._qualityEditDetails(),
          qualityDeleteItems: this._qualityDeleteDetails(),
          //cpcNickNameDeleteUnSavedItems: this._nickNameDeleteUnSavedDetails(),
        });
      },
    });

    this._riskSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_riskSelection', this._getRiskSelectionDetails());
        this.setState({
          riskSelectionDetails: this._getRiskSelectionDetails(),
          riskActionButtonState: this._getRiskActionButtonState(),
          risk: this._riskEditDetails(),
          riskDeleteItems: this._riskDeleteDetails(),
          //cpcVendorDeleteUnSavedItems: this._vendorDeleteUnSavedDetails(),
        });
      },
    });

    this._materialsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_materialsSelection',
          this._getMaterialSelectionDetails()
        );
        this.setState({
          materialSelectionDetails: this._getMaterialSelectionDetails(),
          materialActionButtonState: this._getMaterialActionButtonState(),
          material: this._materialEditDetails(),
          materialDeleteItems: this._resourceDeleteDetails(
            this._materialsSelection
          ),
        });
      },
    });

    this._toolsSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log('_toolsSelection', this._getToolsSelectionDetails());
        this.setState({
          toolsSelectionDetails: this._getToolsSelectionDetails(),
          toolsActionButtonState: this._getToolsActionButtonState(),
          tool: this._toolsEditDetails(),
          toolsDeleteItems: this._resourceDeleteDetails(this._toolsSelection),
        });
      },
    });

    this._labourSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_labourSelection',
          this._getLabourSelectionDetails()
        );
        this.setState({
          labourSelectionDetails: this._getLabourSelectionDetails(),
          labourActionButtonState: this._getLabourActionButtonState(),
          labour: this._labourEditDetails(),
        });
      },
    });

    this._consumableSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_consumableSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          consumableSelectionDetails: this._getConsumableSelectionDetails(),
          consumableActionButtonState: this._getConsumableActionButtonState(),
          consumable: this._consumableEditDetails(),
        });
      },
    });

    this._techLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          techLinksSelectionDetails: this._getTechLinkSelectionDetails(),
          techLinksActionButtonState: this._getTechLinkActionButtonState(),
          techLink: this._techLinkEditDetails(),
        });
      },
    });

    this._saftyLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          safetyLinksSelectionDetails: this._getSaftyLinkSelectionDetails(),
          safetyLinksActionButtonState: this._getSaftyLinkActionButtonState(),
          safetyLink: this._saftyLinkEditDetails(),
        });
      },
    });

    this._envLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_linksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          envLinksSelectionDetails: this._getEnvLinkSelectionDetails(),
          envLinksActionButtonState: this._getEnvLinkActionButtonState(),
          envLink: this._envLinkEditDetails(),
        });
      },
    });

    this._helthLinksSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_helthLinksSelection',
          this._getSaftyLinkSelectionDetails()
        );
        this.setState({
          helthLinksSelectionDetails: this._getHelthLinkSelectionDetails(),
          helthLinksActionButtonState: this._getHelthLinkActionButtonState(),
          helthLink: this._helthLinkEditDetails(),
        });
      },
    });

    this._techInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_techInstructionSelection',
          this._getConsumableSelectionDetails()
        );
        this.setState({
          techInstructionSelectionDetails: this._getTechInstructionSelectionDetails(),
          techInstructionActionButtonState: this._getTechInstructionActionButtonState(),
          techInstruction: this._techInstructionEditDetails(),
        });
      },
    });

    this._competenceSelection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          competenceSelectionDetails: this._getCompetenceSelectionDetails(),
          competenceActionButtonState: this._getCompetenceActionButtonState(),
          competence: this._competenceEditDetails(),
          competenceDeleteItems: this._competenceDeleteDetails(),
          //cpcNickNameDeleteUnSavedItems: this._nickNameDeleteUnSavedDetails(),
        });
      },
    });
    this._shiftSelection = new Selection({
      onSelectionChanged: () => {

      },
    });

    this._saftyInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_saftyInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          saftyInstructionSelectionDetails: this._getSaftyInstructionSelectionDetails(),
          // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          saftyInstructionActionButtonState: this._getSaftyInstructionActionButtonState(),
          saftyInstruction: this._saftyInstructionEditDetails(),
        });
      },
    });

    this._envInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_envInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          envInstructionSelectionDetails: this._getEnvInstructionSelectionDetails(),
          // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          envInstructionActionButtonState: this._getEnvInstructionActionButtonState(),
          envInstruction: this._envInstructionEditDetails(),
        });
      },
    });

    this._helthInstructionSelection = new Selection({
      onSelectionChanged: () => {
        UprinceLogger.log(
          '_helthInstructionSelection',
          this._getSaftyInstructionSelectionDetails()
        );
        this.setState({
          helthInstructionSelectionDetails: this._getHelthInstructionSelectionDetails(),
          // safetyLinksActionButtonState: this._getSaftyInstructionActionButtonState(),
          helthInstructionActionButtonState: this._getHelthInstructionActionButtonState(),
          helthInstruction: this._helthInstructionEditDetails(),
        });
      },
    });
    this.materialsDataCancelToken = axios.CancelToken.source()

  }

  private setLocationAddress = (
    position: Position,
    address?: LocationAddress
  ) => {
    console.log('position', position)
    console.log('address', address)
    if (address) {
      this.setState(
        {
          position: { lat: position.lat, lon: position.lon },
          location: address,
        },
        () => {
          this.props.createPMOLLocation(this.state.location);
        }
      );
    } else {
      this.setState({ position: { lat: position.lat, lon: position.lon } });
      this.setState(
        (prevState) => ({
          location: {
            ...prevState.location,
            position: position,
          },
        }),
        () => {
          this.props.createPMOLLocation(this.state.location);
        }
      );
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.borData != this.props.borData) {
      let formData = this.state.formData;
      formData.bor = this.props.borData;
      this.setState({ formData:  this.props.slectedPBSForPMOL ? {
        ...formData,
        id:null,
        title:null,

      } : formData});
    }

    if (prevProps.formData.id !== this.props.formData.id) {
      this.props.savePMOLStateAttr('loadApproveMsg' ,'')
    }

    if (prevProps.borStatusDropdowns !== this.props.borStatusDropdowns) {
      this.setState({ borStatusDropdowns: this.props.borStatusDropdowns });
    }
    if (prevProps.shiftDetails !== this.props.shiftDetails) {
      this.setState({ shiftDetails: this.props.shiftDetails });
    }

    if (prevProps.newPMOLCreated !== this.props.newPMOLCreated && this.props.newPMOLCreated) {
      //this.props.createPMOLLocation(this.state.location)
      const paths = window.location.pathname.split('/');
       if(paths && paths.length >=5 && paths[5] === 'project-planning') {
        messageService.sendMessage({pmolCreatedFromVP: true})
       }

    }

    if (prevProps.borResources != this.props.borResources) {
      if (this.props.borResources) {
        let borResources = this.props.borResources;
        this.setState({ borResources });
      }
    }

    if (prevProps.pmolLocation != this.props.pmolLocation) {
      if (
        this.props.pmolLocation &&
        this.props.pmolLocation.position &&
        !isNaN(parseFloat(this.props.pmolLocation.position.lat.toString()))
      ) {
        this.setState({
          location: this.props.pmolLocation,
          position: {
            lat: parseFloat(this.props.pmolLocation.position.lat.toString()),
            lon: parseFloat(this.props.pmolLocation.position.lon.toString()),
          },
        }, ()=> {
          if (!this.props.formData.locationId) {
             // this.props.createPMOLLocation(this.state.location)
          }
        }
        );
      } else if (this.props.pmolLocation) {
        this.setState({ location: this.props.pmolLocation });
      } else if(!this.props.pmolLocation){
        this.setState({ location: null, position: { lat: 0.0, lon: 0.0 } });
      }
    }

    if (prevProps.locationFromPMOL !== this.props.locationFromPMOL) {
      if (
        this.props.locationFromPMOL &&
        this.props.locationFromPMOL.position &&
        !isNaN(parseFloat(this.props.locationFromPMOL.position.lat.toString()))
      ) {
        this.setState({
          location: this.props.locationFromPMOL,
          position: {
            lat: parseFloat(this.props.locationFromPMOL.position.lat.toString()),
            lon: parseFloat(this.props.locationFromPMOL.position.lon.toString()),
          },
        });
      } else if (this.props.locationFromPMOL) {
        this.setState({ location: this.props.locationFromPMOL });
      } else if(!this.props.locationFromPMOL){
        this.setState({ location: null, position: { lat: 0.0, lon: 0.0 } });
      }
    }

    if (this.props?.location?.state?.type && this.props?.location?.state?.type !== this.state.pmolType) {
        this.setState({ pmolType: this.props?.location?.state?.type})
    }

    if (
      prevProps.pmolLocationId !== this.props.pmolLocationId &&
      this.state.formData.id && this.props.pmolLocationId
    ) {

      this.props.startHandshake({
        locationId: this.props.pmolLocationId?this.props.pmolLocationId:this.props.pmolLocation.id,
        pmolId: this.state.formData.id ? this.state.formData.id : '',
      });
    }

    if (prevProps.readRiskStatus != this.props.readRiskStatus) {
      this.setState({ riskList: this.props.riskList });
    }

    if (prevProps.riskList != this.props.riskList) {
      this.setState({ riskList: this.props.riskList });
    }

    if (prevProps.qualityList != this.props.qualityList) {
      this.setState({ qualityList: this.props.qualityList });
    }

    if (prevProps.readMaterialsStatus != this.props.readMaterialsStatus) {
      this.setState({ materialList: this.props.materialList });
    }

    if (prevProps.createRiskStatus != this.props.createRiskStatus) {
      //this.setState({ isRiskModalVisible: false });
      if (this.props.createRiskStatus) {
        this.savePbsRisk();
      }
    }

    if (prevProps.saveRiskStatus != this.props.saveRiskStatus) {
      this.getPbsRiskList();
    }

    if (prevProps.createQualityStatus != this.props.createQualityStatus) {
      //this.setState({ isQualityModalVisible: false });
      if (this.props.createQualityStatus) {
        this.savePbsQuality();
      }
    }

    if (prevProps.saveQualityStatus != this.props.saveQualityStatus) {
      if (this.props.saveQualityStatus) {
        this.getPbsQualityList();
      }
    }

    if (prevProps.deleteRiskStatus != this.props.deleteRiskStatus) {
      this.getPbsRiskList();
    }

    if (prevProps.deleteQualityStatus != this.props.deleteQualityStatus) {
      this.getPbsQualityList();
    }

    if (prevProps.createMaterialsStatus != this.props.createMaterialsStatus) {
      this.getMaterisList();
    }

    if (
      prevProps.isNew != this.props.isNew ||
      prevProps.uid != this.props.uid
    ) {
      if (this.props.isNew) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        this.setState({ formData });
      }
    } else {
    }

    if (prevProps.formData != this.props.formData) {
      if (!this.state.isDirty) {
        if (!_.isEmpty(this.props.formData)) {
          let formData = this.props.formData;
          if (this.props.isNew && !formData.projectMoleculeId){
            formData.id = this.props.uid;
          }
          this.setState(
            {
              formData: formData,
              pmolType: formData.pmolType? formData.pmolType: PMOL_TYPE.REGULAR,
              service: this.props.service
              //position:{lat:0.0,lon:0.0}, location:null
            },
            () =>{
             if (this.props.formData.pmolType === PMOL_TYPE.SUB ) {
              messageService.sendMessage({isLoadingPMOLData:false, pmolTypeFromRead: PMOL_TYPE.SUB})
             } else if (this.props.formData.pmolType === PMOL_TYPE.REGULAR){
              messageService.sendMessage({isLoadingPMOLData:false, pmolTypeFromRead: PMOL_TYPE.REGULAR})
             } else if (formData.title) {
              messageService.sendMessage({isLoadingPMOLData:false, pmolTypeFromRead: PMOL_TYPE.REGULAR})
             }
            }
            //this.getPmolData
          );
        }
      } else {

        if (this.props.isNew && _.isEmpty(this.props.formData)) {
          let formData = this.state.formData;
          (formData.bor = BorFormData),
            (formData.name = null),
            (formData.typeId = null),
            this.setState({
              formData: this.props.slectedPBSForPMOL ? pmolFormData: formData,
              pmolType: formData.pmolType,
              service: this.props.service
            });
        }
      }
      if(this.props.formData && (this.props.formData.statusId===APPROVED_STATUS_ID || this.props.formData.statusId===HANDED_OVER_STATUS_ID)) {
        this.setState({
          isDisabled:true,
        })
      }else{
        this.setState({
          isDisabled:false,
        })
      }
      this.resetValidation();
    }

    if (prevState.formData !== this.state.formData) {
      if (!_.isEmpty(this.state.formData) && this.state.formData.id === null) {
        let formData = this.state.formData;
        formData.id = this.props.uid;
        //this.props.readMaterialsData(this.props.uid!!);
        this.setState(
          {
            formData,
          },
          //() => messageService.sendMessage({isLoadingPMOLData:false})
          // this.getPmolData
        );
      }
    }

    if (prevProps.readToolsStatus != this.props.readToolsStatus) {
      this.setState({ tools: this.props.tools });
    }

    if (prevProps.createToolsStatus != this.props.createToolsStatus) {
      this.getToolsList();
    }

    if (prevProps.deleteMaterialsStatus != this.props.deleteMaterialsStatus) {
      this.props.readMaterialsData(this.props.formData.id!!);
    }

    if (prevProps.deleteToolsStatus != this.props.deleteToolsStatus) {
      this.props.readToolsData(this.props.formData.id!!);
    }

    if (prevProps.readLabourStatus != this.props.readLabourStatus) {
      this.setState({ labours: this.props.labours });
    }

    if (prevProps.createLabourStatus != this.props.createLabourStatus) {
      this.getLabourList();
      this.props.formData.projectMoleculeId ? this.props.readPMOLByID(this.props.formData.projectMoleculeId!!) : null
    }

    if (prevProps.deleteTeamMembersStatus != this.props.deleteTeamMembersStatus) {
      if (this.props.deleteTeamMembersStatus) {
        this.props.formData.projectMoleculeId ? this.props.readPMOLByID(this.props.formData.projectMoleculeId!!) : null;
        this.loadLabourTeamList();
        if (!(isPlanning() || isPlanBoards())) {
          this.props.reloadListPaneItem();
        }
        // this.getCpcDetails(selectedCpc);
      }
    }

    if (prevProps.deleteLabourStatus != this.props.deleteLabourStatus) {
      this.props.readLabourData(this.props.formData.id!!);
    }

    if (prevProps.readConsumableStatus != this.props.readConsumableStatus) {
      this.setState({ consumables: this.props.consumables });
    }

    if (prevProps.createConsumableStatus != this.props.createConsumableStatus) {
      //this.setState({ isConsumablesModalVisible: false });
      this.getConsumablesList();
    }

    if (prevProps.deleteConsumableStatus != this.props.deleteConsumableStatus) {
      this.props.readConsumableData(this.props.formData.id!!);
    }

    if (
      prevProps.instructionUploadStatus != this.props.instructionUploadStatus &&
      this.props.uploadedInstructionLink
    ) {
      this.setState({
        uploadedTechInstructionLink: this.props.uploadedInstructionLink,
        uploadedSaftyInstructionLink: this.props.uploadedInstructionLink,
        uploadedenvInstructionLink: this.props.uploadedInstructionLink,
        uploadedhelthInstructionLink: this.props.uploadedInstructionLink,
      });
    }

    if (prevProps.techInstructions != this.props.techInstructions) {
      this.setState({ techInstructions: this.props.techInstructions });
    }

    if (prevProps.selectedInstruction !== this.props.selectedInstruction) {
      this.setState({ techInstruction: this.props.selectedInstruction });
    }

    if (prevProps.saveCompetenceStatus != this.props.saveCompetenceStatus) {
      if (this.props.saveCompetenceStatus) {
        this.getPbsCompetenceList();
      }
    }

    if (prevProps.deleteCompetenceStatus != this.props.deleteCompetenceStatus) {
      if (this.props.deleteCompetenceStatus) {
        this.getPbsCompetenceList();
      }
    }

    if (
      prevProps.createTechInstructionStatus !=
      this.props.createTechInstructionStatus
    ) {
      if (this.props.createTechInstructionStatus) {
        //this.setState({ isOpenTechInstructionModal: false });
        this.setState({ techInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
        this.getPbsTechInstructionList();
        });
      }
    }

    if (
      prevProps.createSaftyInstructionStatus !=
      this.props.createSaftyInstructionStatus
    ) {
      if (this.props.createSaftyInstructionStatus) {
        //this.setState({ isOpenSafetyInstructionModal: false });
        this.setState({ saftyInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
        this.getPbsSaftyInstructionList()
        });
      }
    }

    if (
      prevProps.deleteTechInstructionStatus !=
      this.props.deleteTechInstructionStatus
    ) {
      this.getPbsTechInstructionList();
    }

    if (prevProps.saftyInstructions != this.props.saftyInstructions) {
      this.setState({ saftyInstructions: this.props.saftyInstructions });
    }

    if (
      prevProps.deleteSaftyInstructionStatus !=
      this.props.deleteSaftyInstructionStatus
    ) {
      this.getPbsSaftyInstructionList();
    }

    if (
      prevProps.createEnvInstructionStatus !=
      this.props.createEnvInstructionStatus
    ) {
      if (this.props.createEnvInstructionStatus) {
        //this.setState({ isOpenEnvInstructionModal: false });
        this.setState({ envInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
        this.getPbsEnvInstructionList()
        });
      }
    }

    if (
      prevProps.deleteEnvInstructionStatus !=
      this.props.deleteEnvInstructionStatus
    ) {
      this.getPbsEnvInstructionList();
    }

    if (prevProps.envInstructions != this.props.envInstructions) {
      this.setState({ envInstructions: this.props.envInstructions });
    }

    if (
      prevProps.createHelthInstructionStatus !=
      this.props.createHelthInstructionStatus
    ) {
      if (this.props.createHelthInstructionStatus) {
        //this.setState({ isOpenHelthInstructionModal: false });
        this.setState({ helthInstruction: new Instruction() });
        this.savePbsInstruction().then(() => {
        this.getPbsHelthInstructionList()
        });
      }
    }

    if (
      prevProps.deleteHelthInstructionStatus !=
      this.props.deleteHelthInstructionStatus
    ) {
      this.getPbsHelthInstructionList();
    }

    if (prevProps.helthInstructions != this.props.helthInstructions) {
      this.setState({ helthInstructions: this.props.helthInstructions });
    }

    if (prevProps.materialList != this.props.materialList) {
      if (this.props.borData) {
        let materialList: Resource[] = this.formattedPbsResourcesToBorResources(
          this.props.materialList
        );
        let borResources = this.state.borResources;
        borResources.materials = materialList;
        this.setState({
          borResources,
        });
      }
    }

    if (prevProps.tools != this.props.tools) {
      if (this.props.borData) {
        let tools: Resource[] = this.formattedPbsResourcesToBorResources(
          this.props.tools
        );
        let borResources = this.state.borResources;
        borResources.tools = tools;
        this.setState({
          borResources,
        });
      }
    }

    if (prevProps.consumables != this.props.consumables) {
      if (this.props.borData) {
        let consumables: Resource[] = this.formattedPbsResourcesToBorResources(
          this.props.consumables
        );
        let borResources = this.state.borResources;
        borResources.consumable = consumables;
        this.setState({
          borResources,
        });
      }
    }

    if (prevProps.labours != this.props.labours) {
      if (this.props.borData) {
        let labours: Resource[] = this.formattedPbsResourcesToBorResources(
          this.props.labours
        );
        let borResources = this.state.borResources;
        borResources.labour = labours;
        this.setState({
          borResources,
        });
      }
    }

    if (prevProps.signatureLink != this.props.signatureLink) {
      if (this.props.signatureLink) {
        this.setState({ signatureLink: this.props.signatureLink });
      }
    }

    if (prevProps.stopHandshakeStatus != this.props.stopHandshakeStatus) {
      if (this.props.stopHandshakeStatus) {
        this.setState({ isSignatureModalOpen: false });
        if (this.state.formData.id)
          this.props.readStopHandshakes(this.state.formData.id);
      }
    }
    if (prevProps.handshakes != this.props.handshakes) {
      this.setState({ handshakes: this.props.handshakes });
    }

    if (this.props.journal && prevProps.journal != this.props.journal) {
      this.setState({ journal: this.props.journal });
    } else if (
      !this.props.journal &&
      prevProps.journal !== this.props.journal
    ) {
      this.setState({
        journal: {
          doneWork: null,
          encounteredProblem: null,
          lessonsLearned: null,
          reportedThings: null,
          id: null,
          whatsLeftToDo: null,
          pmolId: null,
          pictureList: [],
        },
      });
    }

    if (prevProps.handshakeDocs != this.props.handshakeDocs) {
      this.setState({ handshakeDocs: this.props.handshakeDocs });
    }
    if (prevProps.extraWork != this.props.extraWork) {
      if (this.props.extraWork) {
        this.setState({
          extraWork: { ...this.props.extraWork, extraWorkFiles: this.props.extraWork.imageList },
        });
      } else {
        this.setState({
          extraWork: {
            id: null,
            description: null,
            pmolId: null,
            imageList: [],
            extraWorkFiles: [],
            audioList: [],
          },
        });
      }
    }
    if (prevProps.teamMembers != this.props.teamMembers) {
      this.setState({ teamMembers: this.props.teamMembers });
    }
    if (prevProps.service != this.props.service) {
      this.setState({ service: _.isArray(this.props?.service) ? this.props?.service[0]:null})
    }

    if (this.props.slectedPBSForPMOL && prevProps.slectedPBSForPMOL !== this.props.slectedPBSForPMOL) {
      this.setState({formData:pmolFormData})
    }
  }

  componentDidMount() {
    if(!this.props.formData.projectMoleculeId){
      this.clearPmolData();
    }
    //this.props.readPmolData();
    //console.time()
    //console.log("componentDidMount", Date.now())
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.newPMOLfromVP) {
          this.setState({formData:pmolFormData})
        }
        if (data.data.isNewPmol) {
          this.props.readLocationsByProjectId(getProject());
          this.setState({isMouValid:true})
          this.resetValidation();
          this.props.resetRiskGridList();
          this.props.clearPmolData();
          this.props.clearPBSData();
          //cancelAllRequest()
          this.clearPmolData();
        }
        if (data.data.savePmolFormData) {
           this.saveFormData();
          this.savePmolExtraWorkData();
          this.PMOLSaveJournal()
        }
        if (data.data.pbsFilter) {
          this.setState({
            filter: data.data.pbsFilter,
          });
        }
        if (data.data.selectedBor) {
          this.getDataBySelectedBor(data.data.selectedBor);
        }
        if (data.data.selectedSuBPBS) {
          this.getPBSService(data.data.selectedSuBPBS);
        }
        if (data.data.isBorCleared) {
          //alert(JSON.stringify(data.data.isBorCleared));
          this.props.resetRiskGridList();
          //this.props.clearPmolData();
          this.props.clearPBSData()
        }

        if (data.data.saveExtraWorkData) {
          this.savePmolExtraWorkData(true);
        }
        if (data.data.openEmployeeModal) {
          this.openEmployeeModal();
        }
        if (data.data.openEmpAddEndTime) {
          this.openEmployeeEditEndTimeModal();
        }

        if (data.data.saveTeamMember) {
          this.saveTeamMembers(data.data.saveTeamMember);
        }

        if (data.data.pmolType) {
            this.setState({pmolType:data.data.pmolType})
        }

        if (data.data.teamMember) {
          this.setState({
            teamMember: data.data.teamMember,
            teamMemberIsEdit: !!(
              data.data.teamMember && data.data.teamMember.cabPersonId
            ),
          });
        }

        if (data.data.deleteSelectedEmployee) {
          this.handleLabourEmployeeDelete(data.data.deleteSelectedEmployee);
        }
        if (data.data.approvePmol) {
          this.handleApprovePmol();
        }
        if (data.data.finishedPmol) {
          this.handleFinishedPmol();
        }
        if (data.data.openEmpTimeClock) {
          this.getEmpShiftData(data.data.openEmpTimeClock);
        }
        if (data.data.rightClickTask) {
          this.clearPmolData();
        }
      }
    });

    if (getProject()) {
      let projectId = getProject();
      if (projectId) {
        this.props.getProjectHeaderDetails(projectId);
        //this.props.readLocationsByProjectId(projectId);
      }
    }

    if (this.props.formData.projectMoleculeId) {

      this.getPmolData();
    }
    if(!this.props.formData.id){
      if (this.props.isNew) {
        this.clearPmolData();
      }
    }
    if(this.props.formData && (this.props.formData.statusId === APPROVED_STATUS_ID || this.props.formData.statusId===HANDED_OVER_STATUS_ID)) {
      this.setState({
        isDisabled:true,
      })
    } else {
      this.setState({
        isDisabled:false,
      })
    }
  }

  componentWillUnmount(){
    this.subscription.unsubscribe()
    this.setState({formData:pmolFormData})
    this.props.savePMOLStateAttr('formData',{})
  }

  getProjectLocation = () => {
    if (!this.props.pmolLocation) {
      this.props.readLocationsByProjectId(getProject())
    }
  }

  getDataBySelectedBor = (selectedBor: {
    value: string;
    label: string;
    productId: string;
    pbsId: string;
  }) => {
    messageService.sendMessage({isLoadingPMOLData:true})
    Promise.all([
      this.props.showProgressbarPmol(true),
      this.props.getBorById(selectedBor.value),
      this.getPbsQualityList(selectedBor.pbsId),
      this.getPbsRiskList(selectedBor.pbsId),
      this.getAllInstructionList(selectedBor.pbsId),
      // this.getPbsTechInstructionList(selectedBor.pbsId),
      // this.getPbsSaftyInstructionList(selectedBor.pbsId),
      // this.getPbsEnvInstructionList(selectedBor.pbsId),
      // this.getPbsHelthInstructionList(selectedBor.pbsId),
       //this.props.readLocationsByProjectId(getProject())
       //this.getProjectLocation()
    ]).then(() => {
      this.props.showProgressbarPmol(false);
      messageService.sendMessage({isLoadingPMOLData:false})
    })
  };

  getDataBySelectedPBS = (selectedPbsId:string) => {
    messageService.sendMessage({isLoadingPMOLData:true})
    Promise.all([
      this.props.showProgressbarPmol(true),
      //this.props.getBorById(selectedBor.value),
      this.getPbsQualityList(selectedPbsId),
      this.getPbsRiskList(selectedPbsId),
      this.getAllInstructionList(selectedPbsId),
      // this.getPbsTechInstructionList(selectedBor.pbsId),
      // this.getPbsSaftyInstructionList(selectedBor.pbsId),
      // this.getPbsEnvInstructionList(selectedBor.pbsId),
      // this.getPbsHelthInstructionList(selectedBor.pbsId),
      // this.props.readLocationsByProjectId(getProject())
      //this.getProjectLocation()
    ]).then(() => {
      this.props.showProgressbarPmol(false);
      messageService.sendMessage({isLoadingPMOLData:false})
    })
  };



  getPBSService = (selectedSuBPBS:string) => {
    messageService.sendMessage({isLoadingPMOLData:true})

    this.props.getPBSData(selectedSuBPBS).then((res:any)=>{
      let pbsId=(res.payload.data.result && res.payload.data.result.id)?res.payload.data.result.id:null;
      if(pbsId){
        this.getDataBySelectedPBS(pbsId)
        this.props.readPBSService(pbsId).then(()=>{
          messageService.sendMessage({isLoadingPMOLData:false})
        })
      }
    })
  };

  private getPMOLLocation = () => {
    if (this.props.formData.id && this.props.formData.locationId) {
      this.props.readPMOLLocationById(this.state.formData.id)
    }
  }

  onVisibitiltyChangeStartHandShake = (isVisible:boolean) => {
    // console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
    // if (this.props.formData.projectMoleculeId && isVisible && !this.props.handshakeDataFetched) {
    //   messageService.sendMessage({isLoadingPMOLData:true})
    //   Promise.all([
    //     this.getAllInstructionList(),
    //     this.getPbsQualityList(),
    //     // this.getPbsTechInstructionList(),
    //     // this.getPbsSaftyInstructionList(),
    //     // this.getPbsEnvInstructionList(),
    //     // this.getPbsHelthInstructionList(),
    //     this.getPbsRiskList(),
    //     this.getPMOLLocation(),
    //     this.getProjectLocation(),
    //     this.getPbsCompetenceList()
    //   ]).then(()=>{
    //     setTimeout(() => {
    //       messageService.sendMessage({isLoadingPMOLData:false})
    //     }, 3000);

    //   })
    // }
  }

  onVisibilityChangePlannedWorks = (isVisible:boolean) => {

    // console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
    // if (this.props.formData.projectMoleculeId && isVisible && !this.props.plannedWorkFetched) {
    //   this.props.setPlannedWorkFetched()
    //   messageService.sendMessage({isLoadingPMOLData:true})
    //   Promise.all([
    //     this.props.readStopHandshakes(this.props.formData.id),
    //     this.getPmolJournal(this.props.formData.id!!),
    //     this.props.readPmolExtraWorkData(this.props.formData.id),
    //     this.getResources(),
    //   ]).then(()=>{
    //     setTimeout(() => {
    //       messageService.sendMessage({isLoadingPMOLData:false})
    //     }, 3000);

    //   })
    // }
  }

  getPmolData = () => {
    if (this.props.formData.projectMoleculeId) {
      // messageService.sendMessage({isLoadingPMOLData:false})
      // Promise.all([
      //   this.getPbsQualityList(),..
      //   this.getPbsTechInstructionList(),..
      //   this.getPbsSaftyInstructionList(),..
      //   this.getPbsEnvInstructionList(),..
      //   this.getPbsHelthInstructionList(),..
      //   // this.props.readLocationsByProjectId(getProject()),
      //   this.getProjectLocation(),..
      //   this.getPbsCompetenceList(),..
      //   this.getPbsRiskList(),..
      //   this.getPMOLLocation(),..
      //   this.getResources(),
      //   this.props.readStopHandshakes(this.props.formData.id),..
      //   this.getPmolJournal(this.props.formData.id!!),
      //   this.props.readPmolExtraWorkData(this.props.formData.id),

      // ]).then(() => {
      //   messageService.sendMessage({isLoadingPMOLData:false})
      // })


    }
  };

  getResources = () => {
    if (
      this.state.formData &&
      this.state.formData.id &&
      this.state.formData.id
    ) {
      this.props.readPmolPlannedWorkResources(this.state.formData.id);
    }
  };

  formattedPbsResourcesToBorResources = (resource: Resource[]) => {
    if (resource) {
      let data: Resource[] = resource.map((item: any) => {
        return {
          id: item.id,
          pmolId: item.pmolId,
          borId: null,
          corporateProductCatalogId: item.corporateProductCatalogId,
          resourceNumber: item.resourceNumber,
          required: item.required,
          consumedQuantity: item.consumedQuantity,
          cpcBasicUnitOfMeasureId: item.cpcBasicUnitOfMeasureId,
          unit: item.unit,
          title: item.title,
          environment: null,
          type: 'Planned',
        };
      });
      return data;
    } else {
      return [];
    }
  };

  resetSelection = () => {
    this._riskSelection.selectToKey('', true);
    this._qualitySelection.selectToKey('', true);
  };

  savePbsRisk = () => {
    if (
      this.state.formData &&
      this.state.formData.bor &&
      !_.isEmpty(this.state.formData.bor.product) &&
      this.props.newRiskId
    ) {
      let risk: PbsRisk = {
        pbsProductId: this.state.formData.bor.product.id,
        riskId: this.props.newRiskId,
      };
      this.props.savePbsRisk(risk);
    }
  };

  savePbsQuality = () => {
    if (
      this.state.formData &&
      this.state.formData.bor &&
      !_.isEmpty(this.state.formData.bor.product) &&
      this.props.newQualityId
    ) {
      let quality: PbsQuality = {
        pbsProductId: this.state.formData.bor.product.id,
        qualityId: this.props.newQualityId,
      };
      this.props.savePbsQuality(quality);
    }
  };

  savePbsInstruction = async () => {
    if (!_.isEmpty(this.state.formData) && this.props.selectedInstruction.id && !this.state.isEditTechInstruction) {
      let instructionData: PbsInstruction = {
        id: uuid4(),
        pbsProductId: this.state.formData.productId,
        instructionsId: this.props.selectedInstruction.id,
      };
      await this.props.savePbsInstruction(instructionData);
    }
  };

  getPbsRiskList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      pbsId !== null &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readRiskList(pbsId);
    } else if (
      !_.isEmpty(this.state.formData) &&
      pbsStateId !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readRiskList(pbsStateId);
    } else if (productId) {
      this.props.readRiskList(productId);
    }
  };

  getPbsQualityList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      pbsId !== null &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readQualityList(pbsId);
    } else if (
      !_.isEmpty(this.state.formData) &&
      pbsStateId !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readQualityList(pbsStateId);
    } else if (productId) {
      this.props.readQualityList(productId);
    }
  };

  getAllInstructionList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      this.props.formData.bor !== null &&
      this.props.formData.bor.product &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readPMOLAllInstruction(pbsId);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.bor !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readPMOLAllInstruction(pbsStateId);
    } else if (productId) {
      this.props.readPMOLAllInstruction(productId);
    }
  };

  getPbsTechInstructionList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product &&
      this.props.formData.productId
        ? this.props.formData.productId
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      this.props.formData.bor !== null &&
      this.props.formData.bor.product &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readPMOLTechInstruction(pbsId, INSTRUCTION_TYPE.TECH);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.bor !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readPMOLTechInstruction(pbsStateId, INSTRUCTION_TYPE.TECH);
    } else if (productId) {
      this.props.readPMOLTechInstruction(productId, INSTRUCTION_TYPE.TECH);
    }
  };

  getPbsSaftyInstructionList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      this.props.formData.bor !== null &&
      this.props.formData.bor.product &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readPMOLSaftyInstruction(pbsId, INSTRUCTION_TYPE.SAFTY);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.bor !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readPMOLSaftyInstruction(pbsStateId, INSTRUCTION_TYPE.SAFTY);
    } else if (productId) {
      this.props.readPMOLSaftyInstruction(productId, INSTRUCTION_TYPE.SAFTY);
    }
  };

  getPbsEnvInstructionList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      this.props.formData.bor !== null &&
      this.props.formData.bor.product &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readPMOLEnvInstruction(pbsId, INSTRUCTION_TYPE.ENVIRONMENT);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.bor !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readPMOLEnvInstruction(
        pbsStateId,
        INSTRUCTION_TYPE.ENVIRONMENT
      );
    } else if (productId) {
      this.props.readPMOLEnvInstruction(
        productId,
        INSTRUCTION_TYPE.ENVIRONMENT
      );
    }
  };

  getPbsHelthInstructionList = (productId?: string) => {
    const pbsId: string =
      this.props.formData &&
      this.props.formData.bor &&
      this.props.formData.bor.product.id
        ? this.props.formData.bor.product.id
        : '';
    const pbsStateId: string =
      this.state.formData.bor.product && this.state.formData.bor.product.id
        ? this.state.formData.bor.product.id
        : '';
    if (
      !_.isEmpty(this.props.formData) &&
      this.props.formData.bor !== null &&
      this.props.formData.bor.product &&
      !_.isEmpty(pbsId)
    ) {
      this.props.readPMOLHelthInstruction(pbsId, INSTRUCTION_TYPE.HELTH);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.bor !== null &&
      !_.isEmpty(pbsStateId)
    ) {
      this.props.readPMOLHelthInstruction(pbsStateId, INSTRUCTION_TYPE.HELTH);
    } else if (productId) {
      this.props.readPMOLHelthInstruction(productId, INSTRUCTION_TYPE.HELTH);
    }
  };

  getPbsCompetenceList = () => {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      this.props.filterCompetenceList(this.props.formData.id);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      this.props.filterCompetenceList(this.state.formData.id);
    }
  };

  getToolsList = () => {
    if (this.props.formData && this.state.formData.id) {
      this.props.readPmolToolsData(this.state.formData.id);
    }
  };

  getLabourList = () => {
    if (this.props.formData && this.state.formData.id) {
      this.props.readPmolLaboursData(this.state.formData.id);
    }
  };

  getMaterisList = () => {
    if (this.props.formData && this.state.formData.id) {
      //alert("getMaterisList");
     // this.materialsDataCancelToken = axios.CancelToken.source()
     this.materialsDataCancelToken.cancel()
      this.props.readPmolMaterialsData(this.state.formData.id, this.materialsDataCancelToken );
    }
  };

  getConsumablesList = () => {
    if (this.props.formData && this.state.formData.id) {
      this.props.readPmolConsumableData(this.state.formData.id);
    }
  };

  handleDiscardChangesNavigate = (path: string) => {
    history.push({
      pathname: path,
      state: { pbsFilter: this.state.filter },
    });
  };

  onAddTechInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.techInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editTechLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type,
      });
    } else {
      if (links.length >= this.state.editTechLinkIndex + 1) {
        links[this.state.editTechLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type,
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({
      techInstruction: instruction,
      //isOpenTechLinkModal: false
    });
  };

  onAddSaftyInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.saftyInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editSaftyLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type,
      });
    } else {
      if (links.length >= this.state.editSaftyLinkIndex + 1) {
        links[this.state.editSaftyLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type,
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({
      saftyInstruction: instruction,
      //isOpenSafetyLinkModal: false
    });
  };

  onAddEnvInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.envInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editEnvLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type,
      });
    } else {
      if (links.length >= this.state.editEnvLinkIndex + 1) {
        links[this.state.editEnvLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type,
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({
      envInstruction: instruction,
      //isOpenEnvLinkModal: false
    });
  };

  onAddHelthInstructionLink = (instructionLink: InstructionLink) => {
    let instruction = this.state.helthInstruction;
    let links = instruction.pbsInstructionLink;
    if (this.state.editHelthLinkIndex === -1) {
      links.push({
        id: null,
        title: instructionLink.title,
        value: instructionLink.value,
        link: instructionLink.link,
        pbsInstructionId: null,
        type: instructionLink.type,
      });
    } else {
      if (links.length >= this.state.editHelthLinkIndex + 1) {
        links[this.state.editHelthLinkIndex] = {
          id: instructionLink.id,
          title: instructionLink.title,
          link: instructionLink.link,
          value: instructionLink.value,
          pbsInstructionId: instruction.id,
          type: instructionLink.type,
        };
      }
    }
    instruction.pbsInstructionLink = links;
    this.setState({
      helthInstruction: instruction,
      //isOpenHelthLinkModal: false
    });
  };

  onAddSignature = (signature: Signature) => {
    this.props.stopHandshake(signature);
  };

  private cpcOptions = (inputValue: any) =>
    new Promise((resolve) => {
      //setTimeout(() => {
      resolve(this.getCpcByName(inputValue));
      // }, 1000);
    });

  private getCpcByName = async (name: string) => {
    let filter = {
      resourceTypeId: this.state.selectedResourceTypeId,
      resourceFamilyId: null,
      resourceNumber: null,
      pmolId: this.state.formData.id,
      title: name,
      type:'Planned',
      status: 1,
      sorter: {
        attribute: 'title',
        order: 'asc',
      },
    };
    if (name.length >= 2) {
      let response = await client.post(
        CPC_PMOL_FILTER_EP,
        filter
      );
      return this.formatCpcResponse(response);
    }
  };

  private formatCpcResponse = (response: any) => {
    let localOptions: { value: string; label: string }[] = [];
    let contractingUnitOptions: { value: string; label: string }[] = [];
    let centralOptions: { value: string; label: string }[] = [];
    let groupedOptions = [
      {
        label: this.props.t('project'),
        options: localOptions,
      },
      {
        label: this.props.t('contractingUnit'),
        options: contractingUnitOptions,
      },
      {
        label: this.props.t('organization'),
        options: centralOptions,
      },
    ];
    if (response && response.data) {
      let localData = response.data.local;
      let contractingUnit = response.data.contractingUnit;
      let central = response.data.central;

      if (localData && _.isArray(localData)) {
        localOptions = localData.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.title,
            environment: 'local',
            item: item
          };
        });
      }
      if (contractingUnit && _.isArray(contractingUnit)) {
        contractingUnitOptions = contractingUnit.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.title,
            environment: 'cu',
            item: item
          };
        });
      }
      if (central && _.isArray(central)) {
        centralOptions = central.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.title,
            environment: 'central',
            item: item
          };
        });
      }
      groupedOptions = [
        {
          label: this.props.t('project'),
          options: localOptions,
        },
        {
          label: this.props.t('contractingUnit'),
          options: contractingUnitOptions,
        },
        {
          label: this.props.t('organization'),
          options: centralOptions,
        },
      ];
    } else {
    }

    return groupedOptions;
  };

  saveTeamMembers = (labourTeamMembers: any) => {
    let currentTeamMembers = this.state.teamMembers;
    let teamMember = labourTeamMembers.teamMember;
    let updatedItem = [teamMember];
    if (labourTeamMembers.isEdit) {
      if (
        labourTeamMembers.teamMember.uid &&
        labourTeamMembers.teamMember.id === null
      ) {
        currentTeamMembers = currentTeamMembers.map(
          (obj) => updatedItem.find((o) => o.uid === obj.uid) || obj
        );
      } else {
        currentTeamMembers = currentTeamMembers.map(
          (obj) => updatedItem.find((o: any) => o.id === obj.id) || obj
        );
      }
      this.setState({
        teamMembers: currentTeamMembers,
      });
    } else {
      currentTeamMembers.push(teamMember);
    }
  };

  handleLabourEmployeeDelete = (selectedTeamMembers: any) => {
    let currentTeamMembers = this.state.teamMembers;
    if (selectedTeamMembers) {
      let deleteList: any = [];
      let deleteUidList: any = [];
      selectedTeamMembers.forEach((item: any) => {
        if (item.uid) {
          deleteUidList.push(item.uid);
        } else if (item.id) {
          deleteList.push(item.id);
        }
      });

      currentTeamMembers = currentTeamMembers.filter(
        (item) => !deleteUidList.includes(item.uid)
      );
      currentTeamMembers = currentTeamMembers.filter(
        (item) => !deleteList.includes(item.id)
      );
      if (deleteList && deleteList.length>0 ) {
        this.props.deleteTeamMembers(deleteList);
      }
      this.setState({
        teamMembers: currentTeamMembers,
      });
    }
  };


  handleApprovePmol = () => {
    if(this.state.formData.projectMoleculeId){
      this.props.approvedPmol(this.state.formData.id).then((res: any) => {
        this.props.readByPMOLId(this.state.formData.projectMoleculeId);
      })
    }

  };

  handleFinishedPmol = () => {
    if(this.state.formData.projectMoleculeId){
      this.props.finishedPmol(this.state.formData.id).then((res: any) => {
        this.props.readByPMOLId(this.state.formData.projectMoleculeId)
      })
    }
  }
  onChangeService = (prop:string, value:any) => {
    this.setState((prevSate)=>({
      service:{
        ...prevSate.service,
        [prop]:value
      }
    }), ()=>{
      let unitPrice = this.state?.service?.unitPrice ? parseFloat(this.state?.service?.unitPrice) : 0
      let quantity = this.state?.service?.quantity ? parseFloat(this.state?.service?.quantity) : 0
      let totPrice = unitPrice * quantity
      this.setState({service: {...this.state.service, totalPrice: totPrice.toString()}})
    })
}

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div id={'topDiv'} style={{ position: 'absolute', width: '75%', zIndex: 1000 }}>
          {(!this.state.isMouValid) &&
            <CustomAlert
              showMsg={true}
              msg={this.props.t("mouErrorMsg")}
              msgType={'error'}
              handleCustomAlert={() => { this.setState({ isMouValid: false }) }}
            />
          }
        </div>
        <RouteLeavingGuard
          when={this.state.isDirty}
          navigate={(path) => this.handleDiscardChangesNavigate(path)}
          shouldBlockNavigation={(location) => {
            return true;
          }}
          handleConfirmation={(isConfirm: boolean) => {
            this.setState({
              isDirty: !isConfirm,
            });
          }}
        />
          <div style={{position:'fixed',width:'75%',zIndex:1000}}>
            <CustomAlert
              showMsg={this.state.showError}
              msg={this.state.errorMsg}
              msgType={'error'}
              isMultiline={true}
              handleCustomAlert={() => this.setState({showError:false})}
            />
          </div>
        <DocumentPaneComponent
          loadApproveMsg={this.props.loadApproveMsg}
          dropDownOptions={{
            toleranceStates: this.props.toleranceStates,
            productStates: this.props.productStates,
            itemTypes: this.props.itemTypes,
            locationTaxonomy: this.props.locationTaxonomy,
            utilityTaxonomy: this.props.utilityTaxonomy,
            status: this.props.pmolDropDowns
              ? this.props.pmolDropDowns.status
              : [],
            types: this.props.pmolDropDowns
              ? this.props.pmolDropDowns.type
              : [],
          }}
          formData={this.state.formData}
          extraWork={this.state.extraWork}
          projectHeader={this.props.selectedPmolProjectHeader ? this.props.selectedPmolProjectHeader : this.props.projectHeader }
          borResources={this.state.borResources}
          handleFormChange={(
            projectMolecule: ProjectMolecule,
            save: boolean
          ) => {
            this.handleFormChange(projectMolecule, save);
          }}
          handleExtraChange = {(extra:any[]) => {this.handleExtraChange(extra)}}
          handleJournalChange = {(journal:any[]) => {this.handleJournalChange(journal)}}
          handleHandshakeChange = {(handshake: any[]) => {this.handleHandshakeChange(handshake)}}
          saveFormData={() => this.saveFormData()}
          PMOLSaveJournal={() => this.PMOLSaveJournal()}
          resetSelection={() => this.resetSelection()}
          submitCpc={this.state.submitCpc}
          isEdit={this.props.isEdit}
          handleRiskDelete={() => {
            this.handleRiskDelete();
          }}
          qualityActionButtonState={this.state.qualityActionButtonState}
          qualitySelection={this._qualitySelection}
          selectedResourceFamily={this.state.resourceFamily}
          onChangeResourceFamily={(id: string | undefined) =>
            this.onChangeResourceFamily(id)
          }
          openRiskModal={() => {
            this.openRiskModal();
          }}
          riskActionButtonState={this.state.riskActionButtonState}
          riskSelection={this._riskSelection}
          cpcBasicUnitOfMeasure={this.props.cpcBasicUnitOfMeasure}
          cpcPressureClass={this.props.cpcPressureClass}
          cpcMaterial={this.props.cpcMaterial}
          cpcBrand={this.props.cpcBrand}
          resourceTypes={this.props.resourceTypes}
          resourceFamily={this.state.resourceFamily}
          languageCodes={this.props.languageCodes}
          cpcUnitOfSizeMeasure={this.props.cpcUnitOfSizeMeasure}
          savePicturesOfTheInstallation={(files: FileList | null) =>
            this.props.savePicturesOfTheInstallation(files)
          }
          validationMessages={this.state.validationMessages}
          riskList={this.state.riskList}
          openQualityModal={() => {
            this.openQualityModal();
          }}
          qualityList={this.state.qualityList}
          handleQualityDelete={() => {
            this.handleQualityDelete();
          }}
          materials={this.state.materialList}
          openMaterialModal={() => {
            this.openMaterialModal();
          }}
          materialActionButtonState={this.state.materialActionButtonState}
          materialSelection={this._materialsSelection}
          tools={this.state.tools}
          toolsActionButtonState={this.state.toolsActionButtonState}
          openToolsModal={() => this.openToolsModal()}
          toolSelection={this._toolsSelection}
          handleMaterialDelete={() => {
            this.handleMaterialsDelete();
          }}
          handleToolsDelete={() => {
            this.handleToolsDelete();
          }}
          materialLoading={this.props.readMaterialsStatus}
          toolsLoading={this.props.readToolsStatus}
          labours={this.state.labours}
          labourActionButtonState={this.state.labourActionButtonState}
          openLabourModal={() => this.openLabourModal()}
          labourSelection={this._labourSelection}
          labourLoading={this.props.readLabourStatus}
          handleLabourDelete={() => {
            this.handleLaboursDelete();
          }}
          openConsumableModal={() => {
            this.openConsumaleModal();
          }}
          consumableSelection={this._consumableSelection}
          consumableActionButtonState={this.state.consumableActionButtonState}
          consumables={this.state.consumables}
          consumalbeLoading={this.props.readConsumableStatus}
          handleConsumableDelete={() => {
            this.handleConsumableDelete();
          }}
          openTechInstuctionModal={() => {
            this.openTechInstructionModal();
          }}
          onFilterPersonChanged={this.onFilterPersonChanged}
          techInstructionsLoading={this.props.readTechInstructionStatus}
          techInstructions={this.state.techInstructions}
          techInstructionSelection={this._techInstructionSelection}
          techInstructionActionButtonState={
            this.state.techInstructionActionButtonState
          }
          onQualityFilterPersonChanged={this.onQualityFilterPersonChanged}
          competenceList={this.props.competenceList}
          competenceSelection={this._competenceSelection}
          competenceActionButtonState={this.state.competenceActionButtonState}
          openCompetenceModal={() => {
            this.openCompetenceModal();
          }}
          handleCompetenceDelete={() => {
            this.handleCompetenceDelete();
          }}
          handleDTechInstructionDelete={() => {
            this.handleTechInstructionDelete();
          }}
          openSaftyInstuctionModal={() => {
            this.openSaftyInstructionModal();
          }}
          saftyInstructionsLoading={this.props.readSaftyInstructionStatus}
          saftyInstructions={this.state.saftyInstructions}
          saftyInstructionSelection={this._saftyInstructionSelection}
          saftyInstructionActionButtonState={
            this.state.saftyInstructionActionButtonState
          }
          handleSaftyInstructionDelete={() => {
            this.handleSaftyInstructionDelete();
          }}
          openEnvInstuctionModal={() => {
            this.openEnvInstructionModal();
          }}
          envInstructionsLoading={this.props.readEnvInstructionStatus}
          envInstructions={this.state.envInstructions}
          envInstructionSelection={this._envInstructionSelection}
          envInstructionActionButtonState={
            this.state.envInstructionActionButtonState
          }
          handleEnvInstructionDelete={() => {
            this.handleEnvInstructionDelete();
          }}
          //
          openHelthInstuctionModal={() => {
            this.openHelthInstructionModal();
          }}
          helthInstructionsLoading={this.props.readHelthInstructionStatus}
          helthInstructions={this.state.helthInstructions}
          helthInstructionSelection={this._helthInstructionSelection}
          helthInstructionActionButtonState={
            this.state.helthInstructionActionButtonState
          }
          handleHelthInstructionDelete={() => {
            this.handleHelthInstructionDelete();
          }}
          setJournal={(feild: any, value: any) => {
            this.setJournal(feild, value);
          }}
          setJournalImage={(type: string, response: any) => {
            this.setJournalImage(type, response);
          }}
          position={this.state.position}
          setLocationAddress={(
            position: Position,
            address: LocationAddress
          ) => {
            this.setLocationAddress(position, address);
          }}
          openMapModal={() => this.openMapModal()}
          address={
            this.state.location &&
            this.state.location?.address &&
            this.state.location.address.freeformAddress
              ? this.state.location.address.freeformAddress
              : ''
          }
          // readOnly={getProject() ? (this.state.isDisabled ? true :  (pbsID ? false : true) ): true}
          readOnly={this.state.isDisabled ? true : false}
          openSignatureModal={() => this.openSignatureModal()}
          signatures={this.state.handshakes}
          handshakeDocs={this.state.handshakeDocs}
          readHandShakeStatus={this.props.readHandShakeStatus}
          saveHandshakeDocuments={(data: {
            pmolId: string;
            docLinks: string[];
          }) => this.props.saveHandshakeDocuments(data)}
          journal={this.state.journal}
          readExtraWork={this.state.readExtraWork}
          borStatusDropdowns={this.state.borStatusDropdowns}
          isLoading={this.props.showProgressBar}
          isDisabled={this.state.isDisabled}
          onVisibitiltyChangeStartHandShake={this.onVisibitiltyChangeStartHandShake}
          onVisibilityChangePlannedWorks={this.onVisibilityChangePlannedWorks}
          isRisksLoading={this.props.readRiskStatus}
          pmolType={this.state.pmolType}
          service={this.state.service}
          onChangeService={(prop, val)=> { this.onChangeService(prop, val)}}
        />

        <ResourceModal
          isOpen={this.state.isMaterialModalVisible}
          openResourceModal={() => this.openMaterialModal()}
          isEdit={this.state.isMaterialEdit}
          handleResourceSave={(resource: Resource) => {
            if(this.state.formData.bor){
              resource.borId=this.state.formData.bor.id
            }
            this.props.createMaterials(resource);
          }}
          resource={this.state.material}
          title={
            this.state.isMaterialEdit ? i18n.t('editMaterial') : i18n.t('addMaterial')
          }
          pmolID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Planned'}
        />

        <ResourceModal
          isOpen={this.state.isToolsModalVisible}
          openResourceModal={() => this.openToolsModal()}
          isEdit={this.state.isToolEdit}
          handleResourceSave={(resource: Resource) => {
            if(this.state.formData.bor){
              resource.borId=this.state.formData.bor.id
            }
            this.props.createTools(resource);
          }}
          resource={this.state.tool}
          title={this.state.isToolEdit ? i18n.t('editTool') : i18n.t('addTool')}
          pmolID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Planned'}
        />

        <LabourModal
          isOpen={this.state.isLabourModalVisible}
          openResourceModal={() => this.openLabourModal()}
          isEdit={this.state.isLabourEdit}
          handleResourceSave={(resource: Resource) => {
            if(this.state.formData.bor){
              resource.borId=this.state.formData.bor.id
            }
            let data: any = resource;
            data.teamRoleList = this.state.teamMembers;
            this.props.createLabour(data).then((res: any) => {
              this.setState({
                teamMembers: [],
              });
            });
          }}
          resource={this.props.selectedLabourResourceItem}
          title={this.state.isLabourEdit ? i18n.t('editLabour') : i18n.t('addLabour')}
          pmolID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Planned'}
          teamMembers={this.state.teamMembers}
          deleteTeamMembersStatus={this.props.deleteTeamMembersStatus}
        />

        <EmployeeModal
          isOpen={this.state.isEmployeeModalVisible}
          openResourceModal={() => this.openEmployeeModal()}
          isEdit={this.state.teamMemberIsEdit}
          handleResourceSave={(resource: Resource) => {
            this.props.createLabour(resource);
          }}
          teamMember={this.state.teamMember}
          title={
            this.state.teamMemberIsEdit ? i18n.t('editEmployee') : i18n.t('addEmployee')
          }
          pmolID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Planned'}
          userRoles={this.props.userRoles}
        />

        <EmployeeShiftModal
          isOpen={this.state.isEmployeeShiftModalVisible}
          openModal={() =>   this.openEmployeeShiftModal()}
          title={i18n.t('employeeShift')}
          shiftDetails={this.state.shiftDetails}
          selection={this._shiftSelection}
        />

        <EmployeeEditEndTimeModal
          isOpen={this.state.isEmployeeEditEndTimeModalVisible}
          openModal={() => this.openEmployeeEditEndTimeModal()}
          title={i18n.t('addEndTime')}
          teamMember={this.state.teamMember}
          pmolID={this.state.formData.id}
          isEndTimeUpdated={this.props.isEndTimeUpdated}
          closeModal={() => this.loadLabourTeamList()}
        />

        <ResourceModal
          isOpen={this.state.isConsumablesModalVisible}
          openResourceModal={() => this.openConsumaleModal()}
          isEdit={this.state.isConsumableEdit}
          handleResourceSave={(resource: Resource) => {
            if(this.state.formData.bor){
              resource.borId=this.state.formData.bor.id
            }
            this.props.createConsumable(resource);
          }}
          resource={this.state.consumable}
          title={
            this.state.isConsumableEdit
              ? i18n.t('editConsumable')
              : i18n.t('addConsumable')
          }
          pmolID={this.state.formData.id}
          cpcOptions={this.cpcOptions}
          handleCpcChange={(selectedCpc: any) => {
            this.getCpcDetails(selectedCpc);
          }}
          clearCpcData={() => this.clearCpcData()}
          cpcData={this.props.cpcData}
          type={'Planned'}
        />

        <RiskModal
          isOpen={this.state.isRiskModalVisible}
          openRiskModal={() => this.openRiskModal()}
          isEdit={this.state.isRiskEdit}
          onFilterPersonChanged={this.onFilterPersonChanged}
          createRisk={(risk: Risk) => this.createRisk(risk)}
          risk={this.state.risk}
        />

        <QualityModal
          isOpen={this.state.isQualityModalVisible}
          openQualityModal={() => this.openQualityModal()}
          isEdit={this.state.isQualityEdit}
          createQuality={(qality: Quality) => {
            this.props.createQuality(qality);
          }}
          quality={this.state.quality}
        />

        <InstructionModal
          isOpen={this.state.isOpenTechInstructionModal}
          openInstructionModal={() => this.openTechInstructionModal()}
          isEdit={this.state.techInstruction}
          // onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createTechInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={i18n.t('technicalInstructions')}
          selection={this._techLinksSelection}
          linkActionButtonState={this.state.techLinksActionButtonState}
          openLinkModal={() => this.openTechLinkModalModal()}
          //links={this.state.techInstructionLinks}
          handleLinkDelete={() => this.handleTechLinkDelete()}
          type={INSTRUCTION_TYPE.TECH}
          pbsId={this.state.formData &&
            this.state.formData.productId
              ? this.state.formData.productId
              : null}

          readByInstructionRegisterId={(id: string) =>
            this.props.readByInstructionRegisterId(id)
          }
        />

        <InstructionModal
          isOpen={this.state.isOpenSafetyInstructionModal}
          openInstructionModal={() => this.openSaftyInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createSaftyInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={i18n.t('saftyInstructions')}
          selection={this._saftyLinksSelection}
          linkActionButtonState={this.state.safetyLinksActionButtonState}
          openLinkModal={() => this.openSaftyLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={() => this.handleSaftyLinkDelete()}
          type={INSTRUCTION_TYPE.SAFTY}
          pbsId={this.state.formData &&
            this.state.formData.productId
              ? this.state.formData.productId
              : null}

          readByInstructionRegisterId={(id: string) =>
            this.props.readByInstructionRegisterId(id)
          }
        />

        <InstructionModal
          isOpen={this.state.isOpenEnvInstructionModal}
          openInstructionModal={() => this.openEnvInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createEnvInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={i18n.t('environmentInstruction')}
          selection={this._envLinksSelection}
          linkActionButtonState={this.state.envLinksActionButtonState}
          openLinkModal={() => this.openEnvLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={() => this.handleEnvLinkDelete()}
          type={INSTRUCTION_TYPE.ENVIRONMENT}
          pbsId={this.state.formData &&
            this.state.formData.productId
              ? this.state.formData.productId
              : null}

          readByInstructionRegisterId={(id: string) =>
            this.props.readByInstructionRegisterId(id)
          }
        />

        <InstructionModal
          isOpen={this.state.isOpenHelthInstructionModal}
          openInstructionModal={() => this.openHelthInstructionModal()}
          isEdit={this.state.techInstruction}
          //onFilterPersonChanged={this.onFilterPersonChanged}
          createInstruction={(instruction: Instruction) =>
            this.props.createHelthInstruction(instruction)
          }
          instruction={this.state.techInstruction}
          title={i18n.t('healthInstructions')}
          selection={this._helthLinksSelection}
          linkActionButtonState={this.state.helthLinksActionButtonState}
          openLinkModal={() => this.openHelthLinkModalModal()}
          //links={this.state.isEditSaftyLinks}
          handleLinkDelete={() => this.handleHelthLinkDelete()}
          type={INSTRUCTION_TYPE.HELTH}
          pbsId={this.state.formData &&
            this.state.formData.productId
              ? this.state.formData.productId
              : null}

          readByInstructionRegisterId={(id: string) =>
            this.props.readByInstructionRegisterId(id)
          }
        />

        <LinkModal
          isOpen={this.state.isOpenTechLinkModal}
          openLinkModal={() => this.openTechLinkModalModal()}
          isEdit={this.state.isEditTechLinks}
          instructionLink={this.state.techLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedTechInstructionLink}
          onAddInstructionLink={this.onAddTechInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenSafetyLinkModal}
          openLinkModal={() => this.openSaftyLinkModalModal()}
          isEdit={this.state.isEditSaftyLinks}
          instructionLink={this.state.safetyLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedSaftyInstructionLink}
          onAddInstructionLink={this.onAddSaftyInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenEnvLinkModal}
          openLinkModal={() => this.openEnvLinkModalModal()}
          isEdit={this.state.isEditEnvLinks}
          instructionLink={this.state.envLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedenvInstructionLink}
          onAddInstructionLink={this.onAddEnvInstructionLink}
        />

        <LinkModal
          isOpen={this.state.isOpenHelthLinkModal}
          openLinkModal={() => this.openHelthLinkModalModal()}
          isEdit={this.state.isEditHelthLinks}
          instructionLink={this.state.helthLink}
          onLinkFileChange={this.onLinkFileChange}
          link={this.state.uploadedhelthInstructionLink}
          onAddInstructionLink={this.onAddHelthInstructionLink}
        />

        <CompetenceModal
          isOpen={this.state.isCompetenceModalVisible}
          openCompetenceModal={() => this.openCompetenceModal()}
          isEdit={this.state.isCompetenceEdit}
          createCompetence={(competence: Competence) => {
            this.props.createCompetence(competence);
          }}
          dropDownOptions={{
            pbsExperience: this.props.pbsExperience,
            pbsSkills: this.props.pbsSkills,
          }}
          competence={this.state.competence}
          pbsId={this.state.formData &&
            this.state.formData.productId
              ? this.state.formData.productId
              : null}
        />

        <MapModal
          isOpen={this.state.isOpenMap}
          openMapModal={() => this.openMapModal()}
          setLocationAddress={(pos: Position, address?: LocationAddress) => {
            this.setLocationAddress(pos);
          }}
          position={this.state.position}
        />

        <SignatureModal
          isOpen={this.state.isSignatureModalOpen}
          openLinkModal={() => this.openSignatureModal()}
          isEdit={this.state.isEditHelthLinks}
          instructionLink={this.state.helthLink}
          onLinkFileChange={this.onSgnatureFileChange}
          link={this.state.signatureLink}
          onAddSignature={this.onAddSignature}
          onFilterPersonChanged={this.onFilterPersonChanged}
          pmolId={this.state.formData.id ? this.state.formData.id : ''}
        />

        {/* <ConfirmationDialog
          hidden={this.state.confimRiskDelete}
          title={'Are you sure?'}
          subText={"You won't be able to revert this!"}
          onClickConfirmDialog={this.onRemoveConfirm}
          onClickCancelDialog={this.onRemoveCancel}
          confirmButtonText={'Yes, delete it!'}
        /> */}
        <PmolPDFExport
          address={
          this.state.location &&
          this.state.location?.address &&
          this.state.location.address.freeformAddress
            ? this.state.location.address.freeformAddress
            : ''
          }
          pmolId={this.state.formData.projectMoleculeId}
          journal={this.state.journal}
          projectHeader={this.props.projectHeader}
        />

        <TRMapModal formData={this.state.formData}/>
      </div>
    );
  }
  private setJournal = (feild: any, value: any) => {
    this.setState((prevState) => ({
      journal: {
        ...prevState.journal,
        [feild]: value,
        pmolId: this.state.formData.id ? this.state.formData.id : '',
      },
    }));
  };

  PMOLSaveJournal = () => {
    this.props.saveJournal(this.state.journal);
  };
  
  private setJournalImage = (type: string, response: any) => {
    this.setState((prevState) => {
      const pictureList = Array.isArray(prevState.journal.pictureList) 
        ? [...prevState.journal.pictureList] 
        : [];
  
      return {
        journal: {
          ...prevState.journal,
          pmolId: this.state.formData.id ? this.state.formData.id : '',
          pictureList: [
            ...pictureList,
            response
          ],
        },
      };
    });
  };
  


  getPmolJournal = (id: string) => {
    this.props.getPmolJournal(id);
  };

  private getCpcDetails = (selectedCpc: any) => {
    if (selectedCpc && selectedCpc.value) {
      this.setState({
        selectedCpcEnvironment: selectedCpc.environment,
      });
      this.props.getCpcById(selectedCpc);
    }
  };

  private clearCpcData = () => {
    this.props.clearCpcData();
  };

  private isValidFileType = (fileType: string): boolean => {
    return acceptedTypes.includes(fileType);
  };
   getFileType = (type: string) => {
    let typeId;
    // console.log("FILE TYPE: ", type);

    switch (type) {
      case 'image/jpeg':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'image/png':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'image/webp':
        // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
        return typeId = 'FileImage';  //Image

      case 'application/pdf':
        // return typeId = 'oo10e768-msms-po02-Lot1-ee367a82adooo';  //PDF
        return typeId = 'PDF';  //PDF

      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'WordDocument';  //Word Document

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'ExcelDocument';  //Word Document

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
        return typeId = 'PowerPointDocument';  //Word Document

      default:
        return typeId = 'Website';   //URL
    }
  };
   onLinkFileChange = (acceptedFiles: File[]) => {
    // if (!this.isValidFileType(acceptedFiles[0].type)) {
    //         return;
    //       }
    const formData = new FormData();

    if (acceptedFiles.length > 0) {
    if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
      formData.append('file',acceptedFiles[0]);
      formData.append('id', this.props.formData.id!!);
      this.props.uploadInstructionDoc(formData);
    } else if (
      !_.isEmpty(this.state.formData) &&
      this.state.formData.id !== null
    ) {
      formData.append('file', acceptedFiles[0]);
      formData.append('id', this.state.formData.id);
      this.props.uploadInstructionDoc(formData);
    }
    // acceptedFiles[0] = null;
  
  }
  };
  // onLinkFileChange = (event: any) => {
  //   if (event && event.target && event.target.files && event.target.files[0]) {
  //     // if (!this.isValidFileType(event.target.files[0].type)) {
  //     //   return;
  //     // }
  //     const formData = new FormData();
  //     if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
  //       formData.append('file', event.target.files[0]);
  //       formData.append('id', this.props.formData.id!!);
  //       this.props.uploadInstructionDoc(formData);
  //     } else if (
  //       !_.isEmpty(this.state.formData) &&
  //       this.state.formData.id !== null
  //     ) {
  //       formData.append('file', event.target.files[0]);
  //       formData.append('id', this.state.formData.id);
  //       this.props.uploadInstructionDoc(formData);
  //     }
  //     event.target.value = null;
  //   }
  // };

  onSgnatureFileChange = (event: any) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      if (!this.isValidFileType(event.target.files[0].type)) {
        return;
      }
      const formData = new FormData();

      if (!_.isEmpty(this.props.formData) && this.props.formData.id !== null) {
        formData.append('file', event.target.files[0]);
        formData.append('id', this.props.formData.id!!);
        this.props.uploadSignature(formData);
      } else if (
        !_.isEmpty(this.state.formData) &&
        this.state.formData.id !== null
      ) {
        formData.append('file', event.target.files[0]);
        formData.append('id', this.state.formData.id);
        this.props.uploadSignature(formData);
      }
      event.target.value = null;
    }
  };

  private createRisk = (risk: Risk) => {
    this.props.createRisk(risk);
  };

  private getMaterialsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadMaterial?name=${name}`);
    return formatCpcResponse(response);
  };

  private getToolsByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadTool?name=${name}`);
    return formatCpcResponse(response);
  };

  private getLaboursByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadLabour?name=${name}`);
    return formatCpcResponse(response);
  };

  private getConsumalbesByName = async (name: string) => {
    let response = await client.get(`PbsResource/ReadConsumable?name=${name}`);
    return formatCpcResponse(response);
  };

  private getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`
    );
    return formatPersonResponse(response);
  };
  private getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(`CentralAddressBook/PersonFilterByName`, {
      fullName: name,
    });
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  private onFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByName(filterText);
    } else {
      return [];
    }
  };
  private onQualityFilterPersonChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getPersonByNameForQulity(filterText);
    } else {
      return [];
    }
  };

  private onFilterMaterialsChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getMaterialsByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterToolsChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getToolsByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterLaboursChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getLaboursByName(filterText);
    } else {
      return [];
    }
  };

  private onFilterConsumalesChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return this.getConsumalbesByName(filterText);
    } else {
      return [];
    }
  };

  private handleFormChange = (formData: ProjectMolecule, save: boolean) => {
    console.log({formData});
    this.setState({
      isDirty: true,
    });
    this.setState({
      formData: formData,
    });
  };

  private handleExtraChange = (extra : any[]) => { // To prevent deleted or copyToPbs images will save as like before If we trigger delete and then save
    if (Array.isArray(extra) && extra.length > 0) {
      let extraWork = this.state.extraWork;
      extraWork.extraWorkFiles = [];
      extraWork.imageList = [];
      let fileList = extra.map((item: any) => ({
        ...item,
        isDelete: false,
        link: item.image
      }))
      extraWork.extraWorkFiles.push(...fileList);
      extraWork.imageList.push(...fileList);
      this.setState({extraWork: extraWork});
    }
  }

  private handleJournalChange = (journal : any[]) => { // To prevent deleted or copyToPbs images will save as like before If we trigger delete and then save
    let journalList = this.state.journal;
    if (Array.isArray(journal) && journal.length > 0) {
      let pictureList = [];
      for(let i=0; i<journalList?.pictureList.length; i++){
        let fileDetails : any = journalList?.pictureList[i];
        const journalDetail = journal.find((item: any) => item.id===fileDetails?.id);
        if(journalDetail && !journalDetail.isDelete){ // for remove & update image
          fileDetails.isApproved = journalDetail.isApproved;
          fileDetails.isDelete = false;
          pictureList.push(fileDetails)
        } else if(!journalDetail){
          fileDetails.isDelete = false;
          pictureList.push(fileDetails)
        }
      }

      journalList.pictureList = pictureList;
      this.setState({ journal: journalList });
    }
  }

  private handleHandshakeChange = (handshakeDocuments: any[]) => { // To prevent deleted images will save as like before If we trigger delete and then save
    this.setState({handshakeDocs: handshakeDocuments});
  }

  private _getQualitySelectionDetails(): string | number | {} | [] {
    return this._qualitySelection.getSelection();
  }

  private _qualityEditDetails() {
    const selectionCount = this._qualitySelection.getSelectedCount();
    let quality = new Quality();

    if (selectionCount == 1) {
      this.setState({
        isQualityEdit: true,
      });
      UprinceLogger.log(
        '_nickNameSelection',
        this._qualitySelection.getSelection()
      );
      quality = this._qualitySelection.getSelection()[0] as Quality;
    } else {
      this.setState({
        isQualityEdit: false,
      });
    }
    return quality;
  }

  private _qualityDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedNickName = this._qualitySelection.getSelection() as SelectedNickNameItem[];
    savedDeleteList = selectedNickName.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: string }) => {
      return item.id;
    });
    return deleteList;
  }

  private _getQualityActionButtonState(): ActionButtonState {
    const selectionCount = this._qualitySelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private _getCompetenceSelectionDetails(): string | number | {} | [] {
    return this._competenceSelection.getSelection();
  }

  private _competenceEditDetails() {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let competence = new Competence();

    if (selectionCount == 1) {
      this.setState({
        isCompetenceEdit: true,
      });
      UprinceLogger.log(
        '_nickNameSelection',
        this._competenceSelection.getSelection()
      );
      competence = this._competenceSelection.getSelection()[0] as Competence;
    } else {
      this.setState({
        isCompetenceEdit: false,
      });
    }
    return competence;
  }

  private _competenceDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedNickName = this._competenceSelection.getSelection() as SelectedNickNameItem[];
    savedDeleteList = selectedNickName.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: string }) => {
      return item.id;
    });
    return deleteList;
  }

  private _getCompetenceActionButtonState(): ActionButtonState {
    const selectionCount = this._competenceSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }

    return disabled;
  }

  private handleCompetenceDelete = () => {
    let selectedList = this._competenceSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });

    if (selectedList.length > 0) {
      this.props.deleteCompetence(selectedList);
    }
    this._competenceSelection.setAllSelected(false);
  };

  private saveFormData = () => {
    console.log('Test Save')
    if (this.validate()) {
      let formData = this.state.formData;
      let isNew=!formData?.projectMoleculeId;
      formData.pmolType = this.state.pmolType;
      formData.locationId = this.props.pmolLocationId
      if (!formData.id){
        formData.id = uuid4()
      }
      this.props.saveFormData(formData).then((res: any) => {
        let pmol = res.payload.data.result;
        formData.title=pmol.title;
        formData.headerTitle=pmol.title;
        formData.projectMoleculeId=pmol.projectMoleculeId;
        formData.isSaved = true;

        this.setState(
          {
            formData,
            readExtraWork: false,
          },
          () => {
            if (this.state.pmolType === PMOL_TYPE.REGULAR) {
              this.props.updateBORStatus({
                borId: formData.bor.id!!,
                statusId: formData.bor.borStatusId!!,
              });
             // this.getResources();
              if(pmol.projectMoleculeId){
                //readByPMOLId

                this.props.readPMOLByID(pmol.projectMoleculeId).then((response: any)=>{
                  this.props.savePMOLStateAttr('isPMOLOpenFromVP',false)
                  if(isNew){
                      this.props.savePMOLStateAttr("pmolCreatedFromVP",true)
                      this.props.savePMOLStateAttr("pmolCreatedVP",response.payload.data.result)
                      // this.props.savePMOLStateAttr("updatedVPPMOL",response.payload.data.result)
                      let pmol = response?.payload?.data?.result;
                      this.checkResourcesCount(pmol);
                  } else {
                      this.props.savePMOLStateAttr("updatedVPPMOL",response.payload.data.result)
                      // this.props.savePMOLStateAttr("pmolUpdateFromVP",true)
                      // this.props.savePMOLStateAttr("pmolUpdateVP",response.payload.data.result)
                  }
                });
              }
            } else {
              this.props.createPMOLService({...this.state.service,pmolId:pmol.id, documents: this.state.service?.documents? this.state.service?.documents:[]}).then((res:any) => {
                if(pmol.projectMoleculeId){
                  //this.props.readByPMOLId(pmol.projectMoleculeId);
                  this.props.readPMOLByID(pmol.projectMoleculeId).then((response: any)=>{
                      if(isNew){
                          let pmol = response?.payload?.data?.result;
                          this.checkResourcesCount(pmol);
                      }
                  });
                  //this.props.readPBSService(formData.)
                }
              })
            }
          }
        );
        // if(pmol.projectMoleculeId){
        //   this.props.readByPMOLId(pmol.projectMoleculeId);
        // }
          if(!_.isEmpty(this.props.borData)){
              // this.checkResourcesCount(this.props.borData);
              this.props.resetBorFormData()
          }


      });
      this.setState({
        isDirty: false,
      });
    }
  };

    checkResourcesCount=(formData:any)=>{
        let bor=formData?.bor
        if(bor?.borResources){
            let  isValid=true
            let materialMissingCount=0;
            let toolsMissingCount=0;
            let consumableMissingCount=0;
            let labourMissingCount=0;
            let borResources:BorResources=bor?.borResources
            if(borResources.materials && borResources.materials.length!=formData.materialCount){
                materialMissingCount=formData.materialCount-borResources.materials.length;
                isValid=false;
            }
            if(borResources.tools && borResources.tools.length!=formData.toolsCount){
                toolsMissingCount=formData.toolsCount-borResources.tools.length;
                isValid=false;
            }
            if(borResources.consumable && borResources.consumable.length!=formData.consumableCount){

                consumableMissingCount=formData.consumableCount-borResources.consumable.length;
                isValid=false;
            }
            if(borResources.labour && borResources.labour.length!=formData.labourCount){
                labourMissingCount=formData.labourCount-borResources.labour.length;
                isValid=false;
            }

            // materialMissingCount=1;
            // isValid=false;

            if(!isValid){
                this.setState({
                    showError:true,
                    errorMsg:` ${this.props.t("resourcesMissingBORToPMOL")}
        ${materialMissingCount!=0? this.props.t("materials")+": "+materialMissingCount:""} 
        ${toolsMissingCount!=0? this.props.t("tools")+"Tools: "+toolsMissingCount:""} 
        ${consumableMissingCount!=0? this.props.t("consumable")+"Consumable: "+consumableMissingCount:""} 
        ${labourMissingCount!=0? this.props.t("labour")+": "+labourMissingCount:""}`
                })
                //setTimeout(()=>{this.setState({showError:false,errorMsg:''})},MSG_AUTO_CLOSE_TIME)
            }else{
            }

            // return {
            //   status:isValid,
            //   materialMissingCount:materialMissingCount,
            //   toolsMissingCount:toolsMissingCount,
            //   consumableMissingCount:consumableMissingCount,
            //   labourMissingCount:labourMissingCount
            // }
        }else{

        }

    }

  private savePmolExtraWorkData = (callGetById?:boolean) => {
    let extraWork = this.state.extraWork;
    extraWork.pmolId = this.state.formData.id;
    if(this.state.formData.isSaved || this.state.formData.projectMoleculeId){
      this.props.saveExtraWorkData(extraWork).then((res: any) => {
       if(callGetById){ this.props.readPmolExtraWorkData(this.state.formData.id);}
      });
    }
  };

  private validate = (): boolean => {
    let validationMessages = this.state.validationMessages;
    let name = this.state.formData.name;
    let type = this.state.formData.typeId;
    let bor = this.state.formData.bor?.itemId;
    let status = this.state.formData.statusId;
    let product = this.state.formData.productId;
    let pmolType = this.state.pmolType
    let quantity = this.state.service?.quantity;
    let mou = this.state.service?.mou;

    let isValid = true;
    if (_.isNil(name) || _.isEmpty(name)) {
      validationMessages.nameErrorMsg = i18n.t('nameRequired');
      isValid = false;
    } else {
      validationMessages.nameErrorMsg = '';
    }
    if (_.isNil(type) || _.isEmpty(type)) {
      validationMessages.typeErrorMsg = i18n.t('typeRequired');
      isValid = false;
    } else {
      validationMessages.typeErrorMsg = '';
    }
    if (_.isNil(bor) || _.isEmpty(bor)) {

      if (pmolType === PMOL_TYPE.SUB){
        validationMessages.borErrorMsg = "";

      } else {
        validationMessages.borErrorMsg = i18n.t('borRequired');
        isValid = false;
      }

    } else {
      validationMessages.borErrorMsg = '';
    }
    if (_.isNil(status) || _.isEmpty(status)) {

      isValid = false;
      if (pmolType === PMOL_TYPE.SUB){
        validationMessages.statusErrorMsg = "";
      } else {
        validationMessages.statusErrorMsg = i18n.t('statusRequired');
        isValid = false;
      }
    } else {
      validationMessages.statusErrorMsg = '';
    }
    if (_.isNil(product) || _.isEmpty(product)) {
      validationMessages.productTitleErrorMsg = i18n.t('productRequired');
      isValid = false;
    } else {
      validationMessages.productTitleErrorMsg = '';
    }
    if(pmolType===PMOL_TYPE.SUB){
      if (!_.isEmpty(quantity) && _.isNil(mou)) {
        validationMessages.mouErrorMsg = i18n.t('basicUnitOfMeasureRequired');
        this.setState({isMouValid: false});
        setTimeout(()=>{this.setState({isMouValid:true})},MSG_AUTO_CLOSE_TIME)
        isValid = false;
      } else {
        validationMessages.mouErrorMsg = '';
        this.setState({isMouValid: true});
      }
    }

    this.setState({ validationMessages: validationMessages });
    return isValid;
  };

  private clearPmolData=()=>{
    let formData={
      id: uuid4(),
      projectMoleculeId: null,
      title:null,
      headerTitle: null,
      productId:null,
      productTitle:null,
      projectId:null,
      name:null,
      typeId:null,
      statusId:null,
      foremanMobileNumber:null,
      executionDate:null,
      executionStartTime:null,
      executionEndTime:null,
      foreman:null,
      foremanId:null,
      comment:null,
      bor:BorFormData,
      locationId: null,
      historyLog:null,
      isSaved:false,
      typeNo: 0,
      projectDefinition:null,
      pmolType:PMOL_TYPE.REGULAR,
      isFinished:false,};
    formData.bor.borTitle = null;
    formData.bor.itemId = null;
    this.setState({
      formData,
      service:{
        projectTitle: null,
        productTitle: null,
        quantity: null,
        mou: null,
        unitPrice: null,
        totalPrice: null,
        documents: null,
        comments:null,

      }
    })
  };

  private resetValidation = () => {
    let validationMessages = this.state.validationMessages;
    validationMessages.typeErrorMsg = '';
    validationMessages.statusErrorMsg = '';
    validationMessages.borErrorMsg = '';
    validationMessages.nameErrorMsg = '';
    validationMessages.mouErrorMsg = '';

    this.setState({
      validationMessages: validationMessages,
      readExtraWork: false,
    });
  };

  private openRiskModal = (): void => {
    this.setState({
      isRiskModalVisible: !this.state.isRiskModalVisible,
      risk: this._riskEditDetails(),
    });
  };

  private openTechInstructionModal = (): void => {
    this.setState({
      isOpenTechInstructionModal: !this.state.isOpenTechInstructionModal,
      techInstruction: this._techInstructionEditDetails(),
    });
  };

  private openSaftyInstructionModal = (): void => {
    this.setState({
      isOpenSafetyInstructionModal: !this.state.isOpenSafetyInstructionModal,
      saftyInstruction: this._saftyInstructionEditDetails(),
    });
  };

  private openHelthInstructionModal = (): void => {
    this.setState({
      isOpenHelthInstructionModal: !this.state.isOpenHelthInstructionModal,
      helthInstruction: this._helthInstructionEditDetails(),
    });
  };

  private openEnvInstructionModal = (): void => {
    this.setState({
      isOpenEnvInstructionModal: !this.state.isOpenEnvInstructionModal,
      envInstruction: this._envInstructionEditDetails(),
    });
  };

  private openTechLinkModalModal = (): void => {
    this.setState({
      isOpenTechLinkModal: !this.state.isOpenTechLinkModal,
      techLink: this._techLinkEditDetails(),
      uploadedTechInstructionLink: '',
    });
  };

  private openSaftyLinkModalModal = (): void => {
    this.setState({
      isOpenSafetyLinkModal: !this.state.isOpenSafetyLinkModal,
      safetyLink: this._saftyLinkEditDetails(),
      uploadedSaftyInstructionLink: '',
    });
  };

  private openEnvLinkModalModal = (): void => {
    this.setState({
      isOpenEnvLinkModal: !this.state.isOpenEnvLinkModal,
      envLink: this._envLinkEditDetails(),
      uploadedenvInstructionLink: '',
    });
  };

  private openHelthLinkModalModal = (): void => {
    this.setState({
      isOpenHelthLinkModal: !this.state.isOpenHelthLinkModal,
      helthLink: this._helthLinkEditDetails(),
      uploadedhelthInstructionLink: '',
    });
  };

  private openMaterialModal = (): void => {
    this.setState({
      isMaterialModalVisible: !this.state.isMaterialModalVisible,
      material: this._materialEditDetails(),
      selectedResourceTypeId: ResourceTypes.materials.id,
    });
  };

  private openToolsModal = (): void => {
    this.setState({
      isToolsModalVisible: !this.state.isToolsModalVisible,
      tool: this._toolsEditDetails(),
      selectedResourceTypeId: ResourceTypes.tools.id,
    });
  };

  private openLabourModal = (): void => {
    // On initial load, isLabourModalVisible is false
    this.props.savePMOLStateAttr("selectedLabourResourceItem", !this.state.isLabourModalVisible ? this._labourEditDetails() : {})
    this.setState(
      {
        isLabourModalVisible: !this.state.isLabourModalVisible,
        labour: this._labourEditDetails(),
        selectedResourceTypeId: ResourceTypes.labour.id,
      },
      this.loadLabourTeamList
    );
  };
  private loadLabourTeamList = () => {
    if (this.state.isLabourModalVisible && this.state.isLabourEdit) {
      this.props.readTeamMembers(this.state.labour.id);
    } else {
      this.setState({
        teamMembers: [],
      });
    }
  };
  private openEmployeeModal = (): void => {
    this.setState({
      isEmployeeModalVisible: !this.state.isEmployeeModalVisible,
      labour: this._labourEditDetails(),
      selectedResourceTypeId: ResourceTypes.labour.id,
    });
  };

  private getEmpShiftData = (cabPerson?:string|null): void => {
    let filterData = {
      startDate: this.state.formData.executionDate,
      endDate: this.state.formData.executionDate,
      userName: cabPerson,
      statusId: null,
      startDateTime:null,
      userId: null,
      projectId: null,
      date: null,
      localDate: moment().toISOString(),
      offSet: getTimeZone(),
      sorter: {
        attribute: 'startDateTime',
        order: 'desc',
      },
    };
    this.props.getAllTimeClocks(filterData).then((res:any)=>{
      if(res?.payload?.data?.result && res?.payload?.data?.result[0]){
        let ShiftId=res?.payload?.data?.result[0]?.id;
        if(ShiftId){
          this.props.getTimeClockById(ShiftId).then(()=>{
            this.openEmployeeShiftModal(cabPerson)
          });
        }else{
          this.setState({
            shiftDetails:null
          })
          this.openEmployeeShiftModal(cabPerson)
        }
      }else{
        this.setState({
          shiftDetails:null
        })
        this.openEmployeeShiftModal(cabPerson)
      }
    })

  };

  private openEmployeeShiftModal = (cabPerson?:string|null): void => {
    this.setState({
      cabPerson:cabPerson?cabPerson:null,
      isEmployeeShiftModalVisible: !this.state.isEmployeeShiftModalVisible
    });
  };

  private openEmployeeEditEndTimeModal = (cabPerson?:string|null): void => {
    this.setState({
      cabPerson:cabPerson?cabPerson:null,
      isEmployeeEditEndTimeModalVisible: !this.state.isEmployeeEditEndTimeModalVisible
    });
  };

  private openConsumaleModal = (): void => {
    this.setState({
      isConsumablesModalVisible: !this.state.isConsumablesModalVisible,
      consumable: this._consumableEditDetails(),
      selectedResourceTypeId: ResourceTypes.consumable.id,
    });
  };

  private openQualityModal = (): void => {
    this.setState({
      isQualityModalVisible: !this.state.isQualityModalVisible,
      quality: this._qualityEditDetails(),
    });
  };

  private openMapModal = (): void => {
    this.setState({
      isOpenMap: !this.state.isOpenMap,
    });
  };

  private openSignatureModal = (): void => {
    this.setState({
      isSignatureModalOpen: !this.state.isSignatureModalOpen,
    });
  };

  private onChangeResourceFamily = (treeData: any): void => {
    if (treeData) {
      this.setState({ resourceFamily: treeData });
    }
    this.setState({
      isDirty: true,
    });
  };

  private openCompetenceModal = (): void => {
    this.setState({
      isCompetenceModalVisible: !this.state.isCompetenceModalVisible,
      competence: this._competenceEditDetails(),
    });
  };

  private _riskDeleteDetails() {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = this._riskSelection.getSelection() as Risk[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: { id: any }) => {
      return item.id;
    });
    return deleteList;
  }

  private _resourceDeleteDetails(selection: Selection) {
    let deleteList = [];
    let savedDeleteList = [];
    let selectedRisk = selection.getSelection() as Resource[];
    savedDeleteList = selectedRisk.filter((item: any) => {
      return item.id != null;
    });
    deleteList = savedDeleteList.map((item: any) => {
      return item.id;
    });
    return deleteList;
  }

  private handleRiskDelete = () => {
    const selectedList = this._riskSelection.getSelection().map((item: any) => {
      return item.pbsRiskId;
    });
    if (selectedList.length > 0) {
      this.props.deleteRisk(selectedList);
    }
    this._riskSelection.setAllSelected(false);
  };

  private handleMaterialsDelete = () => {
    const selectedList = this._materialsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteMaterials(selectedList);
    }
    this._materialsSelection.setAllSelected(false);
  };

  private handleTechLinkDelete = () => {
    let selectedList = [];
    selectedList = this._techLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.techInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.techInstruction;
      instruction.pbsInstructionLink = array;
      this.setState({ techInstruction: instruction }, () => {
        if (this.state.techInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            techLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    this._techLinksSelection.setAllSelected(false);
  };

  private handleSaftyLinkDelete = () => {
    let selectedList = [];
    selectedList = this._saftyLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.saftyInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.saftyInstruction;
      instruction.pbsInstructionLink = array;
      this.setState({ saftyInstruction: instruction }, () => {
        if (this.state.saftyInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            safetyLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    this._saftyLinksSelection.setAllSelected(false);
  };

  private handleHelthLinkDelete = () => {
    let selectedList = [];
    selectedList = this._helthLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.helthInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.helthInstruction;
      instruction.pbsInstructionLink = array;
      this.setState({ helthInstruction: instruction }, () => {
        if (this.state.helthInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            helthLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    this._saftyLinksSelection.setAllSelected(false);
  };

  private handleEnvLinkDelete = () => {
    let selectedList = [];
    selectedList = this._envLinksSelection.getSelectedIndices();
    if (selectedList.length > 0) {
      let array = this.state.envInstruction.pbsInstructionLink;
      let removed = _.pullAt(array, selectedList);
      let instruction = this.state.envInstruction;
      instruction.pbsInstructionLink = array;
      this.setState({ envInstruction: instruction }, () => {
        if (this.state.envInstruction.pbsInstructionLink.length === 0) {
          this.setState({
            envLinksActionButtonState: {
              add: false,
              edit: true,
              remove: true,
            },
          });
        }
      });
    }
    this._envLinksSelection.setAllSelected(false);
  };

  private handleToolsDelete = () => {
    const selectedList = this._toolsSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteTools(selectedList);
    }
    this._toolsSelection.setAllSelected(false);
  };

  private handleLaboursDelete = () => {
    const selectedList = this._labourSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteLabours(selectedList);
    }
    this._labourSelection.setAllSelected(false);
  };

  private handleConsumableDelete = () => {
    const selectedList = this._consumableSelection
      .getSelection()
      .map((item: any) => {
        return item.id;
      });
    if (selectedList.length > 0) {
      this.props.deleteConsumable(selectedList);
    }
    this._consumableSelection.setAllSelected(false);
  };

  private handleTechInstructionDelete = () => {
    const selectedList = this._techInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteTechInstruction(selectedList);
    }
    this._techInstructionSelection.setAllSelected(false);
  };

  private handleSaftyInstructionDelete = () => {
    const selectedList = this._saftyInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteSaftyInstruction(selectedList);
    }
    this._saftyInstructionSelection.setAllSelected(false);
  };

  private handleEnvInstructionDelete = () => {
    const selectedList = this._envInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteEnvInstruction(selectedList);
    }
    this._envInstructionSelection.setAllSelected(false);
  };

  private handleHelthInstructionDelete = () => {
    const selectedList = this._helthInstructionSelection
      .getSelection()
      .map((item: any) => {
        return item.pbsInstructionId;
      });
    if (selectedList.length > 0) {
      this.props.deleteHelthInstruction(selectedList);
    }
    this._helthInstructionSelection.setAllSelected(false);
  };

  private handleQualityDelete = () => {
    let selectedList = this._qualitySelection
      .getSelection()
      .map((item: any) => {
        return item.uid;
      });

    if (selectedList.length > 0) {
      this.props.deleteQuality(selectedList);
    }
    this._qualitySelection.setAllSelected(false);
  };

  private _getRiskSelectionDetails(): string | number | {} | [] {
    return this._riskSelection.getSelection();
  }

  private _getMaterialSelectionDetails(): string | number | {} | [] {
    return this._materialsSelection.getSelection();
  }

  private _getToolsSelectionDetails(): string | number | {} | [] {
    return this._toolsSelection.getSelection();
  }

  private _getLabourSelectionDetails(): string | number | {} | [] {
    return this._labourSelection.getSelection();
  }

  private _getConsumableSelectionDetails(): string | number | {} | [] {
    return this._consumableSelection.getSelection();
  }

  private _getTechLinkSelectionDetails(): string | number | {} | [] {
    return this._techLinksSelection.getSelection();
  }

  private _getSaftyLinkSelectionDetails(): string | number | {} | [] {
    return this._saftyLinksSelection.getSelection();
  }

  private _getEnvLinkSelectionDetails(): string | number | {} | [] {
    return this._envLinksSelection.getSelection();
  }

  private _getHelthLinkSelectionDetails(): string | number | {} | [] {
    return this._helthLinksSelection.getSelection();
  }

  private _getTechInstructionSelectionDetails(): string | number | {} | [] {
    return this._techInstructionSelection.getSelection();
  }

  private _getSaftyInstructionSelectionDetails(): string | number | {} | [] {
    return this._saftyInstructionSelection.getSelection();
  }

  private _getEnvInstructionSelectionDetails(): string | number | {} | [] {
    return this._envInstructionSelection.getSelection();
  }

  private _getHelthInstructionSelectionDetails(): string | number | {} | [] {
    return this._helthInstructionSelection.getSelection();
  }

  private _riskEditDetails() {
    const selectionCount = this._riskSelection.getSelectedCount();
    let risk = new ReadRisk();

    if (selectionCount == 1) {
      this.setState({
        isRiskEdit: true,
      });

      let selectedRisk = this._riskSelection.getSelection()[0] as ReadRisk;
      risk = selectedRisk;
      //assign
    } else {
      this.setState({
        isRiskEdit: false,
      });
    }
    return risk;
  }

  private _techLinkEditDetails() {
    const selectionCount = this._techLinksSelection.getSelectedCount();
    let techLink = new InstructionLink();

    if (selectionCount == 1) {
      let index = this._techLinksSelection.getSelectedIndices();
      this.setState({
        isEditTechLinks: true,
        editTechLinkIndex: index[0],
      });

      let selectedLink = this._techLinksSelection.getSelection()[0] as InstructionLink;
      techLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditTechLinks: false,
        editTechLinkIndex: -1,
      });
    }
    return techLink;
  }

  private _saftyLinkEditDetails() {
    const selectionCount = this._saftyLinksSelection.getSelectedCount();
    let techLink = new InstructionLink();

    if (selectionCount == 1) {
      let index = this._saftyLinksSelection.getSelectedIndices();
      this.setState({
        isEditSaftyLinks: true,
        editSaftyLinkIndex: index[0],
      });

      let selectedLink = this._saftyLinksSelection.getSelection()[0] as InstructionLink;
      techLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyLinks: false,
        editSaftyLinkIndex: -1,
      });
    }
    return techLink;
  }

  private _envLinkEditDetails() {
    const selectionCount = this._envLinksSelection.getSelectedCount();
    let envLink = new InstructionLink();

    if (selectionCount == 1) {
      let index = this._envLinksSelection.getSelectedIndices();
      this.setState({
        isEditEnvLinks: true,
        editEnvLinkIndex: index[0],
      });

      let selectedLink = this._envLinksSelection.getSelection()[0] as InstructionLink;
      envLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyLinks: false,
        editSaftyLinkIndex: -1,
      });
    }
    return envLink;
  }

  private _helthLinkEditDetails() {
    const selectionCount = this._helthLinksSelection.getSelectedCount();
    let helthLink = new InstructionLink();

    if (selectionCount == 1) {
      let index = this._helthLinksSelection.getSelectedIndices();
      this.setState({
        isEditHelthLinks: true,
        editHelthLinkIndex: index[0],
      });

      let selectedLink = this._helthLinksSelection.getSelection()[0] as InstructionLink;
      helthLink = selectedLink;
      //assign
    } else {
      this.setState({
        isEditHelthLinks: false,
        editHelthLinkIndex: -1,
      });
    }
    return helthLink;
  }

  private _techInstructionEditDetails() {
    const selectionCount = this._techInstructionSelection.getSelectedCount();
    let techInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditTechInstruction: true,
      });

      let selectedLink = this._techInstructionSelection.getSelection()[0] as Instruction;
      techInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditTechInstruction: false,
      });
    }
    return techInstruction;
  }

  private _saftyInstructionEditDetails() {
    const selectionCount = this._saftyInstructionSelection.getSelectedCount();
    let saftyInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditSaftyInstruction: true,
      });

      let selectedLink = this._saftyInstructionSelection.getSelection()[0] as Instruction;
      saftyInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditSaftyInstruction: false,
      });
    }
    return saftyInstruction;
  }

  private _envInstructionEditDetails() {
    const selectionCount = this._envInstructionSelection.getSelectedCount();
    let envInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditEnvInstruction: true,
      });

      let selectedLink = this._envInstructionSelection.getSelection()[0] as Instruction;
      envInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditEnvInstruction: false,
      });
    }
    return envInstruction;
  }

  private _helthInstructionEditDetails() {
    const selectionCount = this._helthInstructionSelection.getSelectedCount();
    let helthInstruction = new Instruction();

    if (selectionCount == 1) {
      this.setState({
        isEditHelthInstruction: true,
      });

      let selectedLink = this._helthInstructionSelection.getSelection()[0] as Instruction;
      helthInstruction = selectedLink;
      //assign
    } else {
      this.setState({
        isEditHelthInstruction: false,
      });
    }
    return helthInstruction;
  }

  private _materialEditDetails() {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let material = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isMaterialEdit: true,
      });

      let selectedMaterial = this._materialsSelection.getSelection()[0] as Resource;
      material = selectedMaterial;
      //assign
    } else {
      this.setState({
        isMaterialEdit: false,
      });
    }
    return material;
  }

  private _toolsEditDetails() {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let tool = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isToolEdit: true,
      });

      let selectedTool = this._toolsSelection.getSelection()[0] as Resource;
      tool = selectedTool;
      //assign
    } else {
      this.setState({
        isToolEdit: false,
      });
    }
    return tool;
  }

  private _labourEditDetails() {
    const selectionCount = this._labourSelection.getSelectedCount();
    let labour = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isLabourEdit: true,
      });

      let selectedLabour = this._labourSelection.getSelection()[0] as Resource;
      labour = selectedLabour;
      //assign
    } else {
      this.setState({
        isLabourEdit: false,
      });
    }
    return labour;
  }

  private _consumableEditDetails() {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let consumable = new Resource();

    if (selectionCount == 1) {
      this.setState({
        isConsumableEdit: true,
      });

      let selectedConsumable = this._consumableSelection.getSelection()[0] as Resource;
      consumable = selectedConsumable;
      //assign
    } else {
      this.setState({
        isConsumableEdit: false,
      });
    }
    return consumable;
  }

  private _getRiskActionButtonState(): ActionButtonState {
    const selectionCount = this._riskSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _getMaterialActionButtonState(): ActionButtonState {
    const selectionCount = this._materialsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    UprinceLogger.log('_vendorSelection', disabled);
    return disabled;
  }

  private _getToolsActionButtonState(): ActionButtonState {
    const selectionCount = this._toolsSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getLabourActionButtonState(): ActionButtonState {
    const selectionCount = this._labourSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getConsumableActionButtonState(): ActionButtonState {
    const selectionCount = this._consumableSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getTechLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._techLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getSaftyLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._saftyLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getEnvLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._envLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getHelthLinkActionButtonState(): ActionButtonState {
    const selectionCount = this._helthLinksSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getTechInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._techInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getSaftyInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._saftyInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getEnvInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._envInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }

  private _getHelthInstructionActionButtonState(): ActionButtonState {
    const selectionCount = this._helthInstructionSelection.getSelectedCount();
    let disabled: ActionButtonState;

    if (selectionCount == 1) {
      disabled = {
        add: true,
        edit: false,
        remove: false,
      };
    } else if (selectionCount > 1) {
      disabled = {
        add: true,
        edit: true,
        remove: false,
      };
    } else {
      disabled = {
        add: false,
        edit: true,
        remove: true,
      };
    }
    return disabled;
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isEndTimeUpdated: state.pmol.isEndTimeUpdated,
    loadApproveMsg: state.pmol.loadApproveMsg,
    borData: state.billOfResource.formData,
    borStatusDropdowns: state.pmol.borStatus,
    pmolDropDowns: state.pmol.pmolDropDowns,
    isEdit: state.pmol.isEdit,
    isNew: state.pmol.isNew,
    uid: state.pmol.uid,
    borResources: state.pmol.borResources,
    materialList: state.pmol.materials,
    tools: state.pmol.tools,
    consumables: state.pmol.consumables,
    labours: state.pmol.labours,
    projectHeader: state.project.projectHeader,
    pmolLocation: state.pmol.pmolLocation,
    pmolLocationId: state.pmol.pmolLocationId,
    createMaterialsStatus: state.pmol.createMaterialsStatus,
    readMaterialsStatus: state.pmol.readMaterialsStatus,
    createToolsStatus: state.pmol.createToolsStatus,
    readToolsStatus: state.pmol.readToolsStatus,
    readConsumableStatus: state.pmol.readConsumableStatus,
    createConsumableStatus: state.pmol.createConsumableStatus,
    readLabourStatus: state.pmol.readLabourStatus,
    createLabourStatus: state.pmol.createLabourStatus,
    signatureLink: state.pmol.signatureLink,
    stopHandshakeStatus: state.pmol.stopHandshakeStatus,
    handshakes: state.pmol.stopHandShakes,
    handshakeDocs: state.pmol.handshakeDocs,
    readHandShakeStatus: state.pmol.readHandShakeStatus,
    journal: state.pmol.journal,
    extraWork: state.pmol.extraWork,
    showProgressBar: state.pmol.showProgressBar,
    userRoles: state.pmol.userRoles,
    teamMembers: state.pmol.teamMembers,

    cpcData: state.billOfResource.cpcData,
    riskList: state.pmol.riskList,
    readRiskStatus: state.pmol.readRiskStatus,
    createRiskStatus: state.projectBreakdown.createRiskStatus,
    deleteRiskStatus: state.projectBreakdown.deleteRiskStatus,
    qualityList: state.pmol.qualityList,
    readQualityStatus: state.pmol.readQualityStatus,
    createQualityStatus: state.projectBreakdown.createQualityStatus,
    deleteQualityStatus: state.projectBreakdown.deleteQualityStatus,
    toleranceStates: state.projectBreakdown.toleranceStates,
    productStates: state.projectBreakdown.productStates,
    itemTypes: state.projectBreakdown.itemTypes,
    saveRiskStatus: state.projectBreakdown.saveRiskStatus,
    newRiskId: state.projectBreakdown.newRiskId,
    saveQualityStatus: state.projectBreakdown.saveQualityStatus,
    newQualityId: state.projectBreakdown.newQualityId,
    deleteMaterialsStatus: state.projectBreakdown.deleteMaterialsStatus,
    deleteToolsStatus: state.projectBreakdown.deleteToolsStatus,
    deleteLabourStatus: state.projectBreakdown.deleteLabourStatus,
    deleteConsumableStatus: state.projectBreakdown.deleteConsumableStatus,
    readTechInstructionStatus: state.pmol.readTechInstructionStatus,
    techInstructions: state.pmol.techInstructions,
    uploadedInstructionLink: state.projectBreakdown.uploadedInstructionLink,
    instructionUploadStatus: state.projectBreakdown.instructionUploadStatus,
    locationTaxonomy: state.pmol.locationTaxonomy,
    utilityTaxonomy: state.pmol.utilityTaxonomy,
    competenceList: state.pmol.competenceList,
    pbsSkills: state.projectBreakdown.pbsSkills,
    pbsExperience: state.projectBreakdown.pbsExperience,
    saveCompetenceStatus: state.projectBreakdown.saveCompetenceStatus,
    deleteCompetenceStatus: state.projectBreakdown.deleteCompetenceStatus,
    createTechInstructionStatus:
      state.projectBreakdown.createTechInstructionStatus,
    deleteTechInstructionStatus:
      state.projectBreakdown.deleteTechInstructionStatus,
    readSaftyInstructionStatus: state.pmol.readSaftyInstructionStatus,
    createSaftyInstructionStatus:
      state.projectBreakdown.createSaftyInstructionStatus,
    saftyInstructions: state.pmol.saftyInstructions,
    deleteSaftyInstructionStatus:
      state.projectBreakdown.deleteSaftyInstructionStatus,
    readEnvInstructionStatus: state.pmol.readEnvInstructionStatus,
    createEnvInstructionStatus:
      state.projectBreakdown.createEnvInstructionStatus,
    envInstructions: state.pmol.envInstructions,
    deleteEnvInstructionStatus:
      state.projectBreakdown.deleteEnvInstructionStatus,
    readHelthInstructionStatus: state.pmol.readHelthInstructionStatus,
    createHelthInstructionStatus:
      state.projectBreakdown.createHelthInstructionStatus,
    helthInstructions: state.pmol.helthInstructions,
    deleteHelthInstructionStatus:
      state.projectBreakdown.deleteHelthInstructionStatus,
    locationFromPMOL:state.pmol.locationFromPMOL,
    newPMOLCreated: state.pmol.newPMOLCreated,
    handshakeDataFetched: state.pmol.handshakeDataFetched,
    plannedWorkFetched: state.pmol.plannedWorkFetched,
    service: state.pmol.service,
    readPMOLServiceStatus: state.pmol.readPMOLServiceStatus,
    shiftDetails: state.timeClock.selectedTimeClock,
    selectedInstruction: state.ir.formData,
    selectedBORforPMOL: state.vp.selectedBORforPMOL,
    slectedPBSForPMOL: state.vp.slectedPBSForPMOL,
    deleteTeamMembersStatus: state.pmol.deleteTeamMembersStatus,
    selectedLabourResourceItem: state.pmol.selectedLabourResourceItem,
  };
};
const mapDispatchToProps = {
  saveFormData,
  savePbsInstruction,
  getBorById,
  getProjectHeaderDetails,
  readResourcesByBorId,
  readLocationsByProjectId,
  createPMOLLocation,
  startHandshake,
  readPmolMaterialsData,
  readPmolToolsData,
  readPmolConsumableData,
  readPmolLaboursData,
  readPmolPlannedWorkResources,
  getCpcById,
  clearCpcData,
  clearPmolData,
  createMaterials,
  createTools,
  uploadSignature,
  stopHandshake,
  readStopHandshakes,
  saveHandshakeDocuments,
  readPmolExtraWorkData,
  saveExtraWorkData,
  getUserRoles,
  readTeamMembers,
  deleteTeamMembers,

  getRiskDropDownsList,
  createRisk,
  readRiskList,
  deleteRisk,
  readQualityList,
  createQuality,
  deleteQuality,
  readMaterialsData,
  savePbsRisk,
  resetRiskGridList,
  savePbsQuality,
  readToolsData,
  deleteMaterials,
  deleteTools,
  readLabourData,
  createLabour,
  deleteLabours,
  createConsumable,
  deleteConsumable,
  readConsumableData,
  readInstructionDropDown,
  readPMOLTechInstruction,
  uploadInstructionDoc,
  readTaxonomyData,
  createTechInstruction,
  filterCompetenceList,
  readCompetenceDropDown,
  createCompetence,
  deleteCompetence,
  deleteTechInstruction,
  createSaftyInstruction,
  readPMOLSaftyInstruction,
  deleteSaftyInstruction,
  createEnvInstruction,
  readPMOLEnvInstruction,
  deleteEnvInstruction,
  createHelthInstruction,
  readPMOLHelthInstruction,
  deleteHelthInstruction,
  saveJournal,
  getPmolJournal,
  clearPBSData,
  readBORStatusDropDowns,
  updateBORStatus,
  readPMOLLocationById,
  approvedPmol,
  readByPMOLId,
  readPmolData,
  showProgressbarPmol,
  setPlannedWorkFetched,
  readPMOLAllInstruction,
  readPMOLByID,
  finishedPmol,
  createPMOLService,
  readPBSService,
  getPBSData,
  getAllTimeClocks,
  getTimeClockById,
  resetBorFormData,
  savePMOLStateAttr
};

export enum INSTRUCTION_TYPE {
  TECH = '100',
  ENVIRONMENT = '200',
  SAFTY = '300',
  HELTH = '400',
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentPane))
);
