import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import {
  CBC_EXCEL_UPLOAD,
  CBC_EXCEL_UPLOAD_FAIL,
  CBC_EXCEL_UPLOAD_SUCCESS,
  CLEAR_PBS_FORM_DATA,
  CLEAR_PBS_INS_DATA,
  CLEAR_PBS_UID,
  CLONE_PBS,
  CLONE_PBS_FAIL,
  CLONE_PBS_SUCCESS,
  CREATE_COMPETENCE,
  CREATE_COMPETENCE_FAIL,
  CREATE_COMPETENCE_SUCCESS,
  CREATE_CONSUMABLE,
  CREATE_CONSUMABLE_FAIL,
  CREATE_CONSUMABLE_SUCCESS,
  CREATE_ENV_INSTRUCTION,
  CREATE_ENV_INSTRUCTION_FAIL,
  CREATE_ENV_INSTRUCTION_SUCCESS,
  CREATE_HELTH_INSTRUCTION,
  CREATE_HELTH_INSTRUCTION_FAIL,
  CREATE_HELTH_INSTRUCTION_SUCCESS,
  CREATE_LABOUR,
  CREATE_LABOUR_FAIL,
  CREATE_LABOUR_SUCCESS,
  CREATE_MILESTONE,
  CREATE_MILESTONE_FAIL,
  CREATE_MILESTONE_SUCCESS,
  CREATE_PBS_FOLDER,
  CREATE_PBS_FOLDER_FAIL,
  CREATE_PBS_FOLDER_SUCCESS,
  CREATE_PBS_SERVICE,
  CREATE_PBS_SERVICE_FAIL,
  CREATE_PBS_SERVICE_SUCCESS,
  CREATE_PMOL_WITH_RESOURCE,
  CREATE_PMOL_WITH_RESOURCE_FAIL,
  CREATE_PMOL_WITH_RESOURCE_SUCCESS,
  CREATE_QUALITY,
  CREATE_QUALITY_FAIL,
  CREATE_QUALITY_SUCCESS,
  CREATE_RESOURCE,
  CREATE_RESOURCE_FAIL,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RISK,
  CREATE_RISK_FAIL,
  CREATE_RISK_SUCCESS,
  CREATE_SAFTY_INSTRUCTION,
  CREATE_SAFTY_INSTRUCTION_FAIL,
  CREATE_SAFTY_INSTRUCTION_SUCCESS,
  CREATE_TAXONOMY_NODE,
  CREATE_TAXONOMY_NODE_FAIL,
  CREATE_TAXONOMY_NODE_SUCCESS,
  CREATE_TECH_INSTRUCTION,
  CREATE_TECH_INSTRUCTION_FAIL,
  CREATE_TECH_INSTRUCTION_SUCCESS,
  CREATE_TOOLS,
  CREATE_TOOLS_FAIL,
  CREATE_TOOLS_SUCCESS,
  DELETE_COMPETENCE,
  DELETE_COMPETENCE_FAIL,
  DELETE_COMPETENCE_SUCCESS,
  DELETE_CONSUMABLE,
  DELETE_CONSUMABLE_FAIL,
  DELETE_CONSUMABLE_SUCCESS,
  DELETE_ENV_INSTRUCTION,
  DELETE_ENV_INSTRUCTION_FAIL,
  DELETE_ENV_INSTRUCTION_SUCCESS,
  DELETE_HELTH_INSTRUCTION,
  DELETE_HELTH_INSTRUCTION_FAIL,
  DELETE_HELTH_INSTRUCTION_SUCCESS,
  DELETE_LABOURS,
  DELETE_LABOURS_FAIL,
  DELETE_LABOURS_SUCCESS,
  DELETE_MATERIALS,
  DELETE_MATERIALS_FAIL,
  DELETE_MATERIALS_SUCCESS,
  DELETE_PBS,
  DELETE_PBS_FAIL,
  DELETE_PBS_SUCCESS,
  DELETE_QUALITY,
  DELETE_QUALITY_FAIL,
  DELETE_QUALITY_SUCCESS,
  DELETE_RISK,
  DELETE_RISK_FAIL,
  DELETE_RISK_SUCCESS,
  DELETE_SAFTY_INSTRUCTION,
  DELETE_SAFTY_INSTRUCTION_FAIL,
  DELETE_SAFTY_INSTRUCTION_SUCCESS,
  DELETE_TECH_INSTRUCTION,
  DELETE_TECH_INSTRUCTION_FAIL,
  DELETE_TECH_INSTRUCTION_SUCCESS,
  DELETE_TOOLS,
  DELETE_TOOLS_FAIL,
  DELETE_TOOLS_SUCCESS,
  GET_PBS_BY_ID,
  GET_PBS_BY_ID_FAIL,
  GET_PBS_BY_ID_FOR_TREESTRUCTURE,
  GET_PBS_BY_ID_FOR_TREESTRUCTURE_FAIL,
  GET_PBS_BY_ID_FOR_TREESTRUCTURE_SUCCESS,
  GET_PBS_BY_ID_SUCCESS,
  LOAD_COMPETENCE,
  LOAD_COMPETENCE_DROPDOWN,
  LOAD_COMPETENCE_DROPDOWN_FAIL,
  LOAD_COMPETENCE_DROPDOWN_SUCCESS,
  LOAD_COMPETENCE_FAIL,
  LOAD_COMPETENCE_SUCCESS,
  LOAD_DROPDOWN_DATA,
  LOAD_DROPDOWN_DATA_FAIL,
  LOAD_DROPDOWN_DATA_SUCCESS,
  LOAD_ENV_INSTRUCTION,
  LOAD_ENV_INSTRUCTION_FAIL,
  LOAD_ENV_INSTRUCTION_SUCCESS,
  LOAD_HELTH_INSTRUCTION,
  LOAD_HELTH_INSTRUCTION_FAIL,
  LOAD_HELTH_INSTRUCTION_SUCCESS,
  LOAD_INSTRUCTION_DROPDOWN,
  LOAD_INSTRUCTION_DROPDOWN_FAIL,
  LOAD_INSTRUCTION_DROPDOWN_SUCCESS,
  LOAD_LIST_PANE_LIST,
  LOAD_LIST_PANE_LIST_FAIL,
  LOAD_LIST_PANE_LIST_SUCCESS,
  LOAD_MATERIALS,
  LOAD_MATERIALS_FAIL,
  LOAD_MATERIALS_SUCCESS,
  LOAD_MILESTONE,
  LOAD_MILESTONE_FAIL,
  LOAD_MILESTONE_SUCCESS,
  LOAD_QUALITY,
  LOAD_QUALITY_FAIL,
  LOAD_QUALITY_SUCCESS,
  LOAD_RISK_DROPDOWN,
  LOAD_RISK_DROPDOWN_FAIL,
  LOAD_RISK_DROPDOWN_SUCCESS,
  LOAD_SAFTY_INSTRUCTION,
  LOAD_SAFTY_INSTRUCTION_FAIL,
  LOAD_SAFTY_INSTRUCTION_SUCCESS,
  LOAD_SHORTCUT_PANE_FILTER_LIST,
  LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL,
  LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS,
  LOAD_TAXONOMY,
  LOAD_TAXONOMY_FAIL,
  LOAD_TAXONOMY_SUCCESS,
  LOAD_TECH_INSTRUCTION,
  LOAD_TECH_INSTRUCTION_FAIL,
  LOAD_TECH_INSTRUCTION_SUCCESS,
  READ_CBC_CONTRACTOR,
  READ_CBC_CONTRACTOR_FAIL,
  READ_CBC_CONTRACTOR_SUCCESS,
  READ_CBC_TO_PBS,
  READ_CBC_TO_PBS_FAIL,
  READ_CBC_TO_PBS_SUCCESS,
  READ_CONSUMABLE,
  READ_CONSUMABLE_FAIL,
  READ_CONSUMABLE_SUCCESS,
  READ_CPC_RELATIONS,
  READ_CPC_RELATIONS_FAIL,
  READ_CPC_RELATIONS_SUCCESS,
  READ_DELIVERABLES_TO_PBS,
  READ_DELIVERABLES_TO_PBS_FAIL,
  READ_DELIVERABLES_TO_PBS_SUCCESS,
  READ_LABOURS,
  READ_LABOURS_FAIL,
  READ_LABOURS_SUCCESS,
  READ_PBS_LABOUR,
  READ_PBS_LABOUR_FAIL,
  READ_PBS_LABOUR_SUCCESS,
  READ_PBS_RELATIONS,
  READ_PBS_RELATIONS_FAIL,
  READ_PBS_RELATIONS_SUCCESS,
  READ_PBS_SERVICE,
  READ_PBS_SERVICE_FAIL,
  READ_PBS_SERVICE_SUCCESS,
  READ_PBS_VP_RESOURCES,
  READ_PBS_VP_RESOURCES_FAIL,
  READ_PBS_VP_RESOURCES_SUCCESS,
  READ_RISK,
  READ_RISK_FAIL,
  READ_RISK_SUCCESS,
  READ_TAXONOMY_LEVEL,
  READ_TAXONOMY_LEVEL_FAIL,
  READ_TAXONOMY_LEVEL_SUCCESS,
  READ_TOOLS,
  READ_TOOLS_FAIL,
  READ_TOOLS_SUCCESS,
  REMOVE_CBC_TO_PBS,
  REMOVE_CBC_TO_PBS_FAIL,
  REMOVE_CBC_TO_PBS_SUCCESS,
  RESET_FORM_DATA,
  RESET_IS_CHANGE,
  RESET_RISK_GRID_LIST,
  SAVE_CBC_TO_PBS,
  SAVE_CBC_TO_PBS_FAIL,
  SAVE_CBC_TO_PBS_SUCCESS,
  SAVE_PBS,
  SAVE_PBS_FAIL,
  SAVE_PBS_INSTRUCTION,
  SAVE_PBS_INSTRUCTION_FAIL,
  SAVE_PBS_INSTRUCTION_SUCCESS,
  SAVE_PBS_QUALITY,
  SAVE_PBS_QUALITY_FAIL,
  SAVE_PBS_QUALITY_SUCCESS,
  SAVE_PBS_RISK,
  SAVE_PBS_RISK_FAIL,
  SAVE_PBS_RISK_SUCCESS,
  SAVE_PBS_SCOPE_OF_WORK,
  SAVE_PBS_SCOPE_OF_WORK_FAIL,
  SAVE_PBS_SCOPE_OF_WORK_SUCCESS,
  SAVE_PBS_STATE_ATTR,
  SAVE_PBS_SUCCESS,
  SET_PBS_UID,
  UPLOAD_INSTRUCTION_DOC,
  UPLOAD_INSTRUCTION_DOC_FAIL,
  UPLOAD_INSTRUCTION_DOC_SUCCESS,
} from '../actionTypes/projectBreakdownType';
import {
  CLONE_PBS_EP,
  COMPETENCE_FILTER_EP,
  CREATE_COMPETENCE_EP,
  CREATE_CONSUMABLE_EP,
  CREATE_INSTRUCTION_EP,
  CREATE_LABOUR_EP,
  CREATE_MATERIALS_EP,
  CREATE_PMOL_PBS_EP,
  CREATE_QUALITY_EP,
  CREATE_RISK_EP,
  CREATE_TAXONOMY_NODE_EP,
  CREATE_TOOLS_EP,
  DELETE_COMPETENCE_EP,
  DELETE_CONSUMABLE_EP,
  DELETE_LABOUR_EP,
  DELETE_MATERIALS_EP,
  DELETE_PBS_EP,
  DELETE_QUALITY_EP,
  DELETE_RISK_EP,
  DELETE_TECH_INSTRUCTION_EP,
  DELETE_TOOLS_EP,
  GET_PBS_LABOUR,
  getConsumableByProductIdURL,
  getLabourByProductIdURL,
  getMaterialByProductIdURL,
  getPbsInstructionByType,
  getToolsByProductIdURL,
  PBS_CBC_UPLOAD_EP,
  PBS_CREATE_SCOPE_OF_WORK_EP,
  PBS_CREATE_SERVICE,
  PBS_GET_BY_ID,
  PBS_READ_LIST_PANE_DATA,
  PBS_READ_SERVICE,
  PBS_READ_SHORTCUT_PANE_DATA,
  PBS_SAVE,
  QUALITY_FILTER_EP,
  READ_ALL_INSTRUCTIONS,
  READ_CBC_TO_PBS_EP,
  READ_COMPETENCE_DROPDOWN_EP,
  READ_CPC_RELATIONS_EP,
  READ_DROPDOWN_DATA,
  READ_INSTRUCTION_DROPDOWN_EP,
  READ_QUALITY_BY_ID_EP,
  READ_READ_CBC_CONTRACTOR_EP,
  READ_PBS_RELATIONS_EP,
  READ_RISK_LIST_EP,
  READ_RISK_REGISTER_BY_ID_EP,
  READ_TAXONOMY_DATA,
  READ_TAXONOMY_LEVEL_EP,
  REMOVE_CBC_TO_PBS_EP,
  RISK_DROPDWON_EP,
  RISK_FILTER_LIST_EP,
  SAVE_CBC_TO_PBS_EP,
  SAVE_PBS_INSTRUCTION_EP,
  SAVE_PBS_QUALITY_EP,
  SAVE_PBS_RISK_EP,
  UPLOAD_INSTRUCTION_DOC_EP,
  READ_DELIVERABLES_TO_PBS_EP,
  MILESTONES_SAVE_EP,
  MILESTONE_FILTER_EP,
  CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
} from '../shared/endpoints';
import UprinceLogger from '../shared/Logger/logger';
import {
  Competence,
  FilterItem,
  Milestone,
  PbsInstruction,
  PbsQuality,
  PbsRisk,
  ProjectBreakdown,
  Quality,
  Resource,
  Risk,
} from '../types/projectBreakdownStructure';
import {
  LOAD_PMOL_ALL_INSTRUCTION,
  LOAD_PMOL_ALL_INSTRUCTION_FAIL,
  LOAD_PMOL_ALL_INSTRUCTION_SUCCESS,
} from '../actionTypes/projectMoleculeActionTypes';
//to load risk data into modal
import {
  LOAD_RISK_REGISTER_BY_ID,
  LOAD_RISK_REGISTER_BY_ID_FAIL,
  LOAD_RISK_REGISTER_BY_ID_SUCCESS,
} from '../actionTypes/riskRegisterActionTypes';
//to load quality data into modal
import {
  LOAD_QUALITY_BY_ID,
  LOAD_QUALITY_BY_ID_FAIL,
  LOAD_QUALITY_BY_ID_SUCCESS,
} from '../actionTypes/qualityActionTypes';
import {
  isCuProjectPlanning,
  isMyEnvironment,
  isPlanning,
} from '../shared/util';
import Deliverables from '../components/ProjectBreakdownStructure/deliverables/Deliverables';

const defaultState: any = {
  formData: [],
  shortCutPaneFilters: [],
  listPaneItem: [],
  toleranceStates: [],
  productStates: [],
  itemTypes: [],
  toleranceStatesFilter: [],
  productStatesFilter: [],
  itemTypesFilter: [],
  uid: null,
  loadMsgType: '',
  loadMsg: '',
  alertMsg: '',
  alertMsgType: '',
  loading: false,
  isDataLoaded: false,
  isDetailsLoaded: true,
  isChange: false,
  autoSave: false,
  dropdowns: null,
  createRiskStatus: false,
  riskList: [],
  readRiskStatus: false,
  deleteRiskStatus: false,
  qualityList: [],
  createInstructionStatus: false,
  readQualityStatus: false,
  createQualityStatus: false,
  deleteQualityStatus: false,
  isNew: false,
  isEdit: false,
  readMaterialsStatus: false,
  materials: [],
  createMaterialsStatus: false,
  newRiskId: null,
  saveRiskStatus: false,
  saveInstructionStatus: false,
  newQualityId: null,
  saveQualityStatus: false,
  tools: [],
  readToolsStatus: false,
  createToolsStatus: false,
  deleteMaterialsStatus: false,
  deleteToolsStatus: false,
  readLabourStatus: false,
  labours: [],
  createLabourStatus: false,
  deleteLabourStatus: false,
  readConsumableStatus: false,
  consumables: [],
  createConsumableStatus: false,
  deleteConsumableStatus: false,
  instructionDropDowns: [],
  readTechInstructionStatus: false,
  techInstructions: [],
  instructionUploadStatus: false,
  uploadedInstructionLink: null,
  locationTaxonomy: [],
  utilityTaxonomy: [],
  productTaxonomy: [],
  machineTaxonomy: [],
  utilityTaxonomyLevel: [],
  locationTaxonomyLevel: [],
  machineTaxonomyLevel: [],
  createTechInstructionStatus: false,
  competenceList: [],
  readCompetenceStatus: false,
  saveCompetenceStatus: false,
  milestoneList: [],
  readMilestoneStatus: false,
  saveMilestoneStatus: false,
  pbsSkills: [],
  pbsExperience: [],
  deleteCompetenceStatus: false,
  deleteTechInstructionStatus: false,
  readSaftyInstructionStatus: false,
  saftyInstructions: [],
  createSaftyInstructionStatus: false,
  deleteSaftyInstructionStatus: false,
  readEnvInstructionStatus: false,
  envInstructions: [],
  createEnvInstructionStatus: false,
  deleteEnvInstructionStatus: false,
  readHelthInstructionStatus: false,
  helthInstructions: [],
  createHelthInstructionStatus: false,
  deleteHelthInstructionStatus: false,
  createNewNode: false,
  pbsService: null,
  pbsCreated: false,
  pbsLoaded: false,
  cbcExcelUploaded: false,
  showProgressBar: false,

  riskData: [], //to load risk data into modal
  qualityData: [], //to load quality data into modal
  saveBeforeDicard: false,
  updatedVPPBS: null,
  isPBSOpenFromVP: true,
  pbsLabourResources: null,
  pbsLabourResourcesLoading: false,
  pmolCreatedWithResources: [],
  isPbsSqModalOpen: false,
  vpPMOLGeneratedWithPBS: false,
  isDeletedFromVp: false,
  updatedPbsMyEnv: null,
  pbsRelations: {},
  cpcRelations: {},
  cbcContractor: [],
  pbsCbcList: [],
  reFetchCbc: false,
  isLoadingCbc: false,
  deliverables: [],
};

const projectBreakdownReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case RESET_FORM_DATA:
      return { ...state, formData: [] };

    case LOAD_SHORTCUT_PANE_FILTER_LIST:
      return { ...state, loading: true };
    case LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shortCutPaneFilters: action.payload.data.result,
      };
    case LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_LIST_PANE_LIST:
      return {
        ...state,
        loading: true,
        listPaneItem: [],
        isDataLoaded: false,
        loadMsg: '',
      };
    case LOAD_LIST_PANE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isChange: false,
        listPaneItem: formattedPayloadToListPaneTable(action.payload),
        loadMsg: !action.payload.data.status ? action.payload.data.message : '',
        isDataLoaded: true,
        createNewNode: false,
      };
    case LOAD_LIST_PANE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        isChange: false,
        error: 'Error while fetching Time Clock',
        loadMsg: action?.error?.response?.data?.message,
        listPaneItem: [],
        isDataLoaded: true,
      };

    case LOAD_DROPDOWN_DATA:
      return { ...state, loading: true };

    case LOAD_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        toleranceStatesFilter: formattedPayloadToFilterDropdown(
          action.payload.data.result.toleranceStates
        ),
        toleranceStates: formattedPayloadToDropdown(
          action.payload.data.result.toleranceStates
        ),
        productStatesFilter: formattedPayloadToFilterDropdown(
          action.payload.data.result.productStates
        ),
        productStates: formattedPayloadToDropdown(
          action.payload.data.result.productStates
        ),
        itemTypesFilter: formattedPayloadToFilterDropdown(
          action.payload.data.result.itemtypes
        ),
        itemTypes: formattedPayloadToDropdown(
          action.payload.data.result.itemtypes
        ),
        pbsDropdownList: formattedPayloadToDropdownList(
          action.payload.data.result
        ),
      };

    case LOAD_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_RISK_DROPDOWN:
      return { ...state };
    case LOAD_RISK_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdowns: action.payload.data.result,
      };
    case LOAD_RISK_DROPDOWN_FAIL:
      return { ...state };

    case SAVE_PBS:
      return { ...state, pbsCreated: false };
    case SAVE_PBS_SUCCESS:
      return {
        ...state,
        isChange: true,
        isNew: false,
        pbsCreated: true,
      };
    case SAVE_PBS_FAIL:
      return { ...state, pbsCreated: false };

    case GET_PBS_BY_ID:
      return {
        ...state,
        formData: [],
        pbsLoaded: false,
        showProgressBar: true,
      };
    case GET_PBS_BY_ID_SUCCESS:
      return {
        ...state,
        isEdit: true,
        formData: action.payload.data.result,
        getImageId: action.payload.data.result.id,
        showProgressBar: false,
        pbsCreated: false,
        pbsLoaded: true,
        updatedVPPBS:
          isPlanning() || isCuProjectPlanning()
            ? action.payload.data.result
            : null,
        updatedPbsMyEnv:
          isMyEnvironment() || isCuProjectPlanning()
            ? action.payload.data.result
            : null,
        isDeletedFromVp: false,
      };
    case GET_PBS_BY_ID_FAIL:
      return { ...state, pbsLoaded: false, showProgressBar: false };
    case GET_PBS_BY_ID_FOR_TREESTRUCTURE:
      return {
        ...state,
      };
    case GET_PBS_BY_ID_FOR_TREESTRUCTURE_SUCCESS:
      return {
        ...state,
        updatedVPPBS:
          isPlanning() || isCuProjectPlanning()
            ? action.payload.data.result
            : null,
        updatedPbsMyEnv:
          isMyEnvironment() || isCuProjectPlanning()
            ? action.payload.data.result
            : null,
      };
    case GET_PBS_BY_ID_FOR_TREESTRUCTURE_FAIL:
      return { ...state, pbsLoaded: false, showProgressBar: false };
    case CREATE_RISK:
      return { ...state, createRiskStatus: false };
    case CREATE_RISK_SUCCESS:
      return {
        ...state,
        createRiskStatus: true,
        saveRiskStatus: false,
        newRiskId: action.payload.data.result
          ? action.payload.data.result.id
          : null,
      };
    case CREATE_RISK_FAIL:
      return {
        ...state,
        createRiskStatus: false,
      };
    case SAVE_PBS_RISK:
      return { ...state, createRiskStatus: false };
    case SAVE_PBS_RISK_SUCCESS:
      return {
        ...state,
        createRiskStatus: false,
        saveRiskStatus: true,
        newRiskId: null,
      };
    case SAVE_PBS_RISK_FAIL:
      return {
        ...state,
        createRiskStatus: false,
        saveRiskStatus: false,
      };
    case SAVE_PBS_INSTRUCTION:
      return { ...state, createTechInstructionStatus: false };
    case SAVE_PBS_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createTechInstructionStatus: false,
        saveInstructionStatus: true,
        newInstructionsId: null,
      };
    case SAVE_PBS_INSTRUCTION_FAIL:
      return {
        ...state,
        createTechInstructionStatus: false,
        saveInstructionStatus: false,
      };
    case READ_RISK:
      return { ...state, readRiskStatus: true };
    case READ_RISK_SUCCESS:
      return {
        ...state,
        riskList: action.payload.data.result,
        readRiskStatus: false,
        saveRiskStatus: false,
      };
    case READ_RISK_FAIL:
      return {
        ...state,
        readRiskStatus: false,
      };
    case DELETE_RISK:
      return { ...state, deleteRiskStatus: false };
    case DELETE_RISK_SUCCESS:
      return {
        ...state,
        deleteRiskStatus: true,
      };
    case DELETE_RISK_FAIL:
      return {
        ...state,
        readRiskStatus: false,
      };
    case LOAD_QUALITY:
      return { ...state, readQualityStatus: true };
    case LOAD_QUALITY_SUCCESS:
      return {
        ...state,
        readQualityStatus: false,
        qualityList: action.payload.data.result,
      };
    case LOAD_QUALITY_FAIL:
      return {
        ...state,
        readQualityStatus: false,
      };
    case CREATE_QUALITY:
      return { ...state, createQualityStatus: false };
    case CREATE_QUALITY_SUCCESS:
      return {
        ...state,
        createQualityStatus: true,
        saveQualityStatus: false,
        newQualityId: action.payload.data.result
          ? action.payload.data.result.id
          : null,
      };
    case CREATE_QUALITY_FAIL:
      return {
        ...state,
        createQualityStatus: false,
      };

    case SAVE_PBS_QUALITY:
      return { ...state, createQualityStatus: false };
    case SAVE_PBS_QUALITY_SUCCESS:
      return {
        ...state,
        createQualityStatus: false,
        saveQualityStatus: true,
        newQualityId: null,
      };
    case SAVE_PBS_QUALITY_FAIL:
      return {
        ...state,
        createQualityStatus: false,
        saveQualityStatus: false,
      };

    case DELETE_QUALITY:
      return { ...state, deleteQualityStatus: false };
    case DELETE_QUALITY_SUCCESS:
      return {
        ...state,
        deleteQualityStatus: action.payload.data.result,
      };
    case DELETE_QUALITY_FAIL:
      return {
        ...state,
        deleteQualityStatus: false,
      };

    case SET_PBS_UID:
      return {
        ...state,
        isEdit: false,
        uid: uuidv4(),
      };
    case CLEAR_PBS_UID:
      return {
        ...state,
        uid: null,
        isChange: false,
      };
    case CLEAR_PBS_INS_DATA:
      return {
        // readTechInstructionStatus: true,
        // readHelthInstructionStatus: true,
        // readEnvInstructionStatus: true,
        // readSaftyInstructionStatus: true
      };
    case CLEAR_PBS_FORM_DATA:
      return {
        ...state,
        uid: null,
        isNew: true,
        isChange: false,
        formData: [],
        envInstructions: [],
        helthInstructions: [],
        saftyInstructions: [],
        techInstructions: [],
        competenceList: [],
        milestoneList: [],
        qualityList: [],
        materials: [],
        tools: [],
        consumables: [],
        labours: [],
        riskList: [],
        // readTechInstructionStatus: false,
        // readHelthInstructionStatus: false,
        // readEnvInstructionStatus: false,
        // readSaftyInstructionStatus: false
      };
    case RESET_IS_CHANGE:
      return {
        ...state,
        isChange: false,
      };
    case RESET_RISK_GRID_LIST:
      return {
        ...state,
        riskList: [],
      };
    case LOAD_MATERIALS:
      UprinceLogger.log('LOAD_MATERIALS');
      return { ...state, readMaterialsStatus: true, materials: [] };
    case LOAD_MATERIALS_SUCCESS:
      UprinceLogger.log('LOAD_MATERIALS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readMaterialsStatus: false,
        materials: action.payload.data.result,
      };
    case LOAD_MATERIALS_FAIL:
      UprinceLogger.log('LOAD_MATERIALS_FAIL');
      return {
        ...state,
        readMaterialsStatus: false,
      };
    case CREATE_RESOURCE:
      return { ...state, createMaterialsStatus: false };
    case CREATE_RESOURCE_SUCCESS:
      return {
        ...state,
        createMaterialsStatus: true,
      };
    case CREATE_RESOURCE_FAIL:
      return {
        ...state,
        createMaterialsStatus: false,
      };
    case READ_TOOLS:
      return { ...state, readToolsStatus: true, tools: [] };
    case READ_TOOLS_SUCCESS:
      return {
        ...state,
        readToolsStatus: false,
        tools: action.payload.data.result,
      };
    case READ_TOOLS_FAIL:
      return {
        ...state,
        readToolsStatus: false,
      };
    case CREATE_TOOLS:
      return { ...state, createToolsStatus: false };
    case CREATE_TOOLS_SUCCESS:
      return {
        ...state,
        createToolsStatus: true,
      };
    case CREATE_TOOLS_FAIL:
      return {
        ...state,
        createToolsStatus: false,
      };
    case DELETE_MATERIALS:
      return { ...state, deleteMaterialsStatus: false };
    case DELETE_MATERIALS_SUCCESS:
      return {
        ...state,
        deleteMaterialsStatus: true,
      };
    case DELETE_MATERIALS_FAIL:
      return {
        ...state,
        deleteMaterialsStatus: false,
      };
    case DELETE_TOOLS:
      return { ...state, deleteToolsStatus: false };
    case DELETE_TOOLS_SUCCESS:
      return {
        ...state,
        deleteToolsStatus: true,
      };
    case DELETE_TOOLS_FAIL:
      return {
        ...state,
        deleteToolsStatus: false,
      };
    case READ_LABOURS:
      return { ...state, readLabourStatus: true, labours: [] };
    case READ_LABOURS_SUCCESS:
      return {
        ...state,
        readLabourStatus: false,
        labours: action.payload.data.result,
      };
    case READ_LABOURS_FAIL:
      return {
        ...state,
        labours: [],
        readLabourStatus: false,
      };
    case CREATE_LABOUR:
      return { ...state, createLabourStatus: false };
    case CREATE_LABOUR_SUCCESS:
      return {
        ...state,
        createLabourStatus: true,
      };
    case CREATE_LABOUR_FAIL:
      return {
        ...state,
        createLabourStatus: false,
      };
    case DELETE_LABOURS:
      return { ...state, deleteLabourStatus: false };
    case DELETE_LABOURS_SUCCESS:
      return {
        ...state,
        deleteLabourStatus: true,
      };
    case DELETE_LABOURS_FAIL:
      return {
        ...state,
        deleteLabourStatus: false,
      };
    case READ_CONSUMABLE:
      return { ...state, readConsumableStatus: true, consumables: [] };
    case READ_CONSUMABLE_SUCCESS:
      return {
        ...state,
        readConsumableStatus: false,
        consumables: action.payload.data.result,
      };
    case READ_CONSUMABLE_FAIL:
      return {
        ...state,
        consumables: [],
        readConsumableStatus: false,
      };

    case CREATE_CONSUMABLE:
      return { ...state, createConsumableStatus: false };
    case CREATE_CONSUMABLE_SUCCESS:
      return {
        ...state,
        createConsumableStatus: true,
      };
    case CREATE_CONSUMABLE_FAIL:
      return {
        ...state,
        createConsumableStatus: false,
      };
    case DELETE_CONSUMABLE:
      return { ...state, deleteConsumableStatus: false };
    case DELETE_CONSUMABLE_SUCCESS:
      return {
        ...state,
        deleteConsumableStatus: true,
      };
    case DELETE_CONSUMABLE_FAIL:
      return {
        ...state,
        deleteConsumableStatus: false,
      };
    case LOAD_INSTRUCTION_DROPDOWN:
      return { ...state, instructionDropDowns: [] };
    case LOAD_INSTRUCTION_DROPDOWN_SUCCESS:
      return {
        ...state,
        instructionDropDowns: action.payload.data.result
          ? action.payload.data.result.pbsInstructionFamily
          : [],
      };
    case LOAD_INSTRUCTION_DROPDOWN_FAIL:
      return {
        ...state,
        instructionDropDowns: [],
      };
    case LOAD_TECH_INSTRUCTION:
      return { ...state, readTechInstructionStatus: true };
    case LOAD_TECH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        readTechInstructionStatus: false,
        techInstructions: action.payload.data.result,
      };
    case LOAD_TECH_INSTRUCTION_FAIL:
      return {
        ...state,
        readTechInstructionStatus: false,
      };
    case UPLOAD_INSTRUCTION_DOC:
      return {
        ...state,
        uploadedInstructionLink: null,
        instructionUploadStatus: false,
      };
    case UPLOAD_INSTRUCTION_DOC_SUCCESS:
      return {
        ...state,
        instructionUploadStatus: true,
        uploadedInstructionLink: action.payload.data.result,
      };
    case UPLOAD_INSTRUCTION_DOC_FAIL:
      return {
        ...state,
        instructionUploadStatus: false,
        uploadedInstructionLink: null,
      };
    case LOAD_TAXONOMY:
      return { ...state, createNewNode: false };
    case LOAD_TAXONOMY_SUCCESS:
      return {
        ...state,
        locationTaxonomy: action.payload.data.result.locationTaxonomy,
        // locationTaxonomy: exapleTreeData(),
        // utilityTaxonomy: exapleTreeData(),
        utilityTaxonomy: action.payload.data.result.utilityTaxonomy,
        productTaxonomy: action.payload.data.result.productTaxonomy,
        machineTaxonomy: action.payload.data.result.machineTaxonomy,
        createNewNode: false,
      };
    case LOAD_TAXONOMY_FAIL:
      return {
        ...state,
        createNewNode: false,
      };
    case CREATE_TECH_INSTRUCTION:
      return { ...state, createTechInstructionStatus: false };
    case CREATE_TECH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createTechInstructionStatus: true,
      };
    case CREATE_TECH_INSTRUCTION_FAIL:
      return { ...state, createTechInstructionStatus: false };

    case LOAD_COMPETENCE:
      return { ...state, readCompetenceStatus: true };
    case LOAD_COMPETENCE_SUCCESS:
      return {
        ...state,
        competenceList: action.payload.data.result,
        readCompetenceStatus: false,
        saveCompetenceStatus: false,
      };
    case LOAD_COMPETENCE_FAIL:
      return {
        ...state,
        readCompetenceStatus: false,
      };
    case LOAD_MILESTONE:
      return { ...state, readMilestoneStatus: true };
    case LOAD_MILESTONE_SUCCESS:
      return {
        ...state,
        milestoneList: action.payload.data.result,
        readMilestoneStatus: false,
        saveMilestoneStatus: false,
      };
    case LOAD_MILESTONE_FAIL:
      return {
        ...state,
        readMilestoneStatus: false,
      };
    case LOAD_COMPETENCE_DROPDOWN:
      return { ...state, pbsSkills: [], pbsExperience: [] };
    case LOAD_COMPETENCE_DROPDOWN_SUCCESS:
      return {
        ...state,
        pbsSkills: action.payload.data.result
          ? action.payload.data.result.pbsSkills
          : [],
        pbsExperience: action.payload.data.result
          ? action.payload.data.result.pbsExperience
          : [],
      };
    case LOAD_COMPETENCE_DROPDOWN_FAIL:
      return {
        ...state,
        pbsSkills: [],
        pbsExperience: [],
      };

    case CREATE_COMPETENCE:
      return { ...state, saveCompetenceStatus: false };
    case CREATE_COMPETENCE_SUCCESS:
      return {
        ...state,
        saveCompetenceStatus: true,
      };
    case CREATE_COMPETENCE_FAIL:
      return { ...state, saveCompetenceStatus: false };

    case CREATE_MILESTONE:
      return { ...state, saveMilestoneStatus: false };
    case CREATE_MILESTONE_SUCCESS:
      return {
        ...state,
        saveMilestoneStatus: true,
      };
    case CREATE_MILESTONE_FAIL:
      return { ...state, saveMilestoneStatus: false };

    case DELETE_COMPETENCE:
      return { ...state, deleteCompetenceStatus: false };
    case DELETE_COMPETENCE_SUCCESS:
      return {
        ...state,
        deleteCompetenceStatus: true,
      };
    case DELETE_COMPETENCE_FAIL:
      return { ...state, deleteCompetenceStatus: false };
    case DELETE_TECH_INSTRUCTION:
      return { ...state, deleteTechInstructionStatus: false };
    case DELETE_TECH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        deleteTechInstructionStatus: true,
      };
    case DELETE_TECH_INSTRUCTION_FAIL:
      return { ...state, deleteTechInstructionStatus: false };

    case DELETE_PBS:
      return { ...state, loading: true };
    case DELETE_PBS_SUCCESS:
      return {
        ...state,
        isChange: true,
        loading: false,
        alertMsg: '',
        alertMsgType: 'success',
        isDeletedFromVp: isPlanning() || isCuProjectPlanning(),
      };
    case DELETE_PBS_FAIL:
      return {
        ...state,
        loadMsg: action?.error?.response?.data?.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching CPC',
      };
    case READ_PBS_RELATIONS:
      return {
        ...state,
        loading: true,
        pbsRelations: {},
        showProgressBar: true,
      };
    case READ_PBS_RELATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        showProgressBar: false,
        pbsRelations: action.payload.data.result,
      };
    case READ_PBS_RELATIONS_FAIL:
      return {
        ...state,
        pbsRelations: {},
        showProgressBar: false,
      };
    case READ_CPC_RELATIONS:
      return {
        ...state,
        loading: true,
        cpcRelations: {},
        showProgressBar: true,
      };
    case READ_CPC_RELATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        showProgressBar: false,
        cpcRelations: action.payload.data.result,
      };
    case READ_CPC_RELATIONS_FAIL:
      return {
        ...state,
        cpcRelations: {},
        showProgressBar: false,
      };
    case LOAD_SAFTY_INSTRUCTION:
      return { ...state, readSaftyInstructionStatus: true };
    case LOAD_SAFTY_INSTRUCTION_SUCCESS:
      return {
        ...state,
        readSaftyInstructionStatus: false,
        saftyInstructions: action.payload.data.result,
      };
    case LOAD_SAFTY_INSTRUCTION_FAIL:
      return {
        ...state,
        readSaftyInstructionStatus: false,
      };
    case CREATE_SAFTY_INSTRUCTION:
      return { ...state, createSaftyInstructionStatus: false };
    case CREATE_SAFTY_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createSaftyInstructionStatus: true,
      };
    case CREATE_SAFTY_INSTRUCTION_FAIL:
      return { ...state, createSaftyInstructionStatus: false };
    case DELETE_SAFTY_INSTRUCTION:
      return { ...state, deleteSaftyInstructionStatus: false };
    case DELETE_SAFTY_INSTRUCTION_SUCCESS:
      return {
        ...state,
        deleteSaftyInstructionStatus: true,
      };
    case DELETE_SAFTY_INSTRUCTION_FAIL:
      return { ...state, deleteSaftyInstructionStatus: false };
    // //
    case LOAD_ENV_INSTRUCTION:
      return { ...state, readEnvInstructionStatus: true };
    case LOAD_ENV_INSTRUCTION_SUCCESS:
      return {
        ...state,
        readEnvInstructionStatus: false,
        envInstructions: action.payload.data.result,
      };
    case LOAD_ENV_INSTRUCTION_FAIL:
      return {
        ...state,
        readEnvInstructionStatus: false,
      };
    case CREATE_ENV_INSTRUCTION:
      return { ...state, createEnvInstructionStatus: false };
    case CREATE_ENV_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createEnvInstructionStatus: true,
      };
    case CREATE_ENV_INSTRUCTION_FAIL:
      return { ...state, createEnvInstructionStatus: false };
    case DELETE_ENV_INSTRUCTION:
      return { ...state, deleteEnvInstructionStatus: false };
    case DELETE_ENV_INSTRUCTION_SUCCESS:
      return {
        ...state,
        deleteEnvInstructionStatus: true,
      };
    case DELETE_ENV_INSTRUCTION_FAIL:
      return { ...state, deleteEnvInstructionStatus: false };
    // /
    case LOAD_HELTH_INSTRUCTION:
      return { ...state, readHelthInstructionStatus: true };
    case LOAD_HELTH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        readHelthInstructionStatus: false,
        helthInstructions: action.payload.data.result,
      };
    case LOAD_HELTH_INSTRUCTION_FAIL:
      return {
        ...state,
        readHelthInstructionStatus: false,
      };
    case READ_PBS_LABOUR:
      return {
        ...state,
        pbsLabourResourcesLoading: true,
        pbsLabourResources: null,
      };
    case READ_PBS_LABOUR_SUCCESS:
      return {
        ...state,
        pbsLabourResourcesLoading: false,
        pbsLabourResources: action.payload.data.result,
      };
    case READ_PBS_VP_RESOURCES_SUCCESS:
      console.log('bbbbbbbbb', action.payload.data.result.materials);
      const x = [
        ...action.payload.data.result.materials,
        ...action.payload.data.result.tools,
        ...action.payload.data.result.consumables,
        ...action.payload.data.result.labours,
      ];
      return {
        ...state,
        pbsLabourResourcesLoading: false,
        pbsLabourResources: [
          ...action.payload.data.result.materials,
          ...action.payload.data.result.tools,
          ...action.payload.data.result.consumables,
          ...action.payload.data.result.labours,
        ],
      };
    case READ_PBS_LABOUR_FAIL:
      return {
        ...state,
        readHelthInstructionStatus: false,
        pbsLabourResources: null,
        pbsLabourResourcesLoading: false,
      };

    case CREATE_PMOL_WITH_RESOURCE:
      return { ...state, pbsLabourResourcesLoading: true };
    case CREATE_PMOL_WITH_RESOURCE_SUCCESS:
      return {
        ...state,
        pbsLabourResourcesLoading: false,
        pmolCreatedWithResources: action.payload.data.result,
        vpPMOLGeneratedWithPBS: true,
      };
    case CREATE_PMOL_WITH_RESOURCE_FAIL:
      return {
        ...state,
        readHelthInstructionStatus: false,
      };
    case CREATE_HELTH_INSTRUCTION:
      return {
        ...state,
        createHelthInstructionStatus: false,
        createSaftyInstructionStatus: false,
      };
    case CREATE_HELTH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createHelthInstructionStatus: true,
      };
    case CREATE_HELTH_INSTRUCTION_FAIL:
      return { ...state, createHelthInstructionStatus: false };
    case DELETE_HELTH_INSTRUCTION:
      return { ...state, deleteHelthInstructionStatus: false };
    case DELETE_HELTH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        deleteHelthInstructionStatus: true,
      };
    case DELETE_HELTH_INSTRUCTION_FAIL:
      return { ...state, deleteHelthInstructionStatus: false };

    case READ_TAXONOMY_LEVEL:
      return {
        ...state,
        utilityTaxonomyLevel: [],
        locationTaxonomyLevel: [],
        machineTaxonomyLevel: [],
      };
    case READ_TAXONOMY_LEVEL_SUCCESS:
      return {
        ...state,
        // utilityTaxonomyLevel:[],
        utilityTaxonomyLevel: formattedPayloadToTaxonomyLevel(
          action.payload.data.result.utilityTaxonomyLevels
        ),
        locationTaxonomyLevel: formattedPayloadToTaxonomyLevel(
          action.payload.data.result.locationTaxonomyLevels
        ),
        machineTaxonomyLevel: formattedPayloadToTaxonomyLevel(
          action.payload.data.result.machineTaxonomyLevels
        ),
      };

    case READ_TAXONOMY_LEVEL_FAIL:
      return { ...state };

    case CREATE_TAXONOMY_NODE:
      return { ...state };
    case CREATE_TAXONOMY_NODE_SUCCESS:
      return {
        ...state,
        createNewNode: true,
      };
    case CREATE_TAXONOMY_NODE_FAIL:
      return { ...state };

    case CLONE_PBS:
      return { ...state };
    case CLONE_PBS_SUCCESS:
      return {
        ...state,
        isChange: true,
      };
    case CLONE_PBS_FAIL:
      return { ...state };
    case LOAD_PMOL_ALL_INSTRUCTION:
      return {
        ...state,
        readTechInstructionStatus: true,
        readSaftyInstructionStatus: true,
        readEnvInstructionStatus: true,
        readHelthInstructionStatus: true,
      };
    case LOAD_PMOL_ALL_INSTRUCTION_SUCCESS:
      return {
        ...state,
        readTechInstructionStatus: false,
        readSaftyInstructionStatus: false,
        readEnvInstructionStatus: false,
        readHelthInstructionStatus: false,
        techInstructions: action.payload.data.result.technical,
        saftyInstructions: action.payload.data.result.safety,
        envInstructions: action.payload.data.result.environmental,
        helthInstructions: action.payload.data.result.health,
      };
    case LOAD_PMOL_ALL_INSTRUCTION_FAIL:
      return {
        ...state,
        readTechInstructionStatus: false,
        readSaftyInstructionStatus: false,
        readEnvInstructionStatus: false,
        readHelthInstructionStatus: false,
      };
    case READ_PBS_SERVICE:
      return {
        ...state,
        pbsService: null,
      };
    case READ_PBS_SERVICE_SUCCESS:
      return {
        ...state,
        pbsService: action.payload.data.result,
      };
    case READ_PBS_SERVICE_FAIL:
      return {
        ...state,
        pbsService: null,
      };

    case CBC_EXCEL_UPLOAD:
      return {
        ...state,
        cbcExcelUploaded: false,
        isDataLoaded: false,
      };
    case CBC_EXCEL_UPLOAD_SUCCESS:
      return {
        ...state,
        cbcExcelUploaded: true,
        isDataLoaded: true,
      };
    case CBC_EXCEL_UPLOAD_FAIL:
      return {
        ...state,
        cbcExcelUploaded: true,
        isDataLoaded: true,
      };

    // eslint-disable-next-line no-case-declarations
    case SAVE_PBS_STATE_ATTR:
      const attr = Object.keys(action)[1];
      const value = Object.values(action)[1];
      return {
        ...state,
        [attr]: value,
      };
    //to load risk data into modal
    case LOAD_RISK_REGISTER_BY_ID:
      return {
        ...state,
        riskData: {},
        showProgressBar: true,
        isLoadingRiskRegister: true,
      };
    case LOAD_RISK_REGISTER_BY_ID_SUCCESS:
      return {
        ...state,
        isEdit: true,
        riskData: getRiskData(action.payload.data.result),
        showProgressBar: false,
        isLoadingRiskRegister: false,
      };
    case LOAD_RISK_REGISTER_BY_ID_FAIL:
      return {
        ...state,
        riskData: {},
        showProgressBar: false,
        isLoadingRiskRegister: false,
      };

    //to load quality data into modal
    case LOAD_QUALITY_BY_ID:
      return {
        ...state,
        qualityData: {},
        showProgressBar: true,
        isLoadingRiskRegister: true,
      };
    case LOAD_QUALITY_BY_ID_SUCCESS:
      return {
        ...state,
        isEdit: true,
        qualityData: action.payload.data.result,
        showProgressBar: false,
        isLoadingRiskRegister: false,
      };
    case LOAD_QUALITY_BY_ID_FAIL:
      return {
        ...state,
        qualityData: {},
        showProgressBar: false,
        isLoadingRiskRegister: false,
      };

    case READ_CBC_CONTRACTOR:
      return {
        ...state,
        cbcContractor: [],
      };
    case READ_CBC_CONTRACTOR_SUCCESS:
      return {
        ...state,
        cbcContractor: action.payload.data.result,
      };
    case READ_CBC_CONTRACTOR_FAIL:
      return {
        ...state,
        cbcContractor: [],
      };

    case READ_CBC_TO_PBS:
      return {
        ...state,
        pbsCbcList: [],
        isLoadingCbc: true,
      };
    case READ_CBC_TO_PBS_SUCCESS:
      return {
        ...state,
        pbsCbcList: action.payload.data.result,
        reFetchCbc: false,
        isLoadingCbc: false,
      };
    case READ_CBC_TO_PBS_FAIL:
      return {
        ...state,
        cbcContractor: [],
        reFetchCbc: false,
        isLoadingCbc: false,
      };

    case SAVE_CBC_TO_PBS:
      return {
        ...state,
      };
    case SAVE_CBC_TO_PBS_SUCCESS:
      return {
        ...state,
        reFetchCbc: true,
      };
    case SAVE_CBC_TO_PBS_FAIL:
      return {
        ...state,
      };

    case REMOVE_CBC_TO_PBS:
      return {
        ...state,
      };
    case REMOVE_CBC_TO_PBS_SUCCESS:
      return {
        ...state,
        reFetchCbc: true,
      };
    case REMOVE_CBC_TO_PBS_FAIL:
      return {
        ...state,
      };

    case READ_DELIVERABLES_TO_PBS:
      return {
        ...state,
      };
    case READ_DELIVERABLES_TO_PBS_SUCCESS:
      return {
        ...state,
        // deliverables:pmols,
        deliverables: action.payload.data.result,
      };
    case READ_DELIVERABLES_TO_PBS_FAIL:
      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export function savePbsStateAttr(attr: string, value: any) {
  return {
    type: SAVE_PBS_STATE_ATTR,
    [attr]: value,
  };
}

export const readPMOLAllInstruction = (pbsId: string) => {
  return {
    types: [
      LOAD_PMOL_ALL_INSTRUCTION,
      LOAD_PMOL_ALL_INSTRUCTION_SUCCESS,
      LOAD_PMOL_ALL_INSTRUCTION_FAIL,
    ],
    payload: { request: { url: READ_ALL_INSTRUCTIONS + pbsId } },
  };
};

export const getShortcutPaneFilterList = () => {
  return {
    types: [
      LOAD_SHORTCUT_PANE_FILTER_LIST,
      LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS,
      LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL,
    ],
    payload: {
      request: {
        url: PBS_READ_SHORTCUT_PANE_DATA,
        method: 'GET',
      },
    },
  };
};

export const getById = (id: string, project?: string) => {
  return {
    types: [GET_PBS_BY_ID, GET_PBS_BY_ID_SUCCESS, GET_PBS_BY_ID_FAIL],
    payload: {
      request: {
        url: PBS_GET_BY_ID + id,
        headers: isCuProjectPlanning() ? { project: project } : undefined,
      },
    },
  };
};

export const getPbsById = (id: string, project?: string) => { // After Update the product status(round icon) get the updated pbs-data to update only the treestructure and not the document pane.
  return {
    types: [GET_PBS_BY_ID_FOR_TREESTRUCTURE, GET_PBS_BY_ID_FOR_TREESTRUCTURE_SUCCESS, GET_PBS_BY_ID_FOR_TREESTRUCTURE_FAIL],
    payload: {
      request: {
        url: PBS_GET_BY_ID + id,
        headers: isCuProjectPlanning() ? { project: project } : undefined,
      },
    },
  };
};

export const getByIdForPopover = (id: string) => {
  return {
    types: ['GET_PBS_POP'],
    payload: {
      request: {
        url: PBS_GET_BY_ID + id,
        method: 'GET',
      },
    },
  };
};

export const setUid = () => {
  return { type: SET_PBS_UID };
};

export const clearUid = () => {
  return { type: CLEAR_PBS_UID };
};
export const clearFormData = () => {
  return { type: CLEAR_PBS_FORM_DATA };
};
export const clearInstructionData = () => {
  return { type: CLEAR_PBS_INS_DATA };
};
export const resetIsChange = () => {
  return { type: RESET_IS_CHANGE };
};
export const resetRiskGridList = () => {
  return { type: RESET_RISK_GRID_LIST };
};

export const saveFormData = (
  projectBreakdown: ProjectBreakdown | any,
  project?: string
) => {
  return {
    types: [SAVE_PBS, SAVE_PBS_SUCCESS, SAVE_PBS_FAIL],
    payload: {
      request: {
        url: PBS_SAVE,
        method: 'POST',
        data: projectBreakdown,
        headers: isCuProjectPlanning() ? { project: project } : undefined,
      },
    },
  };
};

export const createTeamsFolder = (data: any, headers: any) => {
  return {
    types: [CREATE_PBS_FOLDER, CREATE_PBS_FOLDER_SUCCESS, CREATE_PBS_FOLDER_FAIL],
    payload: {
      url: CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
      method: 'POST',
      data: data,
      headers: headers
    }
  }
}

export const getListPaneData = (filterItem: FilterItem) => {
  return {
    types: [
      LOAD_LIST_PANE_LIST,
      LOAD_LIST_PANE_LIST_SUCCESS,
      LOAD_LIST_PANE_LIST_FAIL,
    ],
    payload: {
      request: {
        url: PBS_READ_LIST_PANE_DATA,
        method: 'POST',
        data: filterItem,
      },
    },
  };
};

export const getRiskDropDownsList = () => {
  return {
    types: [
      LOAD_RISK_DROPDOWN,
      LOAD_RISK_DROPDOWN_SUCCESS,
      LOAD_RISK_DROPDOWN_FAIL,
    ],
    payload: { request: { url: RISK_DROPDWON_EP } },
  };
};

export const createRisk = (data: Risk) => {
  return {
    types: [CREATE_RISK, CREATE_RISK_SUCCESS, CREATE_RISK_FAIL],
    payload: {
      request: {
        url: CREATE_RISK_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const savePbsRisk = (data: PbsRisk) => {
  return {
    types: [SAVE_PBS_RISK, SAVE_PBS_RISK_SUCCESS, SAVE_PBS_RISK_FAIL],
    payload: {
      request: {
        url: SAVE_PBS_RISK_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const savePbsInstruction = (data: PbsInstruction) => {
  return {
    types: [
      SAVE_PBS_INSTRUCTION,
      SAVE_PBS_INSTRUCTION_SUCCESS,
      SAVE_PBS_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: SAVE_PBS_INSTRUCTION_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteRisk = (data: string[]) => {
  return {
    types: [DELETE_RISK, DELETE_RISK_SUCCESS, DELETE_RISK_FAIL],
    payload: {
      request: {
        url: DELETE_RISK_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readRiskList = () => {
  return {
    types: [READ_RISK, READ_RISK_SUCCESS, READ_RISK_FAIL],
    payload: { request: { url: READ_RISK_LIST_EP } },
  };
};

export const filterRiskList = (pbsId: string) => {
  return {
    types: [READ_RISK, READ_RISK_SUCCESS, READ_RISK_FAIL],
    payload: {
      request: {
        url: RISK_FILTER_LIST_EP + pbsId,
        method: 'GET',
      },
    },
  };
};

export const readDropdownData = () => {
  return {
    types: [
      LOAD_DROPDOWN_DATA,
      LOAD_DROPDOWN_DATA_SUCCESS,
      LOAD_DROPDOWN_DATA_FAIL,
    ],
    payload: {
      request: {
        url: READ_DROPDOWN_DATA,
        method: 'GET',
      },
    },
  };
};

export const readTaxonomyData = () => {
  return {
    types: [LOAD_TAXONOMY, LOAD_TAXONOMY_SUCCESS, LOAD_TAXONOMY_FAIL],
    payload: {
      request: {
        url: READ_TAXONOMY_DATA,
        method: 'GET',
      },
    },
  };
};

export const filterQualityList = (id: string) => {
  return {
    types: [LOAD_QUALITY, LOAD_QUALITY_SUCCESS, LOAD_QUALITY_FAIL],
    payload: {
      request: {
        url: QUALITY_FILTER_EP + id,
        method: 'GET',
        // data: new QualityFilter(),
      },
    },
  };
};

export const uploadCBCExcelFile = (data: any) => {
  return {
    types: [CBC_EXCEL_UPLOAD, CBC_EXCEL_UPLOAD_SUCCESS, CBC_EXCEL_UPLOAD_FAIL],
    payload: {
      request: {
        url: PBS_CBC_UPLOAD_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const createQuality = (data: Quality) => {
  return {
    types: [CREATE_QUALITY, CREATE_QUALITY_SUCCESS, CREATE_QUALITY_FAIL],
    payload: {
      request: {
        url: CREATE_QUALITY_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const savePbsQuality = (data: PbsQuality) => {
  return {
    types: [SAVE_PBS_QUALITY, SAVE_PBS_QUALITY_SUCCESS, SAVE_PBS_QUALITY_FAIL],
    payload: {
      request: {
        url: SAVE_PBS_QUALITY_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteQuality = (data: string[]) => {
  return {
    types: [DELETE_QUALITY, DELETE_QUALITY_SUCCESS, DELETE_QUALITY_FAIL],
    payload: {
      request: {
        url: DELETE_QUALITY_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readMaterialsData = (id: string) => {
  return {
    types: [LOAD_MATERIALS, LOAD_MATERIALS_SUCCESS, LOAD_MATERIALS_FAIL],
    payload: {
      request: {
        url: getMaterialByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const createMaterials = (data: Resource, project?: string) => {
  return {
    types: [CREATE_RESOURCE, CREATE_RESOURCE_SUCCESS, CREATE_RESOURCE_FAIL],
    payload: {
      request: {
        url: CREATE_MATERIALS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readToolsData = (id: string) => {
  return {
    types: [READ_TOOLS, READ_TOOLS_SUCCESS, READ_TOOLS_FAIL],
    payload: {
      request: {
        url: getToolsByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const createTools = (data: Resource, project?: string) => {
  return {
    types: [CREATE_TOOLS, CREATE_TOOLS_SUCCESS, CREATE_TOOLS_FAIL],
    payload: {
      request: {
        url: CREATE_TOOLS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteMaterials = (data: string[]) => {
  return {
    types: [DELETE_MATERIALS, DELETE_MATERIALS_SUCCESS, DELETE_MATERIALS_FAIL],
    payload: {
      request: {
        url: DELETE_MATERIALS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteTools = (data: string[]) => {
  return {
    types: [DELETE_TOOLS, DELETE_TOOLS_SUCCESS, DELETE_TOOLS_FAIL],
    payload: {
      request: {
        url: DELETE_TOOLS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readLabourData = (id: string) => {
  return {
    types: [READ_LABOURS, READ_LABOURS_SUCCESS, READ_LABOURS_FAIL],
    payload: {
      request: {
        url: getLabourByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const createLabour = (data: Resource) => {
  return {
    types: [CREATE_LABOUR, CREATE_LABOUR_SUCCESS, CREATE_LABOUR_FAIL],
    payload: {
      request: {
        url: CREATE_LABOUR_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteLabours = (data: string[]) => {
  return {
    types: [DELETE_LABOURS, DELETE_LABOURS_SUCCESS, DELETE_LABOURS_FAIL],
    payload: {
      request: {
        url: DELETE_LABOUR_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readConsumableData = (id: string) => {
  return {
    types: [READ_CONSUMABLE, READ_CONSUMABLE_SUCCESS, READ_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: getConsumableByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const createConsumable = (data: Resource) => {
  return {
    types: [
      CREATE_CONSUMABLE,
      CREATE_CONSUMABLE_SUCCESS,
      CREATE_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_CONSUMABLE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteConsumable = (data: string[]) => {
  return {
    types: [
      DELETE_CONSUMABLE,
      DELETE_CONSUMABLE_SUCCESS,
      DELETE_CONSUMABLE_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_CONSUMABLE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readInstructionDropDown = () => {
  return {
    types: [
      LOAD_INSTRUCTION_DROPDOWN,
      LOAD_INSTRUCTION_DROPDOWN_SUCCESS,
      LOAD_INSTRUCTION_DROPDOWN_FAIL,
    ],
    payload: { request: { url: READ_INSTRUCTION_DROPDOWN_EP } },
  };
};

export const readTechInstruction = (pbsId: string, type: string) => {
  return {
    types: [
      LOAD_TECH_INSTRUCTION,
      LOAD_TECH_INSTRUCTION_SUCCESS,
      LOAD_TECH_INSTRUCTION_FAIL,
    ],
    payload: { request: { url: getPbsInstructionByType(pbsId, type) } },
  };
};

export const readSaftyInstruction = (pbsId: string, type: string) => {
  return {
    types: [
      LOAD_SAFTY_INSTRUCTION,
      LOAD_SAFTY_INSTRUCTION_SUCCESS,
      LOAD_SAFTY_INSTRUCTION_FAIL,
    ],
    payload: { request: { url: getPbsInstructionByType(pbsId, type) } },
  };
};

export const readEnvInstruction = (pbsId: string, type: string) => {
  return {
    types: [
      LOAD_ENV_INSTRUCTION,
      LOAD_ENV_INSTRUCTION_SUCCESS,
      LOAD_ENV_INSTRUCTION_FAIL,
    ],
    payload: { request: { url: getPbsInstructionByType(pbsId, type) } },
  };
};

export const readHelthInstruction = (pbsId: string, type: string) => {
  return {
    types: [
      LOAD_HELTH_INSTRUCTION,
      LOAD_HELTH_INSTRUCTION_SUCCESS,
      LOAD_HELTH_INSTRUCTION_FAIL,
    ],
    payload: { request: { url: getPbsInstructionByType(pbsId, type) } },
  };
};

export const uploadInstructionDoc = (formData: any) => {
  return {
    types: [
      UPLOAD_INSTRUCTION_DOC,
      UPLOAD_INSTRUCTION_DOC_SUCCESS,
      UPLOAD_INSTRUCTION_DOC_FAIL,
    ],
    payload: {
      request: {
        url: UPLOAD_INSTRUCTION_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createTechInstruction = (instruction: any) => {
  return {
    types: [
      CREATE_TECH_INSTRUCTION,
      CREATE_TECH_INSTRUCTION_SUCCESS,
      CREATE_TECH_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_INSTRUCTION_EP,
        method: 'POST',
        data: instruction,
      },
    },
  };
};

export const createSaftyInstruction = (instruction: any) => {
  return {
    types: [
      CREATE_SAFTY_INSTRUCTION,
      CREATE_SAFTY_INSTRUCTION_SUCCESS,
      CREATE_SAFTY_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_INSTRUCTION_EP,
        method: 'POST',
        data: instruction,
      },
    },
  };
};

export const createEnvInstruction = (instruction: any) => {
  return {
    types: [
      CREATE_ENV_INSTRUCTION,
      CREATE_ENV_INSTRUCTION_SUCCESS,
      CREATE_ENV_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_INSTRUCTION_EP,
        method: 'POST',
        data: instruction,
      },
    },
  };
};

export const createHelthInstruction = (instruction: any) => {
  return {
    types: [
      CREATE_HELTH_INSTRUCTION,
      CREATE_HELTH_INSTRUCTION_SUCCESS,
      CREATE_HELTH_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_INSTRUCTION_EP,
        method: 'POST',
        data: instruction,
      },
    },
  };
};

// /api/Competence/ReadCompetenceList
export const filterCompetenceList = (id: string) => {
  return {
    types: [LOAD_COMPETENCE, LOAD_COMPETENCE_SUCCESS, LOAD_COMPETENCE_FAIL],
    payload: {
      request: {
        url: COMPETENCE_FILTER_EP + id,
        method: 'GET',
      },
    },
  };
};

export const filterMilestoneList = (id: string) => {
  return {
    types: [LOAD_MILESTONE, LOAD_MILESTONE_SUCCESS, LOAD_MILESTONE_FAIL],
    payload: {
      request: {
        url: MILESTONE_FILTER_EP + id,
        method: 'GET',
      },
    },
  };
};

export const readCompetenceDropDown = () => {
  return {
    types: [
      LOAD_COMPETENCE_DROPDOWN,
      LOAD_COMPETENCE_DROPDOWN_SUCCESS,
      LOAD_COMPETENCE_DROPDOWN_FAIL,
    ],
    payload: { request: { url: READ_COMPETENCE_DROPDOWN_EP } },
  };
};

export const createCompetence = (competence: Competence) => {
  return {
    types: [
      CREATE_COMPETENCE,
      CREATE_COMPETENCE_SUCCESS,
      CREATE_COMPETENCE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_COMPETENCE_EP,
        method: 'POST',
        data: competence,
      },
    },
  };
};

export const deleteCompetence = (data: string[]) => {
  return {
    types: [
      DELETE_COMPETENCE,
      DELETE_COMPETENCE_SUCCESS,
      DELETE_COMPETENCE_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_COMPETENCE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const createMilestone = (milestone: Milestone) => {
  return {
    types: [CREATE_MILESTONE, CREATE_MILESTONE_SUCCESS, CREATE_MILESTONE_FAIL],
    payload: {
      request: {
        url: MILESTONES_SAVE_EP,
        method: 'POST',
        data: milestone,
      },
    },
  };
};

export const deleteProduct = (data: string[]) => {
  return {
    types: [DELETE_PBS, DELETE_PBS_SUCCESS, DELETE_PBS_FAIL],
    payload: {
      request: {
        url: DELETE_PBS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readPbsRelations = (pbsId: string | null) => {
  return {
    types: [
      READ_PBS_RELATIONS,
      READ_PBS_RELATIONS_SUCCESS,
      READ_PBS_RELATIONS_FAIL,
    ],
    payload: {
      request: {
        url: READ_PBS_RELATIONS_EP + pbsId,
        method: 'GET',
      },
    },
  };
};

export const readCpcRelations = (cpcIdData: any) => {
  return {
    types: [
      READ_CPC_RELATIONS,
      READ_CPC_RELATIONS_SUCCESS,
      READ_CPC_RELATIONS_FAIL,
    ],
    payload: {
      request: {
        url: READ_CPC_RELATIONS_EP,
        method: 'POST',
        data: cpcIdData,
      },
    },
  };
};

export const deleteTechInstruction = (data: string[]) => {
  return {
    types: [
      DELETE_TECH_INSTRUCTION,
      DELETE_TECH_INSTRUCTION_SUCCESS,
      DELETE_TECH_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_TECH_INSTRUCTION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteSaftyInstruction = (data: string[]) => {
  return {
    types: [
      DELETE_SAFTY_INSTRUCTION,
      DELETE_SAFTY_INSTRUCTION_SUCCESS,
      DELETE_SAFTY_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_TECH_INSTRUCTION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteEnvInstruction = (data: string[]) => {
  return {
    types: [
      DELETE_ENV_INSTRUCTION,
      DELETE_ENV_INSTRUCTION_SUCCESS,
      DELETE_ENV_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_TECH_INSTRUCTION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteHelthInstruction = (data: string[]) => {
  return {
    types: [
      DELETE_HELTH_INSTRUCTION,
      DELETE_HELTH_INSTRUCTION_SUCCESS,
      DELETE_HELTH_INSTRUCTION_FAIL,
    ],
    payload: {
      request: {
        url: DELETE_TECH_INSTRUCTION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const resetFormData = () => {
  return { type: RESET_FORM_DATA };
};

export const clonePbs = (id: string) => {
  return {
    types: [CLONE_PBS, CLONE_PBS_SUCCESS, CLONE_PBS_FAIL],
    payload: {
      request: {
        url: CLONE_PBS_EP,
        method: 'POST',
        data: { id: id },
      },
    },
  };
};

export const readTaxonomyLevel = () => {
  return {
    types: [
      READ_TAXONOMY_LEVEL,
      READ_TAXONOMY_LEVEL_SUCCESS,
      READ_TAXONOMY_LEVEL_FAIL,
    ],
    payload: {
      request: {
        url: READ_TAXONOMY_LEVEL_EP,
        method: 'GET',
      },
    },
  };
};

export const createTaxonomyNode = (newNode: any) => {
  return {
    types: [
      CREATE_TAXONOMY_NODE,
      CREATE_TAXONOMY_NODE_SUCCESS,
      CREATE_TAXONOMY_NODE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_TAXONOMY_NODE_EP,
        method: 'POST',
        data: newNode,
      },
    },
  };
};

export const createPBSService = (data: any) => {
  return {
    types: [
      CREATE_PBS_SERVICE,
      CREATE_PBS_SERVICE_SUCCESS,
      CREATE_PBS_SERVICE_FAIL,
    ],
    payload: {
      request: {
        url: PBS_CREATE_SERVICE,
        method: 'POST',
        data: data,
      },
    },
  };
};
export const createPBSScopeOfWork = (data: any) => {
  return {
    types: [
      SAVE_PBS_SCOPE_OF_WORK,
      SAVE_PBS_SCOPE_OF_WORK_SUCCESS,
      SAVE_PBS_SCOPE_OF_WORK_FAIL,
    ],
    payload: {
      request: {
        url: PBS_CREATE_SCOPE_OF_WORK_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getPBSLabourForVP = (pbsId: string) => {
  return {
    types: [READ_PBS_LABOUR, READ_PBS_LABOUR_SUCCESS, READ_PBS_LABOUR_FAIL],
    payload: {
      request: {
        url: GET_PBS_LABOUR + pbsId,
        method: 'GET',
      },
    },
  };
};

export const getPBSResourcesForVP = (pbsId: string) => {
  return {
    types: [
      READ_PBS_VP_RESOURCES,
      READ_PBS_VP_RESOURCES_SUCCESS,
      READ_PBS_VP_RESOURCES_FAIL,
    ],
    payload: {
      request: {
        url: 'PbsResource/ReadPbsResourcesByPbsProduct/' + pbsId,
        method: 'GET',
      },
    },
  };
};

export const createPMOLwithResources = (data: any) => {
  return {
    types: [
      CREATE_PMOL_WITH_RESOURCE,
      CREATE_PMOL_WITH_RESOURCE_SUCCESS,
      CREATE_PMOL_WITH_RESOURCE_FAIL,
    ],
    payload: {
      request: {
        url: CREATE_PMOL_PBS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const readPBSService = (id: string) => {
  return {
    types: [READ_PBS_SERVICE, READ_PBS_SERVICE_SUCCESS, READ_PBS_SERVICE_FAIL],
    payload: {
      request: {
        url: PBS_READ_SERVICE + id,
        method: 'GET',
      },
    },
  };
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result && response.data.result.length > 0) {
    const data = response.data.result.map((item: any) => {
      return {
        key: item.productId,
        id: item.productId,
        parentId: item?.parentId,
        productId: item.productId ? item.productId : '-',
        name: item.name ? item.name : '-',
        title: item.title ? item.title : '-',
        headerTitle: item.headerTitle ? item.headerTitle : '-',
        qualityProducerName: item?.qualityProducerName ?? '-',
        qualityProducerId: item?.qualityProducerId ?? '-',
        qualityProducerCompany: item.qualityProducerCompany ?? '-',
        pbsProductItemTypeId: item.pbsProductItemTypeId
          ? item.pbsProductItemTypeId
          : null,
        pbsProductItemType: item.pbsProductItemType
          ? item.pbsProductItemType
          : '-',
        pbsProductStatusId: item.pbsProductStatusId
          ? item.pbsProductStatusId
          : null,
        pbsProductStatus: item.pbsProductStatus ? item.pbsProductStatus : '-',
        pbsToleranceStateId: item.pbsToleranceStateId
          ? item.pbsToleranceStateId
          : null,
        pbsToleranceState: item.pbsToleranceState
          ? item.pbsToleranceState
          : '-',
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToDropdown = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    const keys = Object.keys(response);
    const data = keys.map((item: any) => {
      return {
        key: item,
        text: item,
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToFilterDropdown = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    const defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(defualtOption, data);
    return defualtOption;
  }
  return [];
};

const exapleTreeData = () => {
  const testData = [
    {
      id: '88bc4819-5968-4c38-8a59-20da60ecb89e',
      title: 'Resource Family 1',
      localeCode: null,
      parentId: null,
      parentLevel: 'parentLevel1',
      childLevel: 'childLevel1',
      displayOrder: 0,
    },
    {
      id: '88bc4819-b78m-4c38-8a59-20da60ecb89e',
      title: 'Pathum',
      localeCode: null,
      parentId: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
      parentLevel: 'parentLevel2',
      childLevel: 'childLevel2',
    },
    {
      id: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
      title: 'Mesith',
      localeCode: null,
      parentId: null,
      parentLevel: 'parentLevel3',
      childLevel: 'childLevel3',
    },
    {
      id: '88bc4819-hn12-4c38-8a59-20da60ecb89e',
      title: 'Sahan',
      localeCode: null,
      parentId: '88bc4819-dn12-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
      parentLevel: 'parentLevel4',
      childLevel: 'childLevel4',
    },
    {
      id: '88bc4819-qn12-4c38-8a59-20da60ecb89e',
      title: 'Shanuka',
      localeCode: null,
      parentId: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
      parentLevel: 'parentLevel5',
      childLevel: 'childLevel5',
    },
    {
      id: '88bc4819-qnmp-4c38-8a59-20da60ecb89e',
      title: 'Kasun',
      localeCode: null,
      parentId: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
      displayOrder: 0,
      parentLevel: 'parentLevel6',
      childLevel: 'childLevel6',
    },
    {
      id: '88bc4819-wbnk-4c38-8a59-20da60ecb89e',
      title: 'Sampath',
      localeCode: null,
      parentId: null,
      displayOrder: 0,
      parentLevel: 'parentLevel7',
      childLevel: 'childLevel7',
    },
  ];
  return testData;
};

export default projectBreakdownReducer;

const formattedPayloadToTaxonomyLevel = (response: any) => {
  const data: any = {};
  if (response && response.length > 0) {
    response.forEach((item: any) => {
      data[item.level] = item;
    });
    return data;
  }
  return [];
};

//to load risk data into pbs modal
export const readRiskById = (id: string) => {
  return {
    types: [
      LOAD_RISK_REGISTER_BY_ID,
      LOAD_RISK_REGISTER_BY_ID_SUCCESS,
      LOAD_RISK_REGISTER_BY_ID_FAIL,
    ],
    payload: { request: { url: READ_RISK_REGISTER_BY_ID_EP + id } },
  };
};

//to load risk data into modal
const getRiskData = (item: any) => {
  let risk: any = {
    id: item.id,
    name: item.name ? item.name : '-',
    title: item.title ? item.title : '-',
    headerTitle: item.headerTitle ? item.headerTitle : '-',
    sequenceCode: item.sequenceCode ? item.sequenceCode : '-',
    person: item.person ? item.person : '-',
    //riskStatus: item?.riskStatus?.status ? item.riskStatus.status : '-',
    riskStatus: item?.riskStatus ? getStatus(item.riskStatus) : '-',
    riskType: item?.riskType ? getRiskType(item.riskType) : '-',
    riskDetails: item.riskDetails ? item.riskDetails : '-',
    cabPersonId: item && item.person ? item.person.id : null,
    riskTypeId: item && item?.riskType?.id ? item?.riskType?.id : null,
    riskStatusId: item && item?.riskStatus?.id ? item?.riskStatus?.id : null,
  };

  return risk;
};

const getRiskType = (response: any) => {
  if (response) {
    let type = (response: any): any => {
      return {
        key: response.id,
        text: response.type,
      };
    };
    return type(response);
  } else {
    return {
      riskType: null,
    };
  }
};

const getStatus = (response: any) => {
  if (response) {
    let status = (response: any): any => {
      return {
        key: response.id,
        text: response.status,
      };
    };
    return status(response);
  } else {
    return {
      riskStatus: null,
    };
  }
};

//to load risk data into pbs modal
export const readQualityById = (id: string) => {
  return {
    types: [
      LOAD_QUALITY_BY_ID,
      LOAD_QUALITY_BY_ID_SUCCESS,
      LOAD_QUALITY_BY_ID_FAIL,
    ],
    payload: { request: { url: READ_QUALITY_BY_ID_EP + id } },
  };
};

//add cbc to pbs
export const readCbcContractor = () => {
  return {
    types: [
      READ_CBC_CONTRACTOR,
      READ_CBC_CONTRACTOR_SUCCESS,
      READ_CBC_CONTRACTOR_FAIL,
    ],
    payload: { request: { url: READ_READ_CBC_CONTRACTOR_EP } },
  };
};

export const saveCbcToPbs = (data: any) => {
  return {
    types: [SAVE_CBC_TO_PBS, SAVE_CBC_TO_PBS_SUCCESS, SAVE_CBC_TO_PBS_FAIL],
    payload: {
      request: { url: SAVE_CBC_TO_PBS_EP, method: 'POST', data: data },
    },
  };
};
export const removeCbcToPbs = (data: string[]) => {
  return {
    types: [
      REMOVE_CBC_TO_PBS,
      REMOVE_CBC_TO_PBS_SUCCESS,
      REMOVE_CBC_TO_PBS_FAIL,
    ],
    payload: {
      request: { url: REMOVE_CBC_TO_PBS_EP, method: 'DELETE', data: data },
    },
  };
};

export const readCbcToPbs = (id: any) => {
  return {
    types: [READ_CBC_TO_PBS, READ_CBC_TO_PBS_SUCCESS, READ_CBC_TO_PBS_FAIL],
    payload: { request: { url: READ_CBC_TO_PBS_EP + id, method: 'GET' } },
  };
};

export const readDeliverablesToPbs = (id: any) => {
  return {
    types: [
      READ_DELIVERABLES_TO_PBS,
      READ_DELIVERABLES_TO_PBS_SUCCESS,
      READ_DELIVERABLES_TO_PBS_FAIL,
    ],
    payload: {
      request: { url: READ_DELIVERABLES_TO_PBS_EP + id, method: 'GET' },
    },
  };
};

const docLinks = [
  'https://uprincev4dev.blob.core.windows.net/uprincev4dev/StopHandShakeFiles/2/2/2024%209%3A06%3A15%20AMmecop_l.png',
  'https://uprincev4dev.blob.core.windows.net/uprincev4dev/StopHandShakeFiles/2/2/2024%209%3A05%3A51%20AMabans11%20%281%29.xlsx',
  'https://uprincev4dev.blob.core.windows.net/uprincev4dev/StopHandShakeFiles/2/2/2024%209%3A06%3A15%20AMmecop_l.png',
  'https://uprincev4dev.blob.core.windows.net/uprincev4dev/StopHandShakeFiles/2/2/2024%209%3A05%3A51%20AMabans11%20%281%29.xlsx',
  'https://uprincev4dev.blob.core.windows.net/uprincev4dev/StopHandShakeFiles/2/2/2024%209%3A06%3A15%20AMmecop_l.png',
];

const pmols = [
  {
    title: 'PMOL-8909 Deliverables 1',
    docLinks,
  },
  {
    title: 'PMOL-8910 Deliverables 2',
    docLinks,
  },
  {
    title: 'PMOL-8911 Deliverables 3',
    docLinks,
  },
];
