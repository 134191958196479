import React from "react";
import { useTranslation } from "react-i18next";
import { ChartType } from "../../types/public-types";
import styles from "./task-list-header.module.css";

export const TaskListHeaderDefault: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  chartType: string
}> = ({ headerHeight, fontFamily, fontSize, rowWidth, chartType }) => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.ganttTable}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        display: 'flex',
        flex: 1
      }}
    >
      <div
        className={styles.ganttTable_Header}
        style={{
          height: headerHeight - 2,
          display: 'flex',
          flex: 1,
          flexWrap: 'nowrap',
          justifyContent: 'space-between'

        }}
      >
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            width: '49%',
            minWidth: 230,
            marginTop: 20
          }}
        >
          &nbsp;{t('name')}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {(chartType !== ChartType.MS && chartType !== ChartType.RESOURCE) &&
            <>
              
              {chartType === ChartType.PBSTAXONOMY && 
                <>
                  <div
                    className={styles.ganttTable_HeaderSeparator}
                    style={{
                      height: headerHeight * 0.5,
                      marginTop: headerHeight * 0.2
                    }}
                  />
                  <div
                    className={styles.ganttTable_HeaderItem}
                    style={{
                      minWidth: 60,
                      maxWidth: 60,
                      marginTop: 20
                    }}
                  >
                    &nbsp;{t('POS')}
                  </div>
                </>
              }

              {chartType === ChartType.PBSTAXONOMY && 
                  <>
                      <div
                        className={styles.ganttTable_HeaderSeparator}
                        style={{
                          height: headerHeight * 0.5,
                          marginTop: headerHeight * 0.2
                        }}
                      />
                      <div
                        className={styles.ganttTable_HeaderItem}
                        style={{
                          minWidth: 140,
                          maxWidth: 140,
                          marginTop: 20
                        }}
                      >
                        &nbsp;{t('qualityReviewer')}
                      </div>
                  </>
              }

              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.2,
                }}
              />
              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 90,
                  maxWidth: 90,
                  marginTop: 20
                }}
              >
                &nbsp;{chartType === ChartType.PO ? t('orderTime') : t('duration')}

              </div>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.25,
                }}
              />

              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 90,
                  maxWidth: 90,
                  marginTop: chartType === ChartType.PO ? 10 : 20
                }}
              >
               <span style={{display: 'block',
                            wordWrap:'break-word',
                            width: 80,
                            marginLeft: 2,
                            whiteSpace: 'normal'}}> 
                  &nbsp;{chartType === ChartType.PO ? t('requestedDate') : t('from')} 

                </span>
              </div>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.25,
                }}
              />
              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 105,
                  maxWidth: 105,
                  marginTop: chartType === ChartType.PO ? 10 : 20
                }}
              >
                <span style={{display: 'block',
                            wordWrap:'break-word',
                            width: 80,
                            whiteSpace: 'normal'}}> 
                  &nbsp;{chartType === ChartType.PO ? t('expectedDate') : t('to')} 
                </span>
              </div>
            </>
          }

      {/* {chartType === ChartType.PO &&
            <>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.2,
                }}
              />
              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 90,
                  maxWidth: 90,
                  marginTop: 20
                }}
              >
                &nbsp;{t('fffff')}
              </div>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.25,
                }}
              />

              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 90,
                  maxWidth: 90,
                  marginTop: 20
                }}
              >
                &nbsp;{t('ddd')}
              </div>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.25,
                }}
              />
              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 105,
                  maxWidth: 105,
                  marginTop: 20
                }}
              >
                &nbsp;{t('www')}
              </div>
            </>
          } */}


          {chartType === ChartType.MS &&
            <>
              <div
                className={styles.ganttTable_HeaderSeparator}
                style={{
                  height: headerHeight * 0.5,
                  marginTop: headerHeight * 0.25,
                }}
              />
              <div
                className={styles.ganttTable_HeaderItem}
                style={{
                  minWidth: 105,
                  maxWidth: 105,
                  marginTop: 20
                }}
              >
                &nbsp;{t('expectedDate')}
              </div>
            </>
          }

        </div>
      </div>
    </div>
  );
};