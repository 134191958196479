import React, { useState, MouseEvent as ReactMouseEvent } from 'react';
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {useDispatch, useSelector} from "react-redux";
import {AcceptType, foremanId} from "../../../types/myDayPlanning";
import DragBox from "../../../shared/dnd/dragBox";
import { ContextualMenu, DirectionalHint, FontIcon, Icon, TooltipDelay, TooltipHost, mergeStyleSets, mergeStyles } from '@fluentui/react';
import { iconColors } from '../../../../theme';
import _ from 'lodash';
import AbsenceModal from '../../humanResource/documentPane/absenceModal/component';
import { AbsenceModalDTO } from '../../../types/humanResource';
import i18n from '../../../../i18n';
import {v4 as uuidv4} from 'uuid';
import { createAbsence, getAbsenceLeaveTypeDropdown } from '../../../reducers/humanResourceReducer';
import { saveMyDayPlanningStateAttr } from '../../../reducers/myDayPlanningReducer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDateTimeToISOString } from '../../../shared/util';
const iconClass = mergeStyles({
    fontSize: 14,
    height: 14,
    width: 14,
    margin: '0 5px',
});

export const classNames = mergeStyleSets({
    black: [{color: 'black'}, iconClass],
});

function ResourceHeader({label, index, resource}: any) {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);
    const [contextMenuProps, setContextMenuProps] = useState<{ [key: string]: any } | undefined>(undefined);
    const [showAbsencePopUp, setShowAbsencePopUp] = useState(false);
    const [leaveTypes , setLeaveTypes] = useState([]);
    const [selectedPerson , setSelectedPerson] = useState<any>(null);
    const workSchedule = [
        {
          id: uuidv4(),
          day: i18n.t('monday'),
          startTime: '',
          endTime: '',
          displayOrder: '1',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('tuesday'),
          startTime: '',
          endTime: '',
          displayOrder: '2',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('wednesday'),
          startTime: '',
          endTime: '',
          displayOrder: '3',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('thursday'),
          startTime: '',
          endTime: '',
          displayOrder: '4',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('friday'),
          startTime: '',
          endTime: '',
          displayOrder: '5',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('saturday'),
          startTime: '',
          endTime: '',
          displayOrder: '6',
        //   personId: this.props.formData.personId,
        },
        {
          id: uuidv4(),
          day: i18n.t('sunday'),
          startTime: '',
          endTime: '',
          displayOrder: '7',
        //   personId: this.props.formData.personId,
        },
      ]
    
    const acceptType = (label: any, resource: any) => {
        return [AcceptType.TEAM, AcceptType.PMOL, AcceptType.PERSON, AcceptType.PBS, AcceptType.PROJECT, AcceptType.VEHICLE];
    };

    const getVehicles = (resource : any) => {
        return resource?.vehicles?.map((vehicle: any) =>{
            if(vehicle){
                const vehicleTitle = vehicle.resourceNumber&&vehicle.title ? vehicle.title.substring(vehicle.resourceNumber.length+3).trim() : vehicle.title;
                return (<div key={Math.random()} style={{ display: 'flex' }}>
                            <DragBox
                                // disabled={!getProject()}
                                type={AcceptType.VEHICLE}
                                onClick={(item: any) => {}}
                                item={{...vehicle, fromTeamId: resource?.teamId}}
                                name={vehicleTitle}
                                style={{ fontSize: 12 }}
                                icon={<FontIcon aria-label="Compass" iconName="DeliveryTruck" className={classNames.black}/>}
                            />
                     </div>)
            }
        })
    }

    const onItemContextMenu = (e: ReactMouseEvent<HTMLDivElement>, item: any) => {
        setSelectedPerson(item);
        if(!leaveTypes || leaveTypes.length===0){
            Promise.all([dispatch(getAbsenceLeaveTypeDropdown())]).then((response: any) =>{
                console.log(response[0].payload.data.result);
                setLeaveTypes(response[0].payload.data.result);
            })
        }
        e.preventDefault();
        setContextMenuProps({
            target: e.currentTarget,
            items: [
                {
                    key: 'registerLeave',
                    text: t('register Leave'),
                    onClick: () => handleRegisterLeave(item),
                }
            ],
            directionalHint: DirectionalHint.rightCenter
        });
    };

    const handleRegisterLeave = (item: any) => {
        setContextMenuProps(undefined); // Hide the menu after action
        setShowAbsencePopUp(true); // open the absence popup
    };

    const openAbsenceModal = (): void => {
        setShowAbsencePopUp(false);
      };
    
      const onChangeStartTime = (index: number, value: any) => {
          let schedule = workSchedule;
          let task = schedule[index];
          task.startTime = value;
          console.log(schedule);

      };
    
      const onChangeEndTime = (index: number, value: any) => {
        // if (
        //   this.state.workSchedule &&
        //   !_.isEmpty(this.state.workSchedule) &&
        //   this.state.workSchedule.length > index
        // ) {
          let schedule = workSchedule;
          let task = schedule[index];
          task.endTime = value;
          console.log(schedule);
        //   this.setState(
        //     (prevState: any) => ({
        //       workSchedule,
        //       isClear: false,
        //     }),
        //     () => {
        //     },
        //   );
        // }
      };

      const onCreateAbsence = (absence: any) => {
        if (!absence.id) {
          absence.id = uuidv4();
        }
           console.log('Absence data',absence) 
           Promise.all([dispatch(createAbsence(absence))]).then((response: any) => {
            dispatch(saveMyDayPlanningStateAttr('absenceUpdateSucces', true));
       })
      };
    
    const validateAbsenceDate = (startDate:any, endDate:any) => {
        const today = moment(selectedDate).startOf('day'); // Today's date at the start of the day
        const start = moment(startDate).startOf('day');
        const end = moment(endDate).startOf('day');
        
        if (start.isSame(end, 'day')) {
            // If start and end are the same, check if today is that date
            return today.isSame(start, 'day');
        }

        return today.isBetween(start, end, null, '[]');
    }  
      
    const getTeamMembers = (htmlString: any, resource: any) => {
        // console.log('htmlString', htmlString)
        const array = htmlString?.split(",");
        // console.log('array', array)
        const sortedList = array.map((text: any) => {
            const cabPerson = resource?.team?.find((team: any) => team?.name === text);
            return {
                cabPerson: cabPerson,  // needed for extract selected cabPersonId
                resource: resource,
                name: text
            }}).sort((a:any, b:any) => {
                if (a.cabPerson?.roleId === foremanId) {
                    return -1;
                }
                if (b.cabPerson?.roleId === foremanId) {
                    return 1;
                }
                if (a.cabPerson?.companyName == null && b.cabPerson?.companyName != null) {
                    return -1;
                }
                if (a.cabPerson?.companyName != null && b.cabPerson?.companyName == null) {
                    return 1;
                }
                if (a.cabPerson?.companyName < b.cabPerson?.companyName) {
                    return -1;
                }
                if (a.cabPerson?.companyName > b.cabPerson?.companyName) {
                    return 1;
                }
                // If company names are the same, maintain the original order
                return array.indexOf(a.name) - array.indexOf(b.name);
            });

            // console.log('sortedList', sortedList)

            return (
              <div>
                {sortedList?.map((cabData: any) => {
                    const cabPerson = resource?.team?.find((team: any) => team?.name == cabData.name);
                    const item = {
                        cabPerson: cabPerson,  //needed for extract selected cabPersonId
                        resource: resource
                    };
                    const memberName = cabPerson?.name === cabData.name&&cabPerson?.companyName ? cabData.name+" ("+cabPerson?.companyName+")" : cabData.name;
                    return (
                        <div style={{ display: 'flex', width: 'fit-content' }} key={cabPerson?.id} onContextMenu={(e) => onItemContextMenu(e, item)}>
                            <DragBox
                                name={memberName}
                                item={item}
                                type={AcceptType.PERSON}
                                style={{ fontSize: 12 }}
                            />
                            {!_.isEmpty(cabPerson?.absenceData) && validateAbsenceDate(cabPerson?.absenceData[0].startDate, cabPerson?.absenceData[0].endDate) && (
                                <TooltipHost
                                    delay={TooltipDelay.medium}
                                    directionalHint={DirectionalHint.rightCenter}
                                    id={cabPerson?.id}
                                    content={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>{cabPerson?.absenceData[0].reason}</span>
                                            <span>{formatDateTimeToISOString(cabPerson?.absenceData[0].startDate)?.split('T')?.[0]} - {formatDateTimeToISOString(cabPerson?.absenceData[0].endDate)?.split('T')?.[0]}</span>
                                        </div>
                                    }
                                    >
                                        <FontIcon
                                            aria-label='WarningSolid'
                                            iconName='WarningSolid'
                                            style={{ marginTop: 7, cursor: 'pointer', color: iconColors.warningIconColor }}
                                        />
                            </TooltipHost>)}
                        </div>
                    );
                 })}
                {contextMenuProps && (
                    <ContextualMenu
                        items={contextMenuProps.items}
                        target={contextMenuProps.target}
                        onDismiss={() => setContextMenuProps(undefined)}
                        directionalHint={contextMenuProps.directionalHint}
                    />
                )}
              </div>
            )};

    return <div id={'resource' + resource?.resourceId} key={JSON.stringify(label)}
                style={{width: '100%', height: '100%'}}>
        <TdDropBox.DropBox
            item={resource}
            day={selectedDate}
            type={acceptType(index, resource)}
        >
            <div style={{minWidth: '100px', minHeight: '120px', display: 'flex', alignItems: 'flex-start'}}>
                <div style={{padding: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    {resource?.teamTitle} <br/>
                    {/* <span style={{fontSize: 12, fontWeight: 400}}>{resource?.contractingUinit}</span>  */}
                    <br/>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            {getVehicles(resource)}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            {getTeamMembers(label, resource)}                            
                        </div>
                </div>
                </div>
        </TdDropBox.DropBox>
        <AbsenceModal
          isOpen={showAbsencePopUp}
          isEdit={false}
          openAbsenceModal={ openAbsenceModal}
          title={i18n.t('absence')}
          labourHistorySelectionDetails={
            [] as AbsenceModalDTO[]
          }
          onChangeStartTime={(index: number, value: string | number) =>
            onChangeStartTime(index, value)
          }
          onChangeEndTime={(index: number, value: string | number) =>
            onChangeEndTime(index, value)
          }
          createAbsence={(absence: any) => {
            onCreateAbsence(absence);
          }}
          labourPerson={{cabPersonId:'',email:'', key:selectedPerson?.cabPerson?.id, mobile:'',Organisation:'',secondaryText:'', text:selectedPerson?.cabPerson?.name}}
          leaveTypes={leaveTypes}
        />
    </div>
}

export default ResourceHeader;
