import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import { Mention } from "@ckeditor/ckeditor5-mention";
import ENV from '../../../env.json';
import client from '../../api';

//import Essentials from "@ckeditor/ckeditor5-build-classic/src/essentials";


export class CABFilter {
  fullName: string | null = null;
  organisation: string | null = null;
  mobileNumber: string | null = null;
  email: string | null = null;
  jobTitle: string | null = null;
  isSaved: boolean | null = null;
  companyId?: string | null = null;
  cabPersonSortingModel: CabPersonSortingModel = new CabPersonSortingModel();
}

class CabPersonSortingModel {
  attribute: string | null = null;
  order: string | null = null;
}

const TextEditor = (props: {
  value: string | null;
  onChange: (value: any) => void;
  height?: string | number;
  readOnly?: boolean;
}) => {

  useEffect(() => {

  },[props.value])



  const formatPersonResponse = (response: any) => {
    if (response && response.data) {
      if (response.data.result && Array.isArray(response.data.result)) {
        const data = response.data.result.map((item: any) => {
          return {
            cabPersonId: item?.person?.id,
            id: `@${item.person.fullName}`,
            companyId: item.companyId,
            name: item.person.fullName,
            Organisation: item.company.name,
            email: item.personCompany.email,
            mobile: item.personCompany.mobilePhone,
            jobTitle: item.personCompany.jobTitle,
            secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          };
        });
        return data;
      }
      return [];
    }
    return [];
  };


  const getPersonByName = async (queryText: string) => {
    const cabFilter = new CABFilter();
    cabFilter.fullName = queryText;
    //cabFilter.companyId = props.companyId
    if (queryText.length >= 2) {
      const response = await client.post('CentralAddressBook/PersonFilter', cabFilter);
      return formatPersonResponse(response);
    }
  };


  const handleChange = (editor: any) => {
    console.log(props.value)
    if (editor) {
      if (editor.getData() !== props.value) {
        props.onChange(editor.getData());
      }
    }
  };

  return (
    <div>
      <CKEditor
        // disabled={props.readOnly}
        editor={ClassicEditor}
        data={props.value || ""}
        config={{
          ckfinder: {
            // Upload the images to the server using the CKFinder QuickUpload command.
            uploadUrl: `${ENV.BASE_URL}/FileUpload/CKUploadFile`,
            baseUrl: ENV.CK_FIND_BASE_URL,
          },
          width: '500px',
          //plugins: [Mention],
          skin: `office2013,public/skins/office2013/`,
          readOnly: props.readOnly,
          mention: {
            feeds: [
              {
                marker: '@',
                feed: getPersonByName,
                minimumCharacters: 1,
              },
            ],
          },
          toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
            'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo'],
        }}
        onChange={(_event: any, editor: { getData: () => any }) => {
          const data = editor.getData();
          handleChange(editor);
        }}
        onBlur={(_event: any, _editor: any) => {
        }}
        onFocus={(_event: any, _editor: any) => {
        }}
      />
    </div>
  );
};

export default TextEditor;
