import moment from "moment/moment";

export const formatMidTermTaxonomyData = (data: any[]) => {
    const currentDate = new Date();
    // let negativeRemovedList = data.filter((item) => {
    //     if (item.startDate) {
    //         let sd = moment(item.startDate)
    //         let ed = moment(item.endDate)
    //         let diff = ed.diff(sd, 'days')
    //         return diff >= 0
    //     } else {
    //         return true;
    //     }
    // })

    const newData = data.map((item: any, index: number) => {
        return {
            ...item,
            name: item.title,
            id: item.type === 'Pbs' ? item.pbsSequenceId : item?.id,
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            progress: 25,
            isHidden: false,
            isDisabled: true,
        };
    });

    const emptyrows: any = Array(100).fill('').map((_, i) => {
        return {
            id: 1000 + i,
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            isHidden: true,
            parentId: null,
            isDisabled: true
        }
    })
    newData.push(...emptyrows)
    return newData;
};

export const formatMtpOrgTaxonomyData = (data: any[]) => {
    const currentDate = new Date();
    let negativeRemovedList = data?.filter((item) => {
        if (item.startDate) {
            let sd = moment(item.startDate)
            let ed = moment(item.endDate)
            let diff = ed.diff(sd, 'days')
            return diff >= 0
        } else {
            return true;
        }
    });

    return negativeRemovedList.map((item: any, i: any) => {
        return {
            ...item,
            name: '',
            // start: new Date(Date.parse(item.startDate)),
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            endDateTitle: item.endDate ? new Date(Date.parse(item.endDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            // end: new Date(Date.parse(item.endDate)),
            progress: 0,
            isHidden: false,
            isDisabled: true,
            key: item.id + i
        };
    });
};

// This function allows only to display the date range of assigned cpc items.
export const removeUnimportantData = (data: any[]) => {
    return data.map((item: any, i: any) => {
        return {
            ...item,
            startDate: item?.organizationTaxonomyLevelId ? null : item.startDate,
            endDate: item?.organizationTaxonomyLevelId ? null : item.endDate,
            removeTitleFromChart: item?.organizationTaxonomyLevelId,
        };
    });
};

export const formatMtpOrgTaxonomyListData = (data:any[]) => {
    const currentDate = new Date();

    return data.map((item: any, i: any) => {
        return {
            ...item,
            key: item.id + i,
            start: item.startDate ? new Date(Date.parse(item.startDate)) : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
            end: item.endDate ? moment(item.endDate).add(1, 'days').toDate() : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
        };
    });
};

export const dataFifty = [
    {
        "id": "79748643-9d63-4dbb-92c3-6eb6cc1d02e2",
        "title": "PBS-1243 - Kavi Pro",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1243",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-08-09T00:00:00",
        "createdDate": null,
        "treeIndex": 36,
        "pbsProductStatusId": "7bcb4e8d-8e8c-487d-8170-6b91c89fc3da",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1243",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "0D3C3F66-731B-4CAF-91B5-662720FDFDCF",
        "title": "PBS-3306 Kavi Pro",
        "parentId": "PBS-1243",
        "pbsSequenceId": "PBS-3306",
        "startDate": "2023-07-25T00:00:00",
        "endDate": "2023-08-09T00:00:00",
        "createdDate": null,
        "treeIndex": 37,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3306",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "FF643D56-51B0-47CA-8F2B-772DC760C62A",
        "title": "PBS-3269 Kavi Pro",
        "parentId": "PBS-1243",
        "pbsSequenceId": "PBS-3269",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 38,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3269",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "5DC17C09-3533-40E9-9CAA-221496F892AE",
        "title": "PBS-3271 Kavi Pro",
        "parentId": "PBS-3269",
        "pbsSequenceId": "PBS-3271",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 39,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3271",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "C9CBDD35-AC54-4C9D-BCFC-2F29FF3492E7",
        "title": "PBS-3303 Kavi Pro",
        "parentId": "PBS-3269",
        "pbsSequenceId": "PBS-3303",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 40,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3303",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "D6D72E61-6404-45B4-AC5B-91B9A5C97E86",
        "title": "PBS-3304 Kavi Pro",
        "parentId": "PBS-3303",
        "pbsSequenceId": "PBS-3304",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 41,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3304",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "4244DF55-334E-4D22-BD34-52E93B366390",
        "title": "PBS-3272 Kavi Pro",
        "parentId": "PBS-3269",
        "pbsSequenceId": "PBS-3272",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 42,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3272",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "EF513E8A-84BE-4747-8067-32FD711D0881",
        "title": "PBS-3270 Kavi Pro",
        "parentId": "PBS-3269",
        "pbsSequenceId": "PBS-3270",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 43,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3270",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "9c5a3ca2-55f8-4957-95df-7664ffdb93b7",
        "title": "PBS-1312 - Kavi Pro",
        "parentId": "PBS-1243",
        "pbsSequenceId": "PBS-1312",
        "startDate": "2023-06-13T00:00:00",
        "endDate": "2023-06-17T00:00:00",
        "createdDate": null,
        "treeIndex": 44,
        "pbsProductStatusId": "4010e768-3e06-4702-b337-ee367a82addb",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1312",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "1BFA310E-CBFD-4D7C-A68F-3DFA40D96D9A",
        "title": "PBS-1113 - New-Add labor Cpc",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1113",
        "startDate": null,
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 45,
        "pbsProductStatusId": "7143ff01-d173-4a20-8c17-cacdfecdb84c",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1113",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "3BF70649-78EF-4B0E-A54C-31944F0EA20B",
        "title": "PBS-3301 New-Add labor Cpc",
        "parentId": "PBS-1113",
        "pbsSequenceId": "PBS-3301",
        "startDate": null,
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 46,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3301",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "41E3192C-E987-44DB-BBB4-3AA4E6DC8D5F",
        "title": "PBS-3307 New-Add labor Cpc",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3307",
        "startDate": "2023-05-01T00:00:00",
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 48,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3307",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "AE37640B-74CC-452F-AF01-2DFF5EC4AC3C",
        "title": "PBS-1136 - gggg",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1136",
        "startDate": "2023-05-01T00:00:00",
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 49,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1136",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "A1DBC263-DE2D-449A-BB81-C0F4E6788782",
        "title": "PBS-3285 gggg",
        "parentId": "PBS-1136",
        "pbsSequenceId": "PBS-3285",
        "startDate": "2023-05-01T00:00:00",
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 50,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3285",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "c62e40e3-744f-428c-962f-6bff6832f6ac",
        "title": "PBS-0646 - New-Add labor Cpc",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-0646",
        "startDate": "2023-05-31T00:00:00",
        "endDate": "2023-07-10T00:00:00",
        "createdDate": null,
        "treeIndex": 51,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0646",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "39DFF4A0-2BA0-42DB-B694-779C357FBB18",
        "title": "PBS-3286 New-Add labor Cpc",
        "parentId": "PBS-0646",
        "pbsSequenceId": "PBS-3286",
        "startDate": "2023-05-31T00:00:00",
        "endDate": "2023-07-10T00:00:00",
        "createdDate": null,
        "treeIndex": 52,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3286",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "994B6C5B-DE57-4E21-9C9E-46594E10202E",
        "title": "PBS-1137 New-Add labor Cpc",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1137",
        "startDate": "2023-06-12T00:00:00",
        "endDate": "2023-07-08T00:00:00",
        "createdDate": null,
        "treeIndex": 53,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1137",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "C268CE0D-6892-4497-8AA6-99A858D518EC",
        "title": "PBS-1112 - New-Add labor Cpc 1.1",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1112",
        "startDate": "2023-05-03T00:00:00",
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 54,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1112",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "F7EE4DD7-87A5-44FF-A0F9-5A860BF730BA",
        "title": "PBS-1114 - 555",
        "parentId": "PBS-3301",
        "pbsSequenceId": "PBS-1114",
        "startDate": null,
        "endDate": "2023-05-12T00:00:00",
        "createdDate": null,
        "treeIndex": 54,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1114",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "476dfe8a-83f1-4552-820b-6799ca4a5e6f",
        "title": "PBS-0595 - 666",
        "parentId": "PBS-1114",
        "pbsSequenceId": "PBS-0595",
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 55,
        "pbsProductStatusId": "7bcb4e8d-8e8c-487d-8170-6b91c89fc3da",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0595",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "7d5bdcfe-489f-4d16-bfb4-576f11c64d20",
        "title": "PBS-0593 - Kavi Pro",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-0593",
        "startDate": "2023-05-11T00:00:00",
        "endDate": "2023-05-11T00:00:00",
        "createdDate": null,
        "treeIndex": 56,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0593",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "25f3cfb2-c979-4f35-8cbb-fc61ad0be37b",
        "title": "PBS-0504 - Kavi Pro",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-0504",
        "startDate": "2023-05-11T00:00:00",
        "endDate": "2023-05-11T00:00:00",
        "createdDate": null,
        "treeIndex": 57,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0504",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "372cdcb4-2b16-4f30-8318-efedcc9294f1",
        "title": "PBS-0015 - Project Emiel",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-0015",
        "startDate": "2023-05-16T00:00:00",
        "endDate": "2023-05-16T00:00:00",
        "createdDate": null,
        "treeIndex": 58,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0015",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "6e025a82-129d-4311-b3e1-09edafd60f99",
        "title": "PBS-0477 - Project Emiel",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-0477",
        "startDate": "2023-05-04T00:00:00",
        "endDate": "2023-05-04T00:00:00",
        "createdDate": null,
        "treeIndex": 59,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-0477",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "2483B4E3-47B7-45E9-823C-10D7C5F39BBF",
        "title": "PBS-1115 - Project Emiel",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-1115",
        "startDate": "2023-05-04T00:00:00",
        "endDate": "2023-05-04T00:00:00",
        "createdDate": null,
        "treeIndex": 60,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-1115",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "a8765e12-c585-4826-9fa4-bae45aa72e7e",
        "title": "PBS-3234 - Kavi Pro",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3234",
        "startDate": "2023-04-26T00:00:00",
        "endDate": "2023-04-26T00:00:00",
        "createdDate": null,
        "treeIndex": 61,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3234",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "38231406-ff99-415b-8126-7173c568429b",
        "title": "PBS-3308 - 678",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3308",
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 62,
        "pbsProductStatusId": "94282458-0b40-40a3-b0f9-c2e40344c8f1",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3308",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "f180a63b-1667-4871-a17b-ff919404b10f",
        "title": "PBS-3315 - Added a New CPC res fam",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3315",
        "startDate": "2023-08-07T00:00:00",
        "endDate": "2023-08-18T00:00:00",
        "createdDate": null,
        "treeIndex": 63,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3315",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "164f87d9-b482-4030-966b-eb6c76e8f1eb",
        "title": "PBS-3365 - Kavi Pro b",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3365",
        "startDate": "2023-08-17T00:00:00",
        "endDate": "2023-08-17T00:00:00",
        "createdDate": null,
        "treeIndex": 64,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3365",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "2ca42b05-a953-4331-8ef3-5999f0d921e6",
        "title": "PBS-3458 - Meka harima lassana PBS ekak",
        "parentId": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "pbsSequenceId": "PBS-3458",
        "startDate": "2023-09-11T00:00:00",
        "endDate": "2023-10-01T00:00:00",
        "createdDate": null,
        "treeIndex": 65,
        "pbsProductStatusId": "d60aad0b-2e84-482b-ad25-618d80d49477",
        "nodeType": null,
        "type": "Pbs",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": "PBS-3458",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "e094aef8-8bfa-4019-9f07-0a50ee0b0a6f",
        "title": "P0014 - Kavi Pro",
        "parentId": null,
        "pbsSequenceId": null,
        "startDate": "2023-04-26T00:00:00",
        "endDate": "2023-10-01T00:00:00",
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "Project",
        "projectSequenceId": "P0014",
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": "65f2f0fd-ea7a-440e-9fd4-346628ef1299",
        "projectScopeStatusId": "4010e768-3e06-4702-b337-ee367a82addb",
        "sequenceId": "P0014",
        "displayOrder": 0,
        "isFilter": false
    },
    {
        "id": "6d2b3f1e-b458-4069-bd60-1a410b8668fd",
        "title": "CAr01-0066 - Quality",
        "parentId": "PBS-1114",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "020aabdd-df2a-4994-b22f-258790f4aef9",
        "title": "CAr01-0136 - pgup labor 1",
        "parentId": "PBS-3234",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "02206538-20e8-4968-a9ff-fcb2f0a71bce",
        "title": "CAr01-0114 - Figma",
        "parentId": "PBS-3315",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "d73f30b7-c7a7-47c4-89c5-51f89b6ba7ca",
        "title": "CAr01-0067 - New labor CPC",
        "parentId": "PBS-1113",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "02206538-20e8-4968-a9ff-fcb2f0a71bce",
        "title": "CAr01-0114 - Figma",
        "parentId": "PBS-1243",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "d73f30b7-c7a7-47c4-89c5-51f89b6ba7ca",
        "title": "CAr01-0067 - New labor CPC",
        "parentId": "PBS-1114",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "65ac309a-497f-40b7-b7dd-31fd9ad410b3",
        "title": "PAr01-0217 - New CPC labor",
        "parentId": "PBS-3458",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "6d2b3f1e-b458-4069-bd60-1a410b8668fd",
        "title": "CAr01-0066 - Quality",
        "parentId": "PBS-0646",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "6d2b3f1e-b458-4069-bd60-1a410b8668fd",
        "title": "CAr01-0066 - Quality",
        "parentId": "PBS-1112",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "6d2b3f1e-b458-4069-bd60-1a410b8668fd",
        "title": "CAr01-0066 - Quality",
        "parentId": "PBS-1114",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "6d2b3f1e-b458-4069-bd60-1a410b8668fd",
        "title": "CAr01-0066 - Quality",
        "parentId": "PBS-1113",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "02206538-20e8-4968-a9ff-fcb2f0a71bce",
        "title": "CAr01-0114 - Figma",
        "parentId": "PBS-3365",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "21bdc5cb-c0e2-43e3-b2d7-9ec17741ca33",
        "title": "CAr01-0081 - A_labor 1",
        "parentId": "PBS-1113",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "d73f30b7-c7a7-47c4-89c5-51f89b6ba7ca",
        "title": "CAr01-0067 - New labor CPC",
        "parentId": "PBS-0646",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "d73f30b7-c7a7-47c4-89c5-51f89b6ba7ca",
        "title": "CAr01-0067 - New labor CPC",
        "parentId": "PBS-1112",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "02206538-20e8-4968-a9ff-fcb2f0a71bce",
        "title": "CAr01-0114 - Figma",
        "parentId": "PBS-1312",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    },
    {
        "id": "b81c3ff9-e40f-4bc7-a30e-3f25935033c0",
        "title": "Dilanka Perera",
        "parentId": "02206538-20e8-4968-a9ff-fcb2f0a71bce",
        "pbsSequenceId": null,
        "startDate": null,
        "endDate": null,
        "createdDate": null,
        "treeIndex": 0,
        "pbsProductStatusId": null,
        "nodeType": null,
        "type": "PbsAssignedLabour",
        "projectSequenceId": null,
        "cu": "COM-0001",
        "project": "P0014",
        "projectStatus": null,
        "projectScopeStatusId": null,
        "sequenceId": null,
        "displayOrder": 240,
        "isFilter": false
    }
];