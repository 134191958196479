import {
  ActionButton,
  Dropdown,
  IBasePickerSuggestionsProps,
  IDropdownOption,
  IIconProps,
  IPersona,
  IPersonaProps,
  Label,
  NormalPeoplePicker,
  Position,
  SpinButton,
  TextField,
} from '@fluentui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  GetVPFilterDropdownData,
  formatVProductTaxonomyData,
  formatVPurchaseTaxonomyBarDataWithoutRefresh,
  readVPPBSTaxonomyData,
  saveVPStateAttr,
} from '../../../reducers/visualPlanReducer';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
// import 'style.css';
import { ViewMode } from '../../../shared/ganttv2';
import {
  calculateEndDate, calculateStartDate,
  formatDateTimeToISOString,
  getSelectedFilterDates,
  updateVPProductStatusFilter,
  updateVPStandardFilter,
} from '../../../shared/util';
import { ViewModeContext } from './component';
import ModeSwitcherWithRadioButtons from '../../../shared/ganttViewSwitcher/viewSwitcherWithRadioButtons';
import AzureAddressAutoComplete from '../../../shared/azureAddress/container';
import _ from 'lodash';
import client from '../../../api';

export const ViewSwitcher: (props: { onViewSwitcherFilterValueChange: any, showAdditionalFilters?:boolean }) => JSX.Element = (props:{ onViewSwitcherFilterValueChange: any, showAdditionalFilters?:boolean}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  // const [isProjectSelected, setIsProjectSeleted] = useState<boolean>(true);
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedMode, setSelectedViewMode] = useState<ViewMode>(ViewMode.QuaterYear);
  const formRef: any = useRef();
  const [standardFilterKey, setStandardFilterKey] = useState<any>('4'); // Initially set to Project
  const [prodStatusFilterKey, setProdStatusFilterKey] = useState<any>('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
  const [searchString, setSearchStringValue] = useState<any>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onViewModeChange, onViewListChange, isCheck } = useContext(ViewModeContext);
  const standerdFilterOptions = useSelector((state: any) => updateVPStandardFilter(state.vp.vpFilterDropdown));
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({lat: 0, lon: 0});
  const [radius, setRadius] = useState(0);
  const [selectedWorkHour, setSelectedWorkHour] = useState<any>(0);
  const [qualityReviewer, setQualityReviewer] = useState<IPersona[]>([]);
  const [pos, setPos] = useState(0)
 
  const refreshIcon: IIconProps = { iconName: 'Refresh' };

  const productStatusFilterOptions = useSelector((state: any) =>
    updateVPProductStatusFilter(state.projectBreakdown?.productStates)
  );

  const requestSequence = useSelector((state: any) =>state?.vp?.requestSequence);

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  const workHourList: IDropdownOption[] = [
    {key:0, text:`${t('all')}`},
    {key:2, text:'2 Hours'},
    {key:4, text:'4 Hours'},
    {key:6, text:'6 Hours'},
    {key:8, text:'8 Hours'},
  ]
  
  useEffect(() => {
    dispatch(GetVPFilterDropdownData());
  }, []);

  useEffect(() => {
    resetViewSwitcher();
  },[requestSequence])

  const debounced = useDebouncedCallback(
    (value: any) => {
      if (value) {
        dispatch(
          readVPPBSTaxonomyData({
            fromDate: formatDateTimeToISOString(startDate!!),
            toDate: formatDateTimeToISOString(endDate!!),
            title: value,
            productStatus:prodStatusFilterKey == 1? null: prodStatusFilterKey,
            address: null,
            Coordinates: {lat: 0, lon: 0},
            radius: null,
            MinLabourHours: 0,
            MaxLabourHours: 0,
            pos: 0
          })
        );
      } else {
        dispatch(
          readVPPBSTaxonomyData({
            fromDate: formatDateTimeToISOString(startDate!!),
            toDate: formatDateTimeToISOString(endDate!!),
            title: null,
            productStatus:prodStatusFilterKey == 1? null: prodStatusFilterKey,
            address: null,
            Coordinates: {lat: 0, lon: 0},
            radius: null,
            MinLabourHours: 0,
            MaxLabourHours: 0,
            pos: 0
          })
        );
      }
      setAddress("");
      setCoordinates({lat: 0, lon: 0});
      setRadius(0);
      setSelectedWorkHour(0)
    },
    // delay in ms
    1000
  );

  const resetViewSwitcher = () => {
    onViewModeChange(ViewMode.Month);
    setSelectedViewMode(ViewMode.Month);
    onViewListChange(true);
    setStartDate(null);
    setEndDate(null);
    setStandardFilterKey('4');
    setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
    setSearchStringValue('');
    setAddress('');
    setCoordinates({lat: 0, lon: 0});
    setRadius(0);
    setSelectedWorkHour(0);
    setQualityReviewer([]);
    formRef.current.reset();
    formRef.current.mutators.setSearchString(null);
  }

  const filterWorkHours =  async (duration: number, startDate: any, endDate: any) => { //  filtered by frontend for work Hours
        const reponse : any = await dispatch(readVPPBSTaxonomyData({
          title: null,
          fromDate: startDate,
          toDate: endDate,
          productStatus: '7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea',
          address: null,
          Coordinates: {lat: 0, lon: 0},
          radius: null,
          MinLabourHours: 0,
          MaxLabourHours: 0,
          qualityReviewerId: null,
          pos: pos
      }))

      const responseData = formatVProductTaxonomyData(reponse.payload.data.result.productTaxonomy);
        if(duration!==0){
          const durationList = responseData.filter((item: any) => { 
            if(item.consolidatedDuration === duration){
              return item;
            }});
          
          const getAllParentNodes = (node:any) =>{
            responseData.filter((item: any) => {
              if(node.parentId === item.pbsSequenceId){
                durationList.push(item);
                if(item.parentId&&item.parentId!=null){
                  getAllParentNodes(item);
                }
              }});
          }
      
          durationList.filter((durationNode: any) => {
              getAllParentNodes(durationNode);
          }).flat();

          let uniqueList = [];
          const UniqueId = new Map();

          durationList.forEach((item: any) => {
            if (!UniqueId.has(item.id)) {
              UniqueId.set(item.id, item);
            }
          });

          uniqueList = Array.from(UniqueId.values());

          dispatch(saveVPStateAttr('vpProducTaxonomyList', uniqueList.length>0?[...uniqueList]:formatVProductTaxonomyData(uniqueList)));
          dispatch(saveVPStateAttr('vpProducTaxonomyBarList', uniqueList.length>0?[...uniqueList]:[]));
          dispatch(saveVPStateAttr('vpProducTaxonomyInteractBarList', uniqueList.length>0?[...uniqueList]:[]));
        } else {
          dispatch(saveVPStateAttr('vpProducTaxonomyList', [...responseData]))
        }
  }

  const onQualityReviewerSelected = (item: any) => {
    if (item) {
      setQualityReviewer([item]);
    }
    return item;
  };

  const getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(`CentralAddressBook/PersonFilterByName`, {
      fullName: name,
    });
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  const onFilterChanged = async (
    filterText: string,
    currentPersonas?: IPersonaProps[] 
  ): Promise<IPersonaProps[]> => {
    if(filterText){
      const fetchedData = await getPersonByNameForQulity(filterText);
      return fetchedData;
    } else {
      return [];
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 70,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '0.5px solid gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          flexGrow: 0.2,
          width: '100%',
        }}
      >
        <Form
          onSubmit={() => {}}
          initialValues={{
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
          }}
          mutators={{
            setHeaderDocs: (args, state, utils) => {
              const field = state.fields['header.files'];
              field.change(args[0]);
            },
            setSearchString: (args, state, utils) => {
              const field = state.fields['searchString'];
              field.change(args[0]);
            },
            setDateRange: (args, state, utils) => {
              const from = state.fields['startDate'];
              const to = state.fields['endDate'];
              from.change(getSelectedFilterDates(args)[0]);
              to.change(getSelectedFilterDates(args)[1]);
              dispatch(
                saveVPStateAttr(
                  'scrollPosDate',
                  getSelectedFilterDates(args)[0]
                )
              );

              dispatch(
                readVPPBSTaxonomyData({
                  fromDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[0]
                  ),
                  toDate: formatDateTimeToISOString(
                    getSelectedFilterDates(args)[1]
                  ),
                  title: null,
                  filter: args[0],
                  productStatus: '7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea',
                  address: null,
                  Coordinates: {lat: 0, lon: 0},
                  radius: null,
                  MinLabourHours: 0,
                  MaxLabourHours: 0,
                  qualityReviewerId: null,
                  pos: pos
                })
              );
              setSearchStringValue('');
              setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
              setAddress("");
              setCoordinates({lat: 0, lon: 0});
              setRadius(0);
              setSelectedWorkHour(0);
              setQualityReviewer([]);
            },
          }}
          // validate={ validate }
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            // props.submit(handleSubmit);
            formRef.current = form;
            props.onViewSwitcherFilterValueChange(values);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 70,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 70,
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        height: 70,
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: 180,
                          marginLeft: 15,
                          marginRight: 15,
                          marginTop: 30,
                        }}
                      >
                        <div style={{ width: 180, marginLeft: 15 }}>
                          <Field name="searchString">
                            {({ input, meta }) => (
                              <TextField
                                value={searchString}
                                placeholder={t('search')}
                                onChange={(event, value) => {
                                  setSearchStringValue(value?value:'');
                                  if (value?.length!! >= 1) {
                                    input.onChange(value);
                                  } else {
                                    input.onChange(null);
                                  }
                                  if (value?.length!! >= 3) {
                                    setSearchTriggered(true);
                                    debounced(value);
                                    //handleTreeOnSearch(value);
                                  } else if (
                                    value?.length!! === 0 &&
                                    searchTriggered
                                  ) {
                                    setSearchTriggered(false);
                                    debounced(null);
                                    //handleTreeOnSearch('');
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      
                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Label>{t('qualityReviewer')}</Label>
                        <div key={'qualityReviewer'}>
                          <NormalPeoplePicker
                            onChange={(items) => {
                              if (items && _.isArray(items) && items.length === 0) {
                                setQualityReviewer([]);
                              }
                              setSearchStringValue('');
                              setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
                              setAddress("");
                              setCoordinates({lat: 0, lon: 0});
                              setRadius(0);
                              setSelectedWorkHour(0);
                              dispatch(readVPPBSTaxonomyData({
                                title: null,
                                fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                productStatus: '7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea',
                                address: null,
                                Coordinates: null,
                                radius: null,
                                MinLabourHours: 0,
                                MaxLabourHours: 0,
                                qualityReviewerId: items && items[0]?.key ? items[0].key : null,
                                pos: pos
                              }));
                            }}
                            selectedItems={
                              qualityReviewer &&
                              qualityReviewer[0] &&
                              qualityReviewer[0]
                                ? qualityReviewer
                                : []
                            }
                            onResolveSuggestions={onFilterChanged}
                            onItemSelected={onQualityReviewerSelected}
                            pickerSuggestionsProps={limitedSearchAdditionalProps}
                            className={'ms-PeoplePicker'}
                            key={'qualityReviewer'}
                            itemLimit={1}
                            removeButtonAriaLabel={'Remove'}
                            resolveDelay={300}
                          />
                        </div>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="productStatus">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('productStatus')}
                              label={t('productStatus')}
                              selectedKey={prodStatusFilterKey}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                item: IDropdownOption | undefined
                              ) => {
                                if (item) {
                                  setProdStatusFilterKey(item.key);
                                  input.onChange(
                                    dispatch(
                                      readVPPBSTaxonomyData({
                                        title: null,
                                        fromDate: values.date
                                          ? formatDateTimeToISOString(
                                              getSelectedFilterDates(
                                                values.date
                                              )[0]
                                            )
                                          : null,
                                        toDate: values.date
                                          ? formatDateTimeToISOString(
                                              getSelectedFilterDates(
                                                values.date
                                              )[1]
                                            )
                                          : null,
                                        productStatus: item.key == 1 ? null : item.key,
                                        address: null,
                                        Coordinates: null,
                                        radius: null,
                                        MinLabourHours: 0,
                                        MaxLabourHours: 0 ,
                                        pos: pos
                                      })
                                    )
                                  );
                                  input.onChange(item.key);
                                  setSearchStringValue('');
                                  setAddress("");
                                  setCoordinates({lat: 0, lon: 0});
                                  setRadius(0);
                                  setSelectedWorkHour(0);
                                }
                              }}
                              options={productStatusFilterOptions}
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ marginLeft: 15, width: 80 }}>
                        <Field name="pos">
                          {({ input, meta }) => (
                            <TextField 
                              label={t('POS')}
                              value={pos.toString()}
                              onChange={async (event, newValue) => {
                                const numericValue = newValue ? parseInt(newValue) : 0;
                                setPos(numericValue)
                                await dispatch(readVPPBSTaxonomyData({
                                  title: null,
                                  fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                  toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                  productStatus: prodStatusFilterKey == 1 ? null : prodStatusFilterKey,
                                  address: address ? address : null,
                                  Coordinates: coordinates ? coordinates : {lat: 0, lon: 0},
                                  radius: radius ? radius : null,
                                  MinLabourHours: 0,
                                  MaxLabourHours: 0,
                                  qualityReviewerId: null,
                                  pos: numericValue
                                }))
                              }}
                              type='number'
                            />
                          )}
                        </Field>
                      </div>

                      <div style={{ width: 180, marginLeft: 15 }}>
                        <Field name="date">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('standardFilter')}
                              label={t('standardFilter')}
                              // selectedKey={values.date ? values.date : '5'}
                              selectedKey={standardFilterKey}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(event, value: any) => {
                                let date = null;
                                if (value) {
                                  date = value.key;
                                  input.onChange(value.key);
                                  setStandardFilterKey(value.key);
                                } else {
                                  date = null;
                                  input.onChange(null);
                                }
                                form.mutators.setDateRange(value?.key);
                                if (value?.key === '5') {
                                  onViewModeChange(ViewMode.QuaterYear);
                                  // setIsProjectSeleted(true)
                                  setSelectedViewMode(ViewMode.QuaterYear);
                                } else {
                                  onViewModeChange(ViewMode.Week);
                                  if(value.key === '3' || value.key === '4') {
                                    setSelectedViewMode(ViewMode.Month)
                                  } else 
                                  // setIsProjectSeleted(false)
                                  setSelectedViewMode(ViewMode.Week);
                                }
                              }}
                              options={
                                standerdFilterOptions
                                  ? standerdFilterOptions
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>
                      
                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15, display: 'none' }}
                      >
                        <Label style={{ width: 180 }}>{t('fromDate')}:</Label>
                        <Field name="startDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setValue={values.startDate}
                              getValue={(date: Date) => {
                                setStandardFilterKey('8');
                                input.onChange(date);
                                setStartDate(date);
                                input.onChange(date);
                                const newDate = calculateEndDate(selectedMode, date);
                                setEndDate(newDate);

                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(date),
                                    toDate: formatDateTimeToISOString(newDate),
                                    productStatus:
                                      prodStatusFilterKey == 1
                                        ? null
                                        : prodStatusFilterKey,
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                     
                      <div
                        className={'vpDatePicker180'}
                        style={{ width: 180, marginLeft: 15, display: 'none' }}
                      >
                        <Label style={{ width: 180 }}>{t('toDate')}:</Label>
                        <Field name="endDate">
                          {({ input, meta }) => (
                            <CustomDatePicker
                              setMinDate={
                                startDate
                                  ? startDate
                                  : new Date(
                                      form.getFieldState('startDate')?.value
                                    )
                              }
                              setValue={values.endDate}
                              getValue={(date: Date) => {
                                setStandardFilterKey('8');
                                input.onChange(date);
                                const newDate = calculateStartDate(selectedMode, date);
                                setStartDate(newDate);
                                setEndDate(date);
                                dispatch(
                                  readVPPBSTaxonomyData({
                                    fromDate: formatDateTimeToISOString(newDate),
                                    toDate: formatDateTimeToISOString(date),
                                    productStatus:
                                      prodStatusFilterKey == 1
                                        ? null
                                        : prodStatusFilterKey,
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      {props.showAdditionalFilters &&
                        <div style={{ marginLeft: 15 }}>
                          <Field name="location">
                              {({ input, meta }) => (
                                <AzureAddressAutoComplete
                                onAddressSelected={(location: any) => {
                                  if (location.address.freeformAddress && location.position) {
                                    setAddress(location.address.freeformAddress);
                                    setCoordinates(location.position);
                                    dispatch(readVPPBSTaxonomyData({
                                      title: null,
                                      fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                      toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                      productStatus: '7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea',
                                      address: location.address.freeformAddress ? location.address.freeformAddress : null,
                                      Coordinates: location.position ? location.position : null,
                                      radius: null,
                                      MinLabourHours: 0,
                                      MaxLabourHours: 0,
                                      qualityReviewerId: null,
                                      pos: pos
                                    }));
                                  } else {
                                    setAddress("");
                                    setCoordinates({ lat: 0, lon: 0 });
                                  }
                                  setSearchStringValue('');
                                  setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea')
                                  setSelectedWorkHour(0);
                                  setQualityReviewer([]);
                                } }
                                address={address}
                                disabled={false}
                                lable={'Location'} 
                                onChange={function (value: any): void {
                                  if(value){
                                    setAddress(value);
                                  } else {
                                    setAddress('');
                                    setCoordinates({lat: 0, lon: 0})
                                  }                                  
                                }}
                                />
                                )}
                          </Field>
                        </div>
                      }

                      {props.showAdditionalFilters &&
                        <div style={{ marginLeft: 15 }}>
                          <Field name="radius">
                            {({ input, meta }) => (
                              <SpinButton
                                label={t("radius")}
                                labelPosition={Position.top}
                                value={radius.toString()}
                                min={0}
                                max={10000}
                                step={1}
                                incrementButtonAriaLabel="Increase value by 1"
                                decrementButtonAriaLabel="Decrease value by 1"
                                styles={{ spinButtonWrapper: { width: 75 } }}
                                onChange={(event, value) =>{
                                  if(value){
                                    const numericValue = value ? parseFloat(value) : 0;
                                    setRadius(numericValue);
                                    dispatch(readVPPBSTaxonomyData({
                                      title: null,
                                      fromDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null,
                                      toDate: values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null,
                                      productStatus: '7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea',
                                      address: address ? address : null,
                                      Coordinates: coordinates ? coordinates : {lat: 0, lon: 0},
                                      radius: numericValue ? numericValue : null,
                                      MinLabourHours: 0,
                                      MaxLabourHours: 0,
                                      qualityReviewerId: null,
                                      pos: pos
                                  }))
                                  } else {
                                    setRadius(0);
                                  }
                                  setSearchStringValue('');
                                  setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
                                  setSelectedWorkHour(0);
                                  setQualityReviewer([]);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      }

                      {props.showAdditionalFilters &&
                        <div style={{ width: screen.width<2540? 108 :160, marginLeft: 15 }}>
                        <Field name="workHours">
                          {({ input, meta }) => (
                            <Dropdown
                              placeholder={t('workHours')}
                              label={t('workHours')}
                              selectedKey={selectedWorkHour}
                              onChange={(event, value: any) => {
                                  setSelectedWorkHour(value?.key);
                                  const startDate = values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[0]) : null;
                                  const endDate = values.date ? formatDateTimeToISOString(getSelectedFilterDates(values.date)[1]) : null;
                                filterWorkHours(value.key,startDate,endDate);
                                setSearchStringValue('');
                                setProdStatusFilterKey('7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea');
                                setAddress("");
                                setCoordinates({lat: 0, lon: 0});
                                setRadius(0);
                                setQualityReviewer([]);
                              }}
                              options={
                                workHourList
                                  ? workHourList
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>
                      }

                      <ModeSwitcherWithRadioButtons
                        onViewModeChange={(t: any) => {
                          onViewModeChange(t);
                          setSelectedViewMode(t);
                        }}
                        onViewListChange={onViewListChange}
                        isCheck={isCheck}
                        selectedViewMode={selectedMode}
                        showGanttForProjectPlanning={props.showAdditionalFilters}
                      />

                      <ActionButton
                        onClick={() => {
                          resetViewSwitcher();
                          dispatch(
                            readVPPBSTaxonomyData({
                              fromDate: formatDateTimeToISOString(startDate!!),
                              toDate: formatDateTimeToISOString(endDate!!),
                              productStatus:'7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea'
                            })
                          );
                        }}
                        iconProps={refreshIcon}
                        allowDisabledFocus
                        disabled={false}
                        checked={false}
                        style={{ marginTop: 15 }}
                      >
                        {t('refresh')}
                      </ActionButton>
                    </div>
                  </div>
                </div>

                 {/*<pre>{ JSON.stringify(values, null, 2) }</pre>*/}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
