import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  Label,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import TextEditor from '../../../shared/textEditor/textEditor';
import { Journal, JournalImage } from '../../../types/projectMolecule';
import { formatDateTimeToISOString, PMOL_PBS_HEADER_SECTION_LIST } from '../../../shared/util';
import { UPLOAD_PMOL_JOURNAL_DOCUMENTS } from '../../../shared/endpoints';
import PictureUploadComponent from '../extraWork/PictureUpload/PictureUploadComponent';

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 0,
  },
};

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const ProjectMoleculerJournal = (props: {
  PMOLSaveJournal: () => void;
  setJournal: (feild: any, value: any) => void;
  setJournalImage: (type: string, response: any) => void;
  journal: Journal
  readOnly: boolean
  productId:any
  renameSuccess?: () => void;
  handleJournalChange: (item:any[]) => void;
  pmolId?: string;
}) => {
  const { t } = useTranslation();

  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const getShortFileName = (item: string) => {
    let shortFileName = ((getFileName(item)).substring(0, getFileName(item).length));

    return shortFileName;
  };

  const onImageSave = (type: any, image: any, phase: string | null) => {
    if (image) {
      let imageProp = {
        image: image,
        link: image,
        id: null,
        type: type,
        isLast: true,
        isJournal: true,
        isApproved: false,
        //
        phase: phase,
        fileName: getShortFileName(image),
        location: null,
        createdDate: formatDateTimeToISOString(new Date()),
      }
      props.setJournalImage(type, imageProp)
    }
  }

  const getImages = (images: JournalImage[], type: string) => {
    if (images) {
      let typeImages = images.filter(image => image.type === type);
      let formattedImages = typeImages.map((item: any) => {
        return {
          image: item.link,
          link: item.link,
          id: item.id,
          type: item.type,
          isLast: true,
          isJournal: true,
          isApproved: item.isApproved,
          //
          phase: item.phase,
          fileName: item.fileName,
          location: item.location,
          createdDate: item.createdDate || formatDateTimeToISOString(new Date()),
          lat: item.lat,
          lon: item.lon,
          freeFormAddress: item.freeFormAddress
        };
      });
      return formattedImages;
    } else {
      return [];
    }
  };

  const imageDeleteSuccess = (imageList: any) => {
    props.journal.pictureList = imageList
  }

  return (
    <div className="proj-detail-content inner">
      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('whathaveyoudonetoday')}</Label>
          <TextEditor
            value={props.journal && props.journal.doneWork ? props.journal.doneWork : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('doneWork', value);
              } else {
                props.setJournal('doneWork', null);
              }
            }}
          />
        </div>
      </div>

      <div className="marginTop">
        <PictureUploadComponent 
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '1')}
          // uploadedImages={type1}
          onSaveSuccess={(response: any, phase: string | null) => onImageSave('1', response, phase)}
          isDragEnabled={true}
          updateDocument={(item: any, isDelete: boolean) => {
            if(!isDelete){
              props.handleJournalChange(item);
            }
          }}
          pmolId={props?.pmolId}
          pbsId={null}
          sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.JOURNALWORK}
          renameSuccess={() => {
            if(props.renameSuccess) {
              props.renameSuccess();
            }
          }}
          imageDeleteSuccess={imageDeleteSuccess}
          uploadURL={UPLOAD_PMOL_JOURNAL_DOCUMENTS}
          productId={props.productId}
          isProduct={false}
        />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('didyouencounteranyproblem')}</Label>
          <TextEditor
            value={props.journal && props.journal.encounteredProblem ? props.journal.encounteredProblem : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('encounteredProblem', value);
              } else {
                props.setJournal('encounteredProblem', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <PictureUploadComponent 
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '2')}
          // uploadedImages={type1}
          onSaveSuccess={(response: any, phase: string | null) => onImageSave('2', response, phase)}
          isDragEnabled={true}
          updateDocument={(item: any, isDelete: boolean) => {
            if(!isDelete){
              props.handleJournalChange(item);
            }
          }}
          pmolId={props?.pmolId}
          pbsId={null}
          sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.JOURNALWORK}
          renameSuccess={() => {
            if(props.renameSuccess) {
              props.renameSuccess();
            }
          }}
          imageDeleteSuccess={imageDeleteSuccess}
          uploadURL={UPLOAD_PMOL_JOURNAL_DOCUMENTS}
          productId={props.productId}
          isProduct={false}
        />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('whatdidwelearntoday')}</Label>
          <TextEditor
            value={props.journal && props.journal.lessonsLearned ? props.journal.lessonsLearned : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('lessonsLearned', value);
              } else {
                props.setJournal('lessonsLearned', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <PictureUploadComponent 
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '3')}
          // uploadedImages={type1}
          onSaveSuccess={(response: any, phase: string | null) => onImageSave('3', response, phase)}
          isDragEnabled={true}
          updateDocument={(item: any, isDelete: boolean) => {
            if(!isDelete){
              props.handleJournalChange(item);
            }
          }}
          pmolId={props?.pmolId}
          pbsId={null}
          sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.JOURNALWORK}
          renameSuccess={() => {
            if(props.renameSuccess) {
              props.renameSuccess();
            }
          }}
          imageDeleteSuccess={imageDeleteSuccess}
          uploadURL={UPLOAD_PMOL_JOURNAL_DOCUMENTS}
          productId={props.productId}
          isProduct={false}
        />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('doyouhaveotherthingstoreport')}</Label>
          <TextEditor
            value={props.journal && props.journal.reportedThings ? props.journal.reportedThings : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('reportedThings', value);
              } else {
                props.setJournal('reportedThings', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <PictureUploadComponent 
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '4')}
          onSaveSuccess={(response: any, phase: string | null) => onImageSave('4', response, phase)}
          isDragEnabled={true}
          updateDocument={(item: any, isDelete: boolean) => {
            if(!isDelete){
              props.handleJournalChange(item);
            }
          }}
          pmolId={props?.pmolId}
          pbsId={null}
          sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.JOURNALWORK}
          renameSuccess={() => {
            if(props.renameSuccess) {
              props.renameSuccess();
            }
          }}
          imageDeleteSuccess={imageDeleteSuccess}
          uploadURL={UPLOAD_PMOL_JOURNAL_DOCUMENTS}
          productId={props.productId}
          isProduct={false}
        />
      </div>

        {/*PMOL JOB NOT DONE - WHAT'S LEFT TO DO?*/}
      {/*<div className="marginTop">*/}

      {/*<div className={`${classNames.fullWidth}`}>*/}
      {/*  <Label>{t('whatsLeftToDo?')}</Label>*/}
      {/*  <TextEditor*/}
      {/*    value={props.journal && props.journal.whatsLeftToDo ? props.journal.whatsLeftToDo : ''}*/}
      {/*    onChange={(value) => {*/}
      {/*      if (value) {*/}
      {/*        props.setJournal('whatsLeftToDo', value);*/}
      {/*      } else {*/}
      {/*        props.setJournal('whatsLeftToDo', null);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*</div>*/}

      <div className={'card-footer'}>
        <Separator/>
        <Stack styles={stackStyles}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              disabled={props.readOnly}
              onClick={() => {
                props.PMOLSaveJournal();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default ProjectMoleculerJournal;
