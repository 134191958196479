import moment from 'moment';
import i18n from '../../i18n';
import * as XLSX from 'xlsx';
import { AddressBookItem } from '../types/addressBookItem';
import _ from 'lodash';
import UprinceLogger from './Logger/logger';
import { AccessLevel, UprincePermissions, UserRole } from '../types/permissions';
import { Cost, ProjectCost } from '../types/progressStatement';
import { DayOfWeek, defaultCalendarStrings, IDatePickerStrings } from '@fluentui/react';
import { ViewMode } from './ganttv2/types/public-types';
import ExcelJS from "exceljs";
export const CURRENCY_PREFIX = '€';

export const getCodeType = (type: number) => {
  switch (type) {
    case 0:
      return i18n.t('travel');
    case 1:
      return i18n.t('work');
    case 2:
      return i18n.t('unload');
    case 4:
      return i18n.t('start');
    case 5:
      return i18n.t('stop');
    case 6:
      return i18n.t('break');
    case -1:
      return i18n.t('all');
    default:
      return i18n.t('all');
  }
};

export const getQRName = (qrCode: any) => {
  // const t = i18n.t;
  let imageName = 'code';
  if (qrCode && qrCode.activityType) {
    if (
      qrCode.activityType.type === i18n.t('start') ||
      qrCode.activityType.type === i18n.t('unload') ||
      qrCode.activityType.type === i18n.t('stop')
    ) {
      imageName =
        qrCode.activityType.type +
        (qrCode.projectDefinition && qrCode.projectDefinition.title !== '-'
          ? '-' + qrCode.projectDefinition.title
          : '') +
        '_' +
        qrCode.location +
        '_' +
        format24hDateTime(qrCode.date);
    } else if (
      qrCode.activityType.type === i18n.t('work') ||
      qrCode.activityType.type === i18n.t('break')
    ) {
      imageName =
        qrCode.activityType.type +
        (qrCode.projectDefinition && qrCode.projectDefinition.title !== '-'
          ? '-' + qrCode.projectDefinition.title
          : '') +
        '_' +
        format24hDateTime(qrCode.date);
    } else if (qrCode.activityType.type === i18n.t('travel')) {
      imageName =
        qrCode.activityType.type +
        (qrCode.projectDefinition && qrCode.projectDefinition.title !== '-'
          ? '-' + qrCode.projectDefinition.title
          : '') +
        '_' +
        qrCode.vehicleNo +
        '_' +
        format24hDateTime(qrCode.date);
    } else {
      imageName = i18n.t('code');
    }
  }
  return imageName;
};

export const getFormattedProjects = (projects: any[]) => {
  let formattedProjects: { key: string; text: string }[] = [];
  if (projects) {
    formattedProjects = projects.map((item: { id: string; title: string }) => {
      return {
        key: item.id,
        text: item.title,
      };
    });
  }

  return formattedProjects;
};

export const format24hDateTime = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM HH:mm (ddd)');
  return formatted === 'Invalid date' ? '' : formatted;
};

export const formatwithoutTime = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM/YYYY');
  return formatted === 'Invalid date' ? '' : formatted;
};

export const formatDateTimeToLocal = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM/YYYY HH:mm');
  return formatted;
};

export const formatDateTimeToLocalExcel = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss');
  return formatted;
};

export const formatDateTimeToISOString = (dateString: Date | string) => {
  if (dateString) {
    const date = new Date(dateString);
    if (date) {
      const month =
        (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
      const day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
      let formatDate = null;
      if (month !== 'NaN') {
        formatDate =
          date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.000Z';
      }
      return formatDate;
    }
    return null;
  }
  return null;
};

export const formatDateAndTimeToISOString = (
  dateString: Date | string,
  time: String | string
) => {
  if (dateString) {
    const date = new Date(dateString);
    if (date) {
      const month =
        (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
      const day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
      let formatDate = null;
      if (month !== 'NaN') {
        formatDate =
          date.getFullYear() +
          '-' +
          month +
          '-' +
          day +
          'T' +
          time +
          ':00.000Z';
      }
      return formatDate;
    }
    return null;
  }
  return null;
};

export const formatDateTimeToISOStringWithTime = (
  dateString: Date | string
) => {
  if (dateString) {
    const date = new Date(dateString);
    if (date) {
      const month =
        (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
      const day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
      let formatDate = null;
      if (month !== 'NaN') {
        formatDate =
          date.getFullYear() +
          '-' +
          month +
          '-' +
          day +
          'T' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':00.000Z';
      }
      return formatDate;
    }
    return null;
  }
  return null;
};

export const formatEndDateTimeToISOString = (dateString: Date | string) => {
  if (dateString) {
    const date = new Date(dateString);
    if (date) {
      const month =
        (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
      const day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
      let formatDate = null;
      if (month !== 'NaN') {
        formatDate =
          date.getFullYear() + '-' + month + '-' + day + 'T23:59:59.000Z';
      }

      return formatDate;
    }
    return null;
  }
  return null;
};

export const getTimeZone = () => {
  let offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ('00' + (o % 60)).slice(-2)
  );
};

export const downloadExcel = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }

  return buf;
}
const downloadExcel1 = (buffer: ArrayBuffer, fileName: string) => {
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

type CertificationStatus = {
  title: string;
  weeks: string;
};

type DataItem = {
  id: string;
  cabPersonName: string;
  organisation: string;
  title: string;
  isCiawEligible: string;
  ciawStatus: string;
  platform: string | null;
  certificationStatuses: CertificationStatus[];
  date: string;
  SSNI:number;
  A1:number;
  LIMOSA:number;
  VCA:number
};
export const excelExport1 = async (
  data: DataItem[],
  fileName: string,
  sheetName: string
) => {
  const today = moment().format("YYYYMMDD");
  const FileName = `${today} - ${fileName}.xlsx`;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // Define the columns based on your structure
  const columns = [
    { header: "Date", key: "date", width: 20 },
    { header: "Employee", key: "cabPersonName", width: 20 },
    { header: "Organisation", key: "organisation", width: 20 },
    { header: "Title", key: "title", width: 20 },
    { header: "Eligibility", key: "isCiawEligible", width: 20 },
    { header: "Warning", key: "remark", width: 20 },
    { header: "CIAW Status", key: "ciawStatus", width: 20 },
    { header: "Platform", key: "platform", width: 20 },
    { header: "ID", key: "SSNI", width: 20 },
    { header: "A1", key: "A1", width: 20 },
    { header: "LIM", key: "LIMOSA", width: 20 },
    { header: "VCA", key: "VCA", width: 20 },
  ];

  // Add header row
  worksheet.columns = columns;

  // Add data rows
  data.forEach((item) => {
    const row = {
      ...item,
      title: item.certificationStatuses[0]?.title || "",
      weeks: item.certificationStatuses[0]?.weeks || "",
    };
    worksheet.addRow(row);
  });

  // Apply conditional formatting for isCiawEligible
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      const isCiawEligibleCell = row.getCell("isCiawEligible");
      const value = isCiawEligibleCell.value as string;

      switch (value) {
        case "1":
          isCiawEligibleCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF07AA6B" }, // Green
          };
          break;
        case "2":
          isCiawEligibleCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEB5757" }, // Red
          };
          break;
        case "3":
          isCiawEligibleCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFEB9557" }, // Orange
          };
          break;
        default:
          isCiawEligibleCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF000000" }, // Black (default case)
          };
          break;
      }
    }
  });
  // Apply conditional formatting for SSNI, A1, LIMOSA, and VCA
  const applyConditionalFormatting = (row: ExcelJS.Row, cellKey: string) => {
    const cell = row.getCell(cellKey);
    const value = cell.value as number;

    if (value === 1 || value === 2) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFEB9557" }, // Orange
      };
    } else if (value === 3 || value === 4 || value > 4) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF07AA6B" }, // Green
      };
    } else {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFEB5757" }, // Red
      };
    }
  };

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      applyConditionalFormatting(row, "SSNI");
      applyConditionalFormatting(row, "A1");
      applyConditionalFormatting(row, "LIMOSA");
      applyConditionalFormatting(row, "VCA");
    }
  });

  // Write the Excel file to a buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Trigger the download
  downloadExcel1(buffer, FileName);
};

export const excelExport = (
  data: any[],
  fileName: string,
  sheetName: string
) => {
  const today = moment().format('YYYYMMDD');
  const FileName = today + `- ${fileName}.xlsx`;
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  });
  const url = window.URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
  );
  downloadExcel(url, FileName);
};

export const excelFormatedExport = (
  data: any[],
  fileName: string,
  sheetName: string
) => {
  const today = moment().format('YYYYMMDD');
  const FileName = today + `- ${fileName}.xlsx`;
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  /* new format */
  var fmt = '#,##0.00';
  if (ws && ws['!ref']) {
    var range = XLSX.utils.decode_range(ws['!ref']);
    /* this loop starts on the second row, as it assumes the first row is a header */
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 25 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 26 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 28 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
  }
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  });
  const url = window.URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
  );
  downloadExcel(url, FileName);
};

export const excelFormatedExporttest = (
  data: any[],
  fileName: string,
  sheetName: string
) => {
  const today = moment().format('YYYYMMDD');
  const FileName = today + `- ${fileName}.xlsx`;
  //  data = [
  //     ["pradeep","gff",2,3,4,45,"set"],
  //     ["shenali","dfdfs",3,4,5,456,"tes1t"],
  //     ["shanuka",1,0,12789056.00,5000,7675654,"test"],
  // ];
  var ws = XLSX.utils.aoa_to_sheet(data);
  /* new format */
  var fmt = '#,##0.00';
  if (ws && ws['!ref']) {
    var range = XLSX.utils.decode_range(ws['!ref']);
    /* this loop starts on the second row, as it assumes the first row is a header */
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 25 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 26 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
    for (var R = range.s.r + 1; R <= range.e.r; ++R) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: 28 })];
      // console.log('range', cell);
      if (!cell) continue;
      cell.z = fmt;
    }
  }
  /* generate workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  /* generate file and download */
  const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
  const url = window.URL.createObjectURL(
    new Blob([wbout], { type: 'application/octet-stream' })
  );
  downloadExcel(url, FileName);
};

export const formatPersonResponse = (response: any) => {
  let data: { key: string; text: string }[] = [];
  if (response && response.data && _.isArray(response.data.result)) {
    data = response.data.result.map((item: AddressBookItem) => {
      return {
        ...item,
        text: item.person ? item.person.fullName : '',
        image: item.person ? item.person.image : '',
        secondaryText: item.personCompany ? item.personCompany.jobRole : '',
        key: item.id,
      };
    });
  }
  return data;
};

export const formatCpcResponse = (response: any) => {
  let data: { key: string; text: string }[] = [];
  if (response && response.data && _.isArray(response.data.result)) {
    data = response.data.result.map((item: any) => {
      return {
        name: item.text,
        key: item.key,
      };
    });
  }
  return data;
};

export const getPerentFromTreeData = (treeData: any, nodeId: any) => {
  const lookup: any = {};
  const mapIt = (tree: any) => {
    if (tree) {
      tree.forEach((item: any) => {
        lookup[item.id] = item;
      });
    }
  };
  mapIt(treeData);

  const findAncestors = (nodeId: any) => {
    const ancestors: any = [];
    let parentId = lookup[nodeId] && lookup[nodeId].parentId;
    while (parentId !== undefined) {
      ancestors.unshift(parentId);
      parentId = lookup[parentId] && lookup[parentId].parentId;
    }
    return ancestors;
  };
  const getNodeDetails = () => {
    const selectedNodeParent = findAncestors(nodeId);
    let data: any[] = [];
    if (treeData) {
      let arr = treeData,
        brr = selectedNodeParent,
        res = arr.filter((item: any) => {
          return brr.includes(item.id);
        });
      data = res.map((item: any) => {
        return item.id;
      });
    }
    return data;
  };

  return getNodeDetails();
};

export const getBreadcrumbItemFromData = (treeData: any, nodeId: any) => {
  // We will flatten it down to an object that just holds the id with the object
  const lookup: any = {};
  const mapIt = (tree: any) => {
    if (tree) {
      tree.forEach((item: any) => {
        lookup[item.id] = item;
      });
    }
  };
  mapIt(treeData);
  //   console.log("tData lookup",lookup);
  // This takes a node and loops over the lookup hash to get all of the ancestors
  const findAncestors = (nodeId: any) => {
    const ancestors = [];
    let parentId = lookup[nodeId] && lookup[nodeId].parentId;
    while (parentId !== undefined) {
      ancestors.unshift(parentId);
      parentId = lookup[parentId] && lookup[parentId].parentId;
    }
    return ancestors;
  };
  const retundata: any = [];
  const data: any = { key: '', text: '' };

  const getNodeDetails = () => {
    const selectedNodeParent = findAncestors(nodeId);
    if (treeData && selectedNodeParent) {
      selectedNodeParent.push(nodeId);

      selectedNodeParent.forEach((id) => {
        if (id) {
          const res = treeData.filter((item: any) => {
            return item.id === id;
          });
          if (res[0]) {
            retundata.push({ key: res[0].id, text: res[0].title });
          }
        }
      });
    }

    // console.log("selectedNodeParent selec",selectedNodeParent);
    // console.log("selectedNodeParent retundata",retundata);
    return retundata;
  };

  return getNodeDetails();
};

export const getFileIconName = (type: string | null) => {
  let iconName = 'OpenFile';

  switch (type) {
    case 'image/png':
      iconName = 'FileImage';
      break;
    case 'image/jpeg':
      iconName = 'FileImage';
      break;
    case 'image/webp':
      iconName = 'FileImage';
      break;
    case 'application/pdf':
      iconName = 'PDF';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      iconName = 'ExcelDocument';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      iconName = 'PowerPointDocument';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      iconName = 'WordDocument';
      break;
    case 'audio/mpeg':
      iconName = 'Volume3';
      break;
    case 'video/mp4':
      iconName = 'BoxPlaySolid';
      break;
    case 'text/plain':
      iconName = 'TextDocument';
      break;
    default:
      if (type && type.includes('image')) {
        iconName = 'FileImage';
      } else if (type && type.includes('application')) {
        iconName = 'OfficeLogo';
      } else if (type && type.includes('audio')) {
        iconName = 'Volume3';
      } else if (type && type.includes('video')) {
        iconName = 'BoxPlaySolid';
      } else if (type && type.includes('text')) {
        iconName = 'TextDocument';
      } else {
        iconName = 'OpenFile';
      }
      break;
  }
  return iconName;
};

export const getContractingUnit = () => {
  let cuHeader = null;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    // config.headers['cu'] = window.location.pathname.split('/')[1];
    const paths = window.location.pathname.split('/');
    if (paths && _.isArray(paths) && paths.length >= 2) {
      if (paths[1] === 'CU') {
        cuHeader = paths[2];
      }
    }
  }
  return cuHeader;
};

export const isPlanning = () => {
  const paths = window.location.pathname.split('/');
  if (Array.isArray(paths) && paths.length > 0) {
    const last = paths[paths.length - 1];
    if (last === 'project-planning') {
      return true;
    }
  } else {
    return false;
  }
};

export const isCuProjectPlanning = () => {
  const paths = window.location.pathname.split('/');
  if (Array.isArray(paths) && paths.length > 0) {
    const last = paths[paths.length - 1];
    if (last === 'cu-project-planning') {
      return true;
    }
  } else {
    return false;
  }
};

export const isPlanBoards = () => {
  const paths = window.location.pathname.split('/');
  if (Array.isArray(paths) && paths.length > 0) {
    const last = paths[paths.length - 1];
    if (last === 'project-day-planning' || last === 'day-planning-new') {
      return true;
    }
  } else {
    return false;
  }
};

export const isTeamPlanboardMyEnv = () => {
  const paths = window.location.pathname.split('/');
  if (Array.isArray(paths) && paths.length > 0) {
    const first = paths[1];
    const last = paths[paths.length - 1];
    if (first === 'me' && last === 'day-planning-new') {
      return true;
    }
  } else {
    return false;
  }
};

export const isProjectPlanboardMyEnv = () => {
  const paths = window.location.pathname.split('/');
  if (Array.isArray(paths) && paths.length > 0) {
    const first = paths[1];
    const last = paths[paths.length - 1];
    if (first === 'me' && last === 'project-day-planning') {
      return true;
    }
  } else {
    return false;
  }
};

export const isPPOpenFromPlanboard = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get('from-plan-board');
};

export const isNew = () => {
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    if (paths && _.isArray(paths)) {
      if (paths[paths.length - 1] === 'new') {
        return true;
      }
    }
  }
  return false;
};

export const getProject = () => {
  let projectHeader = null;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    // config.headers['cu'] = window.location.pathname.split('/')[1];
    const paths = window.location.pathname.split('/');
    const index = paths.indexOf('project');
    if (index > -1 && paths && _.isArray(paths) && paths.length >= index + 1) {
      // if (paths[index+1] === 'project') {
      projectHeader = paths[index + 1];
      // }
    }
  }
  return projectHeader;
};

export const isMyEnvironment = () => {
  let isMyEnv = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    if (paths[1] === 'me') {
      isMyEnv = true;
    }
  }

  return isMyEnv;
};

export const isOrganisationSetting =  () => {
  let isorgSetting = false;

  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ){
    const paths = window.location.pathname.split('/');
    if(paths[1] === 'organisation-settings'){
      isorgSetting = true;
    }
  }

  return isorgSetting;

}

export const isCustomHeaderAPI = (url:any) => {
  let urlDatas = url!==""?url.split('/'):false;
  if(urlDatas && urlDatas[0]=='PbsResource' && urlDatas[1]=='ReadLabourByPbsProduct' && urlDatas.length==3){
    return true;
  } else if(urlDatas && urlDatas[0]=='Pmol' && urlDatas[1]=='ClonePmolMultipleDays' && urlDatas.length==2){
    return true;
  } else if(urlDatas && urlDatas[0]=='PbsFilter' && urlDatas[1]=='GetPbsForPmol' && urlDatas.length==2){
    return true;
  } else {
    return false;
  }
}

export const isCBC = () => {
  if (!getContractingUnit()) {
    return true;
  } else if (getContractingUnit() && !getProject()) {
    return true;
  } else {
    return false;
  }
};

export const formatDate = (dateString: Date | string | null, type: number) => {
  if (type === 1) {
    // for set date
    if (dateString) {
      const date = new Date(dateString);
      return date;
    }
    return null;
  } // get date

  if (dateString) {
    const date = new Date(dateString);
    if (date) {
      const month =
        (date.getMonth() + 1 <= 9 ? '0' : '') + (date.getMonth() + 1);
      const day = (date.getDate() <= 9 ? '0' : '') + date.getDate();
      let formatDate = null;
      if (month !== 'NaN') {
        formatDate =
          date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.000Z';
      }
      UprinceLogger.log('formatDate', formatDate);
      return formatDate;
    }
    return null;
  }
  return null;
};

export const getUserProjectscodes = (
  projects: {
    projectDefinitionSequenceCode: string;
    cabCompanySequenceCode: string;
  }[]
) => {
  return _.map(projects, 'sequenceCode');
};

export const getRsourceDropDowns = () => {
  return [
    { key: '-1', text: i18n.t('all') },
    { key: 'c46c3a26-39a5-42cc-n7k1-89655304eh6', text: i18n.t('Materials') },
    { key: 'c46c3a26-39a5-42cc-n9wn-89655304eh6', text: i18n.t('tools') },
    { key: 'c46c3a26-39a5-42cc-m06g-89655304eh6', text: i18n.t('consumables') },
    {
      key: 'c46c3a26-39a5-42cc-b07s-89655304eh6',
      text: i18n.t('humanResources'),
    },
  ];
};

export const hasPermissions = (
  groups: any,
  organisation?: string,
  contractingUnit?: string
) => {
  const permission = new UprincePermissions();
  const isArrayAndNotEmpty = _.isArray(groups) && !_.isEmpty(groups);

  if (isArrayAndNotEmpty) {
    groups.filter((item: any) => {
      if (item.displayName === UserRole.PROJECT_MANAGER) {
        permission.isProjectManager = true;
      }

      if (item.displayName === UserRole.APPLICATION_MANAGER) {
        permission.isApplicationManager = true;
      }

      if (item.displayName === UserRole.PLANNING_MANAGER) {
        permission.isPlanningManager = true;
      }

      if (item.displayName === UserRole.HR_MANAGER) {
        permission.isHRManager = true;
      }

      if (item.displayName === UserRole.BACK_OFFICER) {
        permission.isBackOfficer = true;
      }

      if (item.displayName === UserRole.CU_TENDER_MANAGER) {
        permission.isCUTenderManager = true;
      }

      if (item.displayName === UserRole.PROJECT_TENDER_MANAGER) {
        permission.isProjectTenderManager = true;
      }

      if (item.displayName === UserRole.PROJECT_TEAM) {
        permission.isProjectTeamManager = true;
      }

      if (organisation && item.displayName === organisation) {
        permission.hasOrganisationPermission = true;
      }

      if (contractingUnit && item.displayName === contractingUnit) {
        permission.hasContractingUnitPermission = true;
      }
    });
  }
  return permission;
};

export const getAccessingLevel = () => {
  let accessLevel = -1;

  if (isMyEnvironment()) {
    accessLevel = AccessLevel.MY_ENV;
  }

  if (!getContractingUnit() && !isMyEnvironment()) {
    // organisation level
    accessLevel = AccessLevel.ORGANIZATION;
  }

  if (!getContractingUnit() && isApplicationManagersOnlyRoute()) {
    // organisation level
    accessLevel = AccessLevel.ORGANIZATION_FEATURE;
  }

  if (getContractingUnit() && !getProject()) {
    // Contracting Unit level
    accessLevel = AccessLevel.CONTRACTING_UNIT;
  }

  if (getProject()) {
    accessLevel = AccessLevel.PROJECT;
  }

  if (getProject() && (isProjectManagersOnlyRoute() || isProjectTenderManagerOnlyRoute() || isProjectTeamOnlyRoute())) {
    accessLevel = AccessLevel.PROJECT_FEATURE;
  }

  if (
    getContractingUnit() &&
    !getProject() &&
    (isProjectManagersOnlyRoute() ||
      isHRManagersOnlyRoute() ||
      isPlanningManagersOnlyRoute() ||
      isBackOfficersOnlyRoute() ||
      isCUTenderManagerOnlyRoute())
  ) {
    // Contracting Unit level
    accessLevel = AccessLevel.CONTRACTING_UNIT_FEATURE;
  }
  console.log('ACCESS LEVEL', accessLevel);
  return accessLevel;
};

export const organisationWhiteListRoutes = ['/', '/contracting-unit'];
export const projectManagersOnlyRoutes = [
  'progress-statement',
  'invoice',
  'purchase-order',
];
export const applicationManagersOnlyRoutes = [
  'translations-dashboard',
  'dropdown-translations-dashboard',
];
export const planningManagerOnlyRoutes = [
  'cu-project-planning',
  'cu-my-calendar',
];
export const hrManagerOnlyRoutes = ['time_clock'];
export const backOfficersOnlyRoutes = [
  'visual-plan',
  'purchase-order',
  'progress-statement',
];
export const cuTenderManagerOnlyRoutes = ['standard-mail'];
export const projectTenderManagerOnlyRoutes = ['contract'];
export const projectTeamOnlyRoutes = ['quality','risk-register','instruction-register','wbs','day-planning-new','project-day-planning'];

export const isProjectManagersOnlyRoute = () => {
  let isProjectManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    // config.headers['cu'] = window.location.pathname.split('/')[1];
    const paths = window.location.pathname.split('/');
    // const index = paths.indexOf('project');
    isProjectManagerOnly = paths.some(
      (r) => projectManagersOnlyRoutes.indexOf(r) >= 0
    );
  }
  UprinceLogger.log('Manager Only', isProjectManagerOnly);
  return isProjectManagerOnly;
};

export const isApplicationManagersOnlyRoute = () => {
  let isAppManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    // config.headers['cu'] = window.location.pathname.split('/')[1];
    const paths = window.location.pathname.split('/');
    // const index = paths.indexOf('project');
    isAppManagerOnly = paths.some(
      (r) => applicationManagersOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Manager Only', isAppManagerOnly);
  return isAppManagerOnly;
};

export const isPlanningManagersOnlyRoute = () => {
  let isPlanningManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isPlanningManagerOnly = paths.some(
      (r) => planningManagerOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Planning Manager Only', isPlanningManagerOnly);
  return isPlanningManagerOnly;
};

export const isBackOfficersOnlyRoute = () => {
  let isBackOfficerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isBackOfficerOnly = paths.some(
      (r) => backOfficersOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Planning Manager Only', isPlanningManagerOnly);
  return isBackOfficerOnly;
};

export const isCUTenderManagerOnlyRoute = () => {
  let isTenderManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isTenderManagerOnly = paths.some(
      (r) => cuTenderManagerOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Planning Manager Only', isPlanningManagerOnly);
  return isTenderManagerOnly;
};

export const isProjectTenderManagerOnlyRoute = () => {
  let isProjectTenderManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isProjectTenderManagerOnly = paths.some(
      (r) => projectTenderManagerOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Planning Manager Only', isPlanningManagerOnly);
  return isProjectTenderManagerOnly;
};

export const isProjectTeamOnlyRoute = () => {
  let isProjectTeamrOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isProjectTeamrOnly = paths.some(
      (r) => projectTeamOnlyRoutes.indexOf(r) >= 0
    );
  }
  // UprinceLogger.log('Planning Manager Only', isPlanningManagerOnly);
  return isProjectTeamrOnly;
};

export const isHRManagersOnlyRoute = () => {
  let isHRManagerOnly = false;
  if (
    window.location.pathname &&
    window.location.pathname.split('/').length > 0
  ) {
    const paths = window.location.pathname.split('/');
    isHRManagerOnly = paths.some((r) => hrManagerOnlyRoutes.indexOf(r) >= 0);
  }
  // UprinceLogger.log('HR Manager Only', isHRManagerOnly);
  return isHRManagerOnly;
};

export const getImages = (images: string[]) => {
  if (images) {
    const formattedImages = images.map((item: string) => {
      return {
        image: item,
        id: item,
        isLast: true,
      };
    });
    return formattedImages;
  }
  return [];
};

export const toFixedTrunc = (x: any, n: any) => {
  if (isNaN(x)) {
    return '0.0';
  }
  if (x) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) {
      return v[0];
    }
    let f = v[1] || '';
    if (f.length > n) {
      return `${v[0]}.${f.substr(0, n)}`;
    }
    while (f.length < n) {
      f += '0';
    }
    return `${v[0]}.${f}`;
  }
  return '0';
};

export const numberToLocaleString = (number: any) => {
  const toNumber = parseFloat(number);
  if (toNumber) {
    return toNumber.toLocaleString(window.navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return number;
};

export enum RESOURCE_TYPE_IDS {
  MATERIALS = 'c46c3a26-39a5-42cc-n7k1-89655304eh6',
  TOOLS = 'c46c3a26-39a5-42cc-n9wn-89655304eh6',
  CONSUMABLES = 'c46c3a26-39a5-42cc-m06g-89655304eh6',
  LABOURS = 'c46c3a26-39a5-42cc-b07s-89655304eh6',
}

export const breadcrumbStyles = {
  root: {
    fontSize: 14,
    marginTop: -6,
  },
  item: { fontSize: 14 },
  list: { fontSize: 14 },
  listItem: { fontSize: 14 },
  chevron: { fontSize: 14 },
  overflow: { fontSize: 14 },
  overflowButton: { fontSize: 14 },
  itemLink: { fontSize: 14 },
};

export const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });
  return formatter.format(price);
};
export const formatPriceWithoutSymbol = (price: number) => {
  const formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });
  return formatter
    .formatToParts(price)
    .map((p) => (p.type != 'literal' && p.type != 'currency' ? p.value : ''))
    .join('');
};

export const getSelectedCostList = (selectedCostList: any[]) => {
  const transformData: Cost[] = selectedCostList.map((cost: ProjectCost) => {
    return {
      id: null,
      psId: null,
      cpcId: null,
      cpcResourceNumber: cost.resourceNumber,
      cpcTitle: cost.resourceTitle,
      cpcResourceType: cost.resourceType,
      consumedQuantity: cost.consumedQuantity,
      costToMou: cost.costMou ? cost.costMou : 0,
      totalCost: cost.totalCost,
      soldQuantity: cost.consumedQuantity,
      spToMou: cost.spMou ? cost.spMou : 0,
      consumedQuantityMou: cost.mou,
      mouId: cost.mouId,
      status: null,
      cpcResourceTypeId: cost.resourceTypeId,
      projectCostId: cost.id,
      resourceCategory: cost.resourceType,
      productTitle: cost.productTitle,
    };
  });

  return transformData;
};

// export const formattedPayloadToDropdownList = (response: any) => {
//     if (response) {
//         const keys = Object.keys(response);
//         const data = keys.map((item: any) => {
//             return {
//                 key: item,
//                 text: item

//             };
//         });
//         return data;
//     }
//     return [];
// };

export const formattedPayloadToDropdownList = (
  response: any,
  feature: string
) => {
  if (response) {
    const keys = Object.keys(response);
    const data = keys.map((item: any) => {
      let key = null;
      if (item === 'types' && feature === 'stock') {
        key = 'stockTypes';
      } else if (item === 'status' && feature === 'stock') {
        key = 'stockStatus';
      } else if (item === 'types' && feature === 'wf') {
        key = 'wfTypes';
      } else if (item === 'resourceType' && feature === 'wf') {
        key = 'wfResourceType';
      } else if (item === 'types' && feature === 'warehouse') {
        key = 'whTypes';
      } else if (item === 'status' && feature === 'warehouse') {
        key = 'whStatus';
      } else {
        key = item;
      }
      return {
        key: key,
        text: item,
      };
    });
    return data;
  }
  return [];
};

export const getSelectedFilterDates = (filter: any): any => {
  const date = new Date();
  const firstDayPrevMonth = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1
  );
  const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  let dates: any[] = [firstDayPrevMonth, lastDayPrevMonth];
  switch (filter[0]) {
    case '1':
      // LAST WEEK
      let startLast = moment().clone().startOf('week').subtract(7, 'days');
      let endLast = moment().clone().startOf('week').subtract(1, 'days');
      dates = [
        moment(startLast, 'DD-MM-YYYY').toDate(),
        moment(endLast, 'DD-MM-YYYY').toDate(),
      ];
      break;
    case '2':
      // CURRENT WEEK
      let startCur = moment().clone().startOf('week');
      let endCur = moment().clone().endOf('week');
      dates = [
        moment(startCur, 'DD-MM-YYYY').toDate(),
        moment(endCur, 'DD-MM-YYYY').toDate(),
      ];
      break;
    case '3':
      dates = [firstDayPrevMonth, lastDayPrevMonth];
      break;
    case '4':
      // CURRENT MONTH
      const firstDayCurentMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      );
      const lastDayCurentvMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      dates = [firstDayCurentMonth, lastDayCurentvMonth];
      break;
    case '6':
      // NEXT WEEK
      let startLast2 = moment().clone().endOf('week').add(1, 'days');
      let endLast2 = moment().clone().endOf('week').add(7, 'days');
      dates = [
        moment(startLast2, 'DD-MM-YYYY').toDate(),
        moment(endLast2, 'DD-MM-YYYY').toDate(),
      ];
      break;
    case '7':
      // THIS WEEK AND LAST WEEK
      let startDayLastWeek = moment()
        .clone()
        .startOf('week')
        .subtract(7, 'days');
      let endDayCurrentWeek = moment().clone().endOf('week');
      dates = [
        moment(startDayLastWeek, 'DD-MM-YYYY').toDate(),
        moment(endDayCurrentWeek, 'DD-MM-YYYY').toDate(),
      ];
      break;
    case '9':
      // THIS WEEK AND NEXT WEEK
      let startDayThisWeek = moment().clone().startOf('week');
      let endDayNextWeek = moment().clone().endOf('week').add(7, 'days');
      dates = [
        moment(startDayThisWeek, 'DD-MM-YYYY').toDate(),
        moment(endDayNextWeek, 'DD-MM-YYYY').toDate(),
      ];
      break;
    case '5':
      dates = [null, null];
      break;
    case '10':
      const nextMonth = date.getMonth() + 1; // `getMonth()` gives 0 for January, 1 for February, etc.
      const nextYear = date.getFullYear();

      const firstDayOfNextMonth = new Date(nextYear, nextMonth, 1);

      const lastDayOfNextMonth = new Date(nextYear, nextMonth + 1, 0);
      dates = [firstDayOfNextMonth, lastDayOfNextMonth]
      break;
    default:
      dates = [firstDayPrevMonth, lastDayPrevMonth];
      break;
  }
  return dates;
};

export const stringDateToDateObject = (date: any) => {
  if (typeof date === 'string') {
    return new Date(date);
  } else {
    return date;
  }
};

export const projectUniqueColor = (project: string) => {
  let num: number = parseInt(project.replace(/[^0-9]/g, ''));
  return (
    '#' +
    Math.floor((0.6750889005738867 + num / 100) * 16777215)
      .toString(16)
      .substring(0, 6)
  );
};

export const getTextColorAccordingToBg = (color: any) => {
  let r = null;
  let g = null;
  let b = null;
  let hsp = null;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return '#000000';
  } else {
    return '#FFFFFF';
  }
};

export const getInitials = (string: string) => {
  if (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  } else {
    return '';
  }
};

export const stringToColour = (str: any) => {
  var hash = 0;
  if (str) {
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  } else {
    return 'none';
  }
};

export const MSG_AUTO_CLOSE_TIME = 5000;

export const BAR_COLOR_PINK_SHADE1 = '#cb8dc0';
export const BAR_COLOR_PINK_SHADE2 = '#d199c7';
export const BAR_COLOR_PINK_SHADE3 = '#d6a6ce';
export const BAR_COLOR_PINK_SHADE4 = '#dcb3d5';
export const BAR_COLOR_PINK_SHADE5 = '#e2c0dc';
export const BAR_COLOR_PINK_SHADE6 = '#e8cce3';
export const BAR_COLOR_PINK_SHADE7 = '#eed9ea';

export const BAR_COLOR_GREEN_SHADE1 = '#47b36f';
export const BAR_COLOR_GREEN_SHADE2 = '#5cbc7f';
export const BAR_COLOR_GREEN_SHADE3 = '#70c48f';
export const BAR_COLOR_GREEN_SHADE4 = '#85cd9f';
export const BAR_COLOR_GREEN_SHADE5 = '#95d5af';
export const BAR_COLOR_GREEN_SHADE6 = '#adddbf';
export const BAR_COLOR_GREEN_SHADE7 = '#c2e6cf';

export const BAR_COLOR_ORANGE_SHADE1 = '#f9a151';
export const BAR_COLOR_ORANGE_SHADE2 = '#f9ac65';
export const BAR_COLOR_ORANGE_SHADE3 = '#fab678';
export const BAR_COLOR_ORANGE_SHADE4 = '#fbc18b';
export const BAR_COLOR_ORANGE_SHADE5 = '#fccb9f';
export const BAR_COLOR_ORANGE_SHADE6 = '#fcd5b2';
export const BAR_COLOR_ORANGE_SHADE7 = '#fde0c5';

export const BAR_COLOR_RED_SHADE1 = '#fe6060';
export const BAR_COLOR_RED_SHADE2 = '#fe7171';
export const BAR_COLOR_RED_SHADE3 = '#fe8383';
export const BAR_COLOR_RED_SHADE4 = '#fe9595';
export const BAR_COLOR_RED_SHADE5 = '#ffa7a7';
export const BAR_COLOR_RED_SHADE6 = '#ffb8b8';
export const BAR_COLOR_RED_SHADE7 = '#ffcaca';

export const BAR_COLOR_PURPLE_SHADE1 = '#73579b';
export const BAR_COLOR_PURPLE_SHADE2 = '#8269a6';
export const BAR_COLOR_PURPLE_SHADE3 = '#927cb1';
export const BAR_COLOR_PURPLE_SHADE4 = '#a18fbc';
export const BAR_COLOR_PURPLE_SHADE5 = '#b1a2c8';
export const BAR_COLOR_PURPLE_SHADE6 = '#c1b4d3';
export const BAR_COLOR_PURPLE_SHADE7 = '#d0c7de';

export const BAR_COLOR_CEYAN_SHADE1 = '#19cad8';
export const BAR_COLOR_CEYAN_SHADE2 = '#33d0dd';
export const BAR_COLOR_CEYAN_SHADE3 = '#4dd6e1';
export const BAR_COLOR_CEYAN_SHADE4 = '#66dce5';
export const BAR_COLOR_CEYAN_SHADE5 = '#80e2ea';
export const BAR_COLOR_CEYAN_SHADE6 = '#99e7ee';
export const BAR_COLOR_CEYAN_SHADE7 = '#b3edf2';

export const BAR_COLOR_BLUE_SHADE1 = '#4875ae';
export const BAR_COLOR_BLUE_SHADE2 = '#5d85b7';
export const BAR_COLOR_BLUE_SHADE3 = '#7194c0';
export const BAR_COLOR_BLUE_SHADE4 = '#85a3c9';
export const BAR_COLOR_BLUE_SHADE5 = '#9ab3d2';
export const BAR_COLOR_BLUE_SHADE6 = '#aec2db';
export const BAR_COLOR_BLUE_SHADE7 = '#c2d1e4';

export const BAR_COLOR_BROWN_SHADE1 = '#b34e4d';
export const BAR_COLOR_BROWN_SHADE2 = '#bb6161';
export const BAR_COLOR_BROWN_SHADE3 = '#c47574';
export const BAR_COLOR_BROWN_SHADE4 = '#cc8988';
export const BAR_COLOR_BROWN_SHADE5 = '#d59d9c';
export const BAR_COLOR_BROWN_SHADE6 = '#ddb0b0';
export const BAR_COLOR_BROWN_SHADE7 = '#e6c4c4';

export const BAR_COLOR_BLACK_SHADE1 = '#b34e4d';
export const BAR_COLOR_BLACK_SHADE2 = '#bb6161';
export const BAR_COLOR_BLACK_SHADE3 = '#c47574';
export const BAR_COLOR_BLACK_SHADE4 = '#cc8988';
export const BAR_COLOR_BLACK_SHADE5 = '#d59d9c';
export const BAR_COLOR_BLACK_SHADE6 = '#ddb0b0';
export const BAR_COLOR_BLACK_SHADE7 = '#e6c4c4';

export enum PRIMARY_COLOR {
  PINK = 'pink',
  ORANGE = 'orange',
  GREEN = 'green',
  RED = 'red',
  PURPLE = 'purple',
  CEYAN = 'deepsky',
  BLUE = 'blue',
  BROWN = 'brown',
  BLACK = 'black',
}

export const getPinkShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_PINK_SHADE1;
    case 2:
      return BAR_COLOR_PINK_SHADE2;
    case 3:
      return BAR_COLOR_PINK_SHADE5;
    case 4:
      return BAR_COLOR_PINK_SHADE4;
    case 4:
      return BAR_COLOR_PINK_SHADE5;
    default: {
      return BAR_COLOR_PINK_SHADE4;
    }
  }
};

export const getOrangeShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_ORANGE_SHADE1;
    case 2:
      return BAR_COLOR_ORANGE_SHADE2;
    case 3:
      return BAR_COLOR_ORANGE_SHADE3;
    case 4:
      return BAR_COLOR_ORANGE_SHADE4;
    case 4:
      return BAR_COLOR_ORANGE_SHADE5;
    default: {
      return BAR_COLOR_ORANGE_SHADE4;
    }
  }
};

export const getGreenShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_GREEN_SHADE1;
    case 2:
      return BAR_COLOR_GREEN_SHADE2;
    case 3:
      return BAR_COLOR_GREEN_SHADE3;
    case 4:
      return BAR_COLOR_GREEN_SHADE4;
    case 4:
      return BAR_COLOR_GREEN_SHADE5;
    default: {
      return BAR_COLOR_GREEN_SHADE4;
    }
  }
};

export const getRedShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_RED_SHADE1;
    case 2:
      return BAR_COLOR_RED_SHADE2;
    case 3:
      return BAR_COLOR_RED_SHADE3;
    case 4:
      return BAR_COLOR_RED_SHADE4;
    case 4:
      return BAR_COLOR_RED_SHADE5;
    default: {
      return BAR_COLOR_RED_SHADE4;
    }
  }
};

export const getPurpleShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_PURPLE_SHADE1;
    case 2:
      return BAR_COLOR_PURPLE_SHADE2;
    case 3:
      return BAR_COLOR_PURPLE_SHADE3;
    case 4:
      return BAR_COLOR_PURPLE_SHADE4;
    case 4:
      return BAR_COLOR_PURPLE_SHADE5;
    default: {
      return BAR_COLOR_PURPLE_SHADE4;
    }
  }
};

export const getCeyanShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_CEYAN_SHADE1;
    case 2:
      return BAR_COLOR_CEYAN_SHADE2;
    case 3:
      return BAR_COLOR_CEYAN_SHADE3;
    case 4:
      return BAR_COLOR_CEYAN_SHADE4;
    case 4:
      return BAR_COLOR_CEYAN_SHADE5;
    default: {
      return BAR_COLOR_CEYAN_SHADE4;
    }
  }
};

export const getBlueShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_BLUE_SHADE1;
    case 2:
      return BAR_COLOR_BLUE_SHADE2;
    case 3:
      return BAR_COLOR_BLUE_SHADE3;
    case 4:
      return BAR_COLOR_BLUE_SHADE4;
    case 4:
      return BAR_COLOR_BLUE_SHADE5;
    default: {
      return BAR_COLOR_BLUE_SHADE4;
    }
  }
};

export const getBrownShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_BROWN_SHADE1;
    case 2:
      return BAR_COLOR_BROWN_SHADE2;
    case 3:
      return BAR_COLOR_BROWN_SHADE3;
    case 4:
      return BAR_COLOR_BROWN_SHADE4;
    case 4:
      return BAR_COLOR_BROWN_SHADE5;
    default: {
      return BAR_COLOR_BROWN_SHADE4;
    }
  }
};

export const getBlackShades = (index: number) => {
  switch (index) {
    case 1:
      return BAR_COLOR_BLACK_SHADE1;
    case 2:
      return BAR_COLOR_BLACK_SHADE2;
    case 3:
      return BAR_COLOR_BLACK_SHADE3;
    case 4:
      return BAR_COLOR_BLACK_SHADE4;
    case 4:
      return BAR_COLOR_BLACK_SHADE5;
    default: {
      return BAR_COLOR_BLACK_SHADE4;
    }
  }
};

export const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  return tomorrow;
};

export const getFirstDayOfWeekByLanguage = () => {
  let selectedlang = localStorage.getItem('lang');
  const language = selectedlang ? selectedlang : window.navigator.language;
  if (language) {
    switch (language) {
      case 'en-US':
        return DayOfWeek.Sunday;
      case 'en':
        return DayOfWeek.Sunday;
      case 'nl':
        return DayOfWeek.Monday;
      case 'nl-BE':
        return DayOfWeek.Monday;
      case 'de':
        return DayOfWeek.Monday;
      default:
        return DayOfWeek.Sunday;
    }
  } else {
    return DayOfWeek.Sunday;
  }
};

export const removeCurrencyAndThousandSeparatorsOld = (numberString: any) => {
  // Identify the number format (European or American)
  const match = numberString.match(
    /^(\D*)(\d{1,3}([., ]\d{3})*([.,]\d+)?|\d+[.,]\d+)(\D*)$/
  );
  if (!match) {
    return null; // not a valid number format
  }
  const decimalSeparator = match[4] ? match[4].charAt(0) : null;
  const thousandSeparator = match[3] ? match[3].charAt(0) : null;
  const isEuropeanFormat =
    decimalSeparator === ',' && thousandSeparator === '.';

  // Remove the currency symbol and thousand separators
  const numberWithoutSeparators = match[2]
    .replace(thousandSeparator, '')
    .replace(decimalSeparator, '.');

  // Format the number as a string and return it
  const formattedNumber = parseFloat(numberWithoutSeparators).toFixed(2);
  const finalNumber = isEuropeanFormat ? formattedNumber : formattedNumber;
  return parseFloat(finalNumber);
};

export const convertToDecimal = (event: any) => {
  // console.log(event.target.value)
  removeCurrencyAndThousandSeparators(event.target.value);
};

export const removeCurrencyAndThousandSeparators = (numberString: any): any => {
  // console.log({numberString})
  const selectedValue = numberString;
  const convertEuropeanFormat = (value: string): string => {
    return parseFloat(
      String(
        Number(
          value
            .replace(/\./g, '')
            .replace(/,/, '.')
            .replace(/[^\d\.]/g, '')
        )
      )
    ).toFixed(2);
  };

  let formattedNumber: any = '';
  let isEuropeanFormat = '';

  if (selectedValue.match(/,\d{2}$/)) {
    formattedNumber = convertEuropeanFormat(selectedValue);
    isEuropeanFormat = 'This value is in European format with decimal.';
  } else if (selectedValue.includes('€')) {
    if (selectedValue.match(/\.\d{3}$/)) {
      formattedNumber = convertEuropeanFormat(selectedValue);
    } else {
      const regex = /[€,]/g;
      const convetVal = parseFloat(selectedValue.replace(regex, '')).toFixed(2);
      formattedNumber = convetVal === 'NaN' ? null : convetVal.toString();
    }

    isEuropeanFormat = 'This value is in European format.';
  } else if (selectedValue.match(/\.\d{3}$/)) {
    formattedNumber = convertEuropeanFormat(selectedValue);
    isEuropeanFormat = 'This value is in European format without decimal.';
  } else {
    const regex = /[$,]/g;
    const convetVal = parseFloat(selectedValue.replace(regex, '')).toFixed(2);
    formattedNumber = convetVal === 'NaN' ? null : convetVal.toString();
    isEuropeanFormat = '';
  }
  // console.log({isEuropeanFormat})
  // console.log({formattedNumber})
  // const formattedNumberp = document.getElementById("formattedNumber");
  // formattedNumberp ?formattedNumberp.textContent = formattedNumber:false;
  return formattedNumber;
};

export const getCalenderString = () => {
  let selectedlang = localStorage.getItem('lang');
  const language = selectedlang ? selectedlang : window.navigator.language;

  const engString: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
  };

  const nlString: IDatePickerStrings = {
    months: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ],
    shortMonths: [
      'jan',
      'feb',
      'mrt',
      'apr',
      'mei',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec',
    ],
    days: [
      'zondag',
      'maandag',
      'dinsdag',
      'woensdag',
      'donderdag',
      'vrijdag',
      'zaterdag',
    ],
    shortDays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
    goToToday: 'Ga naar vandaag',
    prevMonthAriaLabel: 'Ga naar vorige maand',
    nextMonthAriaLabel: 'Ga naar volgende maand',
    prevYearAriaLabel: 'Ga naar vorig jaar',
    nextYearAriaLabel: 'Ga naar volgend jaar',
  };

  const trString: IDatePickerStrings = {
    months: [
      'ocak',
      'şubat',
      'mart',
      'nisan',
      'mayıs',
      'haziran',
      'temmuz',
      'ağustos',
      'eylül',
      'ekim',
      'kasım',
      'aralık',
    ],
    shortMonths: [
      'oca',
      'şub',
      'mar',
      'nis',
      'may',
      'haz',
      'tem',
      'ağu',
      'eyl',
      'eki',
      'kas',
      'ara',
    ],
    days: [
      'pazar',
      'pazartesi',
      'salı',
      'çarşamba',
      'perşembe',
      'cuma',
      'cumartesi',
    ],
    shortDays: ['P', 'Pz', 'S', 'Ç', 'P', 'C', 'Ct'],
    goToToday: 'Bugüne git',
    prevMonthAriaLabel: 'Önceki aya git',
    nextMonthAriaLabel: 'Sonraki aya git',
    prevYearAriaLabel: 'Önceki yıla git',
    nextYearAriaLabel: 'Sonraki yıla git',
};

  
  const frString:IDatePickerStrings = {
    months: [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
      'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ],
    shortMonths: [
      'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'
    ],
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    goToToday: 'Aujourd\'hui',
    prevMonthAriaLabel: 'Mois précédent',
    nextMonthAriaLabel: 'Mois suivant',
    prevYearAriaLabel: 'Année précédente',
    nextYearAriaLabel: 'Année suivante',
    closeButtonAriaLabel: 'Fermer le sélecteur de date',
  }
  
  switch (language) {
    case 'en-US':
      return engString;
    case 'en':
      return engString;
    case 'nl':
      return nlString;
    case 'nl-BE':
      return nlString;
    case 'de':
      return nlString;
    case 'fr':
      return frString;  
      case 'tr':
      return trString;
    default:
      return defaultCalendarStrings;
  }
};

export const getCalenderLocalizer = () => {
  require('moment/locale/nl.js');
  require('moment/locale/en-gb.js');
  require('moment/locale/fr.js');
  require('moment/locale/tr.js');
  let selectedlang = localStorage.getItem('lang');
  const language = selectedlang ? selectedlang : window.navigator.language;
  switch (language) {
    case 'en-US':
      return moment.locale('en-gb');
    case 'en':
      return moment.locale('en-gb');
    case 'nl':
      return moment.locale('nl');
    case 'nl-BE':
      return moment.locale('nl');
    case 'de':
      return moment.locale('nl');
    case 'fr':
      return moment.locale('fr');  
    case 'tr':
      return moment.locale('tr')
    default:
      return moment.locale('en-gb');
  }
};

export const handleCalendarEndTime = (date: any, endTime: any) => {
  if (endTime.getDate() != new Date(date).getDate()) {
    const endTimeDateObj = moment(date).endOf('day').toDate();
    return ['23:59', endTimeDateObj];
  }
  return [moment(endTime).format('HH:mm'), endTime];
};

export const tooltipAccessor = (event: any) => {
  return '';
};

export const updateVPStandardFilter = (standardFilterOptions: any) => {
  let customFilterObject = { key: '8', text: i18n.t('custom') };
  let newFilterObject = { key: '7', text: i18n.t('thisWeekAndLastWeek') };
  let newFilterObjectWithNextWeek = {
    key: '9',
    text: i18n.t('thisWeekAndNextWeek'),
  };
  let nextMonth = {
    key: '10',
    text: i18n.t('nextMonth')
  }
  return [
    customFilterObject,
    ...standardFilterOptions,
    nextMonth,
    newFilterObject,
    newFilterObjectWithNextWeek,
  ];
};

export const updateVPProductStatusFilter = (
  productStatusFilterOptions: any
) => {
  let newFilterObjectAll = { key: '1', text: i18n.t('all') };
  let newFilterObjectActive = { key: 'activeKey', text: i18n.t('active') };
  return [
    newFilterObjectAll,
    newFilterObjectActive,
    ...productStatusFilterOptions,
  ];
};

export const maskFormat: { [key: string]: RegExp } = {
  H: /[0-9]/,
  m: /[0-5]/,
  M: /[0-9]/,
};

// To change the color of the PMOL Event Wrappers in the calendar, change the colors in kanban.css file.
// This object Changes the color of the Icons in the PMOL Event Body.
export const pmolColors = {
  work: '#92E950',
  travel: '#F35325',
  load: '#04A6F0',
};


export const formatReadableDate = (inputDate:any) => {
  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const date = moment(inputDate);
  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else if (date.isSame(yesterday, "day")) {
    return "Yesterday";
  } else if (inputDate) {
    return date.format('DD-MM-YYYY');
  } else {
    return i18n.t('N/A');
  }
};

export const calculateEndDate = (selectedMode: ViewMode, date: moment.MomentInput) => {
  let newDate;

  switch (selectedMode) {
    case ViewMode.QuarterDay:
    case ViewMode.HalfDay:
    case ViewMode.Day:
      newDate = moment(date).endOf('day').toDate();
      break;
    case ViewMode.Week:
      newDate = moment(date).endOf('week').toDate();
      break;
    case ViewMode.Month:
      newDate = moment(date).endOf('month').toDate();
      break;
    case ViewMode.QuaterYear:
      const quarter = Math.floor((moment(date).month() + 3) / 3);
      newDate = moment(date).quarter(quarter).endOf('quarter').toDate();
      break;
    default:
      newDate = moment(date).endOf('day').toDate();
      break;
  }
  return newDate;
}

export const calculateStartDate = (selectedMode: ViewMode, date: moment.MomentInput) => {
  let newDate;

  switch (selectedMode) {
    case ViewMode.QuarterDay:
    case ViewMode.HalfDay:
    case ViewMode.Day:
      newDate = moment(date).startOf('day').toDate();
      break;
    case ViewMode.Week:
      newDate = moment(date).startOf('week').toDate();
      break;
    case ViewMode.Month:
      newDate = moment(date).startOf('month').toDate();
      break;
    case ViewMode.QuaterYear:
      const quarter = Math.floor((moment(date).month() + 3) / 3);
      newDate = moment(date).quarter(quarter).startOf('quarter').toDate();
      break;
    default:
      newDate = moment(date).startOf('day').toDate();
      break;
  }
  return newDate;
}

export const getFileType = (type: string) => {
  let typeId;
  // console.log("FILE TYPE: ", type);

  switch (type) {
    case 'image/jpeg':
      // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
      return typeId = 'FileImage';  //Image

    case 'image/png':
      // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
      return typeId = 'FileImage';  //Image

    case 'image/webp':
      // return typeId = 'oo10e768-msms-po02-Lot2-ee367a82adooo';  //Image
      return typeId = 'FileImage';  //Image

    case 'application/pdf':
      // return typeId = 'oo10e768-msms-po02-Lot1-ee367a82adooo';  //PDF
      return typeId = 'PDF';  //PDF

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
      return typeId = 'WordDocument';  //Word Document

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
      return typeId = 'ExcelDocument';  //Word Document

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      // return typeId = '2210e768-msms-po02-Lot4-ee367a82ad22';  //Word Document
      return typeId = 'PowerPointDocument';  //Word Document

    default:
      return typeId = 'Website';   //URL
  }
}

export const deepCloneArray = (array: any) => {
  return JSON.parse(JSON.stringify(array));
};

export const getPropParameterName = (functionName : any) => {
  return functionName.toString().match(/\((.*?)\)/)[1];
}

export const addAllItem = (options: any) => {
  const allItem = [{ key: null, id: null, text: i18n.t('all') }];
  return [...allItem, ...options];
};

export const PMOL_PBS_HEADER_SECTION_LIST = {
  EXTRAWORK :'EXTRAWORK',
  JOURNALWORK :'JOURNALWORK',
  HANDSHAKE :'HANDSHAKE',
  PRODUCT : "product",
};

export class deleteImagePayload {
  imageId: string[] = [];
  type: string | null = null;
  pmolId: string | null = null;
  pbsId: string | null = null;
};
