import React, { useState } from 'react';
import {
  ActionButton,
  IBasePickerSuggestionsProps,
  IIconProps,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ISuggestionItemProps,
  ISuggestionsProps,
  Label,
  mergeStyles,
  mergeStyleSets,
  NormalPeoplePicker,
  Persona,
  PrimaryButton,
  Stack,
  ValidationState,
} from '@fluentui/react';
import { Field, Form } from 'react-final-form';
import { uPrinceTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { NumberField, TextFieldFF } from '../../../shared/fluentFinalForm';
import NumberFieldWrapper from '../../../shared/fluentFinalForm/NumberField';
import { AssetItemFormTypes } from './assetRegisters';
import { addIconWhite } from '../../wbsCreate/documentPane/WbsCreateDocument';
// import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';
import _ from 'lodash';
import { AssetRegister } from '../../../types/projectBreakdownStructure';
import SearchableDropdown from '../../../shared/searchableDropdown/searchableDropdown';

interface AssetRegisterFormProps {
  data: AssetRegister[];
  handleSubmit: (val: any) => void;
  assetRegisterOptions: any;
  assetRegisterItemOptions: any;
  initialValue: AssetItemFormTypes;
  selectedAssertRegister: any;
  selectedAssertItemRegister: any;
  onChangeAssertRegisterField: (val: any) => void;
  onChangeAssertItemField: (val: any) => void;
  // createNewAssetItem: () => void
  createNewAssetItemFromPeoplePicker: (selected: any) => void;
}

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};
const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  footer: {
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const AssetRegisterForm = ({
  data,
  handleSubmit,
  assetRegisterOptions,
  assetRegisterItemOptions,
  initialValue,
  selectedAssertRegister,
  selectedAssertItemRegister,
  onChangeAssertRegisterField,
  onChangeAssertItemField,
  // createNewAssetItem,
  createNewAssetItemFromPeoplePicker,
}: AssetRegisterFormProps) => {
  const { t } = useTranslation();

  // For People Picker
  const onFilterPersonChanged = (
    filter: string,
    selectedItems?: IPersonaProps[] | undefined
  ) => {
    if (filter) {
      return getList(filter);
    } else {
      return [];
    }
  };

  const getList = async (fil: string) => {
    const filtered = assetRegisterItemOptions.filter((item: any) =>
      item.text.toLowerCase().includes(fil.toLowerCase())
    );
    return formatPersonResponse(filtered, fil);
  };

  const formatPersonResponse = (response: any, name: any) => {
    let data: { key: string; text: string; isNewItem: boolean }[] = [];
    if (response) {
      data = response.map((item: any) => {
        return {
          // ...item,
          text: item.text,
          key: item.key,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.text.toLowerCase() === name.toLowerCase()
      );

      // if (!obj) {
      data.unshift({ key: '', text: name, isNewItem: true });
      // }
    } else {
      return [{ key: '', text: name, isNewItem: true }];
    }

    return data;
  };

  const addIcon: IIconProps = { iconName: 'Add' };
  const newItem = mergeStyles({ padding: '1px' });
  const existingItem = mergeStyles({ padding: '10px' });
  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return (
        <div
          className={props.isNewItem ? newItem : existingItem}
          key={props.key}
        >
          <ActionButton iconProps={addIcon} onClick={() => console.log('test')}>
            {props.text}
          </ActionButton>
        </div>
      );
    }
    return (
      <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <Persona {...props} size={1} hidePersonaDetails={false} />
      </div>
    );
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsFooter: (props: any) => (
      <ActionButton
        className={classNames.actionButton}
        disabled={false}
        iconProps={addIcon}
        allowDisabledFocus
        onClick={(event) => {
          // messageService.sendMessage({ isOpenNameModal: true });
        }}
      >
        <Label className={classNames.actionButton}>{t('new') as any}</Label>
      </ActionButton>
    ),
    showRemoveButtons: false,
    searchingText: 'Searching...',
  };

  const getSelectedAssetRegisterLabel = (value: any) => {
    const filt = assetRegisterOptions?.find((e: any) => e.value === value);
    return filt?.label;
  };

  const searchAxh = (inputValue: any, option: any[]) => {
    const lowerCasedInput = inputValue.toLowerCase();
    const options = option?.filter((ele: any) =>
      ele?.label?.toLowerCase()?.includes(lowerCasedInput)
    );

    return options;
  };

  const assetRegisterOption = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(searchAxh(inputValue, assetRegisterOptions));
    });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValue}
      keepDirtyOnReinitialize={true}
      render={({ handleSubmit, form, submitting, pristine, values, dirty }) => {
        return (
          <div style={{ padding: '10px 20px' }}>
            {/* assetRegister  */}
            <>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label>{t('assetRegister')}</Label>
                    <SearchableDropdown
                      promiseOptions={assetRegisterOption}
                      selectedOption={
                        selectedAssertRegister
                          ? [
                              {
                                label: getSelectedAssetRegisterLabel(
                                  selectedAssertRegister
                                ),
                                value: selectedAssertRegister,
                              },
                            ]
                          : null
                      }
                      onChange={(item: any) =>
                        onChangeAssertRegisterField(item?.value)
                      }
                      validationMessage={''}
                      disabled={false}
                      required={true}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              {/* assetRegisterItem  */}
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}`}>
                    <Label>{t('assetRegisterItem')}</Label>
                    <NormalPeoplePicker
                      selectedItems={
                        selectedAssertItemRegister
                          ? [
                              {
                                key: selectedAssertItemRegister,
                                text: values?.name,
                              },
                            ]
                          : []
                      }
                      disabled={selectedAssertRegister ? false : true}
                      onResolveSuggestions={onFilterPersonChanged}
                      pickerSuggestionsProps={limitedSearchAdditionalProps}
                      onChange={(items) => {
                        // console.log('items', items)
                        if (items?.length === 0) {
                          onChangeAssertItemField(null);
                        }
                        return items ? items : [];
                      }}
                      onItemSelected={(selected: any) => {
                        if (selected) {
                          if (selected?.isNewItem) {
                            createNewAssetItemFromPeoplePicker &&
                              createNewAssetItemFromPeoplePicker(selected);
                          } else {
                            onChangeAssertItemField(selected.key);
                          }
                        }
                        return null;
                      }}
                      onRenderSuggestionsItem={onRenderSuggestionsItem}
                    />
                  </div>
                </Stack.Item>
              </Stack>
              {/* <div style={{padding: '10px', display: 'flex', alignItems: 'center'}}>
                <Icon iconName='Add' style={{fontWeight: 'bold'}}/>
                <button disabled={selectedAssertRegister ? false : true} onClick={() => createNewAssetItem()} style={{marginLeft: '8px', fontWeight: 'bold'}}>Create New Asset Register Item</button>
              </div> */}
            </>

            {/* Type  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="type"
                    component={TextFieldFF}
                    lable={t('type')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                    // required
                  />
                </div>
              </Stack.Item>
            </Stack>

            {/* Kind  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="kind"
                    component={TextFieldFF}
                    lable={t('kind')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                    // required
                  />
                </div>
              </Stack.Item>
            </Stack>

            {/* Measuring  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="measuring"
                    component={NumberField}
                    lable={t('measuring')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>

            {/* Yard  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="yard"
                    component={NumberField}
                    lable={t('yard')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>

            {/* Progress statement  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="progressStatement"
                    component={NumberField}
                    lable={t('progressStatementAx')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>

            {/* Invoice  */}
            <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
              <Stack.Item grow={6} styles={stackItemStyles}>
                <div className={`${classNames.fullWidth}`}>
                  <Field
                    name="invoice"
                    component={NumberFieldWrapper}
                    lable={t('invoice')}
                    disabled={
                      selectedAssertRegister && selectedAssertItemRegister
                        ? false
                        : true
                    }
                  />
                </div>
              </Stack.Item>
            </Stack>

            <div className={classNames.footer}>
              <PrimaryButton
                iconProps={addIconWhite}
                text={t('save')}
                type="submit"
                style={{ marginTop: 20, marginBottom: 30, marginRight: 20 }}
                onClick={handleSubmit}
                disabled={
                  selectedAssertRegister && selectedAssertItemRegister
                    ? false
                    : true
                }
              />

              <PrimaryButton
                iconProps={addIconWhite}
                text={t('saveAndNew')}
                style={{ marginTop: 20, marginBottom: 30 }}
                onClick={() => {}}
                disabled={
                  selectedAssertRegister && selectedAssertItemRegister
                    ? false
                    : true
                }
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default AssetRegisterForm;
