import React, { Component } from 'react';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import ProjectVisualPlanMainLayout from './projectVisualPlanMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class ProjectVisualPlan extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/project-planning`}
      >
        <ProjectVisualPlanMainLayout/>
      </UprinceNavigation>
    );
  }
}

export default ProjectVisualPlan;
