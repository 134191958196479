import {DirectionalHint, Dropdown, Label, Link, Stack, Text, TooltipHost,} from '@fluentui/react';
import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {iconColors, uPrinceTheme} from '../../../../theme';
import {VPShortCutPaneItem} from '../../../types/visualPlan';
import {readVPPBSTaxonomyData, saveVPStateAttr} from '../../../reducers/visualPlanReducer';
import _ from 'lodash';
import moment from 'moment';
import {isPPOpenFromPlanboard} from '../../../shared/util';

const classNames = mergeStyleSets({
    wrapper: {
        height: 'auto !important',
        position: 'relative',
        minHeight: '400px !important',
    },
    selected: {
        fontWeight: 'bold',
        color: '#854114!important',
    },
});

export const ShortCutPaneComponent = (props: {
    toggleOverlay: () => void;
    toggleOverlayFwd: () => void;
    divClass: string;
    shortCutPaneFilters: any;
    currentActiveSection: string | null;
    handelShortCutPaneFilter: (selectedItem: VPShortCutPaneItem) => void;
    resetFilter: () => void;
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentActiveSection, setCurrentActiveSection] = useState<number>(isPPOpenFromPlanboard() ? 6 : 5);
    const {buList} = useSelector((state: any) => state.projectDPlan);
    const {selectedBuInCuProjectPlanning} = useSelector((state: any) => state.vp);

    const setFilterOption = (selectedItem: VPShortCutPaneItem) => {
        if (selectedItem.value === 10) {
            setCurrentActiveSection(11);
        } else {
            setCurrentActiveSection(selectedItem.value);
        }

        dispatch(saveVPStateAttr('vpProjectSelectedShortcutpaneType', [selectedItem.type]));
        dispatch(saveVPStateAttr('vpProjectSelectedShortcutpaneId', [selectedItem.id]));
        dispatch(saveVPStateAttr('vpProjectSelectedShortcutpaneItems', [selectedItem.value]));
        dispatch(saveVPStateAttr('showvpProjectDocumentPane', false));
        dispatch(saveVPStateAttr('selectedVpProjectBarType', null));
        dispatch(saveVPStateAttr('selectedProjectItemType', selectedItem.value));
        dispatch(saveVPStateAttr('selectedStartDateProjectItem', moment().startOf('month').format('YYYY-MM-DD hh:mm')));
        dispatch(saveVPStateAttr('selectedEndDateProjectItem', moment().endOf('month').format('YYYY-MM-DD hh:mm')));
        props.handelShortCutPaneFilter(selectedItem);
    };

    const getItemIcon = (item: any) => {
        let icon = 'GotoToday';
        switch (item.value) {
            case 1:
                icon = 'Org';
                break;
            case 2:
                icon = 'Calculator';
                break;
            case 3:
                icon = 'EngineeringGroup';
                break;
            case 4:
                icon = 'ActivateOrders';
                break;
            case 5:
                icon = 'RowsChild';
                break;
            case 6:
                icon = 'CalendarWeek';
                break;
            case 7:
                icon = 'RowsChild';
                break;
            case 8:
                icon = 'RowsChild';
                break;

        }

        return icon;
    };

    const renderFilterItems = () => {
        if (props.shortCutPaneFilters && !_.isEmpty(props.shortCutPaneFilters)) {
            return props.shortCutPaneFilters?.map((item: any, index: number) => {
                let divider = false;
                let isSub = false;
                if (item?.id === '13') {
                    return
                }
                if (
                    index === 2
                ) {
                    divider = true;
                }

                if (
                    index === 0 ||
                    index === 1 ||
                    index === 2 ||
                    index === 3 ||
                    index === 5 ||
                    index === 6 ||
                    index === 7 ||
                    index === 8 ||
                    index === 9 ||
                    index === 12 ||
                    index === 13
                ) {
                    isSub = true;
                }
                return (
                    <div key={item.value + index + 'div'}>
                        {divider && (
                            <hr
                                key={item.value + 'hr'}
                                style={{marginBottom: 15, borderTop: '1px solid #d2d2d2'}}
                            />
                        )}
                        <Link
                            key={item.key + index + 'link'}
                            className={`${currentActiveSection === item.value
                                ? classNames.selected
                                : ''
                            }`}
                            href="#"
                            onClick={() => {
                                if (index !== 4 && index !== 11) {
                                    setFilterOption(item);
                                }
                            }}
                            style={{marginLeft: isSub ? 12 : 0}}
                        >
                            <TooltipHost
                                key={item.key + 't'}
                                content={item.name}
                                id="filter-opt-1"
                                calloutProps={{gapSpace: 0}}
                                directionalHint={DirectionalHint.rightCenter}
                            >
                                <i
                                    key={item.id + index + 'i'}
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                    style={
                                        currentActiveSection === item.value
                                            ? {color: iconColors.iconActiveColor}
                                            : {color: uPrinceTheme.palette.themePrimary}
                                    }
                                ></i>
                            </TooltipHost>
                            <i
                                key={item.id + index + 'i2'}
                                className={`ms-Icon ms-Icon--${getItemIcon(item)}`}
                                aria-hidden="true"
                                style={
                                    currentActiveSection === item.value
                                        ? {color: iconColors.iconActiveColor}
                                        : {color: uPrinceTheme.palette.themePrimary}
                                }
                            ></i>
                            <Text style={
                                currentActiveSection === item.value
                                    ? {color: iconColors.iconActiveColor, fontSize: 13}
                                    : {color: uPrinceTheme.palette.black, fontSize: 13}

                            } key={item.value + index}>{t(item.name)} </Text>
                        </Link>
                    </div>
                );
            });
        }
        return <div></div>;
    };

    return (
        <div className={`${props.divClass} ${classNames.wrapper}`}>
            <div className="inner-container" style={{position: 'sticky', top: 0}}>
                <i
                    onClick={props.toggleOverlay}
                    className="ms-Icon ms-Icon--Back back-arrow"
                    aria-hidden="true"
                ></i>
                <i
                    onClick={props.toggleOverlayFwd}
                    className="ms-Icon ms-Icon--Forward fwd-arrow"
                    aria-hidden="true"
                ></i>

                <div className="filter-data filter-title">
                    <TooltipHost
                        content={t('tax')}
                        // This is the important part!
                        id="filter-opt-title"
                        calloutProps={{gapSpace: 0}}
                        directionalHint={DirectionalHint.rightCenter}
                    >
                        <Label
                            aria-describedby={'filter-opt-title'}
                            className={'short-cut-pane-label'}
                        >
                            {t('products')}
                        </Label>
                    </TooltipHost>
                </div>

                <div className="filter-option-list">
                    <Stack
                        // gap={15}
                        verticalFill
                        styles={{
                            root: {
                                width: '100%',
                                verticalAlign: 'center',
                                gap: 15,
                            },
                        }}
                    >

                        <Dropdown
                            placeholder={t('selectBu')}
                            onChange={(ev, item) => {
                                if (item) {
                                    dispatch(saveVPStateAttr('selectedBuInCuProjectPlanning', item?.key));
                                    dispatch(readVPPBSTaxonomyData({
                                        "fromDate": null,
                                        "toDate": null,
                                        "productStatus": "7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea",
                                        "BusinessUnit": [item?.key]
                                    }))
                                } else {
                                    dispatch(saveVPStateAttr('selectedBuInCuProjectPlanning', null));
                                }
                            }}
                            selectedKey={selectedBuInCuProjectPlanning}
                            options={buList ?? []}
                        />

                        <Link
                            key={'delivery'}
                            className={`${currentActiveSection.toString() === '0'
                                ? classNames.selected
                                : ''
                            }`}
                            href="#"
                            onClick={() => {
                                // setFilterOption(allFilterItem);
                            }}
                        >
                            <TooltipHost
                                key={'delivery1'}
                                content={t('delivery')}
                                id="filter-opt-1"
                                calloutProps={{gapSpace: 0}}
                                directionalHint={DirectionalHint.rightCenter}
                            >
                                <i
                                    key={'All-linki'}
                                    className="ms-Icon ms-Icon--ViewAll"
                                    aria-labelledby="filter-opt-1"
                                    aria-hidden="true"
                                    style={
                                        props.currentActiveSection === null
                                            ? {color: uPrinceTheme.palette.themePrimary}
                                            : {color: uPrinceTheme.palette.themePrimary}
                                    }
                                ></i>
                            </TooltipHost>
                            <i
                                key={'delivery2'}
                                className="filter-icon ms-Icon ms-Icon--ViewAll filter-icon"
                                aria-hidden="true"
                                style={
                                    props.currentActiveSection === null
                                        ? {color: uPrinceTheme.palette.themePrimary}
                                        : {color: uPrinceTheme.palette.themePrimary}
                                }
                            ></i>

                            <Text style={
                                currentActiveSection.toString() === '0'
                                    ? {color: uPrinceTheme.palette.black, fontSize: 13}
                                    : {color: uPrinceTheme.palette.black, fontSize: 13}
                            } key={'all'}>{t('planning')} </Text>
                        </Link>
                        {renderFilterItems()}
                    </Stack>
                </div>
            </div>
        </div>
    );
};

// const mapStateToProps = (state: any) => ({});

// const mapDispatchToProps = {};

export default ShortCutPaneComponent;
// connect(
    // mapStateToProps,
    // mapDispatchToProps,
// )(ShortCutPaneComponent);
