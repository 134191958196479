import {useBoolean, useId} from '@uifabric/react-hooks';
import _ from 'lodash';
import {
    Checkbox,
    ContextualMenu,
    DirectionalHint,
    Dropdown,
    FontIcon,
    FontWeights,
    getTheme,
    IconButton,
    IDragOptions,
    IIconProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    TextField,
    TooltipHost,
    Text,
    NormalPeoplePicker,
    IPersonaProps,
    IPersona,
    IBasePickerSuggestionsProps
} from '@fluentui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    getFlatDataFromTree,
    getTreeFromFlatData,
    // SortableTreeWithoutDndContext as SortableTree,  // This seems to be not rendering the tree
    toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
// import SortableTree from '@nosferatu500/react-sortable-tree'; // This was added to resolve the issue with the tree not rendering
import {v4 as uuidv4} from 'uuid';
import i18n from '../../../i18n';
import {uPrinceTheme} from '../../../theme';
import {ProjectBreakdown} from '../../types/projectBreakdownStructure';
import CustomTagPicker from '../tagPicker/customTagPicker';
import {formatDateTimeToISOString, getContractingUnit, getPerentFromTreeData, getProject, getSelectedFilterDates, isCuProjectPlanning, isMyEnvironment, isPlanning, updateVPStandardFilter} from '../util';
// import './react-sortable-tree.css';
// import '../../shared/sortableTreeListView/react-sortable-tree-list-view.css';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { Popover } from '@varld/popover';
import StatusPopOver from '../ganttv2/components/task-list/statusPopOver';
import ProjectScopePopOver from '../ganttv2/components/task-list/projectScopePopOver';
import { PBS_STATE } from '../../components/projectVisualPlan/productTaxonomyListPane/component';
import moment from 'moment';
import headSeperator from "../../shared/ganttv2/components/task-list/task-list-header.module.css";
import { useDispatch, useSelector } from 'react-redux';
import AzureAddressAutoComplete from '../../shared/azureAddress/container';
import client from '../../api';
import { useDebouncedCallback } from 'use-debounce';
import ProgressBar from '../progressBar/progressBar';
import { GET_FOREMAN_BY_NAME_EP } from '../endpoints';

const theme = getTheme();
// Styles definition
const stackStyles: IStackStyles = { root: { padding: 0 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};

const editWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
    width: 17,
    minWidth: 17,
    height: 15,
    paddingRight: 1,
    paddingLeft: 1,
    paddingTop: 1,
    paddingBottom: 1,
  },
  rootHovered: { color: theme.palette.neutralDark },
};
const editIconWhite: IIconProps = {
  iconName: 'Edit',
  styles: editWhiteIconButtonStyles,
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: { color: uPrinceTheme.palette.themePrimary },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    // overflowY: 'hidden',
    paddingTop: 20,
    // minWidth: screen.width > 1280?"40vw":"50vw",
    // minHeight: screen.width > 1280?"60vh":"75vh",
    // height: screen.width > 1280?"80vh":"75vh",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'none',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
  columnTitleContainer: {
    width: '98.5%', 
    display: 'flex', 
    marginRight: '12px', 
    paddingBottom: 16
  },
  nameCol: {
    width: '60%', 
    fontWeight: 'bold', 
    fontSize: 16,
    paddingLeft: 55
  },
  otherCol: {
    width: '10%', 
    borderLeft: '1px solid #bebebe', 
    fontWeight: 'bold', 
    fontSize: 16, 
    paddingLeft: 8
  },
  tableContainer: {
    width: '100%'
  },
  nameRow: {
    width: '60%',
    paddingRight: 60,
    alignItems: 'center'
  },
  pbsTitle: {
    marginTop:'1%', 
    fontSize: '14px',
    fontWeight:'600', 
    color: 'black'
  },
  width10: {
    width: '10%'
  },
  durationRow: {
    width: '10%', 
    display: 'flex', 
    alignItems: 'center'
  },
  durationIcon: {
    marginTop: '6px',
    marginLeft: 'auto',
    marginRight: '5px',
    fontSize: '4px',
    width: 15,
    height: 15,
    color: '#B2BABB',
    paddingBottom: 15,
  },
  noData: {
    marginLeft: 20, 
    marginTop:5
  },
  sortableTreeHeight: {
    height: '100%'
  },
  returnContainer: {
    height: '64vh',
    marginBottom: 10,
  },
  filterContainer: {
    marginBottom: '2%', 
    width: '100%'
  },
  radius: {
    display: 'flex',
    height: 50,
    width: '5%'
  },
  labHours: {
    display: 'flex',
    height: 50,
    width: '6%'
  },
  submitButton: {
    marginTop: 10, 
    marginBottom: 10, 
    marginRight: 10
  }
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const CustomSortableTreeProduct = (props: {
  treeData: any;
  onSelectItem: (id: string) => void;
  onAddNode: (selectedLevel: any) => void;
  onCreateNode: (newNode: any) => void;
  selectItemId: string | null;
  label?: string | null;
  treeLevel: any;
  onFilterTaxonomyNodeChanged: any;
  projectBreakdown?: ProjectBreakdown;
  productStatusList?:any;
  cu?: string | null;
  project?: string | null;
  forCertifications?: boolean | undefined
}) => {
  const dispatch = useDispatch();
  const standerdFilterOptions = useSelector((state: any) => updateVPStandardFilter(state.vp.vpFilterDropdown));
  const [treeData, setTreeData]: any = useState([]);
  const [, setFlatTreeData]: any = useState([]);
  const [searchString, setSearchString]: any = useState('');
  const [searchFocusIndex, setSearchFocusIndex]: any = useState(0);
  const [searchFoundCount, setSearchFoundCount]: any = useState(null);
  const [selectItemId, setSelectItemId]: any = useState(null);
  const [selectedParentIds, setSelectedParentIds]: any = useState([]);
  const [selectedPath, setSelectedPath]: any = useState(null);
  const [selectedNode, setSelectedNode]: any = useState(null);
  const [newNodeName, setNewNodeName]: any = useState('');
  const [newNodeKey, setNewNodeKey]: any = useState('');
  const [childLevelName, setChildLevelName]: any = useState('');
  const [nodeLevel, setNodeLevel]: any = useState(0);
  const [isEdit, setIsEdit]: any = useState(false);
  const [editNodeName, setEditNodeName]: any = useState('');
  const [productTitle, setProductTitle] = useState("");
  const [productStatus, setproductStatus] = useState<string>("7a5d2a4a-0cbe-4575-8f1e-a2ba605e75ea");
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({lat: null, lon: null});
  const [radius, setRadius] = useState("");
  const [minLabourHours, setMinLabourHours] = useState("");
  const [maxLabourHours, setMaxLabourHours] = useState("");
  const [standardFilter, setStandardFilter] = useState([])
  const [qualityReviewer, setQualityReviewer] = React.useState<any[]>([]);

  const [listData, setListData]: any = useState([]);
  const [treeDataList, setTreeDataList]: any = useState([]);
  const [dataLoaded, setDataLoaded]: any = useState<boolean>(false);
  const [noDataFound, setNoDataFound]: any = useState<boolean>(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    // setStandardFilter()
  }, [])
  
  useEffect(() => {
    const productIds = new Set(listData.map((node: any) => node.productId).filter((id: any) => id !== null));
    const tData = getTreeFromFlatData({
      flatData: listData,
      getKey: (node: any) => node.productId,
      getParentKey: (node: any) => node.parentId&&productIds.has(node.parentId)?node.parentId:null,
      // @ts-ignore
      rootKey: null,
    });

    setTreeDataList(tData);
    setSelectedParentIds(
      getPerentFromTreeData(listData, props.selectItemId),
    );
  }, [listData]);

  useEffect(() => {
    setSelectItemId(props.selectItemId);
    setSelectedParentIds(
      getPerentFromTreeData(listData, props.selectItemId),
    );    
  }, [props.selectItemId]);

  useEffect(() => {
    const fData = getFlatDataFromTree({
      treeData: treeDataList,
      getNodeKey: (node: any) => node.productId,
      ignoreCollapsed: false,
    });
    setFlatTreeData(fData);
  }, [treeDataList]);

  useEffect(() => {
    if (!isInitialMount.current) {
      searchProduct();
    }
  }, [productStatus, address, coordinates, radius, minLabourHours, maxLabourHours, qualityReviewer, standardFilter]);

  useEffect(()=>{
    if(!isInitialMount.current){
      debounced();
    }
  },[productTitle])

  useEffect(()=>{
    setDataLoaded(true);
    searchProduct();
    isInitialMount.current = false;
  },[])

  const expand = (expanded: any) => {
    setTreeDataList(
      toggleExpandedForAll({
        // treeData: treeData,
        treeData: treeDataList,
        expanded,
      }),
    );
  };

  const expandAll = () => {
    expand(true);
  };

  const collapseAll = () => {
    setSearchString(null);
    expand(false);
  };

  // const handleNodeClick = () => {
    // alert(JSON.stringify(rowInfo));
    // const { node,path } = rowInfo;
    // setSelectedPath(path);
    // setSelectedNode(node);
    // setChildLevelName(node.childLevel);
    // if (!node.children) {
    //   props.onSelectItem(node.id);
    // }
  // };

  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }: any) => {
    return (
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  };

  // const handleAddClick = (rowInfo: any) => {
  //   const { node, path } = rowInfo;
  //   const depth = path.length;
  //   const level = depth + 1;
  //   const childLevel = props.treeLevel[level];
  //   let childLevelName = '';
  //   if (childLevel) {
  //     childLevelName = props.treeLevel[level].name;
  //   }
  //   setIsOpen(true);
  //   setSelectedPath(path);
  //   setSelectedNode(node);
  //   setNodeLevel(childLevel);
  //   setChildLevelName(childLevelName);
  //   props.onAddNode(childLevel);
  // };

  const handleSave = () => {
    if (validate()) {
      addNewNode();
      resetValues();
      setIsOpen(false);
    }
  };

  const resetValues = () => {
    resetValidation();
    setIsEdit(false);
    setNewNodeName('');
    setNewNodeKey('');
    setSelectedNode('');
    setSelectedPath('');
  };

  const addNewNode = () => {
    if (selectedPath) {
      const newNode = {
        title: newNodeName,
        key: newNodeKey,
        id: uuidv4(),
        pbsTaxonomyLevelId: nodeLevel.id,
        parentId: selectedNode.id,
        children: [],
      };
      setTreeData(
        addNodeUnderParent({
          treeData: treeData,
          parentKey: selectedPath[selectedPath.length - 1],
          expandParent: true,
          newNode: newNode,
          getNodeKey: (node: any) => node.productId,
        }).treeData,
      );
      props.onCreateNode(newNode);
    }
  };

  // const handleEditClick = (rowInfo: any) => {
  //   const { node, path } = rowInfo;
  //   setIsOpen(true);
  //   setSelectedPath(path);
  //   setSelectedNode(node);
  //   setEditNodeName(node.title);
  //   setIsEdit(true);
  // };
  const handleEdit = () => {
    if (validate()) {
      updateNode();
      resetValues();
      setIsOpen(false);
    }
  };

  const updateNode = () => {
    const node = selectedNode;
    const path = selectedPath;
    const { children } = node;
    const editNode = {
      title: editNodeName,
      key: newNodeKey,
      id: selectedNode.id,
      pbsTaxonomyLevelId: nodeLevel.id,
      isEdit: true,
    };
    const value = editNodeName;

    const newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey: (node: any) => node.productId,
      newNode: {
        children,
        title: value,
        id: selectedNode.id,
      },
    });

    setTreeData(newTree);
    props.onCreateNode(editNode);
  };

  const handleNodeCheckboxClick = (rowInfo: any) => {
    const { node, path } = rowInfo;
    setSelectedPath(path);
    setSelectedNode(node);
    const id = isCuProjectPlanning()||isPlanning() ? node.productId : node.id;
    props.onSelectItem(id);
  };

  // const handleTreeOnSearch = (searchString: string) => {
  //   setSearchString(searchString);
  // };

  // const selectPrevMatch = () => {
  //   let searchFoundCountVal = 0;
  //   let searchFocusIndexValue = 0;
  //   if (typeof searchFoundCount === 'number') {
  //     searchFoundCountVal = searchFoundCount;
  //   }
  //   if (typeof searchFocusIndex === 'number') {
  //     searchFocusIndexValue = searchFocusIndex;
  //   }

  //   setSearchFocusIndex(
  //     searchFocusIndex !== null
  //       ? (searchFoundCountVal + searchFocusIndexValue - 1) %
  //       searchFoundCountVal
  //       : searchFoundCountVal - 1,
  //   );
  // };

  // const selectNextMatch = () => {
  //   let searchFoundCountVal = 0;
  //   if (typeof searchFoundCount === 'number') {
  //     searchFoundCountVal = searchFoundCount;
  //   }

  //   setSearchFocusIndex(
  //     searchFocusIndex !== null
  //       ? (searchFocusIndex + 1) % searchFoundCountVal
  //       : 0,
  //   );
  // };

  // model////////
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(true);
  const [isOpen, setIsOpen] = useState(false);
  const titleId = useId('title');
  
  const [nameErrorMsg, setNameErrorMsg]: any = useState('');
  const validate = (): boolean => {
    let isValid = true;
    if (isEdit) {
      if (_.isNil(editNodeName) || _.isEmpty(editNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
    } else {
      if (_.isNil(newNodeName) || _.isEmpty(newNodeName)) {
        setNameErrorMsg(t('nameRequired'));
        isValid = false;
      } else {
        setNameErrorMsg('');
      }
    }
    return isValid;
  };

  const resetValidation = () => {
    setNameErrorMsg('');
  };

  const renderCheckBox = (rowInfo: any) => {
    const isParent = selectedParentIds.includes(rowInfo.node.id);
    const preselectedId = isCuProjectPlanning()||isPlanning() ? rowInfo?.node?.productId : rowInfo?.node?.id;
    if (selectedParentIds.length > 0 && isParent) {
      return (
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <Checkbox
            className="btn btn-outline-success"
            styles={{ root: { verticalAlign: 'middle',alignItems: 'center' },
              checkbox: {
              width: 16, // Decrease checkbox size
              height: 16, // Decrease checkbox size
            } }}
            defaultIndeterminate
            onChange={() => handleNodeCheckboxClick(rowInfo)}
          />
        </div>
      );
    }
    return (
      <div className={'default'} style={{ paddingTop: 0 }}>
        <Checkbox
          className="btn btn-outline-success"
          styles={{
            root: {
              verticalAlign: 'middle',
              paddingTop: 6,
              alignItems: 'center'
            },
            checkbox: {
              width: 16, // Decrease checkbox size
              height: 16, // Decrease checkbox size
            },
          }}
          checked={preselectedId === selectItemId}
          onChange={() => handleNodeCheckboxClick(rowInfo)}
        />
      </div>
    );

  };

  const renderNameField = () => {
    if (nodeLevel && nodeLevel.isSearchable) {
      return (
        <CustomTagPicker
          validationMessage={nameErrorMsg}
          required={true}
          label={t('name')}
          onFilterTagChanged={props.onFilterTaxonomyNodeChanged}
          onItemSelected={(item: any) => {
            if (item) {
              setNewNodeName(item.name);
              setNewNodeKey(item.key ? item.key : null);
            }
            resetValidation();
          }}
        />
      );
    }
    return (
      <TextField
        autoComplete="off"
        label={t('name')}
        value={newNodeName}
        required={true}
        onChange={(event, value) => {
          setNewNodeName(value);
          setNewNodeKey(null);
          resetValidation();
        }}
        errorMessage={nameErrorMsg ? nameErrorMsg : ''}
      />
    );
  };

  const renderEditNameField = () => {
    if (selectedNode) {
      return (
        <div>
          <TextField
            autoComplete="off"
            label={t('name')}
            value={editNodeName}
            required={true}
            onChange={(event, value) => {
              setEditNodeName(value);
              resetValidation();
            }}
            errorMessage={nameErrorMsg ? nameErrorMsg : ''}
          />
        </div>
      );
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case PBS_STATE.PENDDING_DEVELOPMENT:
        return t('pendingDevelopment');
      case PBS_STATE.IN_DEVELOPMENT:
        return t('inDevelopment');
      case PBS_STATE.IN_REVIEW:
        return t('inReview');
      case PBS_STATE.APPROVED:
        return t('approved');
      case PBS_STATE.HAND_OVER:
        return t('handedOver');
      default:
        return t('pendingDevelopment');
    }
  };
  
  const getProductStatusColors = (status: string) => {
    switch (status) {
      case PBS_STATE.PENDDING_DEVELOPMENT:
        return 'pie-chart-pending-development';
      case PBS_STATE.IN_DEVELOPMENT:
        return 'pie-chart-in-development';
      case PBS_STATE.IN_REVIEW:
        return 'pie-chart-in-review';
      case PBS_STATE.APPROVED:
        return 'pie-chart-approved';
      case PBS_STATE.HAND_OVER:
        return 'pie-chart-handed-over';
      default:
        return 'pie-chart-pending-development';
    }}

  // const getButtonContents = (rowInfo: any) => {
  //   return (
  //     <div className="ms-Grid-row" style={{ display: 'flex', flexDirection: 'row' }}>
  //       {/* <div>
  //         <div>{props.projectBreakdown?.id === rowInfo.node.id ? null : renderCheckBox(rowInfo)}</div>
  //         <div style={{marginLeft:'1%', marginTop:'1%', fontSize: '14px',fontWeight:'600', color: 'black'}}>{truncateTitle(rowInfo)}</div>
  //       </div> */}
  //       <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8" style={{marginRight: '70px'}}>
  //         <Label
  //           style={{ width: 80, marginLeft: '20px !important', fontSize: 14 }}
  //           onClick={() => {
  //           }}
  //         >
  //           {rowInfo.node?.qualityReviewer && `${rowInfo.node?.qualityReviewer}(${rowInfo.node?.qualityReviewerCompany})`}
  //         </Label>
  //       </div>
  //       <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
  //         <Popover popover={({ visible, open, close }) => {
  //           return (
  //             (!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning()))) ?
  //               <StatusPopOver
  //                 visible={visible}
  //                 productId={rowInfo.node?.id}
  //                 cu={rowInfo.node?.cu}
  //                 project={rowInfo.node?.project}
  //                 path={rowInfo?.path}
  //                 close={close}
  //                 node={rowInfo?.node}
  //               /> : <ProjectScopePopOver
  //                 visible={visible}
  //                 projectId={rowInfo.node?.id}
  //                 cu={rowInfo.node?.cu}
  //                 project={rowInfo.node?.project}
  //                 path={rowInfo?.path}
  //                 close={close}
  //                 projectScopeStatusId={rowInfo?.node?.projectScopeStatusId}
  //               />
  //           );        
  //         }}>
  //           <TooltipHost
  //             key={'All-link1'}
  //             content={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getStatusText(rowInfo.node?.pbsProductStatusId) : ''}
  //             id='filter-opt-1'
  //             calloutProps={{ gapSpace: 0 }}
  //             directionalHint={DirectionalHint.rightCenter}
  //           >
  //             <FontIcon
  //               iconName={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getProductStatusColors(rowInfo.node?.pbsProductStatusId) : ''}
  //               className='ms-Icon'
  //               style={{
  //                 marginTop: '10px',
  //                 marginLeft: 'auto',
  //                 marginRight: '5px',
  //                 fontSize: '4px',
  //                 width: 15,
  //                 height: 15,
  //                 color: '#B2BABB',
  //                 paddingBottom: 15,
  //               }}
  //             />

  //           </TooltipHost>
  //         </Popover>          
  //       </div>
  //       <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8" style={{marginRight: '62px'}}>
  //         <Label
  //           style={{ width: 80, marginLeft: '20px !important', fontSize: 14 }}
  //           onClick={() => {
  //           }}
  //         >
  //           {rowInfo.node.startDate && rowInfo.node.endDate ? (`${Math.floor(moment.duration(moment(rowInfo.node?.endDate).diff(moment(rowInfo.node?.startDate, 'YYYY-MM-DDTHH:mm:ss').toDate())).asHours())} h`) : ('-')}
  //         </Label>
  //       </div>
  //       <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8" style={{marginRight: '84px'}}>
  //         <Label
  //           style={{
  //             width: 80,
  //             marginLeft: '20px !important',
  //             fontSize: 14,
  //             color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000',
  //           }}
  //           onClick={() => {
  //           }}
  //         >
  //           {moment(rowInfo.node?.startDate).format('DD/MM/YYYY')}
  //         </Label>
  //       </div>
  //       <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8" style={{marginRight: '62px'}}>
  //       <Label
  //           style={{
  //             width: 80,
  //             marginLeft: '20px !important',
  //             fontSize: 14,
  //             color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000',
  //           }}
  //           onClick={() => {
  //           }}
  //         >
  //           {moment(rowInfo.node?.endDate).format('DD/MM/YYYY')}
  //         </Label>
  //       </div>
  //   </div>
  //   )
  // }

  // const truncateTitle = (rowInfo : any) => {
  //   const maxLength = 58;
  //   if (rowInfo.node.title.length > maxLength) {
  //     if(rowInfo.node.parentId){
  //       return rowInfo.node.title.substring(0, maxLength-3) + '...';
  //     } else {
  //         return rowInfo.node.title.substring(0, maxLength) + '...';
  //     }
  //   }
  //   return rowInfo.node.title;
  // };

  const truncateQualityReviewer = (name: string) => {
    const maxLength = 20;
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }

    return name;
  }

  const searchProduct = async () => {
    setDataLoaded(true);
    const payload = {
      title: productTitle!=""?productTitle:null,
      pbsProductStatusId: productStatus!='1'?productStatus:null,
      address: address!=""?address:null,
      Coordinates:{
        lat: coordinates.lat?coordinates.lat:"0.0",
        lon: coordinates.lon?coordinates.lon:"0.0"
      },
      radius: radius!=""?radius:null,
      minLabourHours: minLabourHours!=""?minLabourHours:"0",
      maxLabourHours: maxLabourHours!=""?maxLabourHours:"0",
      qualityReviewerId: qualityReviewer?.[0]?.key,
      fromDate: formatDateTimeToISOString(standardFilter[0]),
      toDate: formatDateTimeToISOString(standardFilter[1]),
    }

    try {
      let headers: any;
      if (props.forCertifications === true) {
        headers = {Cu: props.cu,Project: props.project};
      } else {
        headers = !getProject() && !getContractingUnit()?{Cu: props.cu,Project: props.project}:null;
      }
      
      const config = headers ? { headers: headers } : {};
      const response = await client.post("PbsFilter/GetPbsForPmol",payload,config)
      if (response.data.status) {
        setListData(response.data.result);
        setNoDataFound(false);
      } else if(!response.data.result || response.data.result.length==0){
        setListData([]);
        setNoDataFound(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setNoDataFound(true);
    } finally {
      setDataLoaded(false);
    }
  }

  const debounced =  useDebouncedCallback(()=>{
    searchProduct();
  },
  1000)

  const getPersonByNameForQulity = async (name: string) => {
    let data: { key: string; text: string }[] = [];
    let response = await client.post(GET_FOREMAN_BY_NAME_EP, {
      fullName: name,
    });
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result;
    }
    return data;
  };

  const onFilterChanged = async (
    filterText: string,
    currentPersonas?: IPersonaProps[] 
  ): Promise<IPersonaProps[]> => {
    if(filterText){
      const fetchedData = await getPersonByNameForQulity(filterText);
      return fetchedData;
    } else {
      return [];
    }
  };

  const onQualityReviewerSelected = (item: any) => {
    if (item) {
      setQualityReviewer([item]);
    }
    return item;
  };

  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsMaximumNumber: 10,
    searchingText: 'Searching...',
  };

  return (
    <div className={`${contentStyles.returnContainer} ms-Grid-col ms-sm12 ms-md12 ms-lg12`}>
      <Label>{props.label}</Label>
      <div className="row">
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={expandAll}>
              {t('expandAll')}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <PrimaryButton onClick={collapseAll}>
              {t('collapseAll')}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </div>
  
      <div className={`${contentStyles.filterContainer} row`}>
        <Stack horizontal styles={stackStyles} tokens={stackTokens} style={{width: '100%'}}>
          <Stack.Item grow={1} styles={stackItemStyles} className={contentStyles.width10}>
              <div className="full-width">
                <TextField
                  label={t('productTitle')}
                  value={productTitle}
                  onChange={(event, value) => {
                    if(value){
                      setProductTitle(value);
                    } else {
                      setProductTitle("");
                    }
                  }}
                />
              </div>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemStyles} className={contentStyles.width10}>
            <div className="full-width">
              <Label>{t('qualityReviewer')}</Label>
              <NormalPeoplePicker
                onChange={(items) => {
                  if (items && _.isArray(items) && items.length === 0) {
                    setQualityReviewer([]);
                  }
                  // searchProduct()
                }}
                selectedItems={qualityReviewer &&
                  qualityReviewer[0] &&
                  qualityReviewer[0]
                    ? qualityReviewer
                    : []}
                onResolveSuggestions={onFilterChanged}
                onItemSelected={onQualityReviewerSelected}
                pickerSuggestionsProps={limitedSearchAdditionalProps}
                className={'ms-PeoplePicker'}
                key={'qualityReviewer'}
                itemLimit={1}
                removeButtonAriaLabel={'Remove'}
                resolveDelay={300}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="full-width">
              <Dropdown
                label={ t('productStatus') }
                options={ props.productStatusList }
                onChange={ (event, value) => {
                  if(value){
                    setproductStatus(value.key as string);
                  } else {
                    setproductStatus("1");
                  }
                }}
                selectedKey={productStatus}
                styles={{
                  dropdown: { width:200}
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="full-width">
              <Dropdown
                label={t('standardFilter')}
                options={ standerdFilterOptions }
                onChange={(event, value) => {
                  const dates = getSelectedFilterDates([value?.key])
                  setStandardFilter(dates)
                }}
                styles={{
                  dropdown: { width:200}
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item style={{width:'24%'}}>
            <div>
              <AzureAddressAutoComplete
                onAddressSelected={(location: any) => {
                  if (location.address.freeformAddress && location.position) {
                    setAddress(location.address.freeformAddress);
                    setCoordinates(location.position);
                  } else {
                    setAddress("");
                    setCoordinates({ lat: null, lon: null });
                  }
                } }
                address={''}
                disabled={false}
                lable={t('starting Point')} 
                onChange={function (value: any): void {}}
                // lable={t('startingPoint')}
              />
            </div>              
          </Stack.Item>
          <Stack.Item grow={1} className={contentStyles.radius}>
            <div className="full-width">
              <TextField
                type={'number'}
                label={t('radius')}
                value={radius}
                onChange={(event, value) => {
                  if(value){
                    setRadius(value);
                  } else {
                    setRadius("");
                  }
                }}
              />
            </div>
          </Stack.Item>
          <Stack.Item grow={1} className={contentStyles.labHours}>
              <div className="full-width">
                <TextField
                  type={'number'}
                  label={t('min Labour Hours')}
                  onChange={(event, value) => {
                    if(value){
                      setMinLabourHours(value);
                    } else {
                      setMinLabourHours("");
                    }
                  }}
                />
              </div>
          </Stack.Item>
          <Stack.Item grow={1} className={contentStyles.labHours}>
            <div className="full-width">
              <TextField
                type={'number'}
                label={t('max Labour Hours')}
                onChange={(event, value) => {
                  if(value){
                    setMaxLabourHours(value);
                  } else {
                    setMaxLabourHours("");
                  }
                }}
              />
            </div>
          </Stack.Item>
        </Stack>
      </div>

      {dataLoaded && <ProgressBar show={true} />}

      {!dataLoaded &&  
        <div className={contentStyles.columnTitleContainer}>
          <div className={contentStyles.nameCol}>
            {t('name')}
          </div>

          <div className={contentStyles.otherCol}>
            {t('qualityReviewer')}
          </div>

          <div className={contentStyles.otherCol}>
            {t('duration')}
          </div>

          <div className={contentStyles.otherCol}>
            {t('from')}
          </div>

          <div className={contentStyles.otherCol}>
            {t('to')}
          </div>
        </div>
      }

      {/* <label htmlFor="find-box"></label> */}
      {!noDataFound ? 
          <div className={`${contentStyles.sortableTreeHeight} chart`}>
            <SortableTree
              // className='chart'
              style={{height:'94%'}}
              canDrag={({}) => false}
              canDrop={() => false}
              searchQuery={searchString}
              searchMethod={customSearchMethod}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={(matches) => {
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
                );
              }}
              // isVirtualized={true}
              treeData={treeDataList}
              onChange={(treeDataList) => setTreeDataList(treeDataList)}
              onlyExpandSearchedNodes={true}
              rowHeight={65}
              theme={FileExplorerTheme as any}
              getNodeKey={({ node, treeIndex }: any) => treeIndex}
              generateNodeProps={(rowInfo: any) => {
                return {
                  // buttons: [ getButtonContents(rowInfo) ],
                  title:[
                    <div 
                      className={`${contentStyles.tableContainer} row`} 
                      onClick={() => {
                        if (props.projectBreakdown?.id === rowInfo.node.id) {
                          return;
                        } else {
                          handleNodeCheckboxClick(rowInfo)
                        }
                      }}
                    >
                      <div className={`${contentStyles.nameRow} row`}>
                        <div>{props.projectBreakdown?.id === rowInfo.node.id ? null : renderCheckBox(rowInfo)}</div>
                        {/* <div className={contentStyles.pbsTitle}>{truncateTitle(rowInfo)}</div> */}
                        <div className={contentStyles.pbsTitle}>{rowInfo?.node?.title}</div>
                      </div>

                      <div className={contentStyles.width10}>
                        <TooltipHost directionalHint={DirectionalHint.rightCenter} content={`${rowInfo.node?.qualityReviewer} (${rowInfo.node?.qualityReviewerCompany})`}>
                          <Label style={{ fontSize: 14 }}>
                            {/* {rowInfo.node?.qualityReviewer && `${rowInfo.node?.qualityReviewer} (${rowInfo.node?.qualityReviewerCompany})`} */}
                            {rowInfo.node?.qualityReviewer && truncateQualityReviewer(`${rowInfo.node?.qualityReviewer} (${rowInfo.node?.qualityReviewerCompany})`)}
                          </Label>
                        </TooltipHost>
                      </div>

                      <div className={contentStyles.durationRow}>
                        <Popover popover={({ visible, open, close }) => {
                          return (
                            (!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning()))) ?
                              <StatusPopOver
                                visible={visible}
                                productId={rowInfo.node?.id}
                                cu={rowInfo.node?.cu}
                                project={rowInfo.node?.project}
                                path={rowInfo?.path}
                                close={close}
                                node={rowInfo?.node}
                              /> : <ProjectScopePopOver
                                visible={visible}
                                projectId={rowInfo.node?.id}
                                cu={rowInfo.node?.cu}
                                project={rowInfo.node?.project}
                                path={rowInfo?.path}
                                close={close}
                                projectScopeStatusId={rowInfo?.node?.projectScopeStatusId}
                              />
                          );        
                        }}>
                          <TooltipHost
                            key={'All-link1'}
                            content={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getStatusText(rowInfo.node?.pbsProductStatusId) : ''}
                            id='filter-opt-1'
                            calloutProps={{ gapSpace: 0 }}
                            directionalHint={DirectionalHint.rightCenter}
                          >
                            <FontIcon
                              iconName={!(rowInfo?.node?.projectScopeStatusId && (isMyEnvironment() || isCuProjectPlanning())) ? getProductStatusColors(rowInfo.node?.pbsProductStatusId) : ''}
                              className={`${contentStyles.durationIcon} ms-Icon`}
                            />
                          </TooltipHost>
                        </Popover>
                        <Label style={{ fontSize: 14 }}>
                          {/* {rowInfo.node.startDate && rowInfo.node.endDate ? (`${Math.floor(moment.duration(moment(rowInfo.node?.endDate).diff(moment(rowInfo.node?.startDate, 'YYYY-MM-DDTHH:mm:ss').toDate())).asHours())} h`) : ('-')} */}
                          {rowInfo.node?.consolidatedDuration ? `${rowInfo.node?.consolidatedDuration} h` : `0 h`}
                        </Label>
                      </div>

                      <div className={contentStyles.width10}>
                        <Label
                          style={{
                            fontSize: 14,
                            color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000',
                          }}
                        >
                          {moment(rowInfo.node?.startDate).format('DD/MM/YYYY')}
                        </Label>
                      </div>

                      <div className={contentStyles.width10}>
                        <Label
                          style={{
                            fontSize: 14,
                            color: rowInfo.node?.isSelected ? '#FFFFFF' : '#000000',
                          }}
                        >
                          {moment(rowInfo.node?.endDate).format('DD/MM/YYYY')}
                        </Label>
                      </div>
                    </div>
                  ],
                  onClick: () => {
                    // handleNodeClick(rowInfo);
                  },
                };
              }}
            />
          </div>
          : <div>
              <Text className={contentStyles.noData}>{ t('noData') }</Text>
            </div>
        }

      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          resetValues();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {isEdit ? i18n.t('editNode') : childLevelName}{' '}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {isEdit ? renderEditNameField() : renderNameField()}
              </div>
            </div>
          </div>
        </div>
        <div className={contentStyles.footer}>
          {isEdit ? (
            <PrimaryButton
              iconProps={addIconWhite}
              text={t('save')}
              className={contentStyles.submitButton}
              onClick={() => {
                handleEdit();
              }}
            />
          ) : (
            <PrimaryButton
              iconProps={addIconWhite}
              text={t('add')}
              className={contentStyles.submitButton}
              onClick={() => {
                handleSave();
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomSortableTreeProduct;
