import {
  ActionButton,
  DetailsListLayoutMode,
  getTheme,
  IColumn,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';
import { ActionButtonState } from '../../types/corporateProductCatalogue';
import { ReadRisk } from '../../types/projectBreakdownStructure';
import { GridTableColumn } from '../tableColumn/tableColumn';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const DataGrid = (props: {
  dataList: any[];
  editDataGrid: () => void;
  openModel: () => void;
  selection: any;
  actionButtonState: ActionButtonState;
  title: string;
  deleteDataGrid: () => void;
  readOnly: boolean;
  columns: any[];
  isLoaded: boolean
  isClickable?: boolean;
  type?: string,
  feild?: string,
  id?: string,
}) => {
  // useEffect(() => {
  //   console.log(props)
  // },[])
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      minHeight: (props.dataList && props.dataList.length > 1) ? 200 : 110,
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
    borderNone: {
      border:'none !important'
    },
    labelOverride: {
      fontSize: '14px !important',
      fontWeight:'600'
    },
    subHeaderStyle: { fontSize: '14px !important' }
  });

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  return (
    <div className="">
      <div className="marginTop">
        <div style={{ padding: 12 }}>
          <div className="ms-Grid-row">
            <div className="sub-header-title">
              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={stackStyles}
                tokens={stackTokens}
              >   {props.title &&
              <Stack.Item styles={stackItemStyles}>
                <Link href="#" id={props?.id?props.id:"pmol-cbc-3-2"} className={classNames.borderNone}>
                  <Label className={classNames.labelOverride} style={{ marginTop: 10 }}>{props.title}</Label>
                </Link>
              </Stack.Item>
              }
                {!props.readOnly && (
                  <Stack.Item styles={stackEditbarItemStyles}>                   
                    <ActionButton
                      className={classNames.actionButton}
                      disabled={props.actionButtonState.add}
                      iconProps={addIcon}
                      allowDisabledFocus
                      onClick={() => {
                        props.openModel();
                      }}
                    >
                      <Label className={classNames.actionButton}>{t('add')} </Label>
                    </ActionButton>
                    <ActionButton
                      iconProps={editIcon}
                      disabled={props.actionButtonState.edit}
                      allowDisabledFocus
                      onClick={() => {
                        props.editDataGrid();
                      }}
                    >
                      <Label className={classNames.actionButton}> {t('edit')}</Label>
                    </ActionButton>
                    <ActionButton
                      iconProps={removeIcon}
                      disabled={props.actionButtonState.remove}
                      allowDisabledFocus
                      onClick={() => props.deleteDataGrid()}
                    >
                      <Label className={classNames.actionButton}>
                        {' '}
                        {t('remove')}
                      </Label>
                    </ActionButton>
                  </Stack.Item>
                )}
              </Stack>
            </div>
          </div>

          <div className="ms-Grid-row marginTop">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className={classNames.wrapper}>
                <div className={'scrollablePane'}>
                  <ShimmeredDetailsList
                    items={props.dataList}
                    columns={props.columns}
                    setKey="set"
                    enableShimmer={props.isLoaded}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    checkboxVisibility={1}
                    selection={props.selection}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    onRenderItemColumn={(
                      item: ReadRisk,
                      index?: number,
                      column?: IColumn,
                    ) => GridTableColumn(item, index, column, props.isClickable, props.type)}
                    onRenderDetailsHeader={(headerProps, defaultRender) => {
                      if (defaultRender !== undefined) {
                        return (
                          <Sticky
                            stickyPosition={StickyPositionType.Header}
                            isScrollSynced={true}
                            stickyBackgroundColor="transparent"
                          >
                            <div>{defaultRender(headerProps)}</div>
                          </Sticky>
                        );
                      }
                      return <span></span>;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataGrid;
