import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ActionButton, Dropdown, FontWeights, getTheme, IconButton, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, mergeStyleSets, Modal, PrimaryButton, Stack, TeachingBubble } from '@fluentui/react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { uPrinceTheme } from '../../../../../theme';
import client from '../../../../api';
import PreviewImages from './PreviewImages';
import PreviewProperties from './PreviewProperties';
import { getContractingUnit } from '../../../../shared/util';
import Axios from 'axios';
import ENV from '../../../../../env.json'
import { SAVE_PBS_COMPOSITION_IMAGES } from '../../../../shared/endpoints';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const uploadIcon: IIconProps = {
  iconName: 'Upload',
  styles: iconButtonStyles,
};

const copyIcon: IIconProps = {
  iconName: 'Copy',
  styles: iconButtonStyles,
};
// const removeIconStyles = {
//   root: {
//       color: uPrinceTheme.palette.black,
//       width: 17,
//       minWidth: 17,
//       height: 15,
//       paddingRight: 1,
//       paddingLeft: 1,
//       paddingTop: 1,
//       paddingBottom: 1
//   },
//   rootHovered: { color: theme.palette.neutralDark }
// };
// const removeIcon: IIconProps = {
//   iconName: 'Delete',
//   styles: removeIconStyles
// };
const stackStyles: IStackStyles = { root: { padding: 0, boxSizing:'border-box', justifyContent: 'flex-end' } };

const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const firstStackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
    marginRight: 'auto',
  },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
}
const infoIcon: IIconProps = {iconName: 'Info'}
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  renameContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '25%'
  },
  renameHeader: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    backgroundColor: uPrinceTheme.palette.themePrimary,
    fontSize: 18,
    color: 'white',
  },
  renameFooter: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 14px 24px',
    textAlign: 'end',
  },
});
const PictureUploadComponent = (props: {
  uploadedImages: any;
  onSaveSuccess: any;
  isDragEnabled: boolean;
  updateDocument: any;
  pmolId: any;
  pbsId: any;
  sectionHeading: any;
  renameSuccess: any;
  imageDeleteSuccess?: any;
  uploadURL: any
  productId: any;
  isProduct: boolean
}) => {
  const inputFileRef = useRef<any>(null);
  const { t } = useTranslation();

  const [dragging, setDragging] = useState(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState<boolean>(false)
  const [images, setImages] = useState<any>();
  const [selectedClassificationType, setSelectedClassificationType] = useState<string | null>(null);
  
  // const [validatePhases, setValidatePhases] = useState<boolean>(false);
  const [deletedImageIds, setDeletedImageIds] = useState<any[]>([]);

  const [selectedImages, setSelectedImages] = useState<any[]>([])
  const [selectedImageId, setSelectedImageId] = useState<any>([])

  const [selectedImageForPreview, setSelectedImageForPreview] = useState<any>(null);
  const [bulkEdit, setBulkEdit] = useState(false)

  const [pmolDetails, setPmolDetails] = useState<any>(null)

  const {
    selectedMyDpPmol,
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const {
    formData,
  } = useSelector(
    (state: any) => state.projectBreakdown
  );

  useEffect(() => {
    if (!props.isProduct) {
      if (selectedMyDpPmol?.id) {
        getPmolData(selectedMyDpPmol);
      }
    }
  }, [props.isProduct, selectedMyDpPmol?.id])

  const getPmolData = async (details: any) => {
    try {
      const getPmolByid = await Axios({
        method: 'GET',
        url: `${ENV.BASE_URL}/Pmol/ReadPmolId/${details?.projectMoleculeId}`,
        headers: {
          "Authorization": `Bearer ${localStorage?.getItem('accessToken')}`,
          "Cu": getContractingUnit(),
          "Project": details?.projectSequenceCode
        }
      })

      if (getPmolByid?.status === 200) {
        setPmolDetails(getPmolByid?.data?.result)
      }
    } catch (error) {
      alert('Something went wrong when fetching PMOL details')
    }
  }

  useEffect(() => {
    setImages(props.uploadedImages)
  }, [props.uploadedImages])
  
  useEffect(() => {
    if (props.isDragEnabled) {
      setIsDragAndDropEnabled(props.isDragEnabled)
    }
  }, [props.isDragEnabled])

  const uploadFiles = async (files: any) => {
    await saveImageInBlob(files);
  }

  const saveProductCompositionImages = async (imageList : any, imageId: any) => {
    let payload = {
      id: "",
      description: "",
      pbsProductId: props.pbsId,
      pictureList: [imageList]
    }

    await client.post(SAVE_PBS_COMPOSITION_IMAGES, payload).then(async (response: any) => {
      await props.onSaveSuccess(imageId);
    })
  }

  const saveImageInBlob = async (file: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const data = new FormData();
    if (file) {
      data.append('image', file);
    }
    await client.post(props.uploadURL, data, {headers: headers}).then(async (res) => {
      if (props.isProduct) {
        let newImage = {
          link : res.data.result,
          type: "1"
        }
        saveProductCompositionImages(newImage, res.data.result);
      } else {
        await props.onSaveSuccess(res.data.result, selectedClassificationType);
      }
    }).catch((err) => {
      alert(JSON.stringify(err))
    })
  }
  
  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    // const droppedFiles = Array.from(e.dataTransfer.files);
    for (const file of e.dataTransfer.files){
      isDragAndDropEnabled && uploadFiles(file)
    }
  };

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const handleSelectAll = () => {
    const ids = props.uploadedImages?.map((item: any) => item.id && item.id)
    setSelectedImageId(ids)
    setSelectedImages(props?.uploadedImages)
  }

  const deleteImage = (id: string, section : string) => {
    const extraWorkImages = images?.map((item:any) => {return {...item, image: item.link, isExtrawork: true}});
    let deleteId = deletedImageIds
    deleteId.push(id);
    setDeletedImageIds(deleteId);
    
    if(props.updateDocument){
      if(!props.isProduct) {
        const updatedFiles = extraWorkImages?.filter((file: any) => !deleteId?.includes(file.id));
        setImages(updatedFiles);
        props.updateDocument(updatedFiles, true);
        props.imageDeleteSuccess(updatedFiles)
      } else {
        props.updateDocument(id, true);
      }
    }
  }

  // Download as ZIP files
  const getImageFormat = (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const arr = (new Uint8Array(reader.result as ArrayBuffer)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        let format = '';
        if (header.startsWith('ffd8')) format = 'jpeg'; // JPEG
        else if (header.startsWith('8950')) format = 'png'; // PNG
        else if (header.startsWith('4749')) format = 'gif'; // GIF

        resolve(format);
      };
      reader.readAsArrayBuffer(blob);
    });
  };

  const compressImage = async (imageBlob: Blob, maxWidth: number, maxHeight: number): Promise<Blob | null> => {
    return new Promise(async (resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageBlob);
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate new dimensions
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        // Determine the original format for correct Blob conversion
        const format = await getImageFormat(imageBlob);
        const mimeType = format === 'jpeg' ? 'image/jpeg' : format === 'png' ? 'image/png' : 'image/gif';

        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          resolve(blob);
        }, mimeType, 0.7); // Adjust quality (0-1)
      };
    });
  };

  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const compressAndDownload = async () => {
    const zip = new JSZip();
    const imagesCC = selectedImages?.map((e: any) => e.image)
    if (imagesCC.length !== 0) {
      const promises: any = imagesCC?.map(async (imageURL: any, index: any) => {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        const compressedBlob = await compressImage(blob, 1920, 1024);
        if (compressedBlob) {
          const format = await getFileName(imageURL);
          zip.file(`${format}`, compressedBlob);
        }
      });
  
      await Promise.all(promises);
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const zipFolderName = props.isProduct ? `${formData?.productId}-Pictures.zip` : `${selectedMyDpPmol?.projectMoleculeId}-Pictures.zip`
        FileSaver.saveAs(content, zipFolderName);
      });
    }
  };

  const approveExtraImages =(imageIds: any) =>{
    client.post("Pmol/ApproveImages", imageIds).then(
      (response: any) => {
        setSelectedImageId([]);
        setSelectedImages([])
      },

      (error: any) => {
        alert("Extra not working");
      },
    );
  }

  const copyToProductComposition =(copyImgData: any) =>{
    client.post("Pbs/CopyPicturesToComposition", copyImgData).then(
      (response: any) => {
        
      },

      (error: any) => {
        alert("not working");
      },
    );
  }

  const handleCopyToPBSClick = () => {
    // const extraworkIds = selectedImages?.filter((item: any) => item.isExtrawork).map((item: any) => item.id);
    const pictureList = selectedImages?.map((e) => {
      return {
        link: e.image,
        type: e.type
      }
    })
    
    const copyImgData = {
      id: "",
      description: "",
      pbsProductId: props.productId,
      pictureList: pictureList
    }


    if(selectedImageId && selectedImageId.length > 0){
      const idList = {
        imageIds: selectedImageId
      }
      approveExtraImages(idList);
    }

    if(copyImgData && copyImgData.pbsProductId!=null && copyImgData.pictureList.length>0){
      copyToProductComposition(copyImgData);
    }

    const updated = images?.map((item: any) => {
      if (selectedImageId?.includes(item.id)) {
        return {
          ...item,
          isApproved: true
        }
      } else {
        return {
          ...item,
          isApproved: item.isApproved
        }
      }
    })

    setImages(updated);
    if(props.updateDocument){
      props?.updateDocument(updated, false);
    }
  };

  const getPmolDetailsToThePropertiesField = () => {
    if (props.isProduct) {
      return {
        project: formData?.project,
        product: formData?.title,
        pmol: null,
        foreman: null,
        location: {
          freeFormAddress: formData?.mapLocation?.address?.freeformAddress,
          lat: formData?.mapLocation?.position?.lat,
          lon: formData?.mapLocation?.position?.lon,
          id: formData?.mapLocation?.id
        }
      }
    } else {
      return {
        project: pmolDetails?.header?.projectDefinition?.title,
        product: pmolDetails?.header?.productTitle,
        pmol: pmolDetails?.header?.title,
        foreman: pmolDetails?.header?.foreman,
        location: {
          freeFormAddress: pmolDetails?.mapLocation?.address?.freeformAddress,
          lat: pmolDetails?.mapLocation?.position?.lat,
          lon: pmolDetails?.mapLocation?.position?.lon,
          id: pmolDetails?.mapLocation?.id
        }
      }
    }
  }

  return (
    <div  className={`dropzone ${dragging ? 'dragging' : ''} input-file`} 
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div>
        <input
          style={{ display: 'none' }}
          ref={inputFileRef}
          type="file"
          id='file'
          accept={'image/*'}
          multiple={true}
          onChange={async (e) => {
            if(e.target.files && e.target.files.length>0){
              for(const file of e.target.files){
                await uploadFiles(file);
              }
            }
          }}
        />
      </div>

      {/* //  */}
      <>
        <Stack
          horizontal
          // horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={firstStackItemStyles}>
            <ActionButton
              className={classNames.actionButton}
            >
              <Label>{t('pictures')}</Label>
            </ActionButton>
          </Stack.Item>
          
          <>
            <Stack.Item styles={stackEditbarItemStyles}>
              <IconButton
                iconProps={infoIcon}
                // styles={renameButtonStyles}
                allowDisabledFocus
                disabled={selectedImages?.length > 1 ? false : true}
                onClick={() => {
                  if (selectedImages?.length > 1) {
                    setBulkEdit(true)
                  }
                }}
              />
              <PrimaryButton style={{margin: '0 8px', fontSize: '12px'}} onClick={handleSelectAll}>
                {t('selectAll')}
              </PrimaryButton>
              <PrimaryButton 
                disabled={selectedImageId?.length === 0 ? true : false}
                onClick={compressAndDownload}
                style={{fontSize: '12px'}}
              >
                {t('download')}
              </PrimaryButton>
            </Stack.Item>
          </>
          
          <Stack.Item styles={stackEditbarItemStyles}>
            <Dropdown
              id='phases'
              options={[
                {key: "Before", text: t("before")},
                {key: "During", text: t("during")},
                {key: "After", text: t("after")}
              ]}
              onChange={(event, option, index) => {
                option && setSelectedClassificationType(option?.text)
              }}
              style={{width: '100px', borderColor: 'red'}}
              // required={validatePhases}
            />
          </Stack.Item>
          
          <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={uploadIcon}
              allowDisabledFocus
              // disabled={props.disable}
              onClick={() => {
                // if (selectedClassificationType !== null) {
                //   showFileDialog();
                // } else {
                //   setValidatePhases(true)
                // }
                showFileDialog();
              }}
            >
              <Label className={classNames.actionButton}>{t('upload')}</Label>
            </ActionButton>
          </Stack.Item>
          {!props.isProduct && 
            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                iconProps={copyIcon}
                allowDisabledFocus={selectedImageId?.length === 0 ? true : false}
                disabled={selectedImageId?.length === 0 ? true : false}
                style={{opacity: selectedImageId?.length === 0 ? 0.5 : 1}}
                onClick={() => {
                  handleCopyToPBSClick();
                }}
              >
                <Label className={classNames.actionButton}>{t('copyToPbs')}</Label>
              </ActionButton>
            </Stack.Item>
          }
        </Stack>
      </>

      <div>
        <PreviewImages 
          uploadedImages={images}
          selectedImages={selectedImages}
          selectedImageId={selectedImageId}
          setSelectedImageId={setSelectedImageId}
          setSelectedImages={setSelectedImages}
          setSelectedImageForPreview={setSelectedImageForPreview}
          selectedImageForPreview={selectedImageForPreview}
          deleteImage={(id: string, section: string) => {
            if(id && section){
              deleteImage(id, section)
            }
          }}
          sectionHeading={props.sectionHeading}
          pmolId={props.pmolId}
          renameSuccess={props.renameSuccess}
          project={selectedMyDpPmol?.projectSequenceCode}
          pmolDetails={getPmolDetailsToThePropertiesField()}
          pbsId={props.pbsId}
          isProduct={props.isProduct}
        />
      </div>

      {/* Modal for Bulk Edit */}
      <Modal
        isOpen={bulkEdit}
        onDismiss={() => 
          setBulkEdit(false)
        }
        isBlocking={false}
        containerClassName={classNames.renameContainer}
      >
        <div className={classNames.renameHeader}>
          <span style={{padding:'8px'}}>{t('bulkEditProperties')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel='Close popup modal'
            onClick={() => setBulkEdit(false)}
          />
        </div>
        <PreviewProperties 
          data={images} 
          bulkEdit={{
            show: bulkEdit,
            selectedImages: selectedImages
          }}
          sectionheading={props.sectionHeading}
          setBulkEdit={setBulkEdit}
          projectMoleculeId={selectedMyDpPmol?.projectMoleculeId}
          pmolDetails={getPmolDetailsToThePropertiesField()}
          isProduct={props.isProduct}
        />
      </Modal>
    </div>
  )
}

export default PictureUploadComponent