import React, { useEffect } from 'react';
import { Dropdown, Icon, IDropdownProps, IRenderFunction } from '@fluentui/react';


const ClearableDropdown = (
  props: {
    placeholder: string,
    label: string,
    selectedKey: string,
    errorMessage?: string,
    required?: boolean,
    disabled?: boolean,
    options: [],
    onChange: (event: any, value: any) => void,
    onRenderCaretDown?: any
  },
) => {
  const onRenderCaretDown = (): JSX.Element => {
    return (
      <Icon
        style={{ fontSize: props.selectedKey ? 9 : 14 }}
        iconName={
          props.selectedKey ? 'Clear' : 'ChevronDown'
        }

        onClick={() => {
          if (!props.disabled) {
            props.onChange(null, null);
          }
        }}
      />
    );
  };
  return (
    <Dropdown
      placeholder={props.placeholder}
      label={props.label}
      required={props.required}
      errorMessage={props.errorMessage}
      options={props.options}
      onRenderCaretDown={props.onRenderCaretDown ? props.onRenderCaretDown : onRenderCaretDown}
      selectedKey={props.selectedKey}
      onChange={(event, value) => {
        props.onChange(event, value);
      }}
      disabled={props.disabled}
    />
  );
};

export default ClearableDropdown;
