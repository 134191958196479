import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Field, Form} from 'react-final-form';
import {
    Calendar,
    DayOfWeek,
    DefaultButton,
    defaultCalendarStrings,
    FirstWeekOfYear,
    FontWeights,
    getTheme,
    FontIcon,
    IBasePickerSuggestionsProps,
    ITag,
    Label,
    mergeStyleSets,
    PrimaryButton,
    TagPicker,
    Modal,
    IIconProps,
    IButtonStyles,
    Checkbox,
    IconButton,
    IDragOptions,
    ContextualMenu,
    Stack
} from "@fluentui/react";
import {createForm} from "final-form";
import DefaultCustomModal from '../../customeModal/defaultCustomModal';
import {messageService} from '../../../services/messageService';
import {saveMyDayPlanningStateAttr} from "../../../reducers/myDayPlanningReducer";
import {createPmolCloneForMultipleDays} from "../../../reducers/pmolReducer";
import _ from "lodash";
import {uPrinceTheme} from "../../../../theme";
import {useId} from '@fluentui/react-hooks';
import ValidationLabel from '../../ValidationLabel/component';
import moment from "moment";
import {formatDateTimeToISOString} from "../../util";
import {addMidTermLabourTeam, saveVPStateAttr} from '../../../reducers/visualPlanReducer';
import OrgTaxonomyModel from '../../../components/cuProjectVisualPlan/midTermPlanningTaxonomyListPane/orgTaxonomyModal';
import { getTaxonomyList } from '../../../reducers/organisationSettingReducer';
import { OrganisationSettingListPaneFilter } from '../../../types/organisationSetting';
import { ORGANIZATION_TAXONOMY_LEVEL_TYPES } from '../../../types/cuMyCalendar';
import TaxonomyGrid from '../../taxonomyField/taxonomyGrid';
import client from '../../../api';
import { CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP } from '../../endpoints';
import { Hidden } from '@mui/material';

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '2px 2px 2px 10px',
        backgroundColor: uPrinceTheme.palette.themePrimary,
        fontSize: 18,
        color: 'white',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      minWidth: '45vw',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
    fullWidth: { width: '100%' },
    footer: {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  });

const styles = mergeStyleSets({
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '2px 2px 2px 10px',
            backgroundColor: uPrinceTheme.palette.themePrimary,
            fontSize: 18,
            color: 'white',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '10px 24px',
        overflowY: 'auto',
        minWidth: '23vw',
        minHeight: '10vh',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
        maxHeight: '80vh',
    },
    footer: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 16px 14px 16px',
            textAlign: 'end',
        },
    ]
});

const selectFormanStyles = {
    root: {
      marginLeft:'9%'
    },
  };

  const cancelIconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      color: 'white',
    },
    rootHovered: { color: theme.palette.neutralDark },
  };
  
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
  };
  
  const cancelIcon: IIconProps = { iconName: 'Cancel' };  

  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };

export default function CopyPmolModal() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const today = useConst(new Date());
    const pickerId = useId('inline-picker');
    const foremanId = useId('inline-picker');

    const pmolCopyState = useSelector((state: any) => state.mydPlan.pmolCopyState);
    const showProgressBar = useSelector((state: any) => state.pmol.showProgressBar);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
    const [restrictedDates, setRestrictedDates] = useState<Date[]>([]);

    const titleId = useId('title');
    const [isaddTeamOrPersonModalOpen, setAddTeamOrPersonModalOpen] = React.useState<boolean>(false);
    const cpcNodeInfo = useSelector((state: any) => state.vp.cpcNodeInfo);
    const taxonomyList = useSelector((state: any) => state.os.taxonomyList);
    const [selectedPersonIds, setSelectedPersonIds] = React.useState<any[]>([]);
    const [selectNodeIds, setSelectNodeIds] = React.useState<any[]>([]);
    const [selectedFormanList, setSelectedFormanList] = useState<any[]>([]);
    // const replaceValues = [" (Person)"," (Team)"," (CU)"," (BU)"," (Team)"," (Department)"];
    useEffect(() => {

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.openTaxonomyModal) {

                }
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (pmolCopyState?.isCopyModalOpen) {
            setIsOpen(true);
            setEvent(pmolCopyState.event);
            // console.log('event >', pmolCopyState.event);
        } else {
            setIsOpen(false);
            setEvent({});
        }
    }, [pmolCopyState]);

    useEffect(() => {
        if (selectNodeIds.length > 0) {
          const pbsAssignedLabourNodes = taxonomyList
            ?.filter((item: any) => selectNodeIds.includes(item.id) && item?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON)
            .map((item: any) => ({
              ...item,
              title: item?.title?.replace(" (Person)", ""),
              type: "PbsAssignedLabour",
              cpcId: cpcNodeInfo?.cpcId,
            }));
          dispatch(saveVPStateAttr('pbsAssignedLabourNodes', pbsAssignedLabourNodes));
        }
      }, [selectNodeIds]);
    
      useEffect(() => {
        dispatch(getTaxonomyList(new OrganisationSettingListPaneFilter()));
      }, []);
    
      const clearSelections = () => {
        dispatch(saveVPStateAttr('isOrgTaxonomyModalOpen', false));
        setSelectedPersonIds([]);
        setSelectNodeIds([]);
        setSelectedFormanList([]);
      }

      const closeAddTeamOrLabourModal = () => {
        setAddTeamOrPersonModalOpen(false)
      }

      const removeInvalidString = (title: string) =>{
        if(title.includes(" (Person)")){
            return title.replace(" (Person)", "");
        }
        else if(title.includes(" (CU)")){
            return title.replace(" (CU)", "");
        }
        else if(title.includes(" (BU)")){
            return title.replace(" (BU)", "");
        }
        else if(title.includes(" (Team)")){
            return title.replace(" (Team)", "");
        }
        else if(title.includes(" (Department)")){
            return title.replace(" (Department)", "");
        }
        else{
            return title;
        }
      }
    
      const renderButtons = (rowInfo: any) => {
        const isParent = (rowInfo.node.id);
        const isPerson = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON;
        const isTeam = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM;
    
        if (isPerson || isTeam) {
          return ([
            <div
              key={rowInfo.node.id}
              className={'Indeterminate'}
              style={{ paddingTop: 6 }}
            >
                <span
                  style={{ display: 'none' }}>{JSON.stringify(isParent)}</span> {/* if u remove this part parent selection not working properly   */}
              <Checkbox
                className='btn btn-outline-success'
                styles={{ root: { verticalAlign: 'middle' } }}
                checked={selectNodeIds.includes(rowInfo.node.id)}
                onChange={(_ev, checked) => {
                  if (isTeam) {
                    const teamMembers = rowInfo?.node?.children || [];
                    if (checked) {
                      const allTeamMemberIds = teamMembers.map((item: any) => {
                        return {
                          personId: item.personId,
                          parentId: item.parentId,
                          isTemporaryTeam: false
                        }
                      });
                      const selectedNodeIds = teamMembers.map((item: any) => item.id);
                      setSelectedPersonIds(allTeamMemberIds);
                      setSelectNodeIds([...selectedNodeIds, rowInfo.node.id]); // Person nodes + Team node
                      const foreManList = teamMembers.map((item: any) => 
                          ({
                            id: item.id,
                            name: item.title?removeInvalidString(item.title):""
                        }));
                        setSelectedFormanList(foreManList);
                    } else {
                      setSelectedPersonIds([]);
                      setSelectNodeIds([]);
                      setSelectedFormanList([]);
                    }
                  }
    
                  if (isPerson) {
                    if (checked) {
                      const personId = {
                        personId: rowInfo?.node?.personId,
                        parentId: rowInfo?.node?.parentId,
                        isTemporaryTeam: true
                      };
                      const itemId = rowInfo?.node?.id;
                      setSelectedPersonIds([...selectedPersonIds, personId]);
                      setSelectNodeIds([...selectNodeIds, itemId]);
                      const foreMan = {id: personId, name: rowInfo.node.title?removeInvalidString(rowInfo.node.title):""};
                      setSelectedFormanList([...selectedFormanList, foreMan]);
                    } else {
                      const personId = rowInfo?.node?.personId;
                      const itemId = rowInfo?.node?.id;
                      setSelectedPersonIds(selectedPersonIds.filter((id: any) => id !== personId));
                      setSelectNodeIds(selectNodeIds.filter((id: any) => id !== itemId));
                      setSelectedFormanList(selectedFormanList.filter((item:any)=> item.id !== itemId));
                    }
                  }
                }}
              />
            </div>,
          ]);
        }
      };
      
    const getInitialFormValues = () => {
        return {
            // projectName: {value: null, label: null},
        }
    }

    const onSubmit = async (values: any) => {
      const formattedDates = restrictedDates.map((date: any) => {
        return formatDateTimeToISOString(date)
      });

      const dateArray: string[] = formattedDates.filter((date: string | null): date is string => date !== null).map((date: string | null) => date as string);
      if (values) {
        const data = {
          pmolId: event?.projectMoleculeId,
          contractingUinit: event?.contractingUinit,
          projectSequenceCode: event?.projectSequenceCode,
          typeId: event?.typeId,
          executionDate: dateArray,
          foremanlist:selectedPersonIds
        };
        client.post(CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP, data,{
          headers: {
            Cu: event?.contractingUinit,
            Project: event?.projectSequenceCode,
          }
        }).then(
          (res: any) => {
            if (res?.data?.result) {
              handleClose();
            }
          },
      
          (error: any) => {
            alert(error);
          },
        ).catch((err : any) =>{
          alert(err);
        });

          // await Promise.all([
          //     dispatch(createPmolCloneForMultipleDays(data))
          // ]).then((res: any) => {
          //     if (res[0]?.payload?.data?.result) {
          //         handleClose();
          //     }
          // }).catch((error:any)=>{
              // clearSelections();
          // });
      }
    };

    const formRef = React.useRef(createForm({onSubmit: onSubmit}));

    const handleClose = () => {
        clearSelections();
        setRestrictedDates([]);
        setSelectedItems([]);
        dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {event: {}, isCopyModalOpen: false}));
    }

    const onSelectDate = (date: Date): void => {
        restrictedDates.push(date);
        const tagList: ITag[] = restrictedDates.map(item => ({
            key: moment(item).format('DD/MM/YYYY'),
            name: moment(item).format('DD/MM/YYYY'),
            date: item
        }));

        setRestrictedDates(restrictedDates);
        setSelectedItems(tagList);
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested tags',
        noResultsFoundText: 'No color tags found',
    };

    const getTextFromItem = (item: ITag) => item.name;

    const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
        return [];
    };

    const onChangeTags = (items: any) => {
        const dates = items.map((item: any) => item.date);

        setSelectedItems(items);
        setRestrictedDates(dates);
    }

    const addTeamOpen = () => {
        setAddTeamOrPersonModalOpen(true);
        // dispatch(saveVPStateAttr('cpcNodeInfo', []))
        // dispatch(saveVPStateAttr('expandPath', []))
    }

    const onRenderFooter = () => {
        return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DefaultButton
            text={t('selectForeman')}
            styles={selectFormanStyles}
            onClick={() => addTeamOpen()}
            >
                <FontIcon
                aria-label="People"
                iconName="People"
                style={{margin: 5, cursor: 'pointer'}}
                />
            </DefaultButton>
            <div className={'footer-btn'}>
                <DefaultButton onClick={handleClose}>
                    {t('cancel')}
                </DefaultButton>
                <PrimaryButton type="submit" form={"copy-pmol-form"} disabled={restrictedDates.length==0}>
                    {t('copy')}
                </PrimaryButton>
            </div>
        </div>
    }

    const removeForeman = (id: string) =>{
        const updatedList = selectedFormanList.filter((item) => item.id !== id);
        setSelectedFormanList(updatedList);
    }

    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DefaultCustomModal
            isModalOpen={isOpen}
            onRenderFooter={onRenderFooter}
            title={t('clonePmol')}
            handleClose={handleClose}
            showProgressBar={showProgressBar}
            styles={styles}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={getInitialFormValues()}
                // mutators={{
                //     setProjectName: (args, state) => {
                //         const field = state.fields.projectName;
                //         field.change(args[0]);
                //     },
                // }}
                validate={values => {
                  const errors: any = {};
                  if (_.isEmpty(restrictedDates)) {
                    errors.selectedDates = t('cloneDatesErrMsg');
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  errors,
                  values,
                }) => {
                    formRef.current = form;
                    return <form onSubmit={handleSubmit} id="copy-pmol-form" noValidate style={{width: '100%'}}>
                        <div>
                            <Field
                                name="calendar"
                            >{({input, meta}) => (
                                <Calendar
                                    highlightSelectedMonth
                                    showGoToToday={false}
                                    restrictedDates={restrictedDates}
                                    onSelectDate={onSelectDate}
                                    strings={defaultCalendarStrings}
                                    firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                />
                            )}
                            </Field>
                            <Field
                                name="selectedDates"
                            >{({input, meta}) => (
                              <div>
                                <Label htmlFor={pickerId} required={true}>{t('selectedDates')}</Label>
                                <TagPicker
                                  removeButtonAriaLabel="Remove"
                                  selectionAriaLabel="Selected colors"
                                  onResolveSuggestions={filterSuggestedTags}
                                  getTextFromItem={getTextFromItem}
                                  pickerSuggestionsProps={pickerSuggestionsProps}
                                  disabled={false}
                                  selectedItems={selectedItems ?? []}
                                  onChange={onChangeTags}
                                  inputProps={{
                                      id: pickerId,
                                      readOnly: true,
                                  }}
                                  styles={{
                                      input: {
                                          display:  'none',
                                      },
                                      itemsWrapper:{
                                          maxWidth: '24vw',
                                      }
                                  }}
                                />
                                {((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched &&
                                    <ValidationLabel validationMessage={meta.error}/>
                                }
                              </div>
                            )}
                            </Field>

                            <Field
                                name="selectedForeman"
                            >{({input, meta}) => (
                                <div>
                                    <Label style={{marginTop:'2%'}}>{t('selectedForeman')}</Label>
                                    <div style={{ border: '1px solid black', borderRadius: '2px', overflowX:'hidden', overflowY:'auto', maxWidth:'24vw' }}>
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} style={{display:'flex', flexWrap:'wrap', minHeight:'30px'}}>
                                            {selectedFormanList.map((foreman, index) => (
                                            <Stack.Item key={index} style={{background:'rgb(243, 242, 241)', margin:'2px'}}>
                                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                <span style={{marginLeft:'8px'}}>{foreman.name}</span>
                                                <IconButton
                                                    iconProps={{ iconName: 'Cancel' }}
                                                    title="Remove"
                                                    ariaLabel="Remove"
                                                    style={{paddingLeft:'8px'}}
                                                    styles={{
                                                        icon: { fontSize: '12px' }
                                                    }}
                                                    onClick={() => removeForeman(foreman.id)}
                                                />
                                                </Stack>
                                            </Stack.Item>
                                            ))}
                                        </Stack>
                                    </div>
                                </div>
                            )}
                            </Field>

                            
                        </div>

                        {/*<div style={{padding: 10}}>{JSON.stringify(values, null, 2)}</div>*/}
                    </form>
                }}
            />
        </DefaultCustomModal>

        <Modal
          titleAriaId={titleId}
          isOpen={isaddTeamOrPersonModalOpen}
          onDismiss={() => 
            setAddTeamOrPersonModalOpen(false)
          }
          isBlocking={true}
          containerClassName={contentStyles.container}
          dragOptions={dragOptions}
        >
          <div className={contentStyles.header}>
            <span id={titleId}>{t('selectForeman')}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel='Close popup modal'
              onClick={() => closeAddTeamOrLabourModal()}
            />
          </div>
          <div style={{ padding: 10, height: 700 }}>
            <TaxonomyGrid
              treeData={taxonomyList ?? []}
              renderButtons={renderButtons}
            />
          </div>
          <div className={contentStyles.footer}>
            <PrimaryButton
              iconProps={cancelIcon}
              text={t('close')}
              style={{ marginTop: 10, marginBottom: 5 }}
              onClick={() => {
                // dispatch(addMidTermLabourTeam({
                //   cabPersonId: selectedPersonIds,
                //   cpcId: cpcNodeInfo?.cpcId,
                //   cu: cpcNodeInfo?.cu,
                //   pbsProduct: cpcNodeInfo?.parentId,
                //   project: cpcNodeInfo?.project,
                // }));
                // clearSelection();
                closeAddTeamOrLabourModal();
                // console.log(selectedFormanList);
              }}
              disabled={selectedPersonIds.length === 0}
            />
          </div>
        </Modal>
    </div>
}
