import { mergeStyleSets } from '@fluentui/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';

const classNames = mergeStyleSets({
  container: {
    width: 'calc(100% - 16px)',
    padding: 8
  },
  table: {
    width: '100%', 
    borderCollapse: 'collapse'
  },
  th: {
    padding: '8px',
    color: '#fff',
  },
  td: {
    padding: '8px',
    textAlign: 'center',
    border: '1px solid #ccc',
  },
  highlightRow: {
    background: '#bebebe60'
  },
  highlightTd: {
    padding: '8px',
    border: '1px solid #ccc',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tr: {
    backgroundColor: uPrinceTheme.palette.themePrimary,
    borderRadius: 8,
  }
})

const POSHoverList = (props: {
  pos: any;
  list: any[]
}) => {
  const {t} = useTranslation();

  return (
    <div className={classNames.container}>
      <table className={classNames.table}>
        <thead>
          <tr className={classNames.tr}>
            <th className={classNames.th}>{t('statusOrder')}</th>
            <th className={classNames.th}>{t('status')}</th>
          </tr>
        </thead>
        <tbody>
          {props.list?.map(item => (
            <tr key={item.key} className={item.displayOrder == props.pos ? classNames.highlightRow : ""}>
              <td className={item.displayOrder == props.pos ? classNames.highlightTd : classNames.td}>{item.displayOrder}</td>
              <td className={item.displayOrder == props.pos ? classNames.highlightTd : classNames.td}>{item.text}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default POSHoverList